import { SNAPPING_ERROR_TOLERANCE } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import {
  BACKGROUND_COLOR,
  DEFAULT_LABEL_COLOR,
  HOVER_COLOR,
  HOVER_OUTLINE_COLOR,
  PRIMARY_COLOR,
} from '../../../../constants/colors'
import { InputLabel } from './InputLabel'

export class AngleLabel extends InputLabel {
  private readonly arc: paper.Path
  private readonly text: paper.PointText
  private highlighted = false

  public isClickable = true

  public get origin(): paper.Point {
    return this.center.add(this.center.subtract(this.through))
  }

  public get arcCenterPoint(): paper.Point {
    const centerVector = this.through.subtract(this.origin)
    return this.origin.add(centerVector.normalize(centerVector.length / 2))
  }

  public get inputOverlayPosition(): paper.Point {
    return this.arcCenterPoint
  }

  public hide(): void {
    this.text.visible = false
  }

  public hover(): void {
    this.arc.fillColor = HOVER_COLOR
    this.arc.strokeColor = HOVER_OUTLINE_COLOR
  }

  public unhover(): void {
    this.arc.fillColor = BACKGROUND_COLOR
    this.arc.strokeColor = DEFAULT_LABEL_COLOR
  }

  constructor(
    public readonly angle: number,
    from: paper.Point,
    private through: paper.Point,
    to: paper.Point,
    private readonly center: paper.Point,
    selectionPoint: paper.Point | null,
    fontSize: number,
    strokeWidth: number
  ) {
    super()
    const arc = new paper.Path.Arc(from, through, to)
    arc.add(this.origin)
    arc.add(from)
    arc.strokeColor = DEFAULT_LABEL_COLOR
    arc.fillColor = BACKGROUND_COLOR
    this.arc = arc

    const text = new paper.PointText({
      point: center,
      content: `${parseFloat((Math.round(angle * 10) / 10).toFixed(1))}°`,
      fillColor: DEFAULT_LABEL_COLOR,
      justification: 'center',
    })
    text.bounds.center = center
    this.text = text
    this.addChildren([arc, text])

    this.arc.strokeWidth = strokeWidth * (this.highlighted ? 3 : 1)
    this.text.fontSize = fontSize

    const isSelected =
      selectionPoint != null && this.origin.isClose(selectionPoint, SNAPPING_ERROR_TOLERANCE)

    if (isSelected) {
      this.highlighted = true
      this.arc.strokeWidth *= 3
      this.arc.strokeColor = PRIMARY_COLOR
      this.text.fillColor = PRIMARY_COLOR
    }
  }
}
