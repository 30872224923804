<ion-header>
  <ion-toolbar>
    <ion-row>
      <ion-col size="3">
        <ion-buttons>
          <ion-button
            (click)="closeModal()"
            fill="clear"
            slot="start"
            data-test-selected="result-2d-viewer-pdf-export-close">
            <ion-icon slot="icon-only" name="close" color="primary"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-buttons>
      </ion-col>
      <ion-col size="6">
        <ion-title class="ion-text-center">
          <div class="ion-text-wrap">
            {{ 'SCREENSHOT.CREATE_PDF' | translate }}
          </div>
        </ion-title>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item-divider>
    <div>{{ 'SCREENSHOT.ADD_TO_PDF' | translate }}</div>
  </ion-item-divider>

  <ion-list *ngIf="screens">
    <ion-item>
      <ion-label>
        <div>{{ 'STOCK.RESULT.PIECE_LIST' | translate }}</div>
      </ion-label>
      <ion-toggle color="primary" [(ngModel)]="includeTotalStock"></ion-toggle>
    </ion-item>

    <ion-item *ngIf="showStockPerCycle">
      <ion-label>{{ 'SCREENSHOT.STOCK_PER_CYCLE' | translate }}</ion-label>
      <ion-toggle color="primary" [(ngModel)]="includeCycleStock"></ion-toggle>
    </ion-item>

    <ion-item-divider id="dividerOrder">
      <div>{{ 'SCREENSHOT.SCREENSHOT_ORDER' | translate }}</div>
    </ion-item-divider>

    <div *ngFor="let screen of screens">
      <ion-item>
        <ion-checkbox slot="start" [(ngModel)]="screen.useInPdf"></ion-checkbox>
        <ion-img
          [src]="screen.screenshot"
          class="ion-margin-end ion-margin-vertical screenImage"
          color="primary">
        </ion-img>
        <div>
          <ion-label class="ion-font-bold screenName">{{ screen.name }}</ion-label>
          <ion-label class="ion-date ion-ma-top-5" id="screenDate"
            >{{ 'SCREENSHOT.CREATED' | translate }} {{ screen.date | efpDate }}</ion-label
          >
        </div>
      </ion-item>
    </div>
  </ion-list>
</ion-content>

<!-- button sometimes invisble on android -> position absolte workaround instead of sticky -->
<ion-button
  id="btnStyle"
  (click)="exportPDF()"
  expand="block"
  [disabled]="!hasIncludedScreens && !includeTotalStock"
  class="ion-text-center btnStyle btn-workraound">
  {{ 'SCREENSHOT.PDF_EXPORT' | translate }}
</ion-button>

<div class="btnStyle-overscroll-placeholder"></div>
