<div
  id="measurement-legend-wrapper"
  class="d-flex"
  *ngIf="isInitialized()"
  [ngClass]="{
    additionalSpacing: additionalSpacingBottom,
    'screenshot-modal-view': screenshotModalView,
    'background-white': result,
    'remove-absolute-right': result
  }">
  <div id="measurement-legend">
    <div *ngIf="isWall()" class="d-flex">
      <ion-item lines="none">
        <ion-icon src="assets/icon/wall-height-planner.svg"></ion-icon>
        <ion-label>{{ convertValue(wallHeight) }}</ion-label>
      </ion-item>
    </div>
    <div *ngIf="!isWall()" class="d-flex">
      <ion-item lines="none">
        <ion-icon src="assets/icon/slab-thickness-planner.svg"></ion-icon>
        <ion-label>{{ convertValue(slabThickness) }}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-icon src="assets/icon/slab-top-level-planner.svg"></ion-icon>
        <ion-label>{{ convertValue(slabHeight) }}</ion-label>
      </ion-item>
    </div>
    <div class="d-flex">
      <ion-item lines="none">
        <ion-icon src="assets/icon/measurement-unit-planner.svg"></ion-icon>
        <ion-label
          >{{ measurementUnitPlanner === 'inch' ? 'ft. in.' : measurementUnitPlanner }}</ion-label
        >
      </ion-item>
      <ion-item lines="none" *ngIf="showArticleMeasurementUnit()">
        <ion-icon src="assets/icon/measurement-unit-article.svg"></ion-icon>
        <ion-label>{{ translateMeasurementUnit(measurementUnitArticle) }}</ion-label>
      </ion-item>
    </div>
  </div>
</div>
