import { Component, HostListener, Input } from '@angular/core'
import { Mesh, PlannerStateService } from 'formwork-planner-lib'
import { ZoomAndPanService } from '../../../../services/zoom-and-pan.service'
import { Model } from '../../model/Model'
import { EFP_WEB_BREAK_POINT } from '../../../../constants/layout'
import { isSelectorPresented } from '../../../../shared/directives/keyboard-shortcut.directive'

@Component({
  selector: 'efp-center-button-view',
  templateUrl: 'center-button-view.component.html',
  styleUrls: ['center-button-view.component.scss'],
})
export class CenterButtonViewComponent {
  @Input() model?: Model<Mesh>

  private lastMouseWheelClickTime = 0

  constructor(
    private readonly picasso: PlannerStateService,
    private readonly zoomAndPanService: ZoomAndPanService
  ) {}

  @HostListener('window:mousedown', ['$event'])
  onMouseWheelClick(event: MouseEvent): void {
    // 4 -> wheel
    if (event.buttons === 4) {
      if (
        this.lastMouseWheelClickTime &&
        new Date().getTime() - this.lastMouseWheelClickTime < 500
      ) {
        this.centerView()
      }
      this.lastMouseWheelClickTime = new Date().getTime()
    }
  }

  @HostListener('window:keydown.c')
  centerView(): void {
    if (isSelectorPresented('efp-feedback')) return
    // get actual Height
    const bottomContainerElements = document.querySelectorAll<HTMLElement>(
      'efp-bottom-menu-container'
    )

    if (EFP_WEB_BREAK_POINT > window.innerWidth) {
      this.zoomAndPanService.centerView(this.picasso, this.model, {
        marginTop:
          bottomContainerElements[
            bottomContainerElements.length > 0 ? bottomContainerElements.length - 1 : 0
          ].offsetHeight,
      })
    } else {
      this.zoomAndPanService.centerView(this.picasso, this.model, {
        marginLeft:
          bottomContainerElements[
            bottomContainerElements.length > 0 ? bottomContainerElements.length - 1 : 0
          ].offsetWidth,
      })
    }
  }
}
