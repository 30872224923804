import { ProjectCommandParams } from '../../../generated/efp-api'
import { Project } from '../../models/project'

export abstract class ProjectDao {
  abstract findAllByFavouriteId(favouriteId: number): Promise<Project[]>
  abstract findOne(projectId: number, includePlans: boolean): Promise<Project | undefined>
  abstract findAll(includePlans: boolean): Promise<Project[]>
  abstract findAllByStockId(stockId: number): Promise<Project[]>
  abstract create(params: ProjectCommandParams): Promise<number>
  abstract update(project: Project): Promise<void>
  abstract delete(project: Project): Promise<void>
}
