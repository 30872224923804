import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { checkIfMobileLayout } from '../../constants/layout'
import { Capacitor } from '@capacitor/core'

export enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

@Directive({
  selector: '[efpTooltip]',
})
export class TooltipDirective {
  @Input() tooltipTitle = ''
  @Input() placement:
    | TooltipPosition.BOTTOM
    | TooltipPosition.TOP
    | TooltipPosition.LEFT
    | TooltipPosition.RIGHT = TooltipPosition.RIGHT

  @Input() overflowContainer?: HTMLElement
  tooltip!: HTMLElement
  offset = 5

  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.checkIfContainerOverflows() || !this.overflowContainer) this.show()
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.tooltip) {
      this.hide()
    }
  }

  show(): void {
    if (checkIfMobileLayout() || Capacitor.isNativePlatform()) return
    if (!this.tooltip) {
      this.create()
    }
    document.body.appendChild(this.tooltip)
    this.setPosition()
  }

  hide(): void {
    this.tooltip.remove()
  }

  create(): void {
    this.tooltip = document.createElement('div')
    const contentDiv = document.createElement('div')
    contentDiv.innerHTML = this.tooltipTitle
    this.tooltip.appendChild(contentDiv)
    this.tooltip.classList.add('tooltip')
  }

  checkIfContainerOverflows(): boolean {
    const elementRect = this.el.nativeElement.getBoundingClientRect()
    const overflowContainerEl = this.overflowContainer?.getBoundingClientRect()
    if (overflowContainerEl) {
      return overflowContainerEl?.width < elementRect.width
    } else return false
  }

  setPosition(): void {
    const elementRect = this.el.nativeElement.getBoundingClientRect()
    const tooltipRect = this.tooltip.getBoundingClientRect()

    let left, top

    switch (this.placement) {
      case 'top':
        top = elementRect.top - tooltipRect.height - this.offset
        left = elementRect.left + (elementRect.width - tooltipRect.width) / 2
        this.tooltip.classList.add('tooltip-top')
        break
      case 'bottom':
        top = elementRect.bottom + this.offset
        left = elementRect.left + (elementRect.width - tooltipRect.width) / 2
        this.tooltip.classList.add('tooltip-bottom')
        break
      case 'left':
        top = elementRect.top + (elementRect.height - tooltipRect.height) / 2
        left = elementRect.left - (tooltipRect.width + this.offset)
        this.tooltip.classList.add('tooltip-left')
        break
      case 'right':
        top = elementRect.top + (elementRect.height - tooltipRect.height) / 2
        left = elementRect.right + this.offset
        this.tooltip.classList.add('tooltip-right')
        break
    }

    this.tooltip.style.top = `${top}px`
    this.tooltip.style.left = `${left}px`
  }
}
