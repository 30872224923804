<ion-note class="d-flex article-cycle-note">
  <span class="title">{{ 'CYCLE.ARTICLE.TITLE' | translate }}: </span>
  <div class="d-flex cycle-count-container">
    <div
      class="d-flex cycle-count-bubble"
      [style.background-color]="getCycleColor(cycleNumber)"
      *ngFor="let cycleNumber of sortedCycleNumbers">
      <span>{{ article.cycleUsage ? article.cycleUsage[cycleNumber - 1] : 0 }}</span>
    </div>
  </div>
</ion-note>
