import { Injectable } from '@angular/core'
import { Part } from '../models/part'
import { PartListItem } from '../pages/result/components/part-list/model/partListItem'
import { ChangedResultPartRepository } from '../repositories/changed-result-part.repository'

export interface ChangedResultPart {
  id: number
  planId: number
  articleId: string
  amount: number
}

@Injectable({
  providedIn: 'root',
})
export class PartListService {
  constructor(private readonly changedResultPartRepository: ChangedResultPartRepository) {}

  /**
   * Returns a list of {@link PartListItem} of any parts where the amount to order was changed from the calculated part amount.
   * @param planId The plan for which to load all changed parts.
   * @param partsList List of all parts for a plan.
   */
  public async loadChangedResultPartListForPlan(
    planId: number,
    partsList: Part[]
  ): Promise<PartListItem[]> {
    // TODO Passing partsList here is problematic, it would be best to just load it from the DB
    const allParts = partsList.map((it) => new PartListItem(it))
    const changedParts = await this.changedResultPartRepository.findAllByPlanId(planId)
    const changedResultPartList: PartListItem[] = []

    changedParts.forEach((changedPart) => {
      const partListItem = allParts.find((item) => item.part.articleId === changedPart.articleId)

      if (partListItem && partListItem.part.demand !== changedPart.amount) {
        const changedPartItem = new PartListItem(partListItem.part)
        changedPartItem.orderAmount = changedPart.amount
        changedResultPartList.push(changedPartItem)
      }
    })

    return changedResultPartList
  }

  /**
   * Updates the amounts in the given part list based on changed made by the user for the given plan.
   * @param planId The plan for which changed part amounts should be loaded.0
   * @param partList The list of plans, which should be updated.
   */
  public async updatePartListForPlan(planId: number, partList: Part[]): Promise<Part[]> {
    const changedPartList = await this.changedResultPartRepository.findAllByPlanId(planId)
    changedPartList.forEach((changedPart) => {
      const partInList = partList.find((it) => it.articleId === changedPart.articleId)
      if (partInList) {
        partInList.amount = changedPart.amount
      }
    })

    return partList
  }
}
