<efp-modal-form>
  <ng-container
    *ngIf="
      this.onboardingSeriesKeysOfPage && this.onboardingSeriesKeysOfPage.length > 0;
      else resetAllModal
    ">
    <div class="form-container label-container">
      <ion-title>
        {{ 'ONBOARDING_RESET_WITH_OPTIONS.TITLE' | translate }}
      </ion-title>
    </div>
    <form [formGroup]="resetOnboardingTrackingForm" (ngSubmit)="onSubmit()">
      <div class="p-left-10 p-right-10">
        <ion-radio-group formControlName="resetOptions">
          <ion-item lines="none">
            <div class="basic-label">
              {{ 'ONBOARDING_RESET_WITH_OPTIONS.REACTIVATE_ALL' | translate }}
            </div>
            <ion-radio slot="start" [value]="this.resetOptions.RESET_ALL"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <div class="basic-label">
              {{ 'ONBOARDING_RESET_WITH_OPTIONS.REACTIVATE_PAGE' | translate }}
            </div>
            <ion-radio slot="start" [value]="resetOptions.RESET_SPEC"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>
      <div class="d-flex gap-4 button-wrapper form-container">
        <ion-button
          (click)="closeModal()"
          fill="outline"
          expand="block"
          class="expandable-btn flex-1"
          data-test-selector="favourites-profile-favourite-create-or-update"
          >{{ 'GENERAL.CANCEL' | translate }}
        </ion-button>
        <ion-button
          type="submit"
          expand="block"
          class="ion-text-center flex-1"
          data-test-selector="favourites-profile-favourite-create-or-update">
          {{ 'ONBOARDING_RESET_WITH_OPTIONS.APPLY' | translate }}
        </ion-button>
      </div>
    </form>
  </ng-container>
  <ng-template #resetAllModal>
    <div class="form-container label-container">
      <ion-title>
        {{ 'ONBOARDING_RESET_WITHOUT_OPTIONS.TITLE' | translate }}
      </ion-title>
    </div>
    <div class="d-flex gap-4 button-wrapper form-container">
      {{ 'ONBOARDING_RESET_WITHOUT_OPTIONS.REACTIVATE_ALL' | translate }}
    </div>
    <div class="d-flex gap-4 button-wrapper form-container">
      <ion-button
        (click)="closeModal()"
        fill="outline"
        expand="block"
        class="expandable-btn flex-1"
        data-test-selector="favourites-profile-favourite-create-or-update"
        >{{ 'GENERAL.CANCEL' | translate }}
      </ion-button>
      <ion-button
        (click)="resetAllOnboardingTrackings()"
        expand="block"
        class="ion-text-center flex-1"
        data-test-selector="favourites-profile-favourite-create-or-update"
        >{{ 'ONBOARDING_RESET_WITHOUT_OPTIONS.CONFIRM' | translate }}
      </ion-button>
    </div>
  </ng-template>
</efp-modal-form>
