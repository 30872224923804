import { Injectable } from '@angular/core'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { environment } from '../../environments/environment'
import { DeployEnvironment } from '../models/deploy-environment'
import { AppSettingsRepository } from '../repositories/app-settings.repository'
import { PlanSettingsRepository } from '../repositories/plan-settings.repository'
import { PlanType } from '../shared/formwork-planner/model/PlanType'

export enum ApplicationInsightsStates {
  PLAN_STEP_CHANGED = 'PLAN_STEP_CHANGED',
  PLAN_CREATED = 'PLAN_CREATED',
  CALCULATION_STARTED = 'CALCULATION_STARTED',
  FORMWORK_SYSTEM = 'FORMWORK_SYSTEM',
  COUNTRY = 'COUNTRY',
  PLAN_SHARED = 'PLAN_SHARED',
  PIECELIST_SHARED = 'PIECELIST_SHARED',
  SHOP_CLICKED = 'SHOP_CLICKED',
  PDF_CREATED = 'PDF_CREATED',
  PLAN_DELETED = 'PLAN_DELETED',
  VIEWER_ERROR = 'VIEWER_ERROR',
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL_USER = 'EXTERNAL_USER',
  CALCULATION_ERROR = 'CALCULATION_ERROR',
  CALCULATION_WARNING = 'CALCULATION_WARNING',
  CRITICAL_ERROR = 'CRITICAL_ERROR',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  public appInsights?: ApplicationInsights

  constructor(
    private readonly appSettingRepo: AppSettingsRepository,
    private readonly planSettingsRepo: PlanSettingsRepository
  ) {}

  public enableApplicationInsights(): void {
    // Don't track during development
    if (environment.deployment !== DeployEnvironment.LOCAL) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsightsConnectionString,
        },
      })
      this.appInsights.loadAppInsights()
      this.appInsights.trackPageView()
    }
  }

  public addUserEvent(activity: string, planId?: number): void {
    this.appInsights?.trackEvent({ name: 'EFP Event', properties: { activity, planId } })
  }

  public async trackCalculation(
    planId: number,
    planSettingsId: number,
    buildingType: PlanType
  ): Promise<void> {
    const appSettings = await this.appSettingRepo.getAppSettings()
    const plansettings = await this.planSettingsRepo.findOneById(planSettingsId)

    this.addUserEvent(
      ApplicationInsightsStates.CALCULATION_STARTED +
        ' ' +
        ApplicationInsightsStates.FORMWORK_SYSTEM +
        ' ' +
        (buildingType === PlanType.WALL
          ? plansettings?.formworkWall ?? ''
          : (plansettings?.formworkSlab ?? '') +
            ' ' +
            ApplicationInsightsStates.COUNTRY +
            ' ' +
            appSettings.country),
      planId
    )
  }
}
