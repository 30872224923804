<div
  class="drag-drop-container"
  [ngClass]="{ fileHover: fileHover }"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
  (click)="pickFile()">
  <span>{{ 'STOCK.EMPTY_STOCK_LIST' | translate }}</span>
  <span>{{ 'STOCK.EMPTY_STOCK_LIST_ACTION' | translate }}</span>
</div>
