import { Injectable } from '@angular/core'
import { ChangedResultPart } from '../part-list-service'
import { ChangedResultPartsCommandParam } from '../../../generated/efp-api'

@Injectable()
export abstract class ChangedResultPartDao {
  abstract findAllByPlanId(planId: number): Promise<ChangedResultPart[]>

  abstract deleteAllByPlanId(planId: number): Promise<void>

  abstract deleteOldAndSaveNewByPlanId(
    planId: number,
    partListParams: ChangedResultPartsCommandParam[]
  ): Promise<ChangedResultPart[]>
}
