import { Injectable, inject } from '@angular/core'
import { ScreenshotModel, ScreenshotsApiClient } from '../../../../generated/efp-api'
import { ScreenshotDao, mapModelToScreenshot } from '../screenshot.dao'
import { Screenshot } from '../../../models/screenshot'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class ScreenshotHttpDao extends ScreenshotDao {
  private readonly screenshotApiClient = inject(ScreenshotsApiClient)

  async create(screen: ScreenshotModel): Promise<Screenshot> {
    const result = await firstValueFrom(
      this.screenshotApiClient.sendAddScreenshotEntry({
        body: {
          cycle: screen.cycle,
          defaultView: screen.defaultView,
          height: screen.height,
          name: screen.name,
          planId: screen.planId,
          date: new Date(screen.date).toISOString(),
          width: screen.width,
          screenshotData: screen.screenshotData,
        },
      })
    )

    const model = {
      ...screen,
      id: result,
    }
    return mapModelToScreenshot(model)
  }

  async findAllByPlanId(planId: number): Promise<Screenshot[]> {
    const result = await firstValueFrom(this.screenshotApiClient.getScreenshotsByPlan({ planId }))
    return result.map((model) => mapModelToScreenshot(model))
  }

  async delete(screenId: number): Promise<void> {
    await firstValueFrom(this.screenshotApiClient.deleteScreenshot({ screenshotId: screenId }))
  }

  async deleteAllByPlanId(planId: number): Promise<void> {
    await firstValueFrom(this.screenshotApiClient.deleteScreenshotsByPlan({ planId }))
  }

  async updateName(id: number, name: string): Promise<void> {
    await firstValueFrom(
      this.screenshotApiClient.updateScreenshot({ screenshotId: id, body: { name } })
    )
  }
}
