import { Injectable } from '@angular/core'
import { Subscription } from 'rxjs'
import { UndoRedoHistory } from '../models/history/undoRedoHistory'
import { AccessoryUndoRedoHistory } from '../pages/accessory/model/AccessoryUndoRedoHistory'
import { CycleUndoRedoHistory } from '../pages/cycles/model/cycleUndoRedoHistory'

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private planId?: number

  private planModelHistory?: UndoRedoHistory<unknown>
  private cycleHistory?: CycleUndoRedoHistory
  private accessoryHistory?: AccessoryUndoRedoHistory

  private planHistorySubscription?: Subscription

  constructor() {}

  setPlanHistory(newPlanHistory: UndoRedoHistory<unknown>, planId: number): void {
    if (this.planId !== planId) {
      this.cycleHistory = undefined
    }
    this.planId = planId
    this.planModelHistory = newPlanHistory

    this.planHistorySubscription?.unsubscribe()
    this.planHistorySubscription = this.planModelHistory.historyChanged.subscribe(() => {
      this.cycleHistory = undefined
      this.accessoryHistory = undefined
    })
  }

  setCycleHistory(newCycleHistory: CycleUndoRedoHistory, planId: number): void {
    if (this.planId !== planId) {
      this.planHistorySubscription?.unsubscribe()
      this.planModelHistory = undefined
    }
    this.planId = planId
    this.cycleHistory = newCycleHistory
  }

  setAccessoryHistory(newAccessoryHistory: AccessoryUndoRedoHistory, planId: number): void {
    if (this.planId !== planId) {
      this.planHistorySubscription?.unsubscribe()
      this.planModelHistory = undefined
      this.cycleHistory = undefined
    }
    this.planId = planId
    this.accessoryHistory = newAccessoryHistory
  }

  getPlanHistory<T>(planId: number): UndoRedoHistory<T> | undefined {
    if (this.planId !== planId) {
      return
    }

    if (this.planModelHistory !== undefined) {
      return this.planModelHistory as UndoRedoHistory<T>
    }

    return
  }

  getCycleHistory(planId: number): CycleUndoRedoHistory | undefined {
    if (this.planId !== planId) {
      return
    }
    return this.cycleHistory
  }

  getAccessoryHistory(planId: number): AccessoryUndoRedoHistory | undefined {
    if (this.planId !== planId) {
      return
    }
    return this.accessoryHistory
  }
}
