import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { HttpRequestSyncService } from '../../services/http-request-sync.service'

@Injectable()
export class HttpLoadingRequestInterceptor implements HttpInterceptor {
  constructor(private httpRequestSyncService: HttpRequestSyncService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    this.httpRequestSyncService.setLoading(true, request)
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<T>) => {
          if (event instanceof HttpResponse) {
            this.httpRequestSyncService.setLoading(false, request)
          }
        },
        error: () => {
          this.httpRequestSyncService.showErrorMessage(request)
        },
        complete: () => {
          this.httpRequestSyncService.setLoading(false, request)
        },
      })
    )
  }
}
