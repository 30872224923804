import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'
import { OAuthService } from 'angular-oauth2-oidc'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationRepository {
  public authenticated$ = new BehaviorSubject<boolean>(false)
  public testAuthenticated$ = new BehaviorSubject<boolean>(false)

  constructor(private oauthService: OAuthService, private navCtrl: NavController) {}

  public updateTestAuthentication(authenticated: boolean): void {
    this.testAuthenticated$.next(authenticated)
  }

  public updateAuthentication(authenticated: boolean): void {
    this.authenticated$.next(authenticated)
  }

  public async logoutAndRedirectToLogin(): Promise<void> {
    if (!this.testAuthenticated$.value) {
      this.updateAuthentication(false)
      this.oauthService.logOut(true)
    } else {
      this.updateTestAuthentication(false)
    }

    await this.navCtrl.navigateForward('/login', {})
  }
}
