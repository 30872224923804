<ngx-extended-pdf-viewer
  *ngIf="src"
  [src]="src"
  [zoom]="'auto'"
  [mobileFriendlyZoom]="'false'"
  [showPropertiesButton]="false"
  [showScrollingButton]="false"
  [showToolbar]="false"
  [showSecondaryToolbarButton]="false"
  [showSpreadButton]="false"
  [textLayer]="false"
  [forceUsingLegacyES5]="useLegacyPdfJS"></ngx-extended-pdf-viewer>
