import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core'
import { Mesh, PlanType, SNAP_MINIMUM_ANGLE, WallMesh } from 'formwork-planner-lib'
import { KeypadComponent } from '../../../../shared/components/keypad/keypad.component'
import { Model } from '../../model/Model'
import { AngleInfo, calculateAngleDegree } from '../../types/AngleInfo'
import { SelectAngleRay } from '../../types/selectAngleRay'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { AngleInputComponent } from '../../../../shared/components/angle-input/angle-input.component'

export const ANGLE_EDITOR_BUTTON_CONTAINER_WIDTH = 108
export const ANGLE_EDITOR_BUTTON_CONTAINER_HEIGHT = 88

@Component({
  selector: 'efp-angle-menu',
  templateUrl: './angle-menu.component.html',
  styleUrls: ['./angle-menu.component.scss'],
})
export class AngleMenuComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() editedAngle: AngleInfo | undefined
  @Input() previewAngle: AngleInfo | undefined
  @Input() model!: Model<Mesh>
  @Input() previewModel?: Model<Mesh>
  @Output() readonly closeMenu: EventEmitter<void> = new EventEmitter()

  @ViewChild('efpKeypad') efpKeypad?: KeypadComponent
  @ViewChild('efpAngleInput') efpAngleInput?: AngleInputComponent

  private readonly destroy$ = new Subject<void>()

  readonly selectAngleRay = SelectAngleRay

  public measurementForm = new FormGroup({
    measurementInput: new FormControl('', {
      validators: [Validators.required, Validators.min(0)],
      nonNullable: true,
    }),
  })

  isLeftEditable = true
  isRightEditable = true

  initialAngle = '0'
  editedDegree = '0'

  prevMovedLeg = SelectAngleRay.LEFT
  private internalMovedLeg = SelectAngleRay.LEFT

  public get movedLeg(): SelectAngleRay {
    return this.internalMovedLeg
  }

  public set movedLeg(movedLeg: SelectAngleRay) {
    this.prevMovedLeg = this.internalMovedLeg
    this.internalMovedLeg = movedLeg
    this.updateAngle(this.editedDegree)
  }

  get showErrorMessage(): boolean {
    return (
      this.showErrorMessageWallsLocked ||
      this.showErrorMessageTooSmallAngle ||
      this.showErrorMessageTooBigAngle ||
      this.showErrorMessageSlabOnly90
    )
  }

  get showErrorMessageWallsLocked(): boolean {
    return !(this.isLeftEditable || this.isRightEditable)
  }

  get showErrorMessageTooSmallAngle(): boolean {
    return this.getEditedDegree() < this.minAngle
  }

  get showErrorMessageTooBigAngle(): boolean {
    return this.getEditedDegree() > this.maxAngle
  }

  get showErrorMessageSlabOnly90(): boolean {
    return !(this.model.mesh instanceof WallMesh) && this.getEditedDegree() % 90 !== 0
  }

  get minAngle(): number {
    return SNAP_MINIMUM_ANGLE[this.editedAngle?.angleVertex.planType ?? PlanType.WALL]
  }

  get maxAngle(): number {
    return 360 - this.minAngle
  }

  get valid(): boolean {
    return (
      !this.showErrorMessageTooSmallAngle &&
      !this.showErrorMessageTooBigAngle &&
      !this.showErrorMessageSlabOnly90
    )
  }

  ngAfterViewInit(): void {
    void this.efpAngleInput?.focus()
    this.measurementForm.controls.measurementInput.setValue(this.initialAngle)

    this.measurementForm.controls.measurementInput.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        let stringValue: string
        if (value) {
          stringValue = value.toString()
        } else {
          stringValue = '0'
        }

        this.updateAngle(stringValue)
      })
  }

  ngOnDestroy(): void {
    this.closeMenu.emit()
  }

  ngOnChanges(): void {
    if (this.editedAngle) {
      const angle =
        Math.round((calculateAngleDegree(this.editedAngle) + Number.EPSILON) * 100) / 100
      this.initialAngle = angle.toString()
      this.editedDegree = this.initialAngle
      this.measurementForm.controls.measurementInput.setValue(this.initialAngle)
    }

    this.setEditable()
  }

  public updateAngle(value: string): void {
    this.editedDegree = value
    this.updatePreview()
  }

  public cancel(): void {
    this.closeMenu.emit()
  }

  public close(): boolean {
    if (this.valid) {
      this.save()
      this.closeMenu.next()
      return true
    }
    return false
  }

  public save(): void {
    if (this.editedAngle) {
      const newAngle = this.getEditedDegree()
      this.updateAngleOnModel(newAngle, this.model, this.editedAngle)
      this.initialAngle = newAngle.toString()
    }

    this.model.finalize()

    if (this.model.isValid()) {
      this.closeMenu.emit()
    }
  }

  private updatePreview(): void {
    if (this.previewAngle == null) {
      return
    }

    const newAngle = this.getEditedDegree()
    const originalAngle = calculateAngleDegree(this.previewAngle)
    const rotation = newAngle - originalAngle

    this.previewModel?.changeAngle(this.previewAngle, rotation, this.movedLeg)
  }

  private updateAngleOnModel(degrees: number, model: Model<Mesh>, angle: AngleInfo): void {
    const originalAngle = calculateAngleDegree(angle)
    const rotation = degrees - originalAngle

    model.changeAngle(angle, rotation, this.movedLeg)
  }

  public setEditable(): void {
    if (this.editedAngle) {
      if (this.editedAngle.leftEndpoint.edges.size > 1) {
        this.isLeftEditable = false
      } else {
        this.isLeftEditable = true
      }
      if (this.editedAngle.rightEndpoint.edges.size > 1) {
        this.isRightEditable = false
      } else {
        this.isRightEditable = true
      }
    }
    if (!this.isLeftEditable && this.isRightEditable) {
      this.movedLeg = SelectAngleRay.RIGHT
    } else if (this.isLeftEditable && !this.isRightEditable) {
      this.movedLeg = SelectAngleRay.LEFT
    }

    this.efpKeypad?.focusInput()
    void this.efpAngleInput?.focus()
  }

  public getEditedDegree(): number {
    if (this.editedDegree.endsWith('.')) {
      this.editedDegree = this.editedDegree.slice(0, this.editedDegree.length - 1)
    }
    return Number(this.editedDegree)
  }
}
