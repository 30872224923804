import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class PrimaryMenuService {
  private sidebarShowState = false

  get sidebarShow(): boolean {
    return this.sidebarShowState
  }

  public open(): void {
    this.sidebarShowState = true
  }

  public close(): void {
    this.sidebarShowState = false
  }
}
