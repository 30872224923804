<efp-app-header
  title="{{'FAVOURITES.FORMWORK_TOPIC' | translate}}"
  [hasNavigation]="false"
  [isModal]="true"
  [needsSafeAreaTopInset]="true"></efp-app-header>

<ion-content>
  <efp-center-container>
    <ion-list *ngIf="favouriteFormworksystems">
      <ion-list-header mode="md" color="light">
        <ion-label>{{ 'IMPORT.FORMWORK_PROFILE.SYSTEM_SELECTION' | translate }}</ion-label>
      </ion-list-header>

      <ion-item
        button
        mode="md"
        lines="full"
        *ngFor="let system of favouriteFormworksystems; let i = index"
        (click)="selectFavoriteSystem(system)"
        attr.data-test-selector="favourites-{{ i }}">
        <ion-label class="favSystemItem" (click)="selectFavoriteSystem(system)"
          >{{ "FORMWORK." + system.ID | translate}}
        </ion-label>
      </ion-item>
    </ion-list>
  </efp-center-container>
</ion-content>
