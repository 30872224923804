export const PLAN_FOLDER = 'plans'
export const SCREENSHOT_FOLDER = 'screenshots'
export const RESULT_PDF_FILE_NAME = 'result.pdf'
export const RESULT_XML_FILE_NAME = 'result.xml'
export const RESULT_JSON_FILE_NAME = 'result.json'
export const RESULT_PNG_FILE_NAME = 'result.png'
export const RESULT_THUMBNAIL_PNG_FILE_NAME = 'result_thumb.png'
export const PROTOCOL_JSON_FILE_NAME = 'protocol.json'
export const PLAN_JSON_FILE_NAME = 'plan.json'
export const PLAN_OUTLINE_JSON_FILE_NAME = 'plan-outline.json'
export const PLAN_SETTINGS_JSON_FILE_NAME = 'planSettings.json'
export const PROJECT_XML_FILE_NAME = 'project.xml'
export const VERSION_FILE_NAME = 'version.json'
export const STOCK_FILE_NAME = 'stock.json'
export const FAVOURITES_PROFILE_FILE_NAME = 'favourite-profile.json'
export const FAVOURITES_BLACKLIST_ARTICLES = 'favourite-blacklist-articles.json'
/**
 * @deprecated contour lines are migrated to {@link ACCESSORY_LINES_FILE_NAME}.
 */
export const CONTOUR_LINES_FILE_NAME = 'contourLines.json'
export const ACCESSORY_LINES_FILE_NAME = 'accessoryLines.json'
export const CYCLE_BOUNDARIES_FILE_NAME = 'cycleBoundaries.json'
export const CYCLE_SYMBOLS_FILE_NAME = 'cycleSymbols.json'
export const CHANGED_PART_LIST_FILE_NAME = 'changed-part-list.json'
export const SCREENSHOTS_FILE_NAME = 'screenshots.json'
