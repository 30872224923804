import paper from 'paper/dist/paper-core'
import { CycleBoundary, LineSide, MeshPoint, paperPointToPoint2D } from '../model'

const CIRCLE_DISTANCE_TO_BOUNDARY = 25

export class CycleBoundaryDrawable {
  public selected = false
  public id: number
  public start: paper.Point
  public end: paper.Point
  public cycleNumberLeft: number
  public cycleNumberRight: number

  constructor(cycleBoundary: CycleBoundary) {
    this.id = cycleBoundary.id
    this.start = new paper.Point(cycleBoundary.start)
    this.end = new paper.Point(cycleBoundary.end)
    this.cycleNumberLeft = cycleBoundary.cycleNumberLeft
    this.cycleNumberRight = cycleBoundary.cycleNumberRight
  }

  public getCenter(): paper.Point {
    const centerX = (this.start.x + this.end.x) / 2
    const centerY = (this.start.y + this.end.y) / 2

    return new paper.Point(centerX, centerY)
  }

  public getDistance(boundary: CycleBoundaryDrawable | MeshPoint): number {
    if (boundary instanceof CycleBoundaryDrawable) {
      return this.getCenter().getDistance(boundary.getCenter())
    } else {
      return this.getCenter().getDistance(boundary)
    }
  }

  public isLeftFrom(boundary: CycleBoundaryDrawable | MeshPoint): boolean {
    if (boundary instanceof CycleBoundaryDrawable) {
      return (
        this.getRightCirclePosition().getDistance(boundary.getCenter()) <
        this.getLeftCirclePosition().getDistance(boundary.getCenter())
      )
    } else {
      return (
        this.getRightCirclePosition().getDistance(boundary) <
        this.getLeftCirclePosition().getDistance(boundary)
      )
    }
  }

  public getClosestCircle(point: CycleBoundaryDrawable | MeshPoint): LineSide {
    if (point instanceof CycleBoundaryDrawable) {
      return this.getLeftCirclePosition().getDistance(point.getCenter()) <
        this.getRightCirclePosition().getDistance(point.getCenter())
        ? LineSide.LEFT
        : LineSide.RIGHT
    } else {
      return this.getLeftCirclePosition().getDistance(point) <
        this.getRightCirclePosition().getDistance(point)
        ? LineSide.LEFT
        : LineSide.RIGHT
    }
  }

  public getLeftCirclePosition(): paper.Point {
    return this.getLeftPositionFromCenter(CIRCLE_DISTANCE_TO_BOUNDARY)
  }

  public getRightCirclePosition(): paper.Point {
    return this.getRightPositionFromCenter(CIRCLE_DISTANCE_TO_BOUNDARY)
  }

  public getLeftPositionFromCenter(distance: number): paper.Point {
    const center = this.getCenter()
    const lineVector = center.subtract(this.end)
    return center.add(lineVector.rotate(90).normalize(distance))
  }

  public getRightPositionFromCenter(distance: number): paper.Point {
    const center = this.getCenter()
    const lineVector = center.subtract(this.end)
    return center.add(lineVector.rotate(-90).normalize(distance))
  }

  public clone(): CycleBoundaryDrawable {
    return new CycleBoundaryDrawable(this)
  }

  public equals(boundary: CycleBoundaryDrawable): boolean {
    return this.start.equals(boundary.start) && this.end.equals(boundary.end)
  }

  public toCycleBoundary(): CycleBoundary {
    return {
      id: this.id,
      start: paperPointToPoint2D(this.start),
      end: paperPointToPoint2D(this.end),
      cycleNumberLeft: this.cycleNumberLeft,
      cycleNumberRight: this.cycleNumberRight,
    }
  }
}
