import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class AccordionFunctionsService {
  private buttonCollapseAll = new Subject<void>()
  private buttonExpandAll = new Subject<void>()

  buttonCollapseClick$ = this.buttonCollapseAll.asObservable()
  buttonExpandClick$ = this.buttonExpandAll.asObservable()

  notifyCollapseButtonClick(): void {
    this.buttonCollapseAll.next()
  }

  notifyExpandButtonClick(): void {
    this.buttonExpandAll.next()
  }
}
