import { Injectable } from '@angular/core'
import { CycleBoundaryCreateCommandParam, CycleSymbolModel } from '@efp/api'
import { createStore } from '@ngneat/elf'
import {
  addEntities,
  getEntity,
  selectAllEntities,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { CycleBoundary, CycleSymbol } from 'formwork-planner-lib'
import { CycleDao, mapCycleBoundaryToCycleCreateModel } from '../services/dao/cycle.dao'
import { deepCopy } from '../utils/deepCopy'

export interface PlanCycleBoundaries {
  planId: number
  boundaries: CycleBoundary[]
}

export interface PlanCycleSymbols {
  planId: number
  symbols: CycleSymbol[]
}

const boundariesStore = createStore(
  { name: 'cycleBoundaries' },
  withEntities<PlanCycleBoundaries, 'planId'>({ idKey: 'planId' })
)

const symbolsStore = createStore(
  { name: 'cycleSymbols' },
  withEntities<PlanCycleSymbols, 'planId'>({ idKey: 'planId' })
)

@Injectable({
  providedIn: 'root',
})
export class CycleRepository {
  public readonly planCycleBoundaries$ = boundariesStore.pipe(selectAllEntities())
  public readonly planCycleSymbols$ = symbolsStore.pipe(selectAllEntities())

  constructor(private readonly cycleDao: CycleDao) {}

  private updateBoundaryInActiveCache(planId: number, newBoundary: CycleBoundary): void {
    const cachedPlanBoundaries = boundariesStore.query(getEntity(planId))

    if (cachedPlanBoundaries) {
      /* Important to copy, because CycleBoundaryDrawable is a class (reference type)
         Otherwise changes somewhere else will change the instance in the cache too without calling update
      */
      const copy = deepCopy(cachedPlanBoundaries)
      const newCopiedBoundary: CycleBoundary = {
        id: newBoundary.id,
        start: { x: newBoundary.start.x, y: newBoundary.start.y },
        end: { x: newBoundary.end.x, y: newBoundary.end.y },
        cycleNumberLeft: newBoundary.cycleNumberLeft,
        cycleNumberRight: newBoundary.cycleNumberRight,
      }
      let hasChanged = false

      copy.boundaries = cachedPlanBoundaries.boundaries.map((cachedBoundary) => {
        if (cachedBoundary.id === newBoundary.id) {
          hasChanged = true
          return newCopiedBoundary
        } else {
          return cachedBoundary
        }
      })

      if (!hasChanged) {
        copy.boundaries.push(newCopiedBoundary)
      }
      boundariesStore.update(upsertEntities({ ...copy, planId }))
    } else {
      // This should not happen, because we have to load all for the plan before you can create or update new
      throw new Error('No active cache found')
    }
  }

  public async createCycleBoundary(line: CycleBoundaryCreateCommandParam): Promise<number> {
    try {
      const createdId = await this.cycleDao.createCycleBoundary(line)
      const newBoundary: CycleBoundary = {
        id: createdId,
        start: { x: line.startx, y: line.starty },
        end: { x: line.endx, y: line.endy },
        cycleNumberLeft: line.cycleNumberLeft,
        cycleNumberRight: line.cycleNumberRight,
      }

      boundariesStore.update(
        updateEntities(line.planId, (entity) => {
          entity.boundaries.push(newBoundary)
          return entity
        })
      )

      return createdId
    } catch (e: unknown) {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (createCycleBoundary failed)'
      )
    }
  }

  public async replaceCycleBoundaries(
    boundaries: Omit<CycleBoundary, 'id'>[],
    planId: number
  ): Promise<CycleBoundary[]> {
    await this.cycleDao.removeAllCycleBoundariesByPlanId(planId).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (removeAllCycleBoundariesByPlanId failed)'
      )
    })

    const createdBoundaries = await Promise.all(
      boundaries.map(async (boundary) => {
        const createdId = await this.cycleDao.createCycleBoundary(
          mapCycleBoundaryToCycleCreateModel(boundary, planId)
        )
        return {
          id: createdId,
          start: { x: boundary.start.x, y: boundary.start.y },
          end: { x: boundary.end.x, y: boundary.end.y },
          cycleNumberLeft: boundary.cycleNumberLeft,
          cycleNumberRight: boundary.cycleNumberRight,
        }
      })
    ).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (replaceCycleBoundaries failed)'
      )
    })
    boundariesStore.update(upsertEntities({ planId, boundaries: createdBoundaries }))

    return deepCopy(createdBoundaries)
  }

  public async replaceCycleSymbols(symbols: CycleSymbol[], planId: number): Promise<void> {
    symbolsStore.update(upsertEntities({ planId, symbols }))
    await this.cycleDao.removeAllSymbolsByPlanId(planId).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (removeAllSymbolsByPlanId failed)'
      )
    })

    const udpateSymbols: CycleSymbolModel[] = symbols.map((it) => ({
      posX: it.position.x,
      posY: it.position.y,
      cycleNumber: it.cycleNumber,
      planId,
    }))
    await Promise.all(
      udpateSymbols.map(async (symbol) => this.cycleDao.createCycleSymbol(symbol))
    ).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (replaceCycleSymbols failed)'
      )
    })
  }

  public async updateCycleNumbers(planId: number, lines: CycleBoundary[]): Promise<void> {
    lines.forEach((line) => this.updateBoundaryInActiveCache(planId, line))
    await this.cycleDao.updateCycleNumbers(lines).catch((e) => {
      console.error(e)
      throw new Error('This case should be handled with retry or reset (updateCycleNumbers failed)')
    })
  }

  public async updateCycleBoundaryPosition(planId: number, boundary: CycleBoundary): Promise<void> {
    this.updateBoundaryInActiveCache(planId, boundary)
    await this.cycleDao.updateCycleBoundaryPosition(boundary).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (updateCycleBoundaryPosition failed)'
      )
    })
  }

  public async findAllCycleBoundariesByPlanId(planId: number): Promise<CycleBoundary[]> {
    const cachedPlanBoundaries = boundariesStore.query(getEntity(planId))

    if (cachedPlanBoundaries) {
      return cachedPlanBoundaries.boundaries
    } else {
      const boundaries = await this.cycleDao.findAllCycleBoundariesByPlanId(planId)
      boundariesStore.update(addEntities({ planId, boundaries }))
      return boundaries
    }
  }

  public async findAllCycleSymbolsByPlanId(planId: number): Promise<CycleSymbol[]> {
    const cachedPlanCycles = symbolsStore.query(getEntity(planId))

    if (cachedPlanCycles) {
      return cachedPlanCycles.symbols
    } else {
      const symbols = await this.cycleDao.findAllCycleSymbolsByPlanId(planId)
      symbolsStore.update(addEntities({ planId, symbols }))
      return symbols
    }
  }

  public async removeAllCycleBoundariesAndSymbolsByPlanId(planId: number): Promise<void> {
    boundariesStore.update(updateEntities(planId, { boundaries: [] }))
    symbolsStore.update(updateEntities(planId, { symbols: [] }))
    await Promise.all([
      this.cycleDao.removeAllCycleBoundariesByPlanId(planId),
      this.cycleDao.removeAllSymbolsByPlanId(planId),
    ]).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (removeAllCycleBoundariesAndSymbolsByPlanId failed)'
      )
    })
  }

  public async deleteCycleBoundary(planId: number, id: number): Promise<void> {
    const cachedPlanBoundaries = boundariesStore.query(getEntity(planId))
    if (cachedPlanBoundaries) {
      const copy = deepCopy(cachedPlanBoundaries)
      copy.boundaries = cachedPlanBoundaries.boundaries.filter(
        (cachedBoundary) => cachedBoundary.id !== id
      )
      boundariesStore.update(updateEntities(planId, copy))
    }

    await this.cycleDao.deleteCycleBoundary(id).catch((e) => {
      console.error(e)
      throw new Error(
        'This case should be handled with retry or reset (deleteCycleBoundary failed)'
      )
    })
  }
}
