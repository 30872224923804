import { Component, Input } from '@angular/core'
import { PlanType } from '../../model'
import { UnitOfLength } from 'formwork-planner-lib'
import { FormworkId } from '../../../../../generated/efp-api'
import { MeasurementLegendService } from '../../../../services/measurement-legend.service'

@Component({
  selector: 'efp-measurement-legend',
  templateUrl: 'measurement-legend.html',
  styleUrls: ['measurement-legend.scss'],
})
export class MeasurementLegendComponent {
  @Input() wallHeight?: number | undefined

  @Input() slabThickness?: number | undefined

  @Input() slabHeight?: number | undefined

  @Input() measurementUnitPlanner?: UnitOfLength | undefined

  @Input() buildingType?: PlanType | undefined

  @Input() additionalSpacingBottom = false

  @Input() screenshotModalView = false

  @Input() formwork?: FormworkId | undefined

  @Input() result = false

  public measurementUnitArticle = 'inch'

  constructor(private readonly measurementLegendService: MeasurementLegendService) {}

  public isWall(): boolean {
    return this.buildingType === PlanType.WALL
  }

  public isInitialized(): boolean {
    return this.buildingType !== undefined
  }

  public convertValue(value?: number): string {
    return this.measurementLegendService.convertValue(this.measurementUnitPlanner, value)
  }

  public showArticleMeasurementUnit(): boolean {
    if (this.result) {
      this.measurementUnitArticle = this.measurementLegendService.getMeasurementUnitArticle(
        this.formwork
      )
      if (this.measurementUnitArticle !== this.measurementUnitPlanner) {
        return true
      }
    }

    return false
  }

  public translateMeasurementUnit(measurementUnit: string = 'cm'): string {
    if (this.result && measurementUnit === 'inch') {
      return 'ft. in.'
    } else if (this.result && measurementUnit !== 'cm') {
      return 'cm'
    }
    return measurementUnit
  }
}
