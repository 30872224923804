<ion-content>
  <ion-item lines="none" detail="false" button (click)="setSelection(accessoryLineSelection.ALL)">
    <ion-label>{{ 'ACCESSORY.SELECT' | translate }}</ion-label>
  </ion-item>
  <ion-item lines="none" detail="false" button (click)="setSelection(accessoryLineSelection.NONE)">
    <ion-label>{{ 'ACCESSORY.DESELECT' | translate }}</ion-label>
  </ion-item>
  <ion-item
    lines="none"
    detail="false"
    button
    (click)="setSelection(accessoryLineSelection.INVERT)">
    <ion-label>{{ 'ACCESSORY.INVERT' | translate }}</ion-label>
  </ion-item>
</ion-content>
