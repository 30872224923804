import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { OnboardingRepository } from '../repositories/onboarding.repository'
import { OnboardingHintSeriesKey } from '../models/onboarding/onboarding-hint-series-key'
import { ONBOARDING_PAGE_KEYS_CONFIG } from '../constants/onboarding/onboarding-config'
import { Capacitor } from '@capacitor/core'

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private triggerSubject = new Subject<string>()

  constructor(private onboardingRepository: OnboardingRepository) {}
  public trigger(onboardingHintSeriesKey: string): void {
    this.triggerSubject.next(onboardingHintSeriesKey)
  }

  public getTriggerCondition(onboardingHintSeriesKey: string): Observable<string> {
    return this.triggerSubject
      .asObservable()
      .pipe(filter((hintId) => hintId === onboardingHintSeriesKey))
  }

  public async hasAlreadyBeenShownToTheUser(onboardingHintSeriesKey: string): Promise<boolean> {
    const seenOnboardings = await this.onboardingRepository.getCurrentOnboardingTracking()
    return seenOnboardings.includes(onboardingHintSeriesKey)
  }

  public async trackOnboarding(onboardingHintSeriesKey: string): Promise<void> {
    await this.onboardingRepository.createOnboardingTracking(onboardingHintSeriesKey)
  }

  public async getOnboardingSeriesKeysForPage(pageKey: string): Promise<OnboardingHintSeriesKey[]> {
    const onboardingKeysPerPage = Capacitor.isNativePlatform()
      ? ONBOARDING_PAGE_KEYS_CONFIG.native
      : ONBOARDING_PAGE_KEYS_CONFIG.web

    if (pageKey in onboardingKeysPerPage) return onboardingKeysPerPage[pageKey]
    else {
      return []
    }
  }
}
