import { Injectable } from '@angular/core'
import { PlanResult } from '../../models/planResult'
import {
  CreatePlanResultCommandParam,
  PlanResultImageModel,
  PlanResultModel,
  PlanResultThumbnailModel,
} from '../../../generated/efp-api'

export const mapPlanResultToCreatePlanResultCommandParam = (
  planResult: PlanResult
): CreatePlanResultCommandParam => {
  return {
    planId: planResult.planId,
    resultBase64Image: planResult.resultBase64Image ?? '',
    resultBase64Thumbnail: planResult.resultBase64Thumbnail ?? '',
    resultProtocol: planResult.resultProtocol ? JSON.stringify(planResult.resultProtocol) : null,
    resultXML: planResult.resultXML ?? '',
    partList: JSON.stringify(planResult.partList),
  }
}

export const mapPlanResultModelToPlanResult = (planResult: PlanResultModel): PlanResult => {
  return {
    planId: planResult.planId,
    resultProtocol: planResult.resultProtocol ? JSON.parse(planResult.resultProtocol) : null,
    resultXML: planResult.resultXml,
    partList: JSON.parse(planResult.partList),
  }
}

@Injectable()
export abstract class PlanResultDao {
  abstract savePlanResult(planResult: Omit<PlanResult, 'planId'>, planId: number): Promise<void>

  abstract getPlanResult(planId: number): Promise<PlanResult | undefined>

  abstract getPlanResultImage(planId: number): Promise<PlanResultImageModel | undefined>

  abstract getPlanResultThumbnail(planId: number): Promise<PlanResultThumbnailModel | undefined>

  abstract getIsCalculated(planId: number): Promise<boolean>
}
