import { Injectable } from '@angular/core'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { BackgroundTask } from '@capawesome/capacitor-background-task'
import resultPdfWorkerInstance from '../worker/instances/result-pdf-worker.instance'
import { PdfWorkerRequest, PdfWorkerResponse } from '../worker/resultPdfWorkerData'

@Injectable({
  providedIn: 'root',
})
export class AppBackgroundService {
  private backgroundPromises: Promise<unknown>[] = []

  constructor() {
    if (Capacitor.isNativePlatform()) {
      void App.addListener('appStateChange', ({ isActive }) => {
        void this.finishBackgroundTasks(isActive)
      })
    }
  }

  private async finishBackgroundTasks(isActive: boolean): Promise<void> {
    if (!isActive && this.backgroundPromises.length > 0) {
      /// Tasks should not be longer then 30sec, because of iOS limits
      const taskId = await BackgroundTask.beforeExit(() => {
        void (async () => {
          // Run your code...
          // Finish the background task as soon as everything is done.
          await Promise.all(this.backgroundPromises)
          this.backgroundPromises = []

          BackgroundTask.finish({ taskId })
        })()
      })
    }
  }

  addBackgroundPromise(promise: Promise<unknown>): void {
    if (Capacitor.isNativePlatform()) {
      this.backgroundPromises.push(promise)
    }
  }

  async generateResultPdfInBackground(pdfRequest: PdfWorkerRequest): Promise<PdfWorkerResponse> {
    const resultPromise = new Promise<PdfWorkerResponse>((resolve, reject) => {
      // TODO Theoretically, if we call this method twice without waiting for the first process to finish,
      // we'll override the onmessage callback and
      resultPdfWorkerInstance.onmessage = ({ data }) => {
        if ((data as PdfWorkerResponse).isPdfWorkerResponse) {
          resolve(data)
        } else {
          reject(new Error('Worker response was of incorrect type'))
        }
      }
      const errorHandler = (error: unknown): void => {
        reject(error)
      }
      resultPdfWorkerInstance.onmessageerror = errorHandler
      resultPdfWorkerInstance.onerror = errorHandler
    })

    resultPdfWorkerInstance.postMessage(pdfRequest)
    this.addBackgroundPromise(resultPromise)

    return resultPromise
  }
}
