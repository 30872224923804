import paper from 'paper/dist/paper-core'
import { Edge, Line, LineSide } from '../../model'
import { SNAPPING_ERROR_TOLERANCE } from '../snapping/snappingConstants'

export function getIntersections(
  edgeOutline1: paper.Path,
  edgeOutline2: paper.Path
): paper.CurveLocation[] {
  const cornerPoints = edgeOutline1.segments.map((s) => s.point)

  const ixs = edgeOutline1.getIntersections(edgeOutline2)

  return ixs.filter(
    (ix) => !cornerPoints.find((point) => ix.point.isClose(point, SNAPPING_ERROR_TOLERANCE))
  )
}

export function calculateEdgeOutline(edge: Edge): { [x in LineSide]: Line } {
  return calculateOutline(edge.startPoint, edge.endPoint, edge.thickness)
}

export function calculateOutline(
  A: paper.Point,
  B: paper.Point,
  thickness: number
): { [x in LineSide]: Line } {
  const offset = thickness * 0.5
  const dir = B.subtract(A).normalize(offset)
  const normalOffset = new paper.Point(dir.y, -dir.x)

  return {
    [LineSide.LEFT]: {
      start: A.add(normalOffset),
      end: B.add(normalOffset),
    },
    [LineSide.RIGHT]: {
      start: A.subtract(normalOffset),
      end: B.subtract(normalOffset),
    },
  }
}
