import { Injectable } from '@angular/core'
import { AppSettingsModel, Country } from '@efp/api'
import { createStore, select } from '@ngneat/elf'
import { getAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities'
import { firstValueFrom, switchMap } from 'rxjs'
import { AppSettingDao } from '../services/dao/app-settings.dao'

const store = createStore({ name: 'appSettings' }, withEntities<AppSettingsModel>())

@Injectable({
  providedIn: 'root',
})
export class AppSettingsRepository {
  public appSettings$ = store.pipe(
    select((state) => (state.ids.length > 0 ? state.entities[state.ids[0]] : null)),
    switchMap(async (appSettings) => appSettings ?? (await this.loadAppSettings()))
  )

  constructor(private readonly appSettingDao: AppSettingDao) {}

  public async initAppSettings(): Promise<void> {
    const appSettings = await this.appSettingDao.getAppSettings()
    store.update(setEntities([appSettings]))
  }

  public async updateLanguage(newLang: string): Promise<void> {
    const appSettings = store.query(getAllEntities())
    if (appSettings && appSettings.length > 0) {
      store.update(updateEntities(appSettings[0].id, { language: newLang }))
    }

    await this.appSettingDao.updateLanguage(newLang)
  }

  public async updateCountry(newCountry: Country): Promise<void> {
    const appSettings = store.query(getAllEntities())
    if (appSettings && appSettings.length > 0) {
      store.update(updateEntities(appSettings[0].id, { country: newCountry }))
    }

    await this.appSettingDao.updateCountry(newCountry)
  }

  public async getAppSettings(): Promise<AppSettingsModel> {
    return firstValueFrom(this.appSettings$)
  }

  private async loadAppSettings(): Promise<Readonly<AppSettingsModel>> {
    const newAppSettings = await this.appSettingDao.getAppSettings()
    store.update(setEntities([newAppSettings]))

    return newAppSettings
  }
}
