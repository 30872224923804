import { MeshPoint } from 'formwork-planner-lib'

export interface AngleInfo {
  angleVertex: MeshPoint
  leftEndpoint: MeshPoint
  rightEndpoint: MeshPoint
}

export const calculateAngleDegree = (angleInfo: AngleInfo): number => {
  const leftAngleRay = angleInfo.leftEndpoint.subtract(angleInfo.angleVertex)
  const rightAngleRay = angleInfo.rightEndpoint.subtract(angleInfo.angleVertex)
  return leftAngleRay.getAngle(rightAngleRay)
}
