import { PlanType } from '../../model/PlanType'

export const SNAPPING_ERROR_TOLERANCE = 0.001
export const ANGLE_ERROR_TOLERANCE = 0.001
export const MIN_CLICKABLE_PIXEL_SIZE = 40
export const SNAP_ANGLE_INTERVAL = 15
export const ANGLE_SNAPPING_TOLERANCE = 3

export const SNAPPING_DISTANCE = 30

export const SNAP_MINIMUM_ANGLE = {
  [PlanType.WALL]: 60,
  [PlanType.SLAB]: 90,
}
