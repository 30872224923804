import { Injectable } from '@angular/core'
import { FormworkId } from '@efp/api'
import { createStore } from '@ngneat/elf'
import {
  addEntities,
  deleteEntities,
  getAllEntitiesApply,
  getEntity,
  selectAllEntities,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { FavouriteProfile } from '../models/favourites'
import { FavouriteDao } from '../services/dao/favourite.dao'

export const favouriteStore = createStore({ name: 'favourites' }, withEntities<FavouriteProfile>())

@Injectable({
  providedIn: 'root',
})
export class FavouriteRepository {
  private hasLoadedAll = false
  public readonly favourites$ = favouriteStore.pipe(selectAllEntities())

  constructor(private readonly favouriteDao: FavouriteDao) {}

  public async create(
    favourite: Omit<FavouriteProfile, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<number> {
    const id = await this.favouriteDao.create(favourite)
    const newFavourite = {
      ...favourite,
      id,
      createdAt: new Date(),
      updatedAt: new Date(),
    }

    favouriteStore.update(addEntities(newFavourite))
    return newFavourite.id
  }

  public async update(favourite: FavouriteProfile): Promise<void> {
    await this.favouriteDao.update(favourite)
    favouriteStore.update(updateEntities(favourite.id, favourite))
  }

  public async delete(id: number): Promise<void> {
    await this.favouriteDao.deleteById(id)
    favouriteStore.update(deleteEntities(id))
  }

  public async findAllByFormworkSystemId(
    formworkSystemId: FormworkId
  ): Promise<FavouriteProfile[]> {
    if (this.hasLoadedAll) {
      return favouriteStore.query(
        getAllEntitiesApply({
          filterEntity: (e) => e.formworkSystemId === formworkSystemId,
        })
      )
    }

    const favourites = await this.favouriteDao.findAllByFormworkSystemId(formworkSystemId)
    favouriteStore.update(upsertEntities(favourites))
    return favourites
  }

  public async findOneById(id: number): Promise<FavouriteProfile | undefined> {
    let favourite = favouriteStore.query(getEntity(id))

    if (!favourite) {
      favourite = await this.favouriteDao.findOneById(id)

      if (favourite) {
        favouriteStore.update(upsertEntities(favourite))
      }
    }

    return favourite
  }

  public async findOneStandardByFormworkSystemId(
    formworkSystemId: FormworkId
  ): Promise<FavouriteProfile | undefined> {
    const favourites = favouriteStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.formworkSystemId === formworkSystemId && e.isStandard,
      })
    )

    let favourite = favourites && favourites.length > 0 ? favourites[0] : undefined

    if (!favourite) {
      favourite = await this.favouriteDao.findOneStandardByFormworkSystemId(formworkSystemId)

      if (favourite) {
        favouriteStore.update(upsertEntities(favourite))
      }
    }

    return favourite
  }

  public async findAll(): Promise<FavouriteProfile[]> {
    let favourites = favouriteStore.query(getAllEntitiesApply({}))

    if (favourites && favourites.length > 0 && this.hasLoadedAll) {
      return favourites
    } else {
      favourites = await this.favouriteDao.findAll()
      favouriteStore.update(setEntities(favourites))
      this.hasLoadedAll = true
      return favourites
    }
  }
}
