import { Injectable } from '@angular/core'
import { PlanAccessory } from '../../../models/plan/PlanAccessory'
import { PlanAccessoryLine } from '../../../models/plan/PlanAccessoryLine'
import { DataService } from '../../data.service'
import { PlanAccessoryLineDao } from '../plan-accessory-line.dao'

@Injectable({
  providedIn: 'root',
})
export class PlanAccessoryLineSqlDao implements PlanAccessoryLineDao {
  constructor(private readonly dataService: DataService) {}

  public async createAccessoryLines(
    accessoryLines: Omit<PlanAccessoryLine, 'id'>[]
  ): Promise<PlanAccessoryLine[]> {
    if (accessoryLines.length === 0) {
      return []
    }

    const statement =
      'INSERT INTO PlanAccessoryLine (planId, outlineId, accessoriesAsString) VALUES (?,?,?)'
    const updatedAccessoryLines: Promise<PlanAccessoryLine>[] = accessoryLines.map(async (line) => {
      const result = await this.dataService.executeStatement(statement, [
        line.planId,
        line.outlineId,
        line.accessoriesAsString,
      ])
      const planAccessoryLine: PlanAccessoryLine = {
        ...line,
        id: result.insertId,
      }

      return planAccessoryLine
    })

    return await Promise.all(updatedAccessoryLines)
  }

  public async updateAccessoryLineAssignments(
    accessoryLines: Pick<PlanAccessoryLine, 'id' | 'accessoriesAsString'>[]
  ): Promise<void> {
    const statement = 'UPDATE PlanAccessoryLine set accessoriesAsString = ? where id = ?'
    const updatePromises = accessoryLines.map(async (line) => {
      const values = [line.accessoriesAsString, line.id]

      return this.dataService.executeStatement(statement, values)
    })

    await Promise.all(updatePromises)
  }

  public async getAccessoriesForPlan(planId: number): Promise<PlanAccessory[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * FROM PlanAccessoryLine WHERE planId = ?',
      [planId]
    )
    const planAccesories: PlanAccessory[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      planAccesories.push({
        id: row.id,
        planId: row.planId,
        outlineId: row.outlineId,
        accessoriesAsString: row.accessoriesAsString,
      })
    }

    return planAccesories
  }

  public async getAccessoryLinesForPlan(planId: number): Promise<PlanAccessoryLine[]> {
    const result = await this.dataService.executeStatement(
      'SELECT al.id, al.planId, al.outlineId, al.accessoriesAsString,' +
        'ol.start_x, ol.start_y, ol.end_x, ol.end_y FROM PlanAccessoryLine al JOIN PlanOutline ol ON al.outlineId = ol.id WHERE al.planId = ?',
      [planId]
    )
    const planLines: PlanAccessoryLine[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      planLines.push({
        id: row.id,
        planId: row.planId,
        outlineId: row.outlineId,
        accessoriesAsString: row.accessoriesAsString,
        start: {
          x: row.start_x,
          y: row.start_y,
        },
        end: {
          x: row.end_x,
          y: row.end_y,
        },
      })
    }

    return planLines
  }

  public async deleteAccessoryLinesForPlan(planId: number): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM PlanAccessoryLine where planId=?', [
      planId,
    ])
  }
}
