/* eslint-disable max-len */
export const xmlParser = new DOMParser()
export const ROOT_XML_ELEMENT = 'TiposProjectExport'
export const PROJECT_DATA = 'projectData'
export const INITIAL_STOCK = 'initialStock'
export const STOCK = 'stock'
export const FORMWORK_SYSTEM = 'formworkSystem'
export const CURRENT_WALL_SYSTEM = 'currentWallSystem'
export const CURRENT_SLAB_SYSTEM = 'currentSlabSystem'
export const FORMWORK_MODE_WALL = 'formworkModeWall'
export const FORMWORK_MODE_SLAB = 'formworkModeSlab'
export const MANUFACTURER_ATTRIBUTE = 'manufacturer'
export const ARTICLE_NUMBER_ATTRIBUTE = 'articleNumber'
export const QUANTITY_BUILDING_SITE_ATTRIBUTE = 'quantityBuildingSite'
export const QUANTITY_BUILDING_YARD_ATTRIBUTE = 'quantityBuildingYard'
export const QUANTITY_VENDOR_ATTRIBUTE = 'quantityVendor'
export const PRINT_LANGUAGE = 'printLanguage'
export const NUMBER_FORMAT = 'numberFormat'
export const BRANCH = 'branch'
export const INTERNAL = 'internal'

export const emptyTiposXml: Readonly<string> = `<?xml version=\"1.0\" encoding=\"utf-8\" standalone=\"no\"?>
<TiposProjectExport>
  <version>1.0</version>
  <MinimumExtrem>0.000000,0.000000,0.000000</MinimumExtrem>
  <MaximumExtrem>6000.000000,6035.796641,0.000000</MaximumExtrem>
  <RefPoint>-0.000000,0.000000,0.000000</RefPoint>
  <RefAngle>0.000000</RefAngle>
  <projectData>
    <projectOptions>
      <article3d>false</article3d>
      <wallLevelBottom>0.0</wallLevelBottom>
      <wallLevelTop>3000.0</wallLevelTop>
      <wallThicknessLeft2d>250.0</wallThicknessLeft2d>
      <wallThicknessRight2d>0.0</wallThicknessRight2d>
      <slabLevel>3000.0</slabLevel>
      <slabThickness>200.0</slabThickness>
      <floorLevel>0.0</floorLevel>
      <floorThickness>0.0</floorThickness>
      <tipCatArticle2d3d>false</tipCatArticle2d3d>
      <artikelSchalsystemSichtbar>false</artikelSchalsystemSichtbar>
      <volumenbloecke>false</volumenbloecke>
      <partsListColumnOrder I0=\"1\" I1=\"2\" I10=\"11\" I11=\"12\" I12=\"13\" I13=\"14\" I14=\"15\" I15=\"16\" I16=\"17\" I17=\"18\" I18=\"19\" I19=\"20\" I2=\"3\" I3=\"4\" I4=\"5\" I5=\"6\" I6=\"7\" I7=\"8\" I8=\"9\" I9=\"10\" />
      <partsListSortOrder I0=\"1\" I1=\"2\" I10=\"11\" I11=\"12\" I12=\"13\" I13=\"14\" I14=\"15\" I15=\"16\" I16=\"17\" I17=\"18\" I18=\"19\" I19=\"20\" I2=\"3\" I3=\"4\" I4=\"5\" I5=\"6\" I6=\"7\" I7=\"8\" I8=\"9\" I9=\"10\" />
      <plistTableStyle>ACWC_DokaCAD_Piecelist</plistTableStyle>
      <plistTableHeadTop>true</plistTableHeadTop>
      <plistTableSortClipboard>false</plistTableSortClipboard>
      <printLanguage>de-de</printLanguage>
      <logisticNl>080</logisticNl>
      <priceBracket>DI01</priceBracket>
      <priceDate>20181114</priceDate>
      <numberFormat>de-de</numberFormat>
    </projectOptions>
    <formworkModes>
      <formworkModeWall>
        <manufacturer>DOKA</manufacturer>
        <twoSided>true</twoSided>
        <allCycles>true</allCycles>
        <prioPrevCycle>true</prioPrevCycle>
        <evenHeights>true</evenHeights>
        <closureBeforeFillerWall>false</closureBeforeFillerWall>
        <minExcess>0.0</minExcess>
        <maxExcess>1490.0</maxExcess>
        <considerShiftingUnitWidth>false</considerShiftingUnitWidth>
        <shiftingUnitWidth>0.0</shiftingUnitWidth>
        <preferUserTemplate>false</preferUserTemplate>
        <avoidFiller>false</avoidFiller>
      </formworkModeWall>
      <formworkModeSlab>
        <manufacturer>DOKA</manufacturer>
        <distTransvBeamsStatMax>false</distTransvBeamsStatMax>
        <distLongitBeamsStatMax>false</distLongitBeamsStatMax>
        <distPropsLongitBeamsStatMax>false</distPropsLongitBeamsStatMax>
        <beamSelectionByPriority>false</beamSelectionByPriority>
        <distTransvBeams>500.0</distTransvBeams>
        <distLongitBeams>2000.0</distLongitBeams>
        <distProps>1000.0</distProps>
        <formwExcessOutside>350.0</formwExcessOutside>
        <maxCantileverTransvBeams>500.0</maxCantileverTransvBeams>
        <maxCantileverLongitBeams>500.0</maxCantileverLongitBeams>
        <minOverlapTransvBeams>200.0</minOverlapTransvBeams>
        <minOverlapLongitBeams>900.0</minOverlapLongitBeams>
        <maxOverlapTransvBeams>6000.0</maxOverlapTransvBeams>
        <maxOverlapLongitBeams>2000.0</maxOverlapLongitBeams>
        <maxEdgeDistTransvBeams>200.0</maxEdgeDistTransvBeams>
        <maxEdgeDistLongitBeams>200.0</maxEdgeDistLongitBeams>
        <maxDistPropsAuxLongitBeams>1500.0</maxDistPropsAuxLongitBeams>
        <statikNorm>EUROCODE</statikNorm>
        <allowedBending>500</allowedBending>
        <allCycles>true</allCycles>
      </formworkModeSlab>
    </formworkModes>
    <currentPlatfSystem manufacturer=\"DOKA\" systemId=\"KOBMDIDI\" />
    <currentScope></currentScope>
    <currentCycle>1</currentCycle>
  </projectData>
  <WallContour></WallContour>
  <SlabContour></SlabContour>
  <FloorBodySymbol></FloorBodySymbol>
  <FloorCycleSymbol></FloorCycleSymbol>
  <EFPData>
    <api>1</api>
    <branch></branch>
    <internal></internal>
  </EFPData>
</TiposProjectExport>`
