import { Injectable } from '@angular/core'
import { createStore } from '@ngneat/elf'
import {
  deleteEntitiesByPredicate,
  getEntity,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { PartList, ResultPart } from '../models/part'
import { PlanResult } from '../models/planResult'
import { PlanResultProtocol } from '../models/planResultProtocol'
import { Stock } from '../models/stock'
import { PlanResultDao } from '../services/dao/plan-result.dao'
import { PlanResultService } from '../services/plan-result.service'
import { PlanRepository } from './plan.repository'
import { StockRepository } from './stock.repository'
import { screenshotStore } from './screenshot.repository'
import { changedResultPartStore } from './changed-result-part.repository'
import { ResetCalculationService } from '../services/cache/reset-calculation.service'
import { PlanResultResetCalculationDao } from '../services/dao/plan-result-reset-calculation.dao'
import { distinctUntilChanged } from 'rxjs'

interface PlanResultCalculated {
  id: number
  calculated: boolean
}

const planResultStore = createStore(
  { name: 'planResult' },
  withEntities<PlanResult, 'planId'>({ idKey: 'planId' })
)

const planResultCalculatedStore = createStore(
  { name: 'planResultCalculated' },
  withEntities<PlanResultCalculated>()
)

@Injectable({
  providedIn: 'root',
})
export class PlanResultRepository {
  public readonly planResults$ = planResultStore.pipe(selectAllEntities())

  public readonly planResultCalculationStates$ = planResultCalculatedStore.pipe(selectAllEntities())

  constructor(
    private readonly planResultDao: PlanResultDao,
    private readonly planResultResetCalculationDao: PlanResultResetCalculationDao,
    private readonly planRepository: PlanRepository,
    private readonly stockRepository: StockRepository,
    private readonly resetCalculationService: ResetCalculationService
  ) {
    this.resetCalculationService.planIdsNeededReCalculation$
      .pipe(
        distinctUntilChanged(
          (prev: number[], curr: number[]) =>
            prev.length === curr.length && prev.every((v, _) => curr.includes(v))
        )
      )
      .subscribe((planIds) => {
        planIds.forEach((planId) => {
          void this.resetCalculation(planId)
        })
      })
  }

  public async savePlanResult(
    planResult: Omit<PlanResult, 'planId'>,
    planId: number
  ): Promise<void> {
    await this.planResultDao.savePlanResult(planResult, planId)
    planResultStore.update(upsertEntities({ ...planResult, planId }))
    planResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
  }

  /* TODO: (#79273 & #79272) This should be a private method.

     Don't use this method directly, and try to implement it with the ResetCalculationService instead.
     It's only public for now, because of FavoriteProfile TODOs.
  */
  public async resetCalculation(resetPlanId: number): Promise<void> {
    const isCalculated = await this.getIsCalculated(resetPlanId)
    if (!isCalculated) {
      return
    }

    planResultCalculatedStore.update(upsertEntities({ calculated: false, id: resetPlanId }))
    planResultStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))
    screenshotStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))
    changedResultPartStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))

    await this.planResultResetCalculationDao.resetCalculation(resetPlanId).catch(() => {
      // If the reset fails, we remove the cache entry, so that the backend is queried again
      planResultCalculatedStore.update(deleteEntitiesByPredicate(({ id }) => id === resetPlanId))
    })
  }

  public async getArticleListForPlanExport(planId: number): Promise<ResultPart[] | undefined> {
    let planResult = planResultStore.query(getEntity(planId))

    if (!planResult?.partList) {
      planResult = await this.getPlanResultFromDao(planId, planResult)
    }

    return planResult?.partList
  }

  public async getArticleListWithCycleUsageForPlan(planId: number): Promise<PartList | undefined> {
    const articleList = await this.getArticleListForPlanExport(planId)

    if (!articleList) {
      return undefined
    } else {
      const partlist = articleList
      const plan = await this.planRepository.findOne(planId)
      if (!plan) {
        throw new Error('Plan not found')
      }
      let stock: Stock | undefined
      if (plan.stockId) {
        stock = await this.stockRepository.loadByIdWithArticles(plan.stockId)
      }

      return await PlanResultService.calculateCycleUsage(partlist, stock)
    }
  }

  public async getResultMessages(planId: number): Promise<PlanResultProtocol | null> {
    let planResult = planResultStore.query(getEntity(planId))
    if (planResult?.resultProtocol) {
      return planResult.resultProtocol
    } else {
      planResult = await this.getPlanResultFromDao(planId, planResult)

      return planResult?.resultProtocol ?? null
    }
  }

  public async getResultXML(planId: number): Promise<string | null> {
    let planResult = planResultStore.query(getEntity(planId))
    if (planResult?.resultXML) {
      return planResult.resultXML
    } else {
      planResult = await this.getPlanResultFromDao(planId, planResult)

      return planResult?.resultXML ?? null
    }
  }

  public async getResult(planId: number): Promise<PlanResult | undefined> {
    let planResult = planResultStore.query(getEntity(planId))
    if (planResult?.resultXML) {
      return planResult
    } else {
      planResult = await this.getPlanResultFromDao(planId, planResult)

      return planResult
    }
  }

  public async getIsCalculated(planId: number): Promise<boolean> {
    const planResultCalculated = planResultCalculatedStore.query(getEntity(planId))
    if (planResultCalculated !== undefined) {
      return planResultCalculated.calculated
    }

    const isCalculated = await this.planResultDao.getIsCalculated(planId)

    planResultCalculatedStore.update(upsertEntities({ calculated: isCalculated, id: planId }))

    return isCalculated
  }

  public async getResultImage(planId: number, thumbnail: boolean = false): Promise<string | null> {
    const planResult = planResultStore.query(getEntity(planId))
    const planResultCalculated = planResultCalculatedStore.query(getEntity(planId))

    let resultBase64Image: string | null = null
    if (planResult) {
      if (planResult.resultBase64Image && !thumbnail) {
        resultBase64Image = planResult.resultBase64Image
      } else {
        if (thumbnail) {
          const resultImage = await this.planResultDao.getPlanResultThumbnail(planId)
          resultBase64Image = resultImage?.resultBase64Thumbnail ?? null
        } else {
          const resultImage = await this.planResultDao.getPlanResultImage(planId)
          resultBase64Image = resultImage?.resultBase64Image ?? null
        }
      }
    } else if (planResultCalculated === undefined || planResultCalculated.calculated === true) {
      if (thumbnail) {
        const resultImage = await this.planResultDao.getPlanResultThumbnail(planId)
        resultBase64Image = resultImage ? resultImage.resultBase64Thumbnail : null
      } else {
        const resultImage = await this.planResultDao.getPlanResultImage(planId)
        resultBase64Image = resultImage ? resultImage.resultBase64Image : null
      }
    }

    // TODO Ideally we should be consistent in the database here, but there's no validation for it now
    if (resultBase64Image && !resultBase64Image.startsWith('data:image')) {
      resultBase64Image = `data:image/png;base64,${resultBase64Image}`
    }

    if (resultBase64Image) {
      this.setPlanResultImageInStore(planId, resultBase64Image, thumbnail, planResult)
    }

    return resultBase64Image
  }

  private setPlanResultImageInStore(
    planId: number,
    resultBase64Image: string,
    thumbnail: boolean,
    currentPlanResult?: PlanResult
  ): void {
    const planResult = currentPlanResult ?? {
      planId,
    }

    if (thumbnail) {
      planResult.resultBase64Thumbnail = resultBase64Image
    } else {
      planResult.resultBase64Image = resultBase64Image
    }

    planResultStore.update(upsertEntities({ ...planResult, planId }))
    planResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
  }

  private async getPlanResultFromDao(
    planId: number,
    storePlanResult?: PlanResult
  ): Promise<PlanResult | undefined> {
    const planResult = await this.planResultDao.getPlanResult(planId)
    if (planResult && storePlanResult?.resultBase64Image) {
      planResult.resultBase64Image = storePlanResult.resultBase64Image
    }

    if (planResult) {
      planResultStore.update(upsertEntities({ ...planResult, planId }))
      planResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
    }

    return planResult
  }
}
