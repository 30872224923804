import { Pipe, PipeTransform } from '@angular/core'
import { Translation } from '../../services/translation.service'

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private readonly translate: Translation) {}
  transform(time: Date): string {
    const now = new Date()
    const seconds = Math.floor((now.getTime() - time.getTime()) / 1000)

    if (seconds < 60) {
      return this.translate.translate('HOME.TIME_AGO.EDITED_NOW')
    } else if (seconds < 120) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.1MIN')
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan: '',
        timeUnit,
      })
      // if less than one hour, show minutes
    } else if (seconds < 3600) {
      const timeSpan = this.calcTime(seconds, 60)
      const timeUnit = this.translate.translate('HOME.TIME_AGO.MINUTES')
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan,
        timeUnit,
      })

      // if less than 2 hours, show 1 hour
    } else if (seconds < 7200) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.1HOUR')
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan: '',
        timeUnit,
      })

      // if less than a day, show hours
    } else if (seconds < 86400) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.HOURS')
      const timeSpan = this.calcTime(seconds, 3600)
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan,
        timeUnit,
      })

      // if less than 2 days, show 1 day
    } else if (seconds < 172800) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.1DAY')
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan: '',
        timeUnit,
      })

      // if less than a month, show days
    } else if (seconds < 2628000) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.DAYS')
      const timeSpan = this.calcTime(seconds, 86400)
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan,
        timeUnit,
      })
    }
    // if less than a year, show months
    else if (seconds < 3.154e7) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.MONTHS')
      const timeSpan = this.calcTime(seconds, 2.628e6)
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan,
        timeUnit,
      })
    }
    // if less than 2 years, show 1 year
    else if (seconds < 6.307e7) {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.1YEAR')
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan: '',
        timeUnit,
      })
    }
    // if less than 2 years, show 1 year
    else {
      const timeUnit = this.translate.translate('HOME.TIME_AGO.YEARS')
      const timeSpan = this.calcTime(seconds, 3.154e7)
      return this.translate.translate('HOME.TIME_AGO.EDITED', {
        timeSpan,
        timeUnit,
      })
    }
  }

  calcTime(seconds: number, factor: number): number {
    return Math.floor(seconds / factor)
  }
}
