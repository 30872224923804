import { createStore } from '@ngneat/elf'
import {
  deleteEntitiesByPredicate,
  getAllEntitiesApply,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { PlanOutline } from '../models/plan/PlanOutline'
import { PlanOutlineDao } from '../services/dao/plan-outline.dao'
import { CreatePlanOutlineCommandParam } from '../../generated/efp-api'

const store = createStore({ name: 'planOutline' }, withEntities<PlanOutline>())

@Injectable({
  providedIn: 'root',
})
export class PlanOutlineRepository {
  constructor(private readonly planOutlineDao: PlanOutlineDao) {}

  public async create(outlines: CreatePlanOutlineCommandParam[]): Promise<void> {
    await this.planOutlineDao.create(outlines)

    // TODO: optimistic create of outlines or backend return
  }

  public async findAllOutlinesByPlanId(planId: number): Promise<PlanOutline[]> {
    let outlines = store.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.planId === planId,
      })
    )

    if (outlines && outlines.length > 0) {
      return outlines
    } else {
      outlines = await this.planOutlineDao.findAllOutlinesByPlanId(planId)
      store.update(upsertEntities(outlines))
      return outlines
    }
  }

  public async filteredPlanIdsWithOutlines(planIds: number[]): Promise<number[]> {
    const outlines = store.query(
      getAllEntitiesApply({
        filterEntity: (e) => planIds.includes(e.planId),
      })
    )

    if (outlines && outlines.length > 0) {
      return outlines.map((outline) => outline.planId)
    } else {
      return this.planOutlineDao.filteredPlanIdsWithOutlines(planIds)
    }
  }

  public async removeAllByPlanId(planId: number): Promise<void> {
    await this.planOutlineDao.removeAllByPlanId(planId)
    store.update(deleteEntitiesByPredicate((e) => e.planId === planId))
  }
}
