import { Injectable } from '@angular/core'
import { AlertController, NavController } from '@ionic/angular'
import { PlanType } from 'formwork-planner-lib'
import { Plan } from '../models/plan'
import { AuthenticationService } from './authentication.service'
import { LimitService } from './limit.service'
import { Translation } from './translation.service'
import { ZoomAndPanService } from './zoom-and-pan.service'
import {
  PlanCreateCommandParams,
  PlanType as ApiPlanType,
  NavStep,
  PlansModel,
} from '../../generated/efp-api'
import { Project } from '../models/project'
import { PlanCreationRepository } from '../repositories/plan-creation.repository'
import { PlanRepository } from '../repositories/plan.repository'
import { ProjectRepository } from '../repositories/project.repository'

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private readonly translation: Translation,
    private readonly alertCtrl: AlertController,
    private readonly navCtrl: NavController,
    private readonly planRepository: PlanRepository,
    private readonly limitService: LimitService,
    private readonly authService: AuthenticationService,
    private readonly zoomAndPanService: ZoomAndPanService,
    private readonly projectRepository: ProjectRepository,
    private readonly planCreationRepository: PlanCreationRepository
  ) {}

  public static mapPlanModelToPlan(model: PlansModel): Plan {
    return {
      id: model.id,
      name: model.name,
      date: new Date(model.date),
      lastUsed: new Date(model.lastUsed),
      buildingType: model.buildingType === 'SLAB' ? PlanType.SLAB : PlanType.WALL,
      projectId: model.projectId,
      settingsId: model.settingsId,
      currentStep: model.currentStep,
      stockId: model.stockId ?? null,
      serializedMesh: model.serializedMesh ?? undefined,
    }
  }

  public async findOne(planId: number): Promise<Plan> {
    const plan = await this.planRepository.findOne(planId)
    if (!plan) {
      throw new Error('Plan not found in loadPlan')
    }
    return plan
  }

  public async createPlan(planType: string, projectId?: number, planName?: string): Promise<void> {
    const canCreatePlan = await this.limitService.canCreatePlan()
    if (!canCreatePlan) {
      await this.showAccountNecessaryAlert()
      return
    }

    let newPlanName = planName
    if (!newPlanName) {
      const translationKey =
        planType === PlanType.WALL ? 'HOME.QUICK_WALL_PREFIX' : 'HOME.QUICK_CEILING_PREFIX'
      newPlanName = this.translation.translate(translationKey)
    }

    const createPlanDto: PlanCreateCommandParams = {
      name: newPlanName,
      addNextPlanNumberToName: planName ? false : true,
      buildingType: planType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
      projectId,
      currentStep: NavStep.Plansettings,
    }

    let project: Project | undefined
    if (createPlanDto.projectId !== undefined && createPlanDto.projectId !== null) {
      project = await this.projectRepository.findOne(createPlanDto.projectId, false)
    }

    const plan = await this.planCreationRepository.createPlan(createPlanDto, project)

    this.beforeOpeningPlan()
    await this.navCtrl.navigateForward(['plansettings/' + plan.id], {
      queryParams: { backUrl: '/homepage' },
    })
  }

  private beforeOpeningPlan(): void {
    this.zoomAndPanService.reset()
  }

  private async showAccountNecessaryAlert(): Promise<void> {
    const alert = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      backdropDismiss: true,
      header: this.translation.translate('LIMIT.ACCOUNT_NEEDED_TITLE'),
      message: this.translation.translate('LIMIT.ACCOUNT_NEEDED_TEXT'),
      buttons: [
        {
          text: this.translation.translate('LIMIT.LATER'),
          role: 'cancel',
        },
        {
          text: this.translation.translate('LIMIT.LOGIN_REGISTER'),
          handler: async () => {
            await this.authService.logoutAndRedirectToLogin()
          },
        },
      ],
    })
    await alert.present()
  }
}
