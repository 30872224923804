import { registerPlugin } from '@capacitor/core'

export interface CountryCodeResult {
  countryCode: string
}

export interface CountryCodePlugin {
  get(): Promise<CountryCodeResult>
}

const CountryCode = registerPlugin<CountryCodePlugin>('CountryCode')

export default CountryCode
