import paper from 'paper/dist/paper-core'
import { AUX_GUIDELINE_COLOR } from '../../../../constants/colors'
import { AuxiliaryGuideline } from '../guidelines/AuxiliaryGuideline'

const CROSS_SIZE = 4
const CROSSING_POINT_STYLE = {
  strokeColor: AUX_GUIDELINE_COLOR,
  strokeWidth: 2,
} as paper.Style

export class AuxiliaryGuidelineItem extends paper.Group {
  constructor(private readonly auxiliaryGuideline: AuxiliaryGuideline) {
    super()

    const linePath = new paper.Path.Line(auxiliaryGuideline.line.start, auxiliaryGuideline.line.end)
    linePath.style = {
      strokeColor: AUX_GUIDELINE_COLOR,
      strokeWidth: 1,
    } as paper.Style

    this.addChild(linePath)

    auxiliaryGuideline.crossingPoints.forEach((point) => this.addCrossingPoint(point))
  }

  private addCrossingPoint(point: paper.Point): void {
    const leftTopTemplate = new paper.Point(-CROSS_SIZE, -CROSS_SIZE)
    const rightTopTemplate = new paper.Point(CROSS_SIZE, -CROSS_SIZE)
    const leftBottomTemplate = new paper.Point(-CROSS_SIZE, CROSS_SIZE)
    const rightBottomTemplate = new paper.Point(CROSS_SIZE, CROSS_SIZE)

    const line1 = new paper.Path.Line(point.add(leftTopTemplate), point.add(rightBottomTemplate))
    const line2 = new paper.Path.Line(point.add(rightTopTemplate), point.add(leftBottomTemplate))

    line1.style = CROSSING_POINT_STYLE
    line2.style = CROSSING_POINT_STYLE

    this.addChild(line1)
    this.addChild(line2)
  }
}
