import { AfterViewInit, Directive, HostListener, Input } from '@angular/core'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'
import { OnboardingService } from '../../services/onboarding.service'

export enum TriggerType {
  /*
  Triggers when the component is rendered ( after view init).
   */
  COMPONENT_RENDERED,
  /*
  Triggers when the user clicks on the component.
   */
  CLICK,
}

/**
 * This directive allows to define triggers for the onboarding dialog.
 */
@Directive({
  selector: '[efpOnboardingTrigger]',
})
export class OnboardingTriggerDirective implements AfterViewInit {
  @Input('efpOnboardingTrigger') onboardingHintSeriesKey!: OnboardingHintSeriesKey
  @Input() triggeredBy: TriggerType = TriggerType.COMPONENT_RENDERED
  constructor(private onboardingService: OnboardingService) {}

  @HostListener('click') onClick(): void {
    if (this.triggeredBy === TriggerType.CLICK) {
      this.onboardingService.trigger(this.onboardingHintSeriesKey)
    }
  }

  ngAfterViewInit(): void {
    if (this.triggeredBy === TriggerType.COMPONENT_RENDERED) {
      this.onboardingService.trigger(this.onboardingHintSeriesKey)
    }
  }
}
