<ion-list>
  <ng-container>
    <efp-article-item
      *ngFor="let article of filteredArticles; trackBy: trackByArticleId"
      [disabledAndChecked]="
        articleItemPage === 'product-catalog' && checkIfDisabled(article.articleId)
      "
      [article]="article"
      [deletable]="deletable"
      [articleItemPage]="articleItemPage"
      [articleForm]="articleForm"
      [selected]="selectedArticleIds.includes(article.articleId)"
      [showArticleNumber]="showArticleNumber"
      [showCalculation]="showCalculation"
      [showCycleQuantity]="showCycleQuantity"
      [cycleNumber]="cycleNumber"
      [showArticleAmount]="showArticleAmount"
      (deleteClicked)="deleteArticle($event)"
      (selectedChanged)="selectArticle($event)">
    </efp-article-item>
  </ng-container>
  <efp-safe-area-bottom></efp-safe-area-bottom>
</ion-list>
