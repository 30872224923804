import { Component } from '@angular/core'
import { PrimaryMenuService } from '../../../services/primary-menu.service'

@Component({
  selector: 'efp-collapsable-menu',
  templateUrl: './collapsable-menu.component.html',
  styleUrls: ['./collapsable-menu.component.scss'],
})
export class CollapsableMenuComponent {
  constructor(public primaryMenuService: PrimaryMenuService) {}

  closeMenu(): void {
    this.primaryMenuService.close()
  }
}
