import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { allUnitsOfLength, PlanType, UnitOfLength } from 'formwork-planner-lib'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { formworkSystems } from '../../../constants/formworkSystems'
import { PlanSettings } from '../../../models/planSettings'
import {
  MAX_SLAB_HEIGHT,
  MAX_SLAB_HEIGHT_IMPERIAL,
  MAX_SLAB_THICKNESS,
  MAX_SLAB_THICKNESS_IMPERIAL,
  MAX_SNAP_ANGLE_INTERVAL,
  MAX_SNAP_LENGTH_INTERVAL,
  MAX_SNAP_LENGTH_INTERVAL_IMPERIAL,
  MAX_WALL_THICKNESS,
  MAX_WALL_THICKNESS_IMPERIAL,
  MIN_SLAB_HEIGHT,
  MIN_SLAB_HEIGHT_IMPERIAL,
  MIN_SLAB_THICKNESS,
  MIN_SLAB_THICKNESS_IMPERIAL,
  MIN_SNAP_ANGLE_INTERVAL,
  MIN_SNAP_LENGTH_INTERVAL,
  MIN_SNAP_LENGTH_INTERVAL_IMPERIAL,
  MIN_WALL_HEIGHT,
  MIN_WALL_HEIGHT_IMPERIAL,
  MIN_WALL_THICKNESS,
  MIN_WALL_THICKNESS_IMPERIAL,
} from '../../../pages/planner/model/snapping/constants'
import { PlanSettingsService } from '../../../services/plan-settings.service'

export type DrawingSettingsMode = 'drawing' | 'plan' | 'cycle'

@Component({
  selector: 'efp-drawingsettings-form',
  templateUrl: './drawingsettings-form.component.html',
  styleUrls: ['drawingsettings-form.component.scss'],
})
export class DrawingsettingsFormComponent implements OnInit, OnDestroy {
  @Input() settingsId?: number
  @Input() buildingType?: PlanType
  @Input() mode: DrawingSettingsMode = 'drawing'

  @Output() readonly planSettingsChanged = new EventEmitter<PlanSettings>()

  private readonly destroy$ = new Subject<void>()

  get isDrawingMode(): boolean {
    return this.mode === 'drawing'
  }

  get isPlanMode(): boolean {
    return this.mode === 'plan'
  }

  get isCycleMode(): boolean {
    return this.mode === 'cycle'
  }

  selectCustomHeaders = {
    cssClass: 'selectCustomHeader',
  }

  readonly unitsOfLength = allUnitsOfLength

  get measurementUnit(): AbstractControl<UnitOfLength> {
    return this.planSettingsService.drawSettingsForm.controls.measurementUnit
  }

  get lengthRastering(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.lengthRastering
  }

  get angleRastering(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.angleRastering
  }

  get wallThickness(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.wallThickness
  }

  get wallHeight(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.wallHeight
  }

  get slabThickness(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.slabThickness
  }

  get slabHeight(): AbstractControl {
    return this.planSettingsService.drawSettingsForm.controls.slabHeight
  }

  get angleRasteringInterval(): number[] {
    return [MIN_SNAP_ANGLE_INTERVAL, MAX_SNAP_ANGLE_INTERVAL]
  }

  get lengthRasteringInterval(): number[] {
    if (this.measurementUnit && this.measurementUnit.value === 'inch') {
      return [MIN_SNAP_LENGTH_INTERVAL_IMPERIAL, MAX_SNAP_LENGTH_INTERVAL_IMPERIAL]
    }
    return [MIN_SNAP_LENGTH_INTERVAL, MAX_SNAP_LENGTH_INTERVAL]
  }

  get wallThicknessInterval(): number[] {
    if (this.measurementUnit && this.measurementUnit.value === 'inch') {
      return [MIN_WALL_THICKNESS_IMPERIAL, MAX_WALL_THICKNESS_IMPERIAL]
    }
    return [MIN_WALL_THICKNESS, MAX_WALL_THICKNESS]
  }

  get slabThicknessInterval(): number[] {
    if (this.measurementUnit && this.measurementUnit.value === 'inch') {
      return [MIN_SLAB_THICKNESS_IMPERIAL, MAX_SLAB_THICKNESS_IMPERIAL]
    }
    return [MIN_SLAB_THICKNESS, MAX_SLAB_THICKNESS]
  }

  get wallHeightInterval(): number[] {
    const fs = formworkSystems.find((val) => val.id === this.planSettings.formworkWall)
    if (this.measurementUnit && this.measurementUnit.value === 'inch') {
      return [MIN_WALL_HEIGHT_IMPERIAL, fs ? fs.maxHeightImperial : 0]
    }
    return [MIN_WALL_HEIGHT, fs ? fs.maxHeight : 0]
  }

  get slabHeightInterval(): number[] {
    if (this.measurementUnit && this.measurementUnit.value === 'inch') {
      return [MIN_SLAB_HEIGHT_IMPERIAL, MAX_SLAB_HEIGHT_IMPERIAL]
    }
    return [MIN_SLAB_HEIGHT, MAX_SLAB_HEIGHT]
  }

  planSettings!: PlanSettings

  constructor(public planSettingsService: PlanSettingsService) {}

  async ngOnInit(): Promise<void> {
    let planSettings: PlanSettings | undefined
    if (this.settingsId !== undefined) {
      planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(this.settingsId)
    } else {
      planSettings = await this.planSettingsService.getDefaultProjectPlanSettingsAndSetLastUnit()
    }

    if (planSettings) {
      this.planSettings = planSettings
    } else {
      throw new Error('DrawingsettingsFormComponent - No plan settings found')
    }

    this.planSettingsService.drawSettingsForm.patchValue(
      {
        ...this.planSettings,
      },
      { emitEvent: false }
    )

    this.planSettingsService.drawSettingsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updatePlanSettings()
      })

    this.planSettingsService.formworkSettingsChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.planSettings.formworkWall = val.formworkWall
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  updatePlanSettings(): void {
    if (this.planSettings) {
      if (this.measurementUnit.valid) {
        this.planSettings.measurementUnit = this.measurementUnit.value
      }
      if (this.lengthRastering.valid) {
        this.planSettings.lengthRastering = this.lengthRastering.value
      }
      if (this.angleRastering.valid) {
        this.planSettings.angleRastering = this.angleRastering.value
      }
      if (this.wallHeight.valid) {
        this.planSettings.wallHeight = this.wallHeight.value
      }
      if (this.slabHeight.valid) {
        this.planSettings.slabHeight = this.slabHeight.value
      }
      if (this.wallThickness.valid) {
        this.planSettings.wallThickness = this.wallThickness.value
      }
      if (this.slabThickness.valid) {
        this.planSettings.slabThickness = this.slabThickness.value
      }

      this.planSettingsChanged.emit(this.planSettings)
    }
  }
}
