import paper from 'paper/dist/paper-core'

export class AngleIndicator {
  private readonly group: paper.Group
  private readonly indicator: paper.Path
  private readonly layer: paper.Layer

  private previousScale: number

  constructor(private readonly paperScope: paper.PaperScope) {
    this.layer = paperScope.project.addLayer(new paper.Layer())
    this.layer.name = 'Angle Indicator Layer'

    this.indicator = new paper.Path()
    this.indicator.strokeColor = new paper.Color('#9ACCA5')
    this.indicator.strokeWidth = 1
    this.layer.addChild(this.indicator)

    this.group = new paper.Group()
    this.group.opacity = 0.5
    this.layer.addChild(this.group)

    this.previousScale = 1

    createGeometry(this.group)
    this.hide()
  }

  draw(x: number, y: number): void {
    if (
      Math.abs(x - this.group.position.x) < Number.EPSILON &&
      Math.abs(y - this.group.position.y) < Number.EPSILON
    ) {
      return
    }

    this.group.position = new paper.Point(x, y)
  }

  hide(): void {
    this.layer.visible = false
    this.indicator.removeSegments()
  }

  indicate(startPoint: paper.Point, endPoint: paper.Point): void {
    this.indicator.removeSegments()
    this.indicator.add(startPoint)
    this.indicator.add(endPoint)
  }

  show(x: number, y: number): void {
    this.layer.visible = true
    this.draw(x, y)
  }

  public updateScale(): void {
    this.group.scale(1 / this.previousScale)

    this.previousScale = 1 / this.paperScope.view.zoom
    this.group.scale(this.previousScale)
  }
}

function createGeometry(group: paper.Group): void {
  drawOuterCircle(group)
  drawBigTicks(group)
  drawSmallTicks(group)
  drawAngleText(group)
  drawInnerCircle(group)
}

function drawBigTicks(group: paper.Group): void {
  const steps = 15

  for (let i = 1; i < 360 / steps; i++) {
    const line = new paper.Path()
    line.strokeColor = new paper.Color('#ccc')
    line.strokeWidth = 1
    line.add(new paper.Point(0, -100))
    line.add(new paper.Point(0, 100))
    line.rotate(i * steps)
    group.addChild(line)
  }
}

function drawSmallTicks(group: paper.Group): void {
  const steps = 5

  for (let i = 1; i < 360 / steps; i++) {
    const line = new paper.Path()
    line.strokeColor = new paper.Color('#ccc')
    line.strokeWidth = 1
    line.add(new paper.Point(0, -100))
    line.add(new paper.Point(0, 100))
    line.rotate(i * steps)

    group.addChild(line)
  }
}

function drawAngleText(group: paper.Group): void {
  for (let i = 0; i < 360 / 15; i++) {
    const point = new paper.Point(-15, -10)
    const size = new paper.Size(30, 15)
    const rec = new paper.Path.Rectangle(point, size)
    rec.fillColor = new paper.Color('#EFEFF4')

    const text = new paper.PointText(new paper.Point(0, 0))
    text.justification = 'center'
    text.fillColor = new paper.Color('grey')
    text.content = i * 15 + ''

    const textGroup = new paper.Group([rec, text])
    textGroup.position = new paper.Point(0, -100)
    textGroup.rotate(i * 15, new paper.Point(0, 0))

    group.addChild(textGroup)
  }
}

function drawOuterCircle(group: paper.Group): void {
  const c = new paper.Path.Circle({
    radius: 100,
    strokeColor: '#ccc',
  })

  group.addChild(c)
}

function drawInnerCircle(group: paper.Group): void {
  const c = new paper.Path.Circle({
    radius: 20,
    fillColor: '#EFEFF4',
  })

  group.addChild(c)
}
