import { PlanAccessory } from '../../models/plan/PlanAccessory'
import { PlanAccessoryLine } from '../../models/plan/PlanAccessoryLine'

export abstract class PlanAccessoryLineDao {
  abstract createAccessoryLines(
    accessoryLines: Omit<PlanAccessoryLine, 'id'>[]
  ): Promise<PlanAccessoryLine[]>

  abstract updateAccessoryLineAssignments(
    accessoryLines: Pick<PlanAccessoryLine, 'id' | 'accessoriesAsString'>[]
  ): Promise<void>

  abstract getAccessoriesForPlan(planId: number): Promise<PlanAccessory[]>

  abstract getAccessoryLinesForPlan(planId: number): Promise<PlanAccessoryLine[]>

  abstract deleteAccessoryLinesForPlan(planId: number): Promise<void>
}
