<div class="edit-detail-view-container">
  <div
    class="efp-sidebar left-sidebar"
    [ngClass]="{ collapsed: leftSidebarCollapsed }"
    *ngIf="sidebarPosition === SidebarPosition.LEFT || sidebarPosition === SidebarPosition.BOTH">
    <div class="efp-sidebar-content">
      <ng-content select="[leftSidebar]"></ng-content>
    </div>
    <button
      class="efp-sidebar-web-button left-sidebar"
      [ngClass]="{ collapsable: collapsableLeftSidebar }"
      (click)="onLeftCollapseButton()">
      <ion-icon
        *ngIf="!leftSidebarCollapsed"
        color="primary"
        src="assets/icon/nav-arrow-left.svg"></ion-icon>
      <ion-icon
        *ngIf="leftSidebarCollapsed"
        color="primary"
        src="assets/icon/nav-arrow-right.svg"></ion-icon>
    </button>
  </div>
  <ion-content efpKeyboardShortcut class="efp-sidebar-main-content">
    <ng-content></ng-content>
  </ion-content>
  <div
    class="efp-sidebar right-sidebar"
    [ngClass]="{ collapsed: rightSidebarCollapsed }"
    *ngIf="sidebarPosition === SidebarPosition.RIGHT || sidebarPosition === SidebarPosition.BOTH">
    <div class="efp-sidebar-content">
      <ng-content select="[rightSidebar]"></ng-content>
    </div>
    <button class="efp-sidebar-web-button right-sidebar" (click)="onRightCollapseButton()">
      <ion-icon
        *ngIf="!rightSidebarCollapsed"
        color="primary"
        src="assets/icon/nav-arrow-right.svg"></ion-icon>
      <ion-icon
        *ngIf="rightSidebarCollapsed"
        color="primary"
        src="assets/icon/nav-arrow-left.svg"></ion-icon>
    </button>
  </div>
</div>
