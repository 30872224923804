import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { Mesh } from 'formwork-planner-lib'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { Model } from '../../../pages/planner/model/Model'
import { RenderService } from '../../../pages/planner/services/render.service'
import { Mode } from '../../../pages/planner/types/mode'
import { VisibilityMenuComponent } from '../visibility-menu/visibility-menu.component'
import { PlanVisibilitySettingsRepository } from '../../../repositories/plan-visibility-settings.repository'
import { checkIfMobileLayout } from '../../../constants/layout'

@Component({
  selector: 'efp-primary-planner-toolbar',
  templateUrl: 'primary-planner-toolbar.component.html',
  styleUrls: ['primary-planner-toolbar.component.scss'],
})
export class PrimaryPlannerToolbarComponent implements OnInit {
  @ViewChild('toolbarFooter', { read: ElementRef }) toolbarFooter?: ElementRef

  @Input() renderService?: RenderService<Model<Mesh>>
  @Input() mode: Mode = Mode.DRAW
  @Input() planVisibilitySettings?: PlanVisibilitySettings
  @Input() model?: Model<Mesh>

  get lengthLabelVisible(): boolean {
    return this.renderService?.lengthLabelsVisible ?? false
  }

  get angleLabelVisible(): boolean {
    return this.renderService?.angleLabelsVisible ?? false
  }

  get meshLabelsVisible(): boolean {
    return this.renderService?.meshHighlightVisible ?? false
  }

  get auxiliaryGuidelinesVisible(): boolean {
    return this.renderService?.auxiliaryGuidelinesVisible ?? false
  }

  ngOnInit(): void {
    if (this.planVisibilitySettings) {
      this.planVisibilitySettingsRepository
        .getVisibilitySettings$(this.planVisibilitySettings?.id)
        .subscribe((visibilitySetting) => {
          if (visibilitySetting) void this.setVisibility(visibilitySetting)
        })
    }
  }

  constructor(
    public popoverController: PopoverController,
    private readonly planVisibilitySettingsRepository: PlanVisibilitySettingsRepository
  ) {}

  async presentVisibilityPopover(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: VisibilityMenuComponent,
      cssClass: 'visibility-menu',
      event: ev,
      showBackdrop: false,
      translucent: true,
      componentProps: {
        planVisibilitySettings: this.planVisibilitySettings,
        mode: this.mode,
      },
      alignment: 'start',
      side: 'end',
    })

    if (checkIfMobileLayout()) popover.side = 'bottom'

    await popover.present()
  }

  async setVisibility(data: PlanVisibilitySettings): Promise<void> {
    if (this.renderService) {
      this.renderService.setVisibilitySettings(data, this.mode)
    }
  }
}
