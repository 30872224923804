import paper from 'paper/dist/paper-core'
import { Edge, MeshPoint, SlabMesh } from 'formwork-planner-lib'

export function createSlabPath(mesh: SlabMesh): paper.CompoundPath {
  const slabCompoundPath = new paper.CompoundPath({ children: [] })
  const foundEdges: Edge[] = []
  const unusedPoints = Array.from(mesh.points)

  if (unusedPoints.length === 0) {
    return slabCompoundPath
  }

  let startPoint = unusedPoints[0]
  let endPoints = mesh.getLooseEnds(mesh)

  let firstEndPoint = endPoints.shift()
  if (firstEndPoint) {
    startPoint = firstEndPoint
  }

  while (unusedPoints.length > 0) {
    unusedPoints.splice(unusedPoints.indexOf(startPoint), 1)

    const edges = Array.from(startPoint.edges)
    const line = new paper.Path()
    line.add(startPoint)
    const usedPoints: MeshPoint[] = [startPoint]

    let currentEdge = edges ? edges[0] : undefined
    while (currentEdge) {
      currentEdge.realLength = currentEdge.endPoint.subtract(currentEdge.startPoint).length
      foundEdges.push(currentEdge)

      const otherPoint = currentEdge.getOtherPoint(startPoint)

      if (usedPoints.indexOf(otherPoint) !== -1) {
        line.closed = true
        break
      }

      line.add(otherPoint)
      usedPoints.push(otherPoint)
      unusedPoints.splice(unusedPoints.indexOf(otherPoint), 1)
      endPoints = endPoints.filter((point) => point !== otherPoint)
      currentEdge = Array.from(otherPoint.edges).find(
        (edge) => startPoint !== edge.startPoint && startPoint !== edge.endPoint
      )

      startPoint = otherPoint
    }

    if (isClockwise(line)) {
      line.reverse()
    }

    slabCompoundPath.addChild(line)

    firstEndPoint = endPoints.shift()
    if (firstEndPoint !== undefined) {
      startPoint = firstEndPoint
    } else if (unusedPoints.length > 0) {
      startPoint = unusedPoints[0]
    }
  }

  return slabCompoundPath
}

function isClockwise(path: paper.Path): boolean {
  let sum = 0
  for (let i = 0; i < path.segments.length; i++) {
    const p0 = path.segments[i].point
    const p1 = path.segments[(i + 1) % path.segments.length].point
    sum += (p1.x - p0.x) * (p1.y + p0.y)
  }

  return sum > 0
}
