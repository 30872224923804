import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-article-image',
  templateUrl: 'article-image.component.html',
  styleUrls: ['article-image.component.scss'],
})
export class ArticleImageComponent {
  @Input() articleId!: string
}
