import paper from 'paper/dist/paper-core'
import { Hoverable } from './Hoverable'

export enum InputOverlayPositionSide {
  Top,
  TopRight,
  Right,
  BottomRight,
  Bottom,
  BottomLeft,
  Left,
  TopLeft,
}

export abstract class InputLabel extends paper.Group implements Hoverable {
  protected constructor() {
    super()
  }

  public abstract get inputOverlayPosition(): paper.Point

  public abstract get isClickable(): boolean

  public abstract hide(): void

  public abstract hover(): void

  public abstract unhover(): void
}
