import { Injectable } from '@angular/core'
import { CycleBoundary, CycleSymbol, PlanType } from 'formwork-planner-lib'
import JSZip from 'jszip'
import {
  ACCESSORY_LINES_FILE_NAME,
  CHANGED_PART_LIST_FILE_NAME,
  CYCLE_BOUNDARIES_FILE_NAME,
  CYCLE_SYMBOLS_FILE_NAME,
  FAVOURITES_BLACKLIST_ARTICLES,
  FAVOURITES_PROFILE_FILE_NAME,
  PLAN_JSON_FILE_NAME,
  PLAN_OUTLINE_JSON_FILE_NAME,
  PLAN_SETTINGS_JSON_FILE_NAME,
  PROTOCOL_JSON_FILE_NAME,
  RESULT_JSON_FILE_NAME,
  RESULT_PNG_FILE_NAME,
  RESULT_THUMBNAIL_PNG_FILE_NAME,
  RESULT_XML_FILE_NAME,
  SCREENSHOTS_FILE_NAME,
  STOCK_FILE_NAME,
  VERSION_FILE_NAME,
} from '../../constants/files'
import { OLD_FAVOURITES_VERSION, SHARE_ZIP_VERSION } from '../../constants/versions'
import { FavouriteProfile } from '../../models/favourites'
import { Plan } from '../../models/plan'
import { PlanAccessoryLine } from '../../models/plan/PlanAccessoryLine'
import { PlanOutline } from '../../models/plan/PlanOutline'
import { PlanSettings } from '../../models/planSettings'
import { Project } from '../../models/project'
import { Screenshot } from '../../models/screenshot'
import { Stock } from '../../models/stock'
import { PartListItem } from '../../pages/result/components/part-list/model/partListItem'
import { ImportFileType } from '../file-handler.service'
import { FileService } from '../file.service'
import { LoadingSpinnerService } from '../loading-spinner.service'
import { PlanSettingsService } from '../plan-settings.service'
import { Translation } from '../translation.service'
import {
  PlanCreateCommandParams,
  PlanType as ApiPlanType,
  NavStep as ApiNavStep,
  UnitOfLength as ApiUnitOfLength,
  CreatePlanSettingsCommandParams,
  CreateStockCommandParams,
  FavouriteProfileCommandParams,
  CycleSymbolModel,
  CycleBoundaryCreateCommandParam,
  ScreenshotCommandParams,
  CreatePlanResultCommandParam,
  ChangedResultPartsCommandParam,
  ImportPlanOutlineCommandParam,
  BlacklistArticleModel,
  CreateBlacklistArticleParams,
} from '../../../generated/efp-api'
import { PlanResultService } from '../plan-result.service'
import { PlanCreationRepository } from '../../repositories/plan-creation.repository'
import { mapPlanResultToCreatePlanResultCommandParam } from '../dao/plan-result.dao'
import { FavouriteRepository } from '../../repositories/favourite.repository'
import { FavouritesService } from '../favourites.service'
import { StockRepository } from '../../repositories/stock.repository'
import { StockService } from '../stock.service'
import { PlanResultRepository } from '../../repositories/plan-result.repository'

export interface EFPZipVersions {
  version: number
}

@Injectable({
  providedIn: 'root',
})
export class EfpImportService {
  constructor(
    private readonly planSettingService: PlanSettingsService,
    private readonly fileService: FileService,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly translate: Translation,
    private readonly planCreationRepository: PlanCreationRepository,
    private readonly favouriteRepo: FavouriteRepository,
    private readonly favouritesService: FavouritesService,
    private readonly stockRepository: StockRepository,
    private readonly planResultRepository: PlanResultRepository
  ) {}

  public async pickEfpPlanFile(project: Project): Promise<Plan | undefined> {
    const file = await this.fileService.pickFile([ImportFileType.efp])
    if (file?.data) {
      const zip = await JSZip.loadAsync(file.data, { base64: true })
      return this.importEfpPlanFile(zip, project.id)
    }
    return
  }

  private async getParsedContent<T>(file: JSZip.JSZipObject): Promise<T> {
    const str = await file.async('string')
    const parsedContent: T = JSON.parse(str) as T
    return this.convertDates(parsedContent as Record<string, unknown>) as T
  }

  private isObject(value: unknown): value is Record<string, unknown> {
    return value != null && typeof value === 'object' && !Array.isArray(value)
  }

  private hasDatePattern(dateString: string): boolean {
    return /^\d{4}-\d{2}-\d{2}/.test(dateString)
  }

  private convertDates<T extends Record<string, unknown> | Record<string, unknown>[]>(obj: T): T {
    if (Array.isArray(obj)) {
      return (obj as unknown[]).map((item) =>
        this.convertDates(item as Record<string, unknown>)
      ) as unknown as T
    }
    const clone = Object.assign({}, obj) as Record<string, unknown>
    Object.keys(clone).forEach((key) => {
      const value = clone[key]
      if (typeof value === 'string' && !isNaN(Date.parse(value)) && this.hasDatePattern(value)) {
        clone[key] = new Date(value)
      } else if (this.isObject(value)) {
        clone[key] = this.convertDates(value) // Recurse into nested objects
      } else if (Array.isArray(value)) {
        clone[key] = (value as unknown[]).map((item) =>
          this.convertDates(item as Record<string, unknown>)
        ) // Recurse into nested arrays
      }
    })

    return clone as T
  }

  public async importEfpPlanFileWithLoadingSpinner(zip: JSZip, projectId: number): Promise<Plan> {
    return this.loadingSpinner.doWithLoadingSpinner(async () => {
      return this.importEfpPlanFile(zip, projectId)
    })
  }

  /**
   * Imports EFP plan files from a zip file for a given project
   * Throws an error if the import fails
   * @param zip JSZip object with the files
   * @param projectId id of the Project to assign the plan to
   * @returns Plan
   * @throws Import Error, should be handled by the caller!
   */
  private async importEfpPlanFile(zip: JSZip, projectId: number): Promise<Plan> {
    const versionData = zip.file(VERSION_FILE_NAME)

    if (versionData == null) {
      throw new Error('IMPORT.ERROR.NO_VERSION_FILE')
    }

    const zipVersions: EFPZipVersions = await this.getParsedContent(versionData)
    if (zipVersions.version !== SHARE_ZIP_VERSION) {
      throw new Error('IMPORT.ERROR.WRONG_VERSION')
    }

    const planData = zip.file(PLAN_JSON_FILE_NAME)
    if (planData == null) {
      throw new Error('IMPORT.ERROR.NO_PLAN_FILE')
    }

    let plan: Plan = await this.getParsedContent(planData)
    plan.date = new Date(plan.date)
    plan.lastUsed = new Date(plan.lastUsed)
    const planSettingsParams = await this.getPlanSettingsParamsFromZip(zip)

    let stockParams: CreateStockCommandParams | undefined
    if (plan.stockId != null) {
      stockParams = await this.getStockParamsFromZip(zip, plan.name)
    }

    const favouriteProfileParams = await this.getFavouriteProfileParamsFromZip(zip)
    const createBlacklistArticleParams = await this.getBlacklistArticleParamsFromZip(zip)
    const importPlanOutlineParams = await this.getPlanOutlineImportParamsFromZip(zip)
    const cycleSymbolModels = await this.getCycleSymbolModelsFromZip(zip, plan.id)
    const cycleBoundaryParams = await this.getCycleBoundaryParamsFromZip(zip, plan.id)
    const screenshotParams = await this.getScreenshotsParamsFromZip(zip)
    const planResultParams = await this.getPlanResultParamsFromZip(zip, plan.id)
    const changedResultPartsParams = await this.getChangedResultPartsFromZip(zip)

    plan.projectId = projectId
    const createParams: PlanCreateCommandParams = {
      addNextPlanNumberToName: false,
      buildingType: plan.buildingType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
      currentStep: plan.currentStep as ApiNavStep,
      name: plan.name,
      planSettingsParams,
      stockParams,
      favouriteProfileParams,
      createBlacklistArticleParams,
      importPlanOutlineParams,
      cycleSymbolModels,
      cycleBoundaryParams,
      screenshotParams,
      planResultParams,
      changedResultPartsParams,
      projectId,
      serializedMesh: plan.serializedMesh,
    }
    plan = await this.planCreationRepository.createPlan(createParams)

    this.planSettingService.changePlanSettingUnit(planSettingsParams.measurementUnit)

    const planSettings = await this.planSettingService.getPlanSettingsAndSetLastUnit(
      plan.settingsId
    )
    if (plan.buildingType === PlanType.WALL && planSettings?.wallFavId) {
      await this.favouriteRepo.findOneById(planSettings?.wallFavId)
    } else if (planSettings?.slabFavId) {
      await this.favouriteRepo.findOneById(planSettings?.slabFavId)
    }

    await this.favouritesService.loadAllFavourites()

    if (planResultParams) {
      await this.planResultRepository.getResult(plan.id)
    }

    return plan
  }

  private async getPlanSettingsParamsFromZip(zip: JSZip): Promise<CreatePlanSettingsCommandParams> {
    const planSettingsData = zip.file(PLAN_SETTINGS_JSON_FILE_NAME)

    if (planSettingsData == null) {
      throw new Error('IMPORT.ERROR.NO_PLAN_SETTINGS_FILE')
    }

    const planSettings: PlanSettings = await this.getParsedContent(planSettingsData)
    this.planSettingService.changePlanSettingUnit(planSettings.measurementUnit)

    const params: CreatePlanSettingsCommandParams = {
      angleRastering: planSettings.angleRastering,
      formworkSlab: planSettings.formworkSlab,
      formworkWall: planSettings.formworkWall,
      lengthRastering: planSettings.lengthRastering,
      measurementUnit: planSettings.measurementUnit as ApiUnitOfLength,
      slabFavouriteProfileId: planSettings.slabFavId,
      slabHeight: planSettings.slabHeight,
      slabThickness: planSettings.slabThickness,
      wallFavouriteProfileId: planSettings.wallFavId,
      wallHeight: planSettings.wallHeight,
      wallThickness: planSettings.wallThickness,
    }

    return params
  }

  private async getFavouriteProfileParamsFromZip(
    zip: JSZip
  ): Promise<FavouriteProfileCommandParams> {
    const favouriteProfileData = zip.file(FAVOURITES_PROFILE_FILE_NAME)

    if (favouriteProfileData == null) {
      throw new Error('IMPORT.ERROR.NO_FAVOURITE_PROFILE_FILE')
    }

    const favouriteProfile: FavouriteProfile = await this.getParsedContent(favouriteProfileData)
    if (favouriteProfile.isStandard) {
      favouriteProfile.name = this.translate.translate(
        'FORMWORK.' + favouriteProfile.formworkSystemId
      )
    }

    const params: FavouriteProfileCommandParams = {
      favouritesJson: JSON.stringify(favouriteProfile.values),
      basisJson: JSON.stringify(favouriteProfile.basis),
      formworkSystemId: favouriteProfile.formworkSystemId,
      isStandard: false,
      name: favouriteProfile.name,
      useOnlyRentableArticles: favouriteProfile.useOnlyRentableArticles,
      formworkVersion: favouriteProfile.formworkVersion ?? OLD_FAVOURITES_VERSION,
    }
    return params
  }

  private async getBlacklistArticleParamsFromZip(
    zip: JSZip
  ): Promise<CreateBlacklistArticleParams[] | null> {
    const blacklistArticleData = zip.file(FAVOURITES_BLACKLIST_ARTICLES)

    if (blacklistArticleData == null) {
      return null
    }

    const blacklistArticles: BlacklistArticleModel[] = await this.getParsedContent(
      blacklistArticleData
    )
    const params: CreateBlacklistArticleParams[] = blacklistArticles.map((article) => {
      return {
        articleId: article.articleId,
        favouriteProfileId: article.favouriteProfileId,
        name: article.name,
      }
    })

    return params
  }

  private async getStockParamsFromZip(
    zip: JSZip,
    planName: string
  ): Promise<CreateStockCommandParams> {
    const stockData = zip.file(STOCK_FILE_NAME)

    if (stockData == null) {
      throw new Error('IMPORT.ERROR.STOCK_NOT_EXPORTED')
    }

    const stock: Stock = await this.getParsedContent(stockData)
    const stockNames: string[] = (await this.stockRepository.fetchAll()).map((item) => item.name)
    stock.name = StockService.createUniqueStockName(stockNames, `${stock.name}_${planName}`)

    const params: CreateStockCommandParams = {
      date: stock.date.toISOString(),
      name: stock.name,
      articles: stock.articles,
    }
    return params
  }

  private async getCycleSymbolModelsFromZip(
    zip: JSZip,
    planId: number
  ): Promise<CycleSymbolModel[] | undefined> {
    const cycleSymbolData = zip.file(CYCLE_SYMBOLS_FILE_NAME)
    if (cycleSymbolData !== null) {
      const exportCycleSymbols: CycleSymbol[] = await this.getParsedContent(cycleSymbolData)
      return exportCycleSymbols.map((symbol) => {
        const cycleSymbolModel: CycleSymbolModel = {
          planId,
          posX: symbol.position.x,
          posY: symbol.position.y,
          cycleNumber: symbol.cycleNumber,
        }
        return cycleSymbolModel
      })
    }
    return undefined
  }

  private async getCycleBoundaryParamsFromZip(
    zip: JSZip,
    planId: number
  ): Promise<CycleBoundaryCreateCommandParam[] | undefined> {
    const cycleBoundaryData = zip.file(CYCLE_BOUNDARIES_FILE_NAME)
    if (cycleBoundaryData !== null) {
      const exportCycleBoundaries: CycleBoundary[] = await this.getParsedContent(cycleBoundaryData)
      return exportCycleBoundaries.map((boundary) => {
        const param: CycleBoundaryCreateCommandParam = {
          cycleNumberLeft: boundary.cycleNumberLeft,
          cycleNumberRight: boundary.cycleNumberRight,
          endx: boundary.end.x,
          endy: boundary.end.y,
          planId,
          startx: boundary.start.x,
          starty: boundary.start.y,
        }
        return param
      })
    }
    return undefined
  }

  private async getPlanResultParamsFromZip(
    zip: JSZip,
    planId: number
  ): Promise<CreatePlanResultCommandParam | undefined> {
    const resultJSONData = zip.file(RESULT_JSON_FILE_NAME)
    const resultPNGData = zip.file(RESULT_PNG_FILE_NAME)
    const resultThumbnailPNGData = zip.file(RESULT_THUMBNAIL_PNG_FILE_NAME)
    const resultXml = zip.file(RESULT_XML_FILE_NAME)
    const resultProtocol = zip.file(PROTOCOL_JSON_FILE_NAME)

    if (
      resultJSONData != null &&
      resultPNGData != null &&
      resultXml !== null &&
      resultProtocol !== null &&
      resultThumbnailPNGData !== null
    ) {
      const result = await PlanResultService.extractResult(zip, planId)
      return mapPlanResultToCreatePlanResultCommandParam(result)
    }
    return undefined
  }

  private async getChangedResultPartsFromZip(
    zip: JSZip
  ): Promise<ChangedResultPartsCommandParam[] | undefined> {
    const changedPartListData = zip.file(CHANGED_PART_LIST_FILE_NAME)
    if (changedPartListData !== null) {
      const changedPartList: PartListItem[] = await this.getParsedContent(changedPartListData)
      return changedPartList.map((part) => {
        const param: ChangedResultPartsCommandParam = {
          amount: part.orderAmount,
          articleId: part.part.articleId,
          planId: -1,
        }
        return param
      })
    }
    return undefined
  }

  private async getScreenshotsParamsFromZip(
    zip: JSZip
  ): Promise<ScreenshotCommandParams[] | undefined> {
    const screenshotsJSON = zip.file(SCREENSHOTS_FILE_NAME)

    if (screenshotsJSON != null) {
      const screenshots: Screenshot[] = await this.getParsedContent(screenshotsJSON)
      const params = screenshots.map((screenshot) => {
        const param: ScreenshotCommandParams = {
          cycle: screenshot.cycle,
          date: screenshot.date.toISOString(),
          defaultView: screenshot.defaultView,
          height: screenshot.height,
          name: screenshot.name,
          planId: screenshot.planId,
          screenshotData: screenshot.screenshot,
          width: screenshot.width,
        }
        return param
      })

      return params
    }
    return undefined
  }

  private async getPlanOutlineImportParamsFromZip(
    zip: JSZip
  ): Promise<ImportPlanOutlineCommandParam[]> {
    const outlineJsonFile = zip.file(PLAN_OUTLINE_JSON_FILE_NAME)
    const accessoryLineData = zip.file(ACCESSORY_LINES_FILE_NAME)

    if (!!outlineJsonFile) {
      const outlines: PlanOutline[] = await this.getParsedContent<PlanOutline[]>(outlineJsonFile)
      const accesoryLines = accessoryLineData
        ? await this.getParsedContent<PlanAccessoryLine[]>(accessoryLineData)
        : null

      const params = outlines.map((outline) => {
        let accessoriesAsString: string | null = null
        if (accesoryLines) {
          const accessoryLine = accesoryLines.find((line) => line.outlineId === outline.id)
          accessoriesAsString = accessoryLine?.accessoriesAsString ?? null
        }
        const model: ImportPlanOutlineCommandParam = {
          accessoriesAsString,
          endX: outline.end.x,
          endY: outline.end.y,
          planId: outline.planId,
          startX: outline.start.x,
          startY: outline.start.y,
          outlineType: outline.outlineType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
        }

        return model
      })
      return params
    } else {
      throw new Error('Could not find outline file!')
    }
  }
}
