import { Directive, ElementRef, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[efpInputRestriction]',
})
export class InputRestrictionDirective {
  @Input('efpInputRestriction') inputRestriction!: string

  private element: ElementRef

  constructor(element: ElementRef) {
    this.element = element
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): boolean {
    const regex = new RegExp(this.inputRestriction)
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode)

    if (regex.test(str)) {
      return true
    }

    event.preventDefault()
    return false
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): boolean {
    if (event.clipboardData == null) {
      return false
    }

    const regex = new RegExp(this.inputRestriction)
    const str = event.clipboardData.getData('text/plain')

    if (regex.test(str)) {
      return true
    }

    event.preventDefault()
    return false
  }
}
