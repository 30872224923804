import { ErrorHandler, Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { extractErrorMessage } from '../utils/error-util'
import { ErrorPopupService } from './error-popup.service'

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private readonly errorPopup: ErrorPopupService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleError(error: any): Promise<void> {
    // Display error popup while whitescreen is visible, otherwise fall through to the global console.error
    if (!environment.applicationReady) {
      const message = extractErrorMessage(error)
      await this.errorPopup.showError(message?.replace(/\n/g, '<br/>'))
    } else {
      console.error(error)
    }
  }
}
