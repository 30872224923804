import paper from 'paper/dist/paper-core'

export class AccessoryPart {
  public color = new paper.Color('#000') //color for displaying the accessory
  public orderPosition = 0 //color for displaying the accessory

  constructor(public id: string) {}

  public matches(other: AccessoryPart): boolean {
    return this.id === other.id
  }
}
