import { createStore } from '@ngneat/elf'
import { getEntity, selectEntity, upsertEntities, withEntities } from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { PlanVisibilitySettingsDao } from '../services/dao/plan-visibility-settings.dao'
import { PlanVisibilitySettings } from '../models/plan-visibility-settings'
import { Observable } from 'rxjs'

const store = createStore(
  { name: 'planVisibilitySettings' },
  withEntities<PlanVisibilitySettings>()
)

@Injectable({
  providedIn: 'root',
})
export class PlanVisibilitySettingsRepository {
  constructor(private readonly planVisibilitySettingsDao: PlanVisibilitySettingsDao) {}

  public async findOne(id: number): Promise<PlanVisibilitySettings | undefined> {
    let settings = store.query(getEntity(id))

    if (!settings) {
      settings = await this.planVisibilitySettingsDao.findOne(id)

      if (settings) {
        store.update(upsertEntities(settings))
      }
    }
    return settings
  }

  public getVisibilitySettings$(id: number): Observable<PlanVisibilitySettings | undefined> {
    return store.pipe(selectEntity(id))
  }

  public async update(planVisibilitySettings: PlanVisibilitySettings): Promise<void> {
    await this.planVisibilitySettingsDao.update(planVisibilitySettings)

    store.update(upsertEntities(planVisibilitySettings))
  }
}
