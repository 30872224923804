import {
  Edge,
  getClosestEdgesOnPoint,
  getDestinationOuter,
  intersectLines,
  LineSide,
  MeshPoint,
  PlanType,
  SNAP_MINIMUM_ANGLE,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { SNAP_FALLBACK_ANGLE_INTERVAL } from './constants'

export function snapFreePoint(
  point: paper.Point,
  anchorPoint: paper.Point,
  target: PlanType,
  angleRastering: number,
  lengthRastering: number
): paper.Point {
  const angleSnappedPoint = getAngleSnappedPoint(
    point,
    anchorPoint,
    anchorPoint.add(new paper.Point(0, 1)),
    target,
    angleRastering,
    false,
    true
  ).point
  return getLengthSnappedPoint(angleSnappedPoint, anchorPoint, lengthRastering)
}

export function snapSingleNeighborAnchoredPoint(
  anchorPoint: MeshPoint,
  destination: paper.Point,
  neighborEdge: Edge,
  target: PlanType,
  angleRastering: number,
  lengthRastering: number,
  thickness: number = 0,
  outerCorner: paper.Point = anchorPoint,
  intoEdge?: Edge
): DirectionedSnapPoint | null {
  const destinationOuterPoint = getDestinationOuter(
    { anchor: anchorPoint, destination, edge: neighborEdge, thickness },
    outerCorner
  )

  if (destinationOuterPoint == null) {
    return null
  }

  const { point: angleSnappedPointOnCornerSide, correctionAngle } = getAngleSnappedPoint(
    destinationOuterPoint,
    outerCorner,
    outerCorner.add(neighborEdge.getOtherPoint(anchorPoint).subtract(anchorPoint)),
    target,
    angleRastering,
    true,
    false,
    intoEdge
  )
  const angleSnappedPoint = destination.rotate(-correctionAngle, outerCorner)
  const newPointOnCornerSide = getLengthSnappedPoint(
    angleSnappedPointOnCornerSide,
    outerCorner,
    lengthRastering
  )
  const newPoint = angleSnappedPoint.add(
    newPointOnCornerSide.subtract(angleSnappedPointOnCornerSide)
  )
  return {
    point: newPoint,
    anchoredDirection: newPointOnCornerSide.subtract(outerCorner).normalize(),
  }
}

export function snapAnchoredPoint(
  anchorPoint: MeshPoint,
  destination: paper.Point,
  thickness: number,
  target: PlanType,
  angleRastering: number,
  lengthRastering: number,
  outerCorner?: paper.Point,
  intoEdge?: Edge
): DirectionedSnapPoint | null {
  if (anchorPoint.edges.size === 0) {
    return null
  }

  const singleNeighbor = anchorPoint.edges.size === 1 ? [...anchorPoint.edges][0] : undefined

  if (singleNeighbor && outerCorner) {
    return snapSingleNeighborAnchoredPoint(
      anchorPoint,
      destination,
      singleNeighbor,
      target,
      angleRastering,
      lengthRastering,
      thickness,
      outerCorner,
      intoEdge
    )
  } else {
    const neighbors = getClosestEdgesOnPoint(anchorPoint, destination)

    const leftNeighbor = neighbors[LineSide.LEFT]
    const rightNeighbor = neighbors[LineSide.RIGHT]

    const { point: leftAngleSnappedPoint, correctionAngle: leftCorrectionAngle } =
      getAngleSnappedPoint(
        destination,
        anchorPoint,
        leftNeighbor.getOtherPoint(anchorPoint),
        target,
        angleRastering,
        true
      )

    const { point: rightAngleSnappedPoint, correctionAngle: rightCorrectionAngle } =
      getAngleSnappedPoint(
        destination,
        anchorPoint,
        rightNeighbor.getOtherPoint(anchorPoint),
        target,
        angleRastering,
        true
      )

    const angleSnappedPoint =
      Math.abs(leftCorrectionAngle) < Math.abs(rightCorrectionAngle)
        ? leftAngleSnappedPoint
        : rightAngleSnappedPoint

    const direction = angleSnappedPoint.subtract(anchorPoint).normalize()
    const normal = new paper.Point(direction.y, -direction.x).multiply(thickness / 2)

    const angleSnappedPointOnLeftSide = angleSnappedPoint.add(normal)
    const angleSnappedPointOnRightSide = angleSnappedPoint.subtract(normal)

    const leftNeighborLine = leftNeighbor.getLineOnSide(
      leftNeighbor.startPoint === anchorPoint ? LineSide.RIGHT : LineSide.LEFT
    )
    const rightNeighborLine = rightNeighbor.getLineOnSide(
      rightNeighbor.startPoint === anchorPoint ? LineSide.LEFT : LineSide.RIGHT
    )

    const leftInnerPoint = intersectLines(leftNeighborLine, {
      start: angleSnappedPointOnLeftSide,
      end: angleSnappedPointOnLeftSide.subtract(direction),
    })
    const rightInnerPoint = intersectLines(rightNeighborLine, {
      start: angleSnappedPointOnRightSide,
      end: angleSnappedPointOnRightSide.subtract(direction),
    })

    if (!leftInnerPoint || !rightInnerPoint) {
      return {
        point: getLengthSnappedPoint(angleSnappedPoint, anchorPoint, lengthRastering),
      }
    }

    if (
      angleSnappedPointOnLeftSide.subtract(leftInnerPoint).length >
      angleSnappedPointOnRightSide.subtract(rightInnerPoint).length
    ) {
      const newPointOnLeftSide = getLengthSnappedPoint(
        angleSnappedPointOnLeftSide,
        leftInnerPoint,
        lengthRastering
      )

      return { point: newPointOnLeftSide.subtract(normal) }
    } else {
      const newPointOnRightSide = getLengthSnappedPoint(
        angleSnappedPointOnRightSide,
        rightInnerPoint,
        lengthRastering
      )

      return { point: newPointOnRightSide.add(normal) }
    }
  }
}

export function getLengthSnappedPoint(
  point: paper.Point,
  anchorPoint: paper.Point,
  lengthRastering: number
): paper.Point {
  const direction = point.subtract(anchorPoint).normalize()
  const length = Math.round(point.subtract(anchorPoint).length / lengthRastering) * lengthRastering
  return anchorPoint.add(direction.multiply(length))
}

function getAngleSnappedPoint(
  point: paper.Point,
  anchorPoint: paper.Point,
  otherPoint: paper.Point,
  target: PlanType,
  angleRastering: number,
  avoidZero: boolean = false,
  skipMinCheck: boolean = false,
  intoEdge?: Edge
): { point: paper.Point; correctionAngle: number } {
  const vecToDestination = point.subtract(anchorPoint)
  const vecToOtherPoint = otherPoint.subtract(anchorPoint)

  // createEdgeInMesh always sets the endPoint to be the moving point
  // the startPoint is static and always the anchor point
  const vecToOriginalPoint = intoEdge?.endPoint.subtract(intoEdge?.startPoint)
  const angle = vecToDestination.getDirectedAngle(vecToOtherPoint)
  const originalAngle = vecToOriginalPoint?.getDirectedAngle(vecToOtherPoint)

  let snappedAngle = snapAngle(angle, target, angleRastering, skipMinCheck)

  if (originalAngle != null && Math.abs(angle - snappedAngle) > Math.abs(angle - originalAngle)) {
    snappedAngle = originalAngle
  }

  if (avoidZero && snappedAngle === 0) {
    if (angle < 0) {
      snappedAngle = -SNAP_MINIMUM_ANGLE[target]
    } else {
      snappedAngle = SNAP_MINIMUM_ANGLE[target]
    }
  }

  const correctionAngle = snappedAngle - angle
  const newPoint = point.rotate(-correctionAngle, anchorPoint)

  return { point: newPoint.clone(), correctionAngle }
}

function snapAngle(
  angle: number,
  target: PlanType,
  angleRastering: number,
  skipMinCheck: boolean = false
): number {
  const isCloseToMultiple = isCloseToMultipleOf(
    angle,
    angleRastering,
    Math.round(angleRastering / 2)
  )
  const angleIntervalToSnap = isCloseToMultiple ? angleRastering : SNAP_FALLBACK_ANGLE_INTERVAL

  let snappedAngle = Math.round(angle / angleIntervalToSnap) * angleIntervalToSnap
  if (!skipMinCheck) {
    if (snappedAngle < 0) {
      snappedAngle = Math.min(-SNAP_MINIMUM_ANGLE[target], snappedAngle)
    } else {
      snappedAngle = Math.max(SNAP_MINIMUM_ANGLE[target], snappedAngle)
    }
  }

  return snappedAngle
}

function isCloseToMultipleOf(
  angle: number,
  angleInterval: number,
  angleThreshold: number
): boolean {
  const closestSnappedAngle = Math.round(angle / angleInterval) * angleInterval
  const delta = Math.abs(angle - closestSnappedAngle)

  return delta <= angleThreshold
}

export interface DirectionedSnapPoint {
  point: paper.Point
  anchoredDirection?: paper.Point
}
