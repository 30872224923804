import { createStore } from '@ngneat/elf'
import {
  addEntities,
  deleteEntities,
  getAllEntitiesApply,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { BlacklistArticleModel } from '../../generated/efp-api'
import { BlacklistArticleDao } from '../services/dao/blacklist-article.dao'

const blacklistArticlesStore = createStore(
  { name: 'blacklistArticles' },
  withEntities<BlacklistArticleModel>()
)

@Injectable({
  providedIn: 'root',
})
export class BlacklistArticleRepository {
  public readonly articles$ = blacklistArticlesStore.pipe(selectAllEntities())

  constructor(private readonly blacklistArticleDao: BlacklistArticleDao) {}

  public async create(articles: BlacklistArticleModel[]): Promise<void> {
    const createdArticles = await this.blacklistArticleDao.createArticles(articles)
    blacklistArticlesStore.update(addEntities(createdArticles))
    return
  }

  public async delete(article: BlacklistArticleModel): Promise<void> {
    await this.blacklistArticleDao.delete(article.id)
    blacklistArticlesStore.update(deleteEntities(article.id))
  }

  public async findAllByFavouriteProfileId(
    favouriteProfileId: number
  ): Promise<BlacklistArticleModel[]> {
    let articles = blacklistArticlesStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.favouriteProfileId === favouriteProfileId,
      })
    )

    if (!articles || articles.length === 0) {
      articles = await this.blacklistArticleDao.findAllByFavouriteProfileId(favouriteProfileId)
      blacklistArticlesStore.update(upsertEntities(articles))
    }

    return articles
  }
}
