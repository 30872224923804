// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorMessage = (error: any): string => {
  let message = ''
  if (error.hasOwnProperty('message')) {
    message = error.message
  } else {
    message = JSON.stringify(error)
  }

  return message
}
