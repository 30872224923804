<div class="content-container">
  <div>
    <b> {{ onboardingHint.title | translate }} </b>
  </div>
  <p *ngFor="let textDescriptionLine of onboardingHint.textDescriptions">
    {{ textDescriptionLine | translate }}
  </p>
  <ng-container *ngIf="onboardingHint.videoUrl">
    <video
      *efpRecreateView="onboardingHint.videoUrl"
      class="onboarding-video"
      [autoplay]="'autoplay'"
      loop
      [muted]="'muted'"
      onpause="this.play()"
      [poster]="onboardingHint.videoUrl"
      playsinline>
      <source [src]="onboardingHint.videoUrl" type="video/mp4" />
    </video>
  </ng-container>
  <ol>
    <li *ngFor="let step of onboardingHint.steps">
      {{ step | translate }}
    </li>
  </ol>
</div>
