import { Component } from '@angular/core'
import { checkIfMobileLayout } from '../../../../constants/layout'

@Component({
  selector: 'efp-toolbar-divider',
  templateUrl: './toolbar-divider.component.html',
  styleUrls: ['./toolbar-divider.component.scss'],
})
export class ToolbarDividerComponent {
  public showDivider = !checkIfMobileLayout()
}
