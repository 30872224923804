import { inject, Injectable } from '@angular/core'
import { PlanCreateCommandParams, PlansApiClient } from '@efp/api'
import { Project } from '../../../models/project'
import { PlanCreationDao } from '../plan-creation.dao'
import { firstValueFrom } from 'rxjs'
import { Plan } from '../../../models/plan'
import { PlanService } from '../../plan.service'

@Injectable()
export class PlanCreationHttpDao extends PlanCreationDao {
  private readonly plansApiClient = inject(PlansApiClient)

  constructor() {
    super()
  }
  public async createPlan(
    params: PlanCreateCommandParams,
    _project?: Project | undefined
  ): Promise<Plan> {
    return PlanService.mapPlanModelToPlan(
      await firstValueFrom(this.plansApiClient.createPlan({ body: params }))
    )
  }
}
