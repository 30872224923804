/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreatePlanResultCommandParam } from '../models/create-plan-result-command-param';
import { PlanResultImageModel } from '../models/plan-result-image-model';
import { PlanResultModel } from '../models/plan-result-model';
import { PlanResultThumbnailModel } from '../models/plan-result-thumbnail-model';

@Injectable({ providedIn: 'root' })
export class PlanResultApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createPlanResult()` */
  static readonly CreatePlanResultPath = '/api/v1/plan-result';

  /**
   * Add planResult entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanResult$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanResult$Plain$Response(
    params: {
      body: CreatePlanResultCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.CreatePlanResultPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add planResult entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanResult$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanResult$Plain(
    params: {
      body: CreatePlanResultCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createPlanResult$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add planResult entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanResult()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanResult$Response(
    params: {
      body: CreatePlanResultCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.CreatePlanResultPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add planResult entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanResult$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanResult(
    params: {
      body: CreatePlanResultCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createPlanResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getPlanResult()` */
  static readonly GetPlanResultPath = '/api/v1/plan-result/{planId}';

  /**
   * Get planResult per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResult$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResult$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultModel>;
      })
    );
  }

  /**
   * Get planResult per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResult$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResult$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultModel> {
    return this.getPlanResult$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultModel>): PlanResultModel => r.body)
    );
  }

  /**
   * Get planResult per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResult$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultModel>;
      })
    );
  }

  /**
   * Get planResult per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResult(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultModel> {
    return this.getPlanResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultModel>): PlanResultModel => r.body)
    );
  }

  /** Path part for operation `deletePlanResult()` */
  static readonly DeletePlanResultPath = '/api/v1/plan-result/{planId}';

  /**
   * Delete planResult entry for plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePlanResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlanResult$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.DeletePlanResultPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete planResult entry for plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePlanResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlanResult(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deletePlanResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPlanResultCalculated()` */
  static readonly GetPlanResultCalculatedPath = '/api/v1/plan-result/{planId}/calculated';

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultCalculated$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultCalculated$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultCalculatedPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultCalculated$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultCalculated$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getPlanResultCalculated$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultCalculated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultCalculated$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultCalculatedPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultCalculated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultCalculated(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getPlanResultCalculated$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getPlanResultImage()` */
  static readonly GetPlanResultImagePath = '/api/v1/plan-result/{planId}/image';

  /**
   * Get planResult image per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultImage$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultImageModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultImagePath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultImageModel>;
      })
    );
  }

  /**
   * Get planResult image per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultImage$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultImageModel> {
    return this.getPlanResultImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultImageModel>): PlanResultImageModel => r.body)
    );
  }

  /**
   * Get planResult image per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultImage$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultImageModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultImagePath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultImageModel>;
      })
    );
  }

  /**
   * Get planResult image per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultImage(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultImageModel> {
    return this.getPlanResultImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultImageModel>): PlanResultImageModel => r.body)
    );
  }

  /** Path part for operation `getPlanResultThumbnail()` */
  static readonly GetPlanResultThumbnailPath = '/api/v1/plan-result/{planId}/thumbnail';

  /**
   * Get planResult thumbnail per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultThumbnail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultThumbnail$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultThumbnailModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultThumbnailPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultThumbnailModel>;
      })
    );
  }

  /**
   * Get planResult thumbnail per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultThumbnail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultThumbnail$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultThumbnailModel> {
    return this.getPlanResultThumbnail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultThumbnailModel>): PlanResultThumbnailModel => r.body)
    );
  }

  /**
   * Get planResult thumbnail per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanResultThumbnail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultThumbnail$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanResultThumbnailModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanResultApiClient.GetPlanResultThumbnailPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResultThumbnailModel>;
      })
    );
  }

  /**
   * Get planResult thumbnail per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanResultThumbnail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanResultThumbnail(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlanResultThumbnailModel> {
    return this.getPlanResultThumbnail$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResultThumbnailModel>): PlanResultThumbnailModel => r.body)
    );
  }

}
