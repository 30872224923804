// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Footinch from 'footinch'
import { UnitOfLength } from '../model'

const unitConversionTable: { [key in UnitOfLength]: number } = {
  mm: 10,
  cm: 1,
  m: 0.01,
  inch: 0.39371,
}

const cmToInchFormatter = Footinch.format.CM.to.FT.IN.FRAC(4)

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LengthUtil {
  export const formatCmToInch = (valueInCm: number): string => cmToInchFormatter(valueInCm)
  export const parseInchAsCm = (valueInInch: string): number => Footinch.parse.M(valueInInch) * 100

  export const convertCmToUnit = (valueInCm: number, unit: UnitOfLength): number =>
    valueInCm * unitConversionTable[unit]
  export const convertUnitToCm = (valueInUnit: number, unit: UnitOfLength): number =>
    valueInUnit / unitConversionTable[unit]

  // rounds to maximal two decimals
  export const roundForDisplay = (value: number, unit: UnitOfLength): number => {
    switch (unit) {
      case 'm':
        return Math.round(value * 1000) / 1000
      case 'cm':
        return Math.round(value * 10) / 10
      case 'mm':
        return Math.round(value)
      case 'inch':
        return Math.round(value * 1000000) / 1000000
    }
  }
}
