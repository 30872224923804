import { Injectable } from '@angular/core'
import { DataService } from '../../data.service'
import { OnboardingDao } from '../onboarding.dao'

@Injectable()
export class OnboardingSqlDao extends OnboardingDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  async createOnboardingTracking(onboardingHintSeriesKey: string): Promise<string[]> {
    const statement = 'INSERT INTO OnboardingTracking(onboardingHintSeriesKey) VALUES(?)'
    const values = [onboardingHintSeriesKey]
    await this.dataService.executeStatement(statement, values)
    return this.findAllTrackings()
  }

  async findAllTrackings(): Promise<string[]> {
    const statement = 'SELECT * FROM OnboardingTracking'
    const result = await this.dataService.executeStatement(statement)
    const trackings: string[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      trackings.push(row.onboardingHintSeriesKey)
    }
    return trackings
  }

  public async deleteByKey(key: string): Promise<void> {
    await this.dataService.executeStatement(
      'DELETE FROM OnboardingTracking WHERE OnboardingSeriesKey = ?',
      [key]
    )
  }

  public async deleteAll(): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM OnboardingTracking')
  }
}
