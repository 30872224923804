/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ProjectCommandParams } from '../models/project-command-params';
import { ProjectsModel } from '../models/projects-model';

@Injectable({ providedIn: 'root' })
export class ProjectsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllProjects()` */
  static readonly GetAllProjectsPath = '/api/v1/projects';

  /**
   * Returns all projects with or without plans.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProjects$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects$Plain$Response(
    params?: {
      includePlans?: boolean;
      favouriteId?: number;
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProjectsModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.GetAllProjectsPath, 'get');
    if (params) {
      rb.query('includePlans', params.includePlans, {});
      rb.query('favouriteId', params.favouriteId, {});
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectsModel>>;
      })
    );
  }

  /**
   * Returns all projects with or without plans.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProjects$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects$Plain(
    params?: {
      includePlans?: boolean;
      favouriteId?: number;
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<Array<ProjectsModel>> {
    return this.getAllProjects$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProjectsModel>>): Array<ProjectsModel> => r.body)
    );
  }

  /**
   * Returns all projects with or without plans.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects$Response(
    params?: {
      includePlans?: boolean;
      favouriteId?: number;
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProjectsModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.GetAllProjectsPath, 'get');
    if (params) {
      rb.query('includePlans', params.includePlans, {});
      rb.query('favouriteId', params.favouriteId, {});
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectsModel>>;
      })
    );
  }

  /**
   * Returns all projects with or without plans.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects(
    params?: {
      includePlans?: boolean;
      favouriteId?: number;
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<Array<ProjectsModel>> {
    return this.getAllProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProjectsModel>>): Array<ProjectsModel> => r.body)
    );
  }

  /** Path part for operation `createProject()` */
  static readonly CreateProjectPath = '/api/v1/projects';

  /**
   * Add a new project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProject$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject$Plain$Response(
    params: {
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.CreateProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProject$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject$Plain(
    params: {
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createProject$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject$Response(
    params: {
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.CreateProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject(
    params: {
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getProject()` */
  static readonly GetProjectPath = '/api/v1/projects/{projectId}';

  /**
   * Get project per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProject$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject$Plain$Response(
    params: {
      projectId: number;
      includePlans?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectsModel>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.GetProjectPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('includePlans', params.includePlans, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsModel>;
      })
    );
  }

  /**
   * Get project per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProject$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject$Plain(
    params: {
      projectId: number;
      includePlans?: boolean;
    },
    context?: HttpContext
  ): Observable<ProjectsModel> {
    return this.getProject$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectsModel>): ProjectsModel => r.body)
    );
  }

  /**
   * Get project per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject$Response(
    params: {
      projectId: number;
      includePlans?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectsModel>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.GetProjectPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('includePlans', params.includePlans, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsModel>;
      })
    );
  }

  /**
   * Get project per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject(
    params: {
      projectId: number;
      includePlans?: boolean;
    },
    context?: HttpContext
  ): Observable<ProjectsModel> {
    return this.getProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectsModel>): ProjectsModel => r.body)
    );
  }

  /** Path part for operation `putProject()` */
  static readonly PutProjectPath = '/api/v1/projects/{projectId}';

  /**
   * Update existing project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putProject$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putProject$Plain$Response(
    params: {
      projectId: number;
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectsModel>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.PutProjectPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsModel>;
      })
    );
  }

  /**
   * Update existing project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putProject$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putProject$Plain(
    params: {
      projectId: number;
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<ProjectsModel> {
    return this.putProject$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectsModel>): ProjectsModel => r.body)
    );
  }

  /**
   * Update existing project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putProject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putProject$Response(
    params: {
      projectId: number;
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectsModel>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.PutProjectPath, 'put');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsModel>;
      })
    );
  }

  /**
   * Update existing project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putProject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putProject(
    params: {
      projectId: number;
      body: ProjectCommandParams
    },
    context?: HttpContext
  ): Observable<ProjectsModel> {
    return this.putProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectsModel>): ProjectsModel => r.body)
    );
  }

  /** Path part for operation `deleteProject()` */
  static readonly DeleteProjectPath = '/api/v1/projects/{projectId}';

  /**
   * Delete project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProject$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsApiClient.DeleteProjectPath, 'delete');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProject(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
