import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-center-container',
  templateUrl: './efp-center-container.component.html',
  styleUrls: ['./efp-center-container.component.scss'],
})
export class EfpCenterContainerComponent {
  @Input() public fullHeight = false
  @Input() public displayFlex = false
  @Input() public bottomPaddingForFloatingButton = false
  @Input() public showSafeArea = true

  constructor() {}
}
