/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CycleSymbolModel } from '../models/cycle-symbol-model';

@Injectable({ providedIn: 'root' })
export class CycleSymbolsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCycleSymbol()` */
  static readonly CreateCycleSymbolPath = '/api/v1/cycle-symbols';

  /**
   * Add a new cycle symbol.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCycleSymbol$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleSymbol$Plain$Response(
    params: {
      body: CycleSymbolModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CycleSymbolsApiClient.CreateCycleSymbolPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new cycle symbol.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCycleSymbol$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleSymbol$Plain(
    params: {
      body: CycleSymbolModel
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCycleSymbol$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new cycle symbol.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCycleSymbol()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleSymbol$Response(
    params: {
      body: CycleSymbolModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CycleSymbolsApiClient.CreateCycleSymbolPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new cycle symbol.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCycleSymbol$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleSymbol(
    params: {
      body: CycleSymbolModel
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCycleSymbol$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAllCycleSymbolsByPlanId()` */
  static readonly FindAllCycleSymbolsByPlanIdPath = '/api/v1/plans/{planId}/cycle-symbols';

  /**
   * Get cycle symbols per plan ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCycleSymbolsByPlanId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleSymbolsByPlanId$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CycleSymbolModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CycleSymbolsApiClient.FindAllCycleSymbolsByPlanIdPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CycleSymbolModel>>;
      })
    );
  }

  /**
   * Get cycle symbols per plan ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCycleSymbolsByPlanId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleSymbolsByPlanId$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<CycleSymbolModel>> {
    return this.findAllCycleSymbolsByPlanId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CycleSymbolModel>>): Array<CycleSymbolModel> => r.body)
    );
  }

  /**
   * Get cycle symbols per plan ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCycleSymbolsByPlanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleSymbolsByPlanId$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CycleSymbolModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CycleSymbolsApiClient.FindAllCycleSymbolsByPlanIdPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CycleSymbolModel>>;
      })
    );
  }

  /**
   * Get cycle symbols per plan ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCycleSymbolsByPlanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleSymbolsByPlanId(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<CycleSymbolModel>> {
    return this.findAllCycleSymbolsByPlanId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CycleSymbolModel>>): Array<CycleSymbolModel> => r.body)
    );
  }

  /** Path part for operation `deleteCycleSymbolsByPlanId()` */
  static readonly DeleteCycleSymbolsByPlanIdPath = '/api/v1/plans/{planId}/cycle-symbols';

  /**
   * Delete cycle symbols per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCycleSymbolsByPlanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleSymbolsByPlanId$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CycleSymbolsApiClient.DeleteCycleSymbolsByPlanIdPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete cycle symbols per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCycleSymbolsByPlanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleSymbolsByPlanId(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteCycleSymbolsByPlanId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
