import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UndoRedoHistory } from '../../../models/history/undoRedoHistory'
import {
  HttpRequestSyncService,
  SynchronizationState,
} from '../../../services/http-request-sync.service'

@Component({
  selector: 'efp-undo-redo',
  templateUrl: 'undo-redo.component.html',
  styleUrls: ['./undo-redo.component.scss'],
})
export class UndoRedoComponent {
  @Input() history?: UndoRedoHistory<unknown>
  @Input() selectionOpen?: boolean

  @Output() readonly undo = new EventEmitter()
  @Output() readonly redo = new EventEmitter()

  constructor(public readonly httpRequestSyncService: HttpRequestSyncService) {}

  protected readonly SynchronizationState = SynchronizationState
}
