/*
 Each set of onboarding hints has a key to identify the hints.
 */
export enum OnboardingHintSeriesKey {
  PLANNER_FIRST_STEPS_NATIVE = 'PLANNER_FIRST_STEPS_NATIVE',
  PLANNER_FIRST_STEPS_WEB = 'PLANNER_FIRST_STEPS_WEB',
  CHECK_AND_SHARE_RESULT_WEB = 'CHECK_AND_SHARE_RESULT_WEB',
  CHECK_AND_SHARE_RESULT_NATIVE = 'CHECK_AND_SHARE_RESULT_NATIVE',
  REPOSITION_T_WALL_WEB = 'REPOSITION_T_WALL_WEB',
  REPOSITION_T_WALL_NATIVE = 'REPOSITION_T_WALL_NATIVE',
}
