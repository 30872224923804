import paper from 'paper/dist/paper-core'
import { Edge, LineSide, MeshPoint } from '../../model'

export function getClosestEdgesOnPoint(
  point: MeshPoint,
  referencePoint: paper.Point,
  referenceEdge?: Edge
): {
  [LineSide.LEFT]: Edge
  [LineSide.RIGHT]: Edge
  minAngle: number
  maxAngle: number
} {
  const edgeAngles = point.calculateEdgeAngles().filter((edgeAngle) => edgeAngle.edge.isValid)
  let angle = referencePoint.subtract(point).angle
  if (angle < 0) {
    angle += 360
  }

  let leftNeighbor = referenceEdge
  let rightNeighbor = referenceEdge
  let minAngle = 360
  let maxAngle = 0

  edgeAngles.forEach((edgeAngle) => {
    if (edgeAngle.edge === referenceEdge) {
      return
    }

    let angleDiff = angle - edgeAngle.angle
    if (angleDiff < 0) {
      angleDiff += 360
    }

    if (angleDiff < minAngle) {
      minAngle = angleDiff
      leftNeighbor = edgeAngle.edge
    }

    if (angleDiff > maxAngle) {
      maxAngle = angleDiff
      rightNeighbor = edgeAngle.edge
    }
  })

  if (!leftNeighbor || !rightNeighbor) {
    throw new Error("Couldn't find neighbor")
  }

  return {
    [LineSide.LEFT]: leftNeighbor,
    [LineSide.RIGHT]: rightNeighbor,
    minAngle,
    maxAngle,
  }
}
