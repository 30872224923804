/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetAllSalesContactsQuery } from '../models/get-all-sales-contacts-query';
import { SalesContactCommandParams } from '../models/sales-contact-command-params';
import { SalesContactModel } from '../models/sales-contact-model';

@Injectable({ providedIn: 'root' })
export class SalesContactsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSalesContacts()` */
  static readonly GetAllSalesContactsPath = '/api/v1/sales-contacts';

  /**
   * Get all salesContacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSalesContacts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalesContacts$Plain$Response(
    params?: {
      command?: GetAllSalesContactsQuery;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SalesContactModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SalesContactsApiClient.GetAllSalesContactsPath, 'get');
    if (params) {
      rb.query('command', params.command, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SalesContactModel>>;
      })
    );
  }

  /**
   * Get all salesContacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSalesContacts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalesContacts$Plain(
    params?: {
      command?: GetAllSalesContactsQuery;
    },
    context?: HttpContext
  ): Observable<Array<SalesContactModel>> {
    return this.getAllSalesContacts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SalesContactModel>>): Array<SalesContactModel> => r.body)
    );
  }

  /**
   * Get all salesContacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSalesContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalesContacts$Response(
    params?: {
      command?: GetAllSalesContactsQuery;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SalesContactModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SalesContactsApiClient.GetAllSalesContactsPath, 'get');
    if (params) {
      rb.query('command', params.command, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SalesContactModel>>;
      })
    );
  }

  /**
   * Get all salesContacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSalesContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalesContacts(
    params?: {
      command?: GetAllSalesContactsQuery;
    },
    context?: HttpContext
  ): Observable<Array<SalesContactModel>> {
    return this.getAllSalesContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SalesContactModel>>): Array<SalesContactModel> => r.body)
    );
  }

  /** Path part for operation `createSalesContact()` */
  static readonly CreateSalesContactPath = '/api/v1/sales-contacts';

  /**
   * Add a new salesContact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSalesContact$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSalesContact$Plain$Response(
    params: {
      body: SalesContactCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SalesContactsApiClient.CreateSalesContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new salesContact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSalesContact$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSalesContact$Plain(
    params: {
      body: SalesContactCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createSalesContact$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new salesContact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSalesContact()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSalesContact$Response(
    params: {
      body: SalesContactCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SalesContactsApiClient.CreateSalesContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new salesContact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSalesContact$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSalesContact(
    params: {
      body: SalesContactCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createSalesContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
