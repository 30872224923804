import paper from 'paper/dist/paper-core'
import {
  Edge,
  CompoundPathWithEdgeMap,
  getOtherLinePosition,
  getOtherLineSide,
  LinePosition,
  LineSide,
  MeshPoint,
  SNAPPING_ERROR_TOLERANCE,
  SNAP_ANGLE_INTERVAL,
  WallMesh,
} from 'formwork-planner-lib'
import { EdgeMap, PathStep } from '../../model/edge/EdgeMap'
import { intersectLines } from './intersectLines'

type VisitedEdgeSides = { [x in LineSide]: Set<Edge> }

interface ProcessedPath {
  path: paper.Path
  edgeMap: EdgeMap
}

export function createWallPath(mesh: WallMesh): CompoundPathWithEdgeMap {
  const allEdges = mesh.getAllEdges()
  if (allEdges.size < 1) {
    return new CompoundPathWithEdgeMap({})
  }

  allEdges.forEach((edge) => {
    edge.realLength = undefined
  })

  const edgeMaps: EdgeMap = new Map<paper.Segment, [PathStep, PathStep]>()
  const paths: paper.Path[] = []
  const visited: VisitedEdgeSides = {
    [LineSide.LEFT]: new Set<Edge>(),
    [LineSide.RIGHT]: new Set<Edge>(),
  }
  ;[LineSide.LEFT, LineSide.RIGHT].forEach((startSide) => {
    ;[...allEdges]
      .sort((e1, e2) => e1.getNeighbours().length - e2.getNeighbours().length)
      .forEach((startEdge) => {
        if (!startEdge.isValid) {
          return
        }

        if (visited[startSide].has(startEdge)) {
          return
        }

        let disconnectedPoints: MeshPoint[] | undefined
        const disconnectedNeighbor = [...allEdges]
          .filter((e) => !e.isSameEdge(startEdge) && !e.getConnectedPoint(startEdge))
          .find((e) => {
            disconnectedPoints = e.getCloseDisconnectedPoints(startEdge)
            return !!(
              disconnectedPoints && disconnectedPoints[1] === startEdge.getPoint(LinePosition.START)
            )
          })

        const processedPath = processPath(
          {
            edge: startEdge,
            side: startSide,
            position: LinePosition.START,
            disconnectedNeighbor,
          },
          visited,
          [...allEdges]
        )
        paths.push(processedPath.path)
        ;[...processedPath.edgeMap].forEach(([key, value]) => edgeMaps.set(key, value))
        fixOrientation(paths)
      })
  })

  allEdges.forEach((edge) => {
    if (!edge.realLength) {
      edge.realLength = edge.endPoint.subtract(edge.startPoint).length
    }
  })
  ;[...allEdges]
    .filter((e) => !e.isValid)
    .forEach((e) => {
      const path = e.getOutlinePath()
      paths.push(path)
    })

  return new CompoundPathWithEdgeMap({ children: paths }, edgeMaps)
}

function processPath(
  startStep: PathStep,
  visited: VisitedEdgeSides,
  allEdges: Edge[]
): ProcessedPath {
  const path = new paper.Path()
  const edgeMap = new Map<paper.Segment, [PathStep, PathStep]>()
  const endStep = getNextStep(startStep, allEdges, true)
  const startStepPoints = calculateStepPoints(endStep, startStep)
  path.moveTo(<paper.Point>startStepPoints.pop())
  edgeMap.set(path.lastSegment, [endStep, startStep])

  let currStep = { ...startStep }
  do {
    visited[currStep.side].add(currStep.edge)
    const { points, nextStep } = processStep(currStep, path.lastSegment.point, allEdges)

    points.forEach((p) => {
      if (p && !p.isClose(path.lastSegment.point, 0)) {
        path.lineTo(p)
      }
      edgeMap.set(path.lastSegment, [currStep, nextStep])
    })

    currStep = nextStep
  } while (
    !visited[currStep.side]?.has(currStep.edge) &&
    !(startStep.edge === currStep.edge && startStep.side === currStep.side)
  )

  const firstSegmentPoint = path.segments[0].point
  const lastSegmentPoint = path.segments[path.segments.length - 1].point
  if (firstSegmentPoint.getDistance(lastSegmentPoint) < 0.1) {
    path.removeSegment(path.segments.length - 1)
  }
  path.closed = true

  return { path, edgeMap }
}

function processStep(
  prevStep: PathStep,
  lastPoint: paper.Point,
  allEdges: Edge[]
): { nextStep: PathStep; points: paper.Point[] } {
  const nextStep = getNextStep(prevStep, allEdges)

  const points = calculateStepPoints(prevStep, nextStep)

  prevStep.edge.realLength = Math.max(
    prevStep.edge.realLength ?? 0,
    lastPoint.subtract(points[0]).length
  )

  return { nextStep, points }
}

function getNextStep(prev: PathStep, allEdges: Edge[], reverse: boolean = false): PathStep {
  const edge = prev.edge.getNeighborOnSide(
    prev.side,
    reverse ? prev.position : getOtherLinePosition(prev.position)
  )
  const connectedPoint = edge.getConnectedPoint(prev.edge)

  const prevLineOnSide = prev.edge.getLineOnSide(prev.side)
  const prevPoint = prevLineOnSide[prev.position]
  const prevOtherPoint = prevLineOnSide[getOtherLinePosition(prev.position)]

  // try to find close mesh points with parallel walls with different widths
  let closeDisconnectedPoints: MeshPoint[] | undefined
  let disconnectedNeighborEdge: Edge | undefined
  let disconnectedPointDistance = Number.MAX_VALUE

  // iterating through every edge, checking whether they are in the right position to be drawn as connected (although they are technically not)
  allEdges.forEach((neighbor) => {
    const disconnectedPoints = prev.edge.getCloseDisconnectedPoints(neighbor)
    if (!disconnectedPoints) {
      return
    }

    const disconnectedPosition = <LinePosition>neighbor.getPosition(disconnectedPoints[1])
    const disconnectedSide =
      disconnectedPosition === LinePosition.START ? LineSide.LEFT : LineSide.RIGHT
    const disconnectedLineOnSide = neighbor.getLineOnSide(disconnectedSide)
    const disconnectedCornerPoint = disconnectedLineOnSide[disconnectedPosition]
    const distance = disconnectedCornerPoint.getDistance(prevPoint)

    // checking whether the disconnected points are in the right position
    let isInRightPosition = false
    let disconnectedVector: paper.Point | undefined
    let parallelNeighbor: Edge | undefined
    let perpendicularNeighbor: Edge | undefined
    let disconnectedNeighbor: Edge | undefined
    let connectionPoint: MeshPoint | undefined

    if (disconnectedPoints[0].edges.size === 1) {
      disconnectedVector = disconnectedPoints[0].subtract(disconnectedPoints[1])
      // if both points have only one edge, the points have to be aligned on the width side of both walls
      if (disconnectedPoints[1].edges.size === 1) {
        const widthVector = prev.edge.getDirection().rotate(90).normalize()
        isInRightPosition =
          (disconnectedVector.normalize().isClose(widthVector, SNAPPING_ERROR_TOLERANCE) ||
            disconnectedVector
              .normalize()
              .isClose(widthVector.multiply(-1), SNAPPING_ERROR_TOLERANCE)) &&
          prev.edge.isParallelTo(neighbor) &&
          prev.edge.thickness !== neighbor.thickness
      } else if (disconnectedPoints[1].edges.size === 2) {
        // if the previous edge doesn't have neighbors and disconnected point has two edges
        parallelNeighbor = [...disconnectedPoints[1].edges].find((e) => e.isParallelTo(prev.edge))
        perpendicularNeighbor = [...disconnectedPoints[1].edges].find((e) =>
          e.isPerpendicular(prev.edge)
        )
        disconnectedNeighbor = prev.edge
        connectionPoint = disconnectedPoints[1]
      }
    } else if (disconnectedPoints[0].edges.size === 2 && disconnectedPoints[1].edges.size === 1) {
      // if the point of the previous edge has two edges and the disconnected point one
      disconnectedVector = disconnectedPoints[1].subtract(disconnectedPoints[0])
      disconnectedNeighbor = neighbor
      connectionPoint = disconnectedPoints[0]
      if (neighbor.isParallelTo(prev.edge)) {
        perpendicularNeighbor = [...disconnectedPoints[0].edges].find((e) => e !== prev.edge)
        parallelNeighbor = prev.edge
      } else if (neighbor.isPerpendicular(prev.edge)) {
        parallelNeighbor = [...disconnectedPoints[0].edges].find((e) => e !== prev.edge)
        perpendicularNeighbor = prev.edge
      }
    }

    if (
      parallelNeighbor &&
      perpendicularNeighbor &&
      disconnectedNeighbor &&
      connectionPoint &&
      disconnectedVector
    ) {
      const edgeVector = connectionPoint.subtract(
        perpendicularNeighbor.getOtherPoint(connectionPoint)
      )
      const widthVector = edgeVector.normalize(parallelNeighbor.thickness / 2)
      const disconnectedWidthVector = edgeVector.normalize(disconnectedNeighbor.thickness / 2)
      const projectedDisconnectedVector = disconnectedVector.project(edgeVector)
      isInRightPosition = widthVector.isClose(
        projectedDisconnectedVector.add(disconnectedWidthVector),
        SNAPPING_ERROR_TOLERANCE
      )
    }

    // checking whether the disconnected edge is truly a neighbor and is disconnected
    if (
      isTrueNeighborAndDisconnected(
        neighbor,
        prev,
        isInRightPosition,
        distance,
        disconnectedPointDistance,
        disconnectedPoints,
        reverse
      )
    ) {
      closeDisconnectedPoints = disconnectedPoints
      disconnectedNeighborEdge = neighbor
      disconnectedPointDistance = distance
    }
  })

  let nextStep: PathStep
  let position: LinePosition
  let side: LineSide
  let cornerPoint: paper.Point
  if (prev.edge !== edge && connectedPoint) {
    position = <LinePosition>edge.getPosition(connectedPoint)
    side = position === LinePosition.START ? LineSide.LEFT : LineSide.RIGHT

    if (prev.position === LinePosition.START && prev.side === LineSide.RIGHT) {
      side = getOtherLineSide(side)
    }

    const edgeLine = edge.getLineOnSide(side)
    cornerPoint =
      intersectLines(
        {
          start: edgeLine[LinePosition.START],
          end: edgeLine[LinePosition.END],
        },
        {
          start: prevLineOnSide[LinePosition.START],
          end: prevLineOnSide[LinePosition.END],
        }
      ) ?? edgeLine[position]
  } else {
    position = getOtherLinePosition(prev.position)
    side = getOtherLineSide(prev.side)
    const lineOnSide = edge.getLineOnSide(side)
    cornerPoint = lineOnSide[position]
  }

  const prevPointDistance = prevPoint.getDistance(cornerPoint)
  const otherPointDistance = prevOtherPoint.getDistance(cornerPoint)
  const distance = prevPointDistance < otherPointDistance ? prevPointDistance : otherPointDistance

  if (disconnectedNeighborEdge && closeDisconnectedPoints) {
    const disconnectedPosition = <LinePosition>(
      disconnectedNeighborEdge.getPosition(closeDisconnectedPoints[1])
    )
    const disconnectedSide =
      disconnectedPosition === LinePosition.START ? LineSide.LEFT : LineSide.RIGHT
    const disconnectedLineOnSide = disconnectedNeighborEdge.getLineOnSide(disconnectedSide)
    const disconnectedCornerPoint = disconnectedLineOnSide[disconnectedPosition]
    const disconnectedDistance1 = prevPoint.getDistance(disconnectedCornerPoint)
    const disconnectedDistance2 = prevOtherPoint.getDistance(disconnectedCornerPoint)
    const disconnectedDistance =
      prevPointDistance < otherPointDistance ? disconnectedDistance1 : disconnectedDistance2
    if (
      disconnectedDistance < distance + SNAPPING_ERROR_TOLERANCE ||
      disconnectedDistance1 + disconnectedDistance2 ===
        prevLineOnSide[LinePosition.END].subtract(prevLineOnSide[LinePosition.START]).length
    ) {
      // close disconnected point is in better position
      nextStep = {
        edge: disconnectedNeighborEdge,
        position: disconnectedPosition,
        side: disconnectedSide,
        disconnectedNeighbor: prev.edge,
      }
    } else {
      // connected point is in better position
      nextStep = { edge, position, side }
    }
  } else {
    // no close disconnected edge found
    nextStep = { edge, position, side }
  }

  if (reverse) {
    return {
      edge: nextStep.edge,
      side: getOtherLineSide(nextStep.side),
      position: getOtherLinePosition(nextStep.position),
      disconnectedNeighbor: nextStep.disconnectedNeighbor,
    }
  }

  return nextStep
}

function calculateStepPoints(prev: PathStep, next: PathStep): paper.Point[] {
  if (next.edge === prev.edge) {
    return [
      prev.edge.getLineOnSide(prev.side)[next.position],
      prev.edge.getLineOnSide(next.side)[next.position],
    ]
  }

  const angle = Math.round(Math.abs(<number>prev.edge.getAngle(next.edge)))
  const diff = Math.abs(angle - 180)

  let point1 = prev.edge.getLineOnSide(prev.side)[getOtherLinePosition(prev.position)]
  let point2 = next.edge.getLineOnSide(next.side)[next.position]

  if (prev.edge.thickness !== next.edge.thickness && diff <= SNAP_ANGLE_INTERVAL / 2) {
    if (point1.isClose(point2, SNAPPING_ERROR_TOLERANCE)) {
      return [point1]
    } else {
      if (next.edge.thickness < prev.edge.thickness) {
        point2 =
          intersectLines(
            {
              start: prev.edge.getLineOnSide(prev.side)[getOtherLinePosition(prev.position)],
              end: prev.edge.getLineOnSide(getOtherLineSide(prev.side))[
                getOtherLinePosition(prev.position)
              ],
            },
            next.edge.getLineOnSide(next.side)
          ) ?? point2
      } else {
        point1 =
          intersectLines(prev.edge.getLineOnSide(prev.side), {
            start: next.edge.getLineOnSide(next.side)[next.position],
            end: next.edge.getLineOnSide(getOtherLineSide(next.side))[next.position],
          }) ?? point1
      }
      return [point1, point2]
    }
  }

  if (diff > SNAPPING_ERROR_TOLERANCE) {
    return [
      <paper.Point>(
        intersectLines(prev.edge.getLineOnSide(prev.side), next.edge.getLineOnSide(next.side))
      ),
    ]
  }

  return [next.edge.getLineOnSide(next.side)[next.position]]
}

function fixOrientation(paths: paper.Path[]): void {
  paths.forEach((path) => {
    let depth = 0
    paths.forEach((otherPath) => {
      if (
        otherPath !== path &&
        path.segments.map((segment) => segment.point).every((point) => otherPath.contains(point))
      ) {
        depth++
      }
    })

    if (depth % 2 === 0 ? !path.clockwise : path.clockwise) {
      path.reverse()
    }
  })
}

function isTrueNeighborAndDisconnected(
  neighbor: Edge,
  prev: PathStep,
  isInRightPosition: boolean,
  distance: number,
  disconnectedPointDistance: number,
  disconnectedPoints: MeshPoint[],
  reverse: boolean
): boolean {
  return (
    (neighbor.isParallelTo(prev.edge) || neighbor.isPerpendicular(prev.edge)) &&
    isInRightPosition &&
    !neighbor.isSameEdge(prev.edge) &&
    !neighbor.getConnectedPoint(prev.edge) &&
    neighbor !== prev.disconnectedNeighbor &&
    !neighbor.getNeighbours().some((other) => other === prev.disconnectedNeighbor) &&
    distance < disconnectedPointDistance &&
    ![...disconnectedPoints[0].edges].some((e1) =>
      [...disconnectedPoints[1].edges].some((e2) => e1.getCloseDisconnectedPoints(e2, 0))
    ) &&
    !reverse
  )
}
