import { Injectable } from '@angular/core'
import { CreatePlanSettingsCommandParams, FormworkId } from '../../../../generated/efp-api'
import { FavouriteProfile } from '../../../models/favourites'
import { PlanSettings } from '../../../models/planSettings'
import { DataService } from '../../data.service'
import { FormworkFavouritesSettings, PlanSettingsDao } from '../plan-settings.dao'
import { PlanDao } from '../plan.dao'
import { FavouriteDao } from '../favourite.dao'
import { PlanResultResetCalculationDao } from '../plan-result-reset-calculation.dao'
import { PlanAccessoryLineDao } from '../plan-accessory-line.dao'

@Injectable()
export class PlanSettingsSqlDao extends PlanSettingsDao {
  constructor(
    private readonly dataService: DataService,
    private readonly planDao: PlanDao,
    private readonly favouriteDao: FavouriteDao,
    private readonly planAccessoryLineDao: PlanAccessoryLineDao,
    private readonly planResultResetCalculationDao: PlanResultResetCalculationDao
  ) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): PlanSettings {
    return {
      id: row.id,
      measurementUnit: row.measurementUnit,
      formworkWall: row.formworkWall,
      formworkSlab: row.formworkSlab,
      angleRastering: row.angleRastering,
      lengthRastering: row.lengthRastering,
      slabHeight: row.slabHeight,
      slabThickness: row.slabThickness,
      wallHeight: row.wallHeight,
      wallThickness: row.wallThickness,
      slabFavId: row.slabFavId,
      wallFavId: row.wallFavId,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFormworkFavouritesSettingsFromRow(row: any): FormworkFavouritesSettings {
    return {
      formwork: row.formwork,
      favouritesId: row.favouritesId,
      planSettingsId: row.planSettingsId,
    }
  }

  private parseFromRows(rows: SQLResultSetRowList): PlanSettings[] {
    const planSettings: PlanSettings[] = []
    for (let i = 0; i < rows.length; i++) {
      planSettings.push(this.parseFromRow(rows.item(i)))
    }
    return planSettings
  }

  async findOneById(settingsId: number): Promise<PlanSettings | undefined> {
    let settings: PlanSettings | undefined = undefined
    const result = await this.dataService.executeStatement(
      'SELECT * from PlanSettings WHERE id=?',
      [settingsId]
    )
    if (result.rows.length === 1) {
      settings = this.parseFromRow(result.rows.item(0))
    }
    return settings
  }

  async findAllById(planSettingIds: number[]): Promise<PlanSettings[]> {
    const rows = (
      await this.dataService.executeStatement('SELECT * FROM PlanSettings WHERE id in (?)', [
        planSettingIds,
      ])
    ).rows
    return this.parseFromRows(rows)
  }

  async findAllByFavouriteId(favouriteId: number): Promise<PlanSettings[]> {
    const rows = (
      await this.dataService.executeStatement(
        'SELECT * FROM PlanSettings WHERE wallFavId = ? OR slabFavId = ?',
        [favouriteId, favouriteId]
      )
    ).rows
    return this.parseFromRows(rows)
  }

  protected async resetPlanCalculationAndAccessoriesIfNeeded(
    newPlanSettings: PlanSettings
  ): Promise<void> {
    const plan = await this.planDao.findOneBySettingsId(newPlanSettings.id)
    if (plan) {
      const oldPlanSettings = await this.findOneById(newPlanSettings.id)
      if (!oldPlanSettings) {
        return
      }
      const formWorkChanged =
        oldPlanSettings.formworkSlab !== newPlanSettings.formworkSlab ||
        oldPlanSettings.formworkWall !== newPlanSettings.formworkWall
      if (formWorkChanged) {
        await this.planAccessoryLineDao.deleteAccessoryLinesForPlan(plan.id)
      }
      const formWorkProfileChanged =
        oldPlanSettings.slabFavId !== newPlanSettings.slabFavId ||
        oldPlanSettings.wallFavId !== newPlanSettings.wallFavId
      if (formWorkChanged || formWorkProfileChanged) {
        await this.planResultResetCalculationDao.resetCalculation(plan.id)
      }
      // check if wall Height has changed
      if (oldPlanSettings.wallHeight !== newPlanSettings.wallHeight) {
        await this.planResultResetCalculationDao.resetCalculation(plan.id)
      }
      //check if slab Height or slab level has changed
      if (
        oldPlanSettings.slabHeight !== newPlanSettings.slabHeight ||
        oldPlanSettings.slabThickness !== newPlanSettings.slabThickness
      ) {
        await this.planResultResetCalculationDao.resetCalculation(plan.id)
      }
    }
  }

  public async create(
    planSettings: CreatePlanSettingsCommandParams,
    templateId: number
  ): Promise<number> {
    const statement = `INSERT INTO PlanSettings (measurementUnit,
                                                 formworkWall,
                                                 formworkSlab,
                                                 angleRastering,
                                                 lengthRastering,
                                                 wallThickness,
                                                 slabThickness,
                                                 wallHeight,
                                                 slabHeight,
                                                 slabFavId,
                                                 wallFavId)
                       VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`
    const values = [
      planSettings.measurementUnit,
      planSettings.formworkWall,
      planSettings.formworkSlab,
      planSettings.angleRastering,
      planSettings.lengthRastering,
      planSettings.wallThickness,
      planSettings.slabThickness,
      planSettings.wallHeight,
      planSettings.slabHeight,
      planSettings.slabFavouriteProfileId,
      planSettings.wallFavouriteProfileId,
    ]
    const result = await this.dataService.executeStatement(statement, values)
    await this.createCopiesOfFormworkFavouritesSettings(templateId, result.insertId)
    return result.insertId
  }

  protected async getNewFavIdAndUpdateAssociations(
    oldFavId: number,
    newFavId: number,
    oldFormwork: FormworkId,
    newFormwork: FormworkId,
    planSettings: PlanSettings
  ): Promise<number> {
    const formworkFavouritesSettings = await this.findFormworkFavouritesSettingsBy(
      newFormwork,
      planSettings
    )
    // Update or create the favourite profile formwork association for the planSettings
    if (newFavId !== 0 && newFavId !== oldFavId && newFormwork === oldFormwork) {
      if (formworkFavouritesSettings) {
        await this.updateFormworkFavouritesSettings(
          formworkFavouritesSettings.formwork,
          planSettings.id,
          newFavId
        )
      } else {
        await this.createFormworkFavouritesSettings(newFormwork, planSettings.id, newFavId)
      }
    }
    // Get the default standard formwork profile for the new formwork as fallback
    const defaultStandardFormworkProfile =
      await this.favouriteDao.findOneStandardByFormworkSystemId(newFormwork)
    if (!defaultStandardFormworkProfile) {
      throw new Error('No default standard formwork profile found')
    }
    let favouriteProfile: FavouriteProfile | undefined
    // If the formwork changed, we try to use the old formwork - favourite association first
    if (newFormwork !== oldFormwork && formworkFavouritesSettings) {
      favouriteProfile = await this.favouriteDao.findOneById(
        formworkFavouritesSettings.favouritesId
      )
    }
    // If there is a newFavId and we not used the old formwork - favourite association, we try to use the newFavId
    if (newFavId !== 0 && !favouriteProfile) {
      favouriteProfile = await this.favouriteDao.findOneById(newFavId)
    }
    // If there is no favourite profile or the formwork is not correct, we will use the default
    return favouriteProfile !== undefined && favouriteProfile.formworkSystemId === newFormwork
      ? favouriteProfile.id
      : defaultStandardFormworkProfile.id
  }

  async update(planSettings: PlanSettings): Promise<PlanSettings> {
    const oldSettings = await this.findOneById(planSettings.id)
    if (!oldSettings) {
      throw new Error('Old plan settings not found')
    }
    /// If the default standard formwork system is not equal of the formworksystem of the profile, we will use the default of the formwork system
    let slabFavId = planSettings.slabFavId
    if (planSettings.slabFavId != null && oldSettings.slabFavId != null) {
      slabFavId = await this.getNewFavIdAndUpdateAssociations(
        oldSettings.slabFavId,
        planSettings.slabFavId,
        oldSettings.formworkSlab,
        planSettings.formworkSlab,
        planSettings
      )
    }
    let wallFavId = planSettings.wallFavId
    if (planSettings.wallFavId !== null && oldSettings.wallFavId != null) {
      wallFavId = await this.getNewFavIdAndUpdateAssociations(
        oldSettings.wallFavId,
        planSettings.wallFavId,
        oldSettings.formworkWall,
        planSettings.formworkWall,
        planSettings
      )
    }
    const statement = `UPDATE PlanSettings
                       SET formworkWall    = ?,
                           formworkSlab    = ?,
                           angleRastering  = ?,
                           lengthRastering = ?,
                           wallThickness   = ?,
                           slabThickness   = ?,
                           wallHeight      = ?,
                           slabHeight      = ?,
                           slabFavId       = ?,
                           wallFavId       = ?
                       WHERE id = ?`
    const values = [
      planSettings.formworkWall,
      planSettings.formworkSlab,
      planSettings.angleRastering,
      planSettings.lengthRastering,
      planSettings.wallThickness,
      planSettings.slabThickness,
      planSettings.wallHeight,
      planSettings.slabHeight,
      slabFavId,
      wallFavId,
      planSettings.id,
    ]
    await this.dataService.executeStatement(statement, values)
    planSettings.slabFavId = slabFavId
    planSettings.wallFavId = wallFavId
    return planSettings
  }

  protected async findAllFormworkFavouritesSettingsByPlanSettingsId(
    planSettingsId: number
  ): Promise<FormworkFavouritesSettings[]> {
    const statement = 'SELECT * FROM FormworkFavouritesSettings WHERE planSettingsId = ?'
    const values = [planSettingsId]
    const rows = (await this.dataService.executeStatement(statement, values)).rows
    const formworkFavouritesSettings: FormworkFavouritesSettings[] = []
    for (let i = 0; i < rows.length; i++) {
      formworkFavouritesSettings.push(this.parseFormworkFavouritesSettingsFromRow(rows.item(i)))
    }
    return formworkFavouritesSettings
  }

  protected async createCopiesOfFormworkFavouritesSettings(
    planSettingsId: number,
    newPlanSettingsId: number
  ): Promise<void> {
    const formworkFavouritesSettings = await this.findAllFormworkFavouritesSettingsByPlanSettingsId(
      planSettingsId
    )
    for (const formworkFavouritesSetting of formworkFavouritesSettings) {
      await this.createFormworkFavouritesSettings(
        formworkFavouritesSetting.formwork,
        newPlanSettingsId,
        formworkFavouritesSetting.favouritesId
      )
    }
  }

  protected async findFormworkFavouritesSettingsBy(
    formwork: string,
    planSettings: PlanSettings
  ): Promise<FormworkFavouritesSettings | undefined> {
    const statement =
      'SELECT * FROM FormworkFavouritesSettings WHERE formwork = ? AND planSettingsId = ?'
    const values = [formwork, planSettings.id]
    const rows = (await this.dataService.executeStatement(statement, values)).rows
    if (rows.length === 0) {
      return undefined
    }
    return this.parseFormworkFavouritesSettingsFromRow(rows.item(0))
  }

  protected async createFormworkFavouritesSettings(
    formwork: string,
    planSettingsId: number,
    favouritesId: number
  ): Promise<void> {
    const statement =
      'INSERT INTO FormworkFavouritesSettings (formwork, planSettingsId, favouritesId) VALUES (?,?,?)'
    const values = [formwork, planSettingsId, favouritesId]
    await this.dataService.executeStatement(statement, values)
  }

  protected async updateFormworkFavouritesSettings(
    formwork: string,
    planSettingsId: number,
    favouritesId: number
  ): Promise<void> {
    const statement =
      'UPDATE FormworkFavouritesSettings SET favouritesId = ? WHERE formwork = ? AND planSettingsId = ?'
    const values = [favouritesId, formwork, planSettingsId]
    await this.dataService.executeStatement(statement, values)
  }
}
