import { Edge, Mesh, MeshPoint, SimplifiedMesh, SlabMesh, WallMesh } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'

export function parseSimplifiedWallMesh(
  paperScope: paper.PaperScope,
  serializedMesh: string
): WallMesh {
  const mesh = new WallMesh(paperScope)

  return parseMeshIntoTarget(serializedMesh, mesh)
}

export function parseSimplifiedSlabMesh(
  paperScope: paper.PaperScope,
  serializedMesh: string
): SlabMesh {
  const mesh = new SlabMesh(paperScope)

  return parseMeshIntoTarget(serializedMesh, mesh)
}

function parseMeshIntoTarget<MESH extends Mesh>(serializedMesh: string, mesh: MESH): MESH {
  const inputMesh = JSON.parse(serializedMesh)
  if (isSimplifiedMesh(inputMesh)) {
    deserializeSimplifiedMesh(inputMesh, mesh)
  } else {
    deserializeLegacyMesh(inputMesh, mesh)
  }

  return mesh
}

function deserializeSimplifiedMesh(inputMesh: SimplifiedMesh, outputMesh: Mesh): void {
  const pointIndexMap = new Map<number, MeshPoint>()

  inputMesh.points.forEach((point, i) => {
    const meshPoint = new MeshPoint(point, outputMesh)
    outputMesh.points.add(meshPoint)
    pointIndexMap.set(i, meshPoint)
  })

  inputMesh.edges.forEach((edge) => {
    if (edge.startPointIdx !== undefined && edge.endPointIdx !== undefined) {
      const startPoint = pointIndexMap.get(edge.startPointIdx) as MeshPoint
      const endPoint = pointIndexMap.get(edge.endPointIdx) as MeshPoint
      const graphEdge = new Edge(startPoint, endPoint, edge.thickness)
      startPoint.edges.add(graphEdge)
      endPoint.edges.add(graphEdge)
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deserializeLegacyMesh(inputMesh: any, outputMesh: Mesh): void {
  const addedEdges: { startPoint: MeshPoint; endPoint: MeshPoint }[] = []

  Object.keys(inputMesh).forEach((key) => {
    const coordinates = key.split('/').map((v) => parseFloat(v))
    outputMesh.points.add(new MeshPoint({ x: coordinates[0], y: coordinates[1] }, outputMesh))
  })

  Object.values(inputMesh).forEach((value) => {
    const edgesData = JSON.parse(value as string) as {
      startPoint: { x: number; y: number }
      endPoint: { x: number; y: number }
      height: number
      thickness: number
    }[]

    edgesData.forEach((edgeData) => {
      let startPoint: MeshPoint | undefined
      let endPoint: MeshPoint | undefined
      outputMesh.points.forEach((point) => {
        if (point.x === edgeData.startPoint.x && point.y === edgeData.startPoint.y) {
          startPoint = point
        }
        if (point.x === edgeData.endPoint.x && point.y === edgeData.endPoint.y) {
          endPoint = point
        }
      })

      if (
        startPoint &&
        endPoint &&
        addedEdges.every(
          (addedEdge) => addedEdge.startPoint !== startPoint || addedEdge.endPoint !== endPoint
        )
      ) {
        const edge = new Edge(startPoint, endPoint, edgeData.thickness)
        edge.thickness = edgeData.thickness

        startPoint.edges.add(edge)
        endPoint.edges.add(edge)

        addedEdges.push({ startPoint, endPoint })
      }
    })
  })
}

export function serializeMesh(mesh: Mesh): string {
  const simplifiedMesh: SimplifiedMesh = {
    points: [],
    edges: [],
  }
  const edgeIndexMap = new Map<Edge, number>()

  mesh.points.forEach((point) => {
    const pointIndex = simplifiedMesh.points.length
    simplifiedMesh.points.push({ x: point.x, y: point.y })

    point.edges.forEach((edge) => {
      const isStart = edge.startPoint === point

      let edgeIndex = edgeIndexMap.get(edge)
      if (edgeIndex !== undefined) {
        const simplifiedEdge = simplifiedMesh.edges[edgeIndex]
        if (isStart) {
          simplifiedEdge.startPointIdx = pointIndex
        } else {
          simplifiedEdge.endPointIdx = pointIndex
        }
      } else {
        edgeIndex = simplifiedMesh.edges.length
        edgeIndexMap.set(edge, edgeIndex)

        simplifiedMesh.edges.push({
          startPointIdx: isStart ? pointIndex : undefined,
          endPointIdx: isStart ? undefined : pointIndex,
          thickness: edge.thickness,
        })
      }
    })
  })
  if (
    simplifiedMesh.edges.some(
      (it) => it.endPointIdx === undefined || it.startPointIdx === undefined
    )
  ) {
    throw new Error('Some edges are missing their start or endpoint')
  }

  return JSON.stringify(simplifiedMesh)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSimplifiedMesh(object: any): object is SimplifiedMesh {
  return (
    Array.isArray((object as SimplifiedMesh).points) &&
    Array.isArray((object as SimplifiedMesh).edges)
  )
}
