<ion-content>
  <ion-item
    *ngFor="let cycle of cycles"
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(cycle)"
    data-test-selector="highlight-menu-3d"
    [ngClass]="{ active: this.toolbarControl?.cycle === cycle.CycleId }">
    <ion-label>{{ cycle.Translation }}</ion-label>
  </ion-item>
</ion-content>
