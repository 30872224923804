/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CatalogArticleGroup } from '../models/catalog-article-group';
import { Country } from '../models/country';
import { FormworkId } from '../models/formwork-id';

@Injectable({ providedIn: 'root' })
export class CatalogApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getArticleGroupsForFormworkSystem()` */
  static readonly GetArticleGroupsForFormworkSystemPath = '/articleGroups';

  /**
   * Get the article catalog groups including the catalog articles for the given formwork system.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleGroupsForFormworkSystem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleGroupsForFormworkSystem$Plain$Response(
    params?: {
      formworkId?: FormworkId;
      isoLanguageCode?: string;
      country?: Country;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CatalogArticleGroup>>> {
    const rb = new RequestBuilder(this.rootUrl, CatalogApiClient.GetArticleGroupsForFormworkSystemPath, 'get');
    if (params) {
      rb.query('formworkId', params.formworkId, {});
      rb.query('isoLanguageCode', params.isoLanguageCode, {});
      rb.query('country', params.country, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CatalogArticleGroup>>;
      })
    );
  }

  /**
   * Get the article catalog groups including the catalog articles for the given formwork system.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleGroupsForFormworkSystem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleGroupsForFormworkSystem$Plain(
    params?: {
      formworkId?: FormworkId;
      isoLanguageCode?: string;
      country?: Country;
    },
    context?: HttpContext
  ): Observable<Array<CatalogArticleGroup>> {
    return this.getArticleGroupsForFormworkSystem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CatalogArticleGroup>>): Array<CatalogArticleGroup> => r.body)
    );
  }

  /**
   * Get the article catalog groups including the catalog articles for the given formwork system.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleGroupsForFormworkSystem()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleGroupsForFormworkSystem$Response(
    params?: {
      formworkId?: FormworkId;
      isoLanguageCode?: string;
      country?: Country;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CatalogArticleGroup>>> {
    const rb = new RequestBuilder(this.rootUrl, CatalogApiClient.GetArticleGroupsForFormworkSystemPath, 'get');
    if (params) {
      rb.query('formworkId', params.formworkId, {});
      rb.query('isoLanguageCode', params.isoLanguageCode, {});
      rb.query('country', params.country, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CatalogArticleGroup>>;
      })
    );
  }

  /**
   * Get the article catalog groups including the catalog articles for the given formwork system.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleGroupsForFormworkSystem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleGroupsForFormworkSystem(
    params?: {
      formworkId?: FormworkId;
      isoLanguageCode?: string;
      country?: Country;
    },
    context?: HttpContext
  ): Observable<Array<CatalogArticleGroup>> {
    return this.getArticleGroupsForFormworkSystem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CatalogArticleGroup>>): Array<CatalogArticleGroup> => r.body)
    );
  }

}
