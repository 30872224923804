import { Component } from '@angular/core'
import { AccordionFunctionsService } from '../../../services/accordion-functions.service'

@Component({
  selector: 'efp-accordion-group-control',
  templateUrl: 'accordion-group-control.component.html',
})
export class AccordionGroupControlComponent {
  constructor(public readonly accordionFunctions: AccordionFunctionsService) {}
}
