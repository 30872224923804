import { Component, Input } from '@angular/core'
import { Highlight } from 'efp-viewer'

@Component({
  templateUrl: './highlight-menu-3d.component.html',
  styleUrls: ['./highlight-menu-3d.component.scss'],
})
export class HighlightMenu3DComponent {
  @Input() items?: Highlight[]

  constructor() {}

  async toggleVisibility(item: Highlight): Promise<void> {
    item.Highlighted = !item.Highlighted
  }
}
