import { Injectable } from '@angular/core'
import { BlacklistArticleModel, CreateBlacklistArticleParams } from '@efp/api'
import { BlacklistArticleDao } from '../blacklist-article.dao'
import { DataService } from '../../data.service'

@Injectable()
export class BlacklistArticleSqlDao extends BlacklistArticleDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): BlacklistArticleModel {
    return {
      id: row.id,
      articleId: row.articleId,
      favouriteProfileId: row.favouriteProfileId,
      name: row.name,
    }
  }

  async findAllByFavouriteProfileId(favouriteProfileId: number): Promise<BlacklistArticleModel[]> {
    const articles: BlacklistArticleModel[] = []
    const statement = 'SELECT * FROM BlacklistArticle WHERE favouriteProfileId = ?'
    const values = [favouriteProfileId]
    const articleResult = await this.dataService.executeStatement(statement, values)
    if (articleResult.rows.length === 0) {
      return articles
    }
    for (let i = 0; i < articleResult.rows.length; i++) {
      const row = articleResult.rows.item(i)
      articles[i] = this.parseFromRow(row)
    }
    return articles
  }

  async delete(id: number): Promise<void> {
    const statement = 'DELETE FROM BlacklistArticle WHERE id = ?'
    const values = [id]
    await this.dataService.executeStatement(statement, values)
  }

  async createArticles(articles: CreateBlacklistArticleParams[]): Promise<BlacklistArticleModel[]> {
    const statement =
      'INSERT INTO BlacklistArticle (articleId, favouriteProfileId, name) VALUES (?,?,?)'
    const articleIds: number[] = []
    for (const article of articles) {
      const values = [article.articleId, article.favouriteProfileId, article.name]
      const result = await this.dataService.executeStatement(statement, values)
      articleIds.push(result.insertId)
    }

    const createdArticles: BlacklistArticleModel[] = []
    for (let i = 0; i < articleIds.length; i++) {
      createdArticles[i] = await this.findArticleById(articleIds[i])
    }

    return createdArticles
  }

  private async findArticleById(id: number): Promise<BlacklistArticleModel> {
    const statement = 'SELECT * FROM BlacklistArticle WHERE id = ?'
    const values = [id]
    const result = await this.dataService.executeStatement(statement, values)
    return this.parseFromRow(result.rows.item(0))
  }
}
