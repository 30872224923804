import { Injectable } from '@angular/core'
import { createStore, select, withProps } from '@ngneat/elf'
import { firstValueFrom } from 'rxjs'
import { filter } from 'rxjs/operators'
import { OnboardingDao } from '../services/dao/onboarding.dao'

interface OnboardingProps {
  onboardingTracking?: string[]
}

const store = createStore(
  { name: 'onboarding' },
  withProps<OnboardingProps>({ onboardingTracking: undefined })
)

@Injectable({
  providedIn: 'root',
})
export class OnboardingRepository {
  constructor(private onboardingDao: OnboardingDao) {}

  public async getCurrentOnboardingTracking(): Promise<string[]> {
    if (store.query((state) => state.onboardingTracking) === undefined) {
      void this.fetchOnboarding()
    }
    return firstValueFrom(
      store.pipe(
        select((state) => <string[]>state.onboardingTracking),
        filter((p) => !!p)
      )
    )
  }

  public async createOnboardingTracking(onboardingHintSeriesKey: string): Promise<void> {
    const newOnboardingTrackings = await this.onboardingDao.createOnboardingTracking(
      onboardingHintSeriesKey
    )
    store.update((state) => ({
      ...state,
      onboardingTracking: newOnboardingTrackings,
    }))
  }

  public async deleteAllOnboardingTracking(): Promise<void> {
    await this.onboardingDao.deleteAll()
    store.update((state) => ({
      ...state,
      onboardingTracking: [],
    }))
  }

  public async deleteSeriesByKey(key: string): Promise<void> {
    await this.onboardingDao.deleteByKey(key)
    store.update((state) => ({
      ...state,
      onboardingTracking: state.onboardingTracking?.filter((p) => p !== key),
    }))
  }

  private async fetchOnboarding(): Promise<void> {
    const onboardingTrackings = await this.onboardingDao.findAllTrackings()
    store.update((state) => ({
      ...state,
      onboardingTracking: onboardingTrackings,
    }))
  }
}
