import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core'
import { Capacitor } from '@capacitor/core'

@Directive({
  selector: '[efpHideOnMobile]',
})
export class EfpHideOnMobileDirective {
  private _isWebPlatform: boolean
  private _hasView = false

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {
    this._isWebPlatform = Capacitor.getPlatform() === 'web'
  }

  @Input() set efpHideOnMobile(shouldHide: boolean) {
    const show = this._isWebPlatform || (!this._isWebPlatform && !shouldHide)
    if (!this._hasView && show) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      this._hasView = true
    } else if (this._hasView && !show) {
      this.viewContainer.clear()
      this._hasView = false
    }
  }
}
