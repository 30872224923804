<ion-segment *efpShowOn="'mobile'" mode="md" [value]="0">
  <ion-segment-button [value]="0">{{ 'PLAN.ANGLE' | translate }}</ion-segment-button>
</ion-segment>

<efp-keypad
  *efpShowOn="'mobile'"
  #efpKeypad
  [initialValue]="initialAngle"
  unit="°"
  (keypadChange)="updateAngle($event)"
  [hasAdditionalContent]="true"
  [highlightError]="!valid"
  [disabled]="showErrorMessageWallsLocked">
  <ion-segment id="leg-selection" mode="ios" swipe-gesture="true" [(ngModel)]="movedLeg">
    <ion-segment-button
      value="LEFT"
      [disabled]="!isLeftEditable"
      data-test-selected="planner-update-angle-left">
      <ion-icon src="assets/icon/resize_angle_left.svg"></ion-icon>
    </ion-segment-button>
    <ion-segment-button
      value="RIGHT"
      [disabled]="!isRightEditable"
      data-test-selected="planner-update-angle-right">
      <ion-icon src="assets/icon/resize_angle_right.svg"></ion-icon>
    </ion-segment-button>
  </ion-segment>

  <ion-note color="danger" *ngIf="showErrorMessageWallsLocked">{{
    'PLAN.ANGLE_ERROR_MESSAGE' | translate
  }}</ion-note>
  <ion-note color="danger" *ngIf="showErrorMessageTooSmallAngle">{{
    'PLAN.ANGLE_ERROR_MESSAGE_SMALL' | translate : { angle: minAngle }
  }}</ion-note>
  <ion-note color="danger" *ngIf="showErrorMessageTooBigAngle">{{
    'PLAN.ANGLE_ERROR_MESSAGE_BIG' | translate : { angle: maxAngle }
  }}</ion-note>
  <ion-note color="danger" *ngIf="showErrorMessageSlabOnly90">{{
    'PLAN.ANGLE_ERROR_MESSAGE_SLAB_90' | translate : { angle: maxAngle }
  }}</ion-note>
</efp-keypad>

<efp-web-mobile-button-bar
  *efpShowOn="'mobile'"
  efpKeyboardShortcut
  (enterShortcut)="save()"
  (escShortcut)="cancel()"
  [applyChangesDisabled]="showErrorMessage"
  (canceled)="cancel()"
  (applied)="save()">
</efp-web-mobile-button-bar>

<overlay-editor-container
  *efpShowOn="'web'"
  efpKeyboardShortcut
  [valid]="!showErrorMessage"
  (enterShortcut)="save()"
  (escShortcut)="cancel()"
  [formGroup]="measurementForm">
  <ion-button
    overlay-button
    class="efp-circle-floating-button align-bottom"
    [disabled]="!isLeftEditable"
    [ngClass]="{
      light: movedLeg != 'LEFT',
      dark: movedLeg == 'LEFT'
    }"
    (click)="movedLeg = selectAngleRay.LEFT"
    (mouseover)="movedLeg = selectAngleRay.LEFT"
    (mouseleave)="movedLeg = prevMovedLeg">
    <ion-icon
      slot="icon-only"
      class="iconSize24"
      src="assets/icon/resize_angle_left.svg"></ion-icon>
  </ion-button>
  <ion-button
    overlay-button
    class="efp-circle-floating-button align-bottom"
    [disabled]="!isRightEditable"
    [ngClass]="{
      light: movedLeg != 'RIGHT',
      dark: movedLeg == 'RIGHT'
    }"
    (click)="movedLeg = selectAngleRay.RIGHT"
    (mouseover)="movedLeg = selectAngleRay.RIGHT"
    (mouseleave)="movedLeg = prevMovedLeg">
    <ion-icon
      slot="icon-only"
      class="iconSize24"
      src="assets/icon/resize_angle_right.svg"></ion-icon>
  </ion-button>
  <efp-angle-input
    #efpAngleInput
    overlay-input
    formControlName="measurementInput"
    [minWidth]="48"
    [showUnit]="false"
    [textAlign]="'center'"
    [shouldResizeInput]="true">
  </efp-angle-input>

  <div overlay-warning>
    <div *ngIf="showErrorMessageWallsLocked">{{ 'PLAN.ANGLE_ERROR_MESSAGE' | translate }}</div>
    <div *ngIf="showErrorMessageTooSmallAngle">
      {{ 'PLAN.ANGLE_ERROR_MESSAGE_SMALL' | translate : { angle: minAngle } }}
    </div>
    <div *ngIf="showErrorMessageTooBigAngle">
      {{ 'PLAN.ANGLE_ERROR_MESSAGE_BIG' | translate : { angle: maxAngle } }}
    </div>
    <div *ngIf="showErrorMessageSlabOnly90">
      {{ 'PLAN.ANGLE_ERROR_MESSAGE_SLAB_90' | translate : { angle: maxAngle } }}
    </div>
  </div>
</overlay-editor-container>
