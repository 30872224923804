/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ChangedResultPartModel } from '../models/changed-result-part-model';
import { ChangedResultPartsCommandParam } from '../models/changed-result-parts-command-param';

@Injectable({ providedIn: 'root' })
export class ChangedResultPartsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deleteOldAndSaveNewByPlanId()` */
  static readonly DeleteOldAndSaveNewByPlanIdPath = '/api/v1/changed-result-parts';

  /**
   * Deletes the current changedResultParts and adds a list of new changedResultParts to the plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOldAndSaveNewByPlanId$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOldAndSaveNewByPlanId$Plain$Response(
    params: {
      body: Array<ChangedResultPartsCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ChangedResultPartModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ChangedResultPartsApiClient.DeleteOldAndSaveNewByPlanIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChangedResultPartModel>>;
      })
    );
  }

  /**
   * Deletes the current changedResultParts and adds a list of new changedResultParts to the plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOldAndSaveNewByPlanId$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOldAndSaveNewByPlanId$Plain(
    params: {
      body: Array<ChangedResultPartsCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<ChangedResultPartModel>> {
    return this.deleteOldAndSaveNewByPlanId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangedResultPartModel>>): Array<ChangedResultPartModel> => r.body)
    );
  }

  /**
   * Deletes the current changedResultParts and adds a list of new changedResultParts to the plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOldAndSaveNewByPlanId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOldAndSaveNewByPlanId$Response(
    params: {
      body: Array<ChangedResultPartsCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ChangedResultPartModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ChangedResultPartsApiClient.DeleteOldAndSaveNewByPlanIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChangedResultPartModel>>;
      })
    );
  }

  /**
   * Deletes the current changedResultParts and adds a list of new changedResultParts to the plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOldAndSaveNewByPlanId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOldAndSaveNewByPlanId(
    params: {
      body: Array<ChangedResultPartsCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<ChangedResultPartModel>> {
    return this.deleteOldAndSaveNewByPlanId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangedResultPartModel>>): Array<ChangedResultPartModel> => r.body)
    );
  }

  /** Path part for operation `getChangedResultPartsByPlan()` */
  static readonly GetChangedResultPartsByPlanPath = '/api/v1/plans/{planId}/changed-result-parts';

  /**
   * Get changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangedResultPartsByPlan$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangedResultPartsByPlan$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ChangedResultPartModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ChangedResultPartsApiClient.GetChangedResultPartsByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChangedResultPartModel>>;
      })
    );
  }

  /**
   * Get changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangedResultPartsByPlan$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangedResultPartsByPlan$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<ChangedResultPartModel>> {
    return this.getChangedResultPartsByPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangedResultPartModel>>): Array<ChangedResultPartModel> => r.body)
    );
  }

  /**
   * Get changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangedResultPartsByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangedResultPartsByPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ChangedResultPartModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ChangedResultPartsApiClient.GetChangedResultPartsByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChangedResultPartModel>>;
      })
    );
  }

  /**
   * Get changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangedResultPartsByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangedResultPartsByPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<ChangedResultPartModel>> {
    return this.getChangedResultPartsByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangedResultPartModel>>): Array<ChangedResultPartModel> => r.body)
    );
  }

  /** Path part for operation `deleteChangedResultPartsByPlan()` */
  static readonly DeleteChangedResultPartsByPlanPath = '/api/v1/plans/{planId}/changed-result-parts';

  /**
   * Delete changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChangedResultPartsByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChangedResultPartsByPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ChangedResultPartsApiClient.DeleteChangedResultPartsByPlanPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete changedResultParts for a specific plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChangedResultPartsByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChangedResultPartsByPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteChangedResultPartsByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
