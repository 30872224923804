import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { NavController, ViewDidEnter, ViewWillLeave } from '@ionic/angular'
import {
  Edge,
  Mesh,
  PlannerComponent,
  PlannerInteractionEvent,
  PlannerStateService,
  PlanType,
  SlabMesh,
  SNAPPING_ERROR_TOLERANCE,
  UnitOfLength,
  WallMesh,
} from 'formwork-planner-lib'
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators'
import { defaultDrawSettings } from '../../models/draw-settings'
import { MeasureType } from '../../models/measureType'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'
import { Plan } from '../../models/plan'
import { PlanVisibilitySettings } from '../../models/plan-visibility-settings'
import { PlanSettings } from '../../models/planSettings'
import { PlanResultRepository } from '../../repositories/plan-result.repository'
import { PlanRepository } from '../../repositories/plan.repository'
import { AppSettingService } from '../../services/app-setting.service'
import { ErrorPopupService } from '../../services/error-popup.service'
import { HistoryService } from '../../services/history.service'
import { PlanMeshService } from '../../services/plan-mesh.service'
import { PlanSettingsService } from '../../services/plan-settings.service'
import { PlanService } from '../../services/plan.service'
import { EfpExportService } from '../../services/share/efp-export.service'
import { Translation } from '../../services/translation.service'
import { ZoomAndPanService } from '../../services/zoom-and-pan.service'
import { SidebarPosition } from '../../shared/components/efp-sidebar-container/efp-sidebar-container.component'
import { TriggerType } from '../../shared/directives/onboarding-trigger.directive'
import { assertEnumCaseUnreachable } from '../../utils/assertEnumCaseUnreachable'
import {
  ANGLE_EDITOR_BUTTON_CONTAINER_HEIGHT,
  ANGLE_EDITOR_BUTTON_CONTAINER_WIDTH,
  AngleMenuComponent,
} from './components/angle-menu/angle-menu.component'
import {
  MEASUREMENT_EDITOR_BUTTON_CONTAINER_HEIGHT,
  MEASUREMENT_EDITOR_BUTTON_CONTAINER_WIDTH,
  MEASUREMENT_EDITOR_INPUT_HEIGHT,
  MEASUREMENT_EDITOR_INPUT_WIDTH,
  MeasurementEditorComponent,
} from './components/measurement-editor/measurement-editor.component'
import { TWallEditorComponent } from './components/t-wall-editor/t-wall-editor.component'
import { Model } from './model/Model'
import { AngleLabel } from './model/paper/AngleLabel'
import { InputLabel, InputOverlayPositionSide } from './model/paper/InputLabel'
import { MeasurementLabel } from './model/paper/MeasurementLabel'
import { SlabModel } from './model/SlabModel'
import { HAS_MOVED_SUFFICIENTLY_TRESHOLD } from './model/snapping/constants'
import { WallModel } from './model/WallModel'
import { DeformationMode } from './modes/deformation-mode'
import { DrawMode } from './modes/draw-mode'
import { RenderService } from './services/render.service'
import { CursorType, SelectionService } from './services/selection.service'
import { SlabRenderService } from './services/slab-render.service'
import { WallRenderService } from './services/wall-render.service'
import { AngleInfo } from './types/AngleInfo'
import { Mode } from './types/mode'

@Component({
  templateUrl: './planner-page.component.html',
  styleUrls: ['./planner-page.component.scss', '../style/canvas-style.scss'],
  providers: [PlannerStateService],
})
export class PlannerPage implements ViewWillLeave, ViewDidEnter {
  /**
   * This flag is used to signal the planner to initialize.
   * This is set via ionViewDidEnter is, that the page DOM needs to be fully ready so the canvas inside can be initialized.
   */
  pageReady = false
  mode: Mode = Mode.DRAW

  public plan$ = new BehaviorSubject<Plan | undefined>(undefined)
  public planType$ = this.plan$.asObservable().pipe(map((p) => p?.buildingType))
  public isCalculated$ = combineLatest([
    this.plan$,
    this.planResultRepository.planResultCalculationStates$,
  ]).pipe(
    map(([plan, planResultCalculationStates]) => {
      if (!plan) {
        return false
      }

      return planResultCalculationStates.some((state) => state.id === plan.id && state.calculated)
    })
  )
  public navNextStep$ = combineLatest([this.plan$, this.isCalculated$]).pipe(
    map(([plan, isCalculated]) => {
      if (plan?.buildingType === PlanType.SLAB) {
        return isCalculated
          ? this.translation.translate('NAVIGATION.RESULT')
          : this.translation.translate('NAVIGATION.CALCULATE')
      }
      return this.translation.translate('NAVIGATION.NEXT')
    }),
    startWith(this.translation.translate('NAVIGATION.NEXT'))
  )

  model?: Model<Mesh>
  previewModel?: Model<Mesh>
  renderService?: RenderService<Model<Mesh>>
  selectionService?: SelectionService
  planVisibilitySettings?: PlanVisibilitySettings
  planSettings?: PlanSettings

  selectedMeasure?: MeasurementLabel
  edgeForMeasureEdit?: Edge
  selectedTWall?: Edge
  selectedAngleCenterPoint?: paper.Point
  previewEdgeForMeasureEdit?: Edge
  previewSelectedAngle?: AngleInfo

  measurementEditorOverlayPosition = { x: 0, y: 0 }
  isWebversion = false

  onboardingHintIndex$?: Observable<number>

  readonly modes = Mode

  ctrlPressed = false
  showDirectionButtons = true
  private drawnEdge?: Edge
  private drawMode!: DrawMode
  private deformationMode!: DeformationMode
  private previewModelSubscription?: Subscription
  private readonly pageLeave$ = new Subject<void>()
  public readonly updateMagnifier$ = new Subject<void>()

  protected readonly SidebarPosition = SidebarPosition

  @ViewChild('tWallEditor') tWallEditor: TWallEditorComponent | undefined
  @ViewChild('measurementEditor') measurementEditor: MeasurementEditorComponent | undefined
  @ViewChild('angleEditor') angleEditor: AngleMenuComponent | undefined
  @ViewChild('efpPlanner') planner: PlannerComponent | undefined

  constructor(
    public readonly efpExportService: EfpExportService,
    private readonly activeRoute: ActivatedRoute,
    private readonly navCtrl: NavController,
    private readonly planMeshService: PlanMeshService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly picasso: PlannerStateService,
    private readonly zoomAndPanService: ZoomAndPanService,
    private readonly errorPopupService: ErrorPopupService,
    private readonly translation: Translation,
    private readonly historyService: HistoryService,
    private readonly planRepository: PlanRepository,
    private readonly planService: PlanService,
    private readonly appSettingService: AppSettingService,
    private readonly planResultRepository: PlanResultRepository
  ) {
    this.isWebversion = Capacitor.getPlatform() === 'web'
  }

  get plannerInitialized(): boolean {
    return (
      this.pageReady &&
      !!this.plan$.value &&
      !!this.model &&
      !!this.renderService &&
      !!this.drawMode &&
      !!this.deformationMode
    )
  }

  set selectedAngle(value: AngleInfo | undefined) {
    if (this.renderService) {
      this.renderService.selectedAngle = value
    }
  }

  get selectedAngle(): AngleInfo | undefined {
    return this.renderService?.selectedAngle
  }

  get hasSelectedEdges(): boolean {
    return (this.selectionService?.getSelectedEdges()?.length ?? 0) > 0
  }

  get showContextMenu(): boolean {
    return !!this.edgeForMeasureEdit || !!this.selectedAngle || this.hasSelectedEdges
  }

  get isMeasureSelectionOpen(): boolean {
    return this.showContextMenu && (this.edgeForMeasureEdit != null || this.selectedAngle != null)
  }

  get planId(): number | undefined {
    return this.plan$.value ? this.plan$.value.id : undefined
  }

  firstStepsOnboardingId(): OnboardingHintSeriesKey {
    return Capacitor.isNativePlatform()
      ? OnboardingHintSeriesKey.PLANNER_FIRST_STEPS_NATIVE
      : OnboardingHintSeriesKey.PLANNER_FIRST_STEPS_WEB
  }

  async ionViewDidEnter(): Promise<void> {
    this.pageReady = true

    // bugfix for BLI 46383;
    if (Capacitor.getPlatform() === 'ios') {
      await Keyboard.hide()
    }
  }

  async ionViewWillLeave(): Promise<void> {
    if (this.model && this.plan$.value) {
      this.historyService.setPlanHistory(this.model.history, this.plan$.value.id)
    }
    this.clearEditorData()
    this.pageLeave$.next()
    this.pageReady = false
    this.plan$.next(undefined)
    this.model = undefined
    this.renderService = undefined
    this.selectedTWall = undefined
  }

  onPlannerInitialized(): void {
    // Start listening for the plan id after everything paper is setup
    this.activeRoute.paramMap.pipe(takeUntil(this.pageLeave$)).subscribe((params) => {
      const planId = params.get('planId')
      if (planId) {
        void this.loadPlan(+planId)
      }
    })
  }

  async onMouseDragged(event: PlannerInteractionEvent): Promise<void> {
    if (this.isMeasureSelectionOpen) {
      if (Capacitor.isNativePlatform()) {
        return
      } else {
        await this.saveAndCloseEditors()
      }
    }

    this.selectedTWall = undefined

    if (event.primaryInteraction) {
      switch (this.mode) {
        case Mode.DRAW:
          if (this.renderService) {
            this.selectionService?.deselectAllEdges()
          }
          this.drawnEdge = this.drawMode.onMouseDragged(event)
          break
        case Mode.DEFORMATION:
          this.deformationMode.onMouseDragged(event)
          break
      }
    }
  }

  async onMouseUp(event: PlannerInteractionEvent): Promise<void> {
    const hasMovedSufficiently = event.dragDirection.length > HAS_MOVED_SUFFICIENTLY_TRESHOLD
    if (hasMovedSufficiently && !this.isMeasureSelectionOpen) {
      switch (this.mode) {
        case Mode.DRAW:
          this.drawMode.onMouseUp(event)
          if (this.drawnEdge && this.isWebversion) {
            const edgeDirection = this.drawnEdge.getDirection().normalize()
            const createdEdge = [...this.drawnEdge.endPoint.edges].find((e) =>
              e.getDirection().normalize().isClose(edgeDirection, SNAPPING_ERROR_TOLERANCE)
            )
            if (createdEdge) {
              const label = this.renderService?.findLabelOfEdge(createdEdge)
              if (label) {
                this.showDirectionButtons = false
                await this.onMeasurementLabelClicked(label)
              }
            }
          }
          break
        case Mode.DEFORMATION:
          this.deformationMode.onMouseUp(event, this.ctrlPressed)
          break
      }
    } else {
      await this.saveAndCloseEditors()
      this.toggleEdgeSelection(event.downPoint)

      if (event.targetItem instanceof MeasurementLabel && event.targetItem.isClickable) {
        this.showDirectionButtons = true
        await this.onMeasurementLabelClicked(event.targetItem)
      } else if (event.targetItem instanceof AngleLabel) {
        this.updateSelectedAngle(event.targetItem)
      }
      this.updateMagnifier()
    }
    this.checkForTWall()
    this.renderService?.drawLabels([], this.selectedTWall)
  }

  async onMouseMoved(event: PlannerInteractionEvent): Promise<void> {
    if (!this.selectionService) {
      return
    }

    this.renderService?.unhoverAll()
    const highlightArea = this.selectionService.findHighlightAreaNearPoint(event.point)
    if (
      event.targetItem instanceof InputLabel &&
      event.targetItem.isClickable &&
      this.mode === Mode.DEFORMATION
    ) {
      this.selectionService.setMouseCursor(CursorType.POINTER)
      event.targetItem.hover()
    } else if (highlightArea) {
      if (this.mode === Mode.DEFORMATION) {
        this.selectionService.setMouseCursor(
          highlightArea.isSelected ? CursorType.GRAB : CursorType.POINTER
        )
      }
      highlightArea.hover()
    } else if (this.planner) {
      if (this.planner.canvasBounds.contains(event.point) && this.mode === Mode.DRAW) {
        this.selectionService.setMouseCursor(CursorType.CROSSHAIR)
      } else {
        this.selectionService.setMouseCursor(CursorType.DEFAULT)
      }
    }
  }

  onMouseLeave(): void {
    this.selectionService?.setMouseCursor(CursorType.DEFAULT)
  }

  onZoomUpdated(zoomInMeters: number): void {
    // TODO (#85341): Find solution to update the measurement position to get the correct point with new zoom settings for hiding the related label
    if (this.isWebversion) {
      this.closeMeasureEditor()
      this.closeAngleMenu()
    }

    this.zoomAndPanService.setZoom(zoomInMeters)
    this.renderService?.drawLabels(undefined, this.selectedTWall)
    this.renderService?.updateAngleIndicator()
  }

  onPanUpdated(pan: paper.Point): void {
    this.zoomAndPanService.setPan(pan)
  }

  onDelete(): void {
    // check if measurement-editor is present because backspace key could also be used for text input
    if (this.edgeForMeasureEdit && !this.selectedTWall) {
      return
    }

    this.clearEditorData()
    this.model?.removeEdges(this.selectionService?.getSelectedEdges() ?? [])
  }

  onCtrl(ctrlPressed: boolean): void {
    this.ctrlPressed = ctrlPressed
  }

  private checkForTWall(): void {
    if (this.mode === Mode.DEFORMATION) {
      const selectedEdges = this.selectionService?.getSelectedEdges()
      if (
        selectedEdges &&
        selectedEdges.length === 1 &&
        selectedEdges[0].isTWall() &&
        !this.edgeForMeasureEdit?.isSameEdge(selectedEdges[0])
      ) {
        this.selectedTWall = selectedEdges[0]
      } else {
        this.selectedTWall = undefined
      }
    } else {
      this.selectedTWall = undefined
    }
  }

  private async loadPlan(planId: number): Promise<void> {
    const plan = await this.planService.findOne(planId)
    this.plan$.next(plan)

    this.planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
      plan.settingsId
    )
    this.planVisibilitySettings = (
      await this.appSettingService.getAppSettings()
    ).drawVisibilitySettings
    if (!this.plan$.value || !this.planSettings || !this.planVisibilitySettings) {
      throw new Error(
        'PlannerPage.loadPlan - Plan, plan settings or plan visibility settings not found in loadPlan'
      )
    }

    let hasChachedHistory = false

    this.selectionService = new SelectionService()

    switch (this.plan$.value.buildingType) {
      case PlanType.SLAB:
        const cachedSlabHistory = this.historyService.getPlanHistory<SlabMesh>(planId)
        hasChachedHistory = cachedSlabHistory !== undefined
        const slabModel = new SlabModel(this.picasso.paper, this.planSettings, cachedSlabHistory)
        this.model = slabModel
        this.renderService = new SlabRenderService(
          slabModel,
          this.picasso.paper,
          this.planVisibilitySettings,
          this.selectionService
        )
        break
      case PlanType.WALL:
        const cachedWallHistory = this.historyService.getPlanHistory<WallMesh>(planId)
        hasChachedHistory = cachedWallHistory !== undefined
        const wallModel = new WallModel(this.picasso.paper, this.planSettings, cachedWallHistory)
        this.model = wallModel
        this.renderService = new WallRenderService(
          wallModel,
          this.picasso.paper,
          this.planVisibilitySettings,
          this.selectionService
        )
        break
    }
    this.historyService.setPlanHistory(this.model.history, planId)

    this.model?.modelChanged$
      .pipe(takeUntil(this.pageLeave$))
      .subscribe(() => this.onModelUpdated())
    if (this.plan$.value.serializedMesh) {
      this.model?.loadSerializedMeshes(this.plan$.value.serializedMesh, !hasChachedHistory)
    }
    this.model?.historyChanged$
      .pipe(takeUntil(this.pageLeave$), debounceTime(500))
      .subscribe(() => void this.storeMesh())

    this.zoomAndPanService.initializeZoomAndPan(this.picasso, this.model)
    await this.initModes()
  }

  closeAngleMenu(): void {
    if (this.renderService == null) {
      return
    }

    this.selectionService?.deselectAllEdges()

    if (this.model && this.renderService.selectedAngle != null) {
      this.renderService.selectionPoint = undefined
      this.renderService.selectedAngle = undefined
      this.previewSelectedAngle = undefined
      this.previewModel = undefined
      this.renderService.previewModel = undefined
      this.onModelUpdated()
    }
  }

  updateLabels(): void {
    this.renderService?.drawLabels()
  }

  /**
   * callback to call when the measurement editor applies changes without closing itself
   *
   * @param updatedEdge the updated edge of the main model
   */
  onMeshChanged(updatedEdge: Edge): void {
    this.edgeForMeasureEdit = updatedEdge
    this.generatePreviewModel()
    this.selectionService?.setSelectedEdges([this.edgeForMeasureEdit])
  }

  clearEditorData(): void {
    if (this.renderService == null) {
      return
    }

    // if edgeForMeasureEdit is already null, then it can be considered as closed
    // this check prevents concurrency issues when closing the measure editor due to simultaneously opening the angle editor
    if (this.model) {
      this.previewEdgeForMeasureEdit = undefined
      this.previewModel = undefined
      this.renderService.previewModel = undefined
      this.selectedMeasure = undefined
      this.edgeForMeasureEdit = undefined
      this.selectedTWall = undefined
      this.renderService.hideLabelPosition = undefined
      this.onModelUpdated()
    }
  }

  closeMeasureEditor(): void {
    this.selectionService?.deselectAllEdges()
    this.clearEditorData()
  }

  onEditEdgeMeasurements(edge: Edge): void {
    if (edge && this.selectionService) {
      this.edgeForMeasureEdit = edge
      this.generatePreviewModel()
      this.selectionService.setSelectedEdges([edge])
      this.updateMagnifier()
    }
  }

  private async saveAndCloseEditors(): Promise<void> {
    if (this.selectedMeasure) {
      if (this.measurementEditor?.valid) {
        await this.measurementEditor?.onSaveClicked()
      }
      await this.tWallEditor?.save()
    }
    if (this.selectedAngle && this.angleEditor?.valid) {
      this.angleEditor?.save()
    }
  }

  private onModelUpdated(): void {
    this.updateMagnifier()
    this.draw()
  }

  private updateMagnifier(): void {
    this.updateMagnifier$.next()
    if (this.renderService == null) {
      return
    }
  }

  private async storeMesh(): Promise<void> {
    if (this.plan$.value && this.model) {
      await this.planMeshService.updatePlanSerializedMesh(this.plan$.value, this.model)
    }
  }

  private async initModes(): Promise<void> {
    if (this.model && this.renderService && this.selectionService) {
      this.drawMode = new DrawMode(this.model, this.renderService)
      this.deformationMode = new DeformationMode(
        this.model,
        this.renderService,
        this.selectionService
      )
      if (this.plan$.value) {
        await this.planRepository.updateCurrentStep(this.plan$.value.id, 'planner')
      }
    }
  }

  private toggleEdgeSelection(point: paper.Point): void {
    if (!this.model || !this.selectionService) {
      return
    }

    const selectedEdges = this.selectionService.getSelectedEdges()
    if (!this.ctrlPressed && this.isWebversion) {
      this.selectionService.deselectAllEdges()
    }

    const closestEdge = this.model.findEdgeNearPoint(point)
    if (closestEdge) {
      if (this.edgeForMeasureEdit) {
        this.clearEditorData()
      }

      if (
        this.ctrlPressed ||
        !this.isWebversion ||
        selectedEdges.length !== 1 ||
        selectedEdges[0] !== closestEdge
      ) {
        this.selectionService.toggleEdge(closestEdge)
        if (this.mode === Mode.DEFORMATION) {
          this.selectionService.setMouseCursor(CursorType.GRAB)
        }
      }
    }
  }

  /**
   * Checks the current point if an angle has been clicked and returns true, if so.
   */
  private updateSelectedAngle(angleLabel: AngleLabel): boolean {
    const origin = angleLabel.origin
    const center = angleLabel.arcCenterPoint
    if (this.model && this.renderService) {
      this.selectedAngle = this.model.getClosestAngle(origin, center)
      if (this.selectedAngle) {
        this.renderService.selectionPoint = origin
        this.selectedAngleCenterPoint = center
      }
      if (this.planner) {
        this.setInputPositionForAngleLabel(
          angleLabel,
          this.planner.canvasBounds,
          this.planner.canvasZoom
        )
      }
      this.generatePreviewModel()
      return !!this.renderService.selectedAngle
    }
    return false
  }

  private async onMeasurementLabelClicked(lengthLabel: MeasurementLabel): Promise<void> {
    const edge = this.model?.getNearestEdge(lengthLabel.bounds.center)
    if (edge && this.selectionService && this.plan$.value) {
      if (this.selectedTWall) {
        const neighbours = this.selectedTWall.getNeighbours()
        const tNeighbours = neighbours.filter((neighbour) =>
          neighbours.filter((e) => e !== neighbour).some((other) => neighbour.canMerge(other))
        )
        const edgeAttributes = this.model?.getAttributes([edge])
        if (
          tNeighbours.some((e) => e.isSameEdge(edge)) &&
          lengthLabel.getMeasureType(edgeAttributes, this.plan$.value.buildingType) !==
            MeasureType.width
        ) {
          this.selectionService.setSelectedEdges([this.selectedTWall])
        } else {
          this.selectionService.setSelectedEdges([edge])
        }
      } else {
        this.selectionService.setSelectedEdges([edge])
      }

      this.selectedMeasure = lengthLabel
      this.edgeForMeasureEdit = edge
      this.generatePreviewModel()
      this.draw()
    }
  }

  private generatePreviewModel(): void {
    if (this.renderService == null) {
      return
    }

    this.removePreviewModel()
    const previewModel = this.model?.clone()

    this.previewModelSubscription?.unsubscribe()
    this.previewModelSubscription = previewModel?.modelChanged$
      .pipe(takeUntil(this.pageLeave$))
      .subscribe(() => this.onModelUpdated())

    const edgeForMeasureEdit = this.selectedTWall ? this.selectedTWall : this.edgeForMeasureEdit
    const selectedAnglePoint = this.renderService.selectionPoint
    if (edgeForMeasureEdit != null) {
      this.previewEdgeForMeasureEdit = previewModel
        ?.getAllEdges()
        .find((previewEdge) => previewEdge.isSameEdge(edgeForMeasureEdit))
    } else if (selectedAnglePoint != null && this.selectedAngleCenterPoint != null) {
      this.previewSelectedAngle = previewModel?.getClosestAngle(
        selectedAnglePoint,
        this.selectedAngleCenterPoint
      )
    }

    if (edgeForMeasureEdit != null || selectedAnglePoint != null) {
      this.previewModel = previewModel
      this.renderService.previewModel = previewModel
    }
  }

  private removePreviewModel(): void {
    if (this.renderService == null) {
      return
    }

    this.previewModelSubscription?.unsubscribe()
    this.previewModel = undefined
    this.renderService.previewModel = undefined
  }

  changeModeToEdit(): void {
    this.changeMode(Mode.DEFORMATION)
  }

  changeModeToDrawing(): void {
    this.changeMode(Mode.DRAW)
  }

  changeMode(mode: Mode): void {
    this.mode = mode
    this.selectedTWall = undefined
    this.draw()
    this.selectionService?.setSelectedEdges(this.selectionService?.getSelectedEdges())
  }

  private draw(): void {
    if (this.renderService) {
      this.renderService.hideLabelPosition = this.isWebversion
        ? this.selectedMeasure?.position
        : undefined
      this.renderService.meshHighlightVisible =
        this.mode === Mode.DEFORMATION && !this.renderService.meshHighlightLayerBlocked
      this.renderService.draw(undefined, this.selectedTWall)
      if (this.isWebversion && this.selectedMeasure && this.edgeForMeasureEdit && this.planner) {
        this.setInputPositionForMeasurementLabel(
          this.selectedMeasure,
          this.edgeForMeasureEdit,
          this.planner.canvasBounds,
          this.planner.canvasZoom,
          !this.showDirectionButtons || !!this.selectedTWall
        )
      }
    }
  }

  async onNextClicked(route: string[]): Promise<void> {
    const errors = this.getErrors(route)
    if (errors.length > 0) {
      await this.errorPopupService.showPlannerErrors(errors)
    } else {
      await this.navCtrl.navigateForward(route)
    }
  }

  private getErrors(route: string[]): string[] {
    if (!this.plan$.value || !this.model) {
      return []
    }
    const errors: string[] = []

    if (
      this.model.getPoints().length === 0 &&
      route.length > 0 &&
      route[0].indexOf('plansettings') === -1
    ) {
      errors.push(this.translation.translate('PLAN.EMPTY_ERROR_MESSAGE'))
    }

    if (this.plan$.value.buildingType === PlanType.SLAB) {
      if (this.model.getPoints().some((point) => point.edges.size % 2 !== 0)) {
        errors.push(this.translation.translate('PLAN.SLAB_NOT_CLOSED_ERROR_MESSAGE'))
      }
    }

    return errors
  }

  undo(): void {
    this.model?.undo()
    this.selectedTWall = undefined
  }

  redo(): void {
    this.model?.redo()
    this.selectedTWall = undefined
  }

  get unit(): UnitOfLength {
    return this.model?.drawSetting?.measurementUnit ?? defaultDrawSettings.measurementUnit
  }

  get showMagnifier(): boolean {
    return (this.planVisibilitySettings?.showMagnifier && Capacitor.isNativePlatform()) ?? false
  }

  protected readonly TriggerType = TriggerType
  protected readonly PlanType = PlanType

  private calculateInputOverlayPositionSide(
    edgeCenterPoint: paper.Point,
    measurePoint: paper.Point
  ): InputOverlayPositionSide {
    const edgeCenterPointX = Math.round(edgeCenterPoint.x)
    const edgeCenterPointY = Math.round(edgeCenterPoint.y)
    const measurePointX = Math.round(measurePoint.x)
    const measurePointY = Math.round(measurePoint.y)

    if (edgeCenterPointX === measurePointX && edgeCenterPointY > measurePointY) {
      return InputOverlayPositionSide.Top
    } else if (edgeCenterPointX < measurePointX && edgeCenterPointY > measurePointY) {
      return InputOverlayPositionSide.TopRight
    } else if (edgeCenterPointX < measurePointX && edgeCenterPointY === measurePointY) {
      return InputOverlayPositionSide.Right
    } else if (edgeCenterPointX < measurePointX && edgeCenterPointY < measurePointY) {
      return InputOverlayPositionSide.BottomRight
    } else if (edgeCenterPointX === measurePointX && edgeCenterPointY < measurePointY) {
      return InputOverlayPositionSide.Bottom
    } else if (edgeCenterPointX > measurePointX && edgeCenterPointY < measurePointY) {
      return InputOverlayPositionSide.BottomLeft
    } else if (edgeCenterPointX > measurePointX && edgeCenterPointY === measurePointY) {
      return InputOverlayPositionSide.Left
    } else if (edgeCenterPointX > measurePointX && edgeCenterPointY > measurePointY) {
      return InputOverlayPositionSide.TopLeft
    }

    throw new Error('Invalid input overlay position side')
  }

  private setInputPositionForMeasurementLabel(
    measurementLabel: MeasurementLabel,
    edge: Edge,
    canvasBounds: paper.Rectangle,
    canvasZoom: number = 1,
    hideButtons = false
  ): void {
    const edgeCenterPoint = edge.getCenterPoint()
    const inputOverlayPositionSide = this.calculateInputOverlayPositionSide(
      edgeCenterPoint,
      measurementLabel.inputOverlayPosition
    )

    const translatedX = Math.abs(measurementLabel.inputOverlayPosition.x - canvasBounds.x)
    const translatedY = Math.abs(measurementLabel.inputOverlayPosition.y - canvasBounds.y)

    const zoomedX = translatedX * canvasZoom
    const zoomedY = translatedY * canvasZoom

    const editorWidth = hideButtons
      ? MEASUREMENT_EDITOR_INPUT_WIDTH
      : MEASUREMENT_EDITOR_BUTTON_CONTAINER_WIDTH
    const editorHeight = hideButtons
      ? MEASUREMENT_EDITOR_INPUT_HEIGHT
      : MEASUREMENT_EDITOR_BUTTON_CONTAINER_HEIGHT

    switch (inputOverlayPositionSide) {
      case InputOverlayPositionSide.Top:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 2 - 6,
        }
        break
      case InputOverlayPositionSide.TopRight:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 + MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 4,
        }
        break
      case InputOverlayPositionSide.Right:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 + MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 2,
        }
        break
      case InputOverlayPositionSide.BottomRight:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 + MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y:
            zoomedY -
            editorHeight +
            MEASUREMENT_EDITOR_INPUT_HEIGHT / 2 +
            MEASUREMENT_EDITOR_INPUT_HEIGHT / 4,
        }
        break
      case InputOverlayPositionSide.Bottom:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 2 + 6,
        }
        break
      case InputOverlayPositionSide.BottomLeft:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 - MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y:
            zoomedY -
            editorHeight +
            MEASUREMENT_EDITOR_INPUT_HEIGHT / 2 +
            MEASUREMENT_EDITOR_INPUT_HEIGHT / 4,
        }
        break
      case InputOverlayPositionSide.Left:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 - MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 2,
        }
        break
      case InputOverlayPositionSide.TopLeft:
        this.measurementEditorOverlayPosition = {
          x: zoomedX - editorWidth / 2 - MEASUREMENT_EDITOR_INPUT_WIDTH / 3,
          y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 4,
        }
        break
      default:
        assertEnumCaseUnreachable(inputOverlayPositionSide)
    }
  }

  private setInputPositionForAngleLabel(
    angleLabel: AngleLabel,
    canvasBounds: paper.Rectangle,
    canvasZoom: number = 1
  ): void {
    const translatedX = Math.abs(angleLabel.inputOverlayPosition.x - canvasBounds.x)
    const translatedY = Math.abs(angleLabel.inputOverlayPosition.y - canvasBounds.y)

    const zoomedX = translatedX * canvasZoom
    const zoomedY = translatedY * canvasZoom

    const editorWidth = ANGLE_EDITOR_BUTTON_CONTAINER_WIDTH
    const editorHeight = ANGLE_EDITOR_BUTTON_CONTAINER_HEIGHT

    this.measurementEditorOverlayPosition = {
      x: zoomedX - editorWidth / 2,
      y: zoomedY - editorHeight + MEASUREMENT_EDITOR_INPUT_HEIGHT / 2 - 6,
    }
  }

  protected readonly CursorType = CursorType
}
