<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/icon/ic_close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large">
      {{ findModalTitle() }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<efp-modal-form>
  <div class="modal-inner">
    <form [formGroup]="feedbackForm" *ngIf="!feedbackSent; else confirmationMessage">
      <div [ngClass]="{ hidden: index !== 0 }">
        <ion-title> {{ 'FEEDBACK.ALERT_DIALOG_MESSAGE' | translate }}</ion-title>
        <ion-radio-group value="bug" formControlName="feedbackType">
          <ion-item lines="none">
            <div class="basic-label">{{ 'FEEDBACK.MAIL_TYPE_BUG' | translate }}</div>
            <ion-radio slot="start" value="BUG"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <div class="basic-label">{{ 'FEEDBACK.MAIL_TYPE_HELP' | translate }}</div>
            <ion-radio slot="start" value="HELP"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <div class="basic-label">{{ 'FEEDBACK.MAIL_TYPE_IDEA' | translate }}</div>
            <ion-radio slot="start" value="IDEA"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>

      <div [ngClass]="index !== 1 ? 'hidden' : ''" class="d-flex-col gap-24">
        <div>
          <ion-title
            >{{ 'FEEDBACK.MAIL_ADD_FEEDBACK' | translate }}
            <ion-note>({{ 'GENERAL.REQUIRED' | translate }})</ion-note></ion-title
          >
          <ion-item lines="none">
            <ion-textarea
              formControlName="feedbackMessage"
              placeholder="{{ 'FEEDBACK.TEXTFIELD_PLACEHOLDER' | translate }}"
              [rows]="6"
              [autoGrow]="false">
            </ion-textarea>
          </ion-item>
        </div>
        <div>
          <ion-title>{{ 'FEEDBACK.WHICH_ATTACHMENTS' | translate }}</ion-title>
          <ion-note class="italic">{{ 'FEEDBACK.ATTACHMENT_HINT' | translate }}</ion-note>
          <div class="d-flex align-center" *ngIf="this.downloadScreenshotUrl">
            <ion-item lines="none" class="d-flex flex-1">
              <ion-checkbox formControlName="attachScreenshot" slot="start"></ion-checkbox>
              <div class="basic-label">{{ 'FEEDBACK.SCREENSHOT' | translate }}</div>
            </ion-item>
            <ion-button
              class="download-btn"
              fill="clear"
              shape="round"
              download="screenshot.png"
              [href]="this.downloadScreenshotUrl">
              <ion-icon slot="icon-only" src="assets/icon/ic_download.svg"></ion-icon>
            </ion-button>
          </div>

          <div class="d-flex align-center" *ngIf="this.planName && this.planData">
            <ion-item lines="none" class="d-flex flex-1">
              <ion-checkbox slot="start" formControlName="attachPlanData"></ion-checkbox>
              <div class="basic-label">
                {{ 'FEEDBACK.PLAN_FILE' | translate }} ({{ this.planName }})
              </div>
            </ion-item>
            <ion-button
              class="download-btn"
              fill="clear"
              shape="round"
              download="{{ this.planName }}.efp"
              [href]="this.downloadPlan">
              <ion-icon slot="icon-only" src="assets/icon/ic_download.svg"></ion-icon>
            </ion-button>
          </div>

          <ion-item lines="none">
            <ion-checkbox slot="start" formControlName="attachContextInformation"></ion-checkbox>
            <div class="basic-label">
              <ng-container *ngIf="isNative; else webTranslation">
                {{ 'FEEDBACK.CONTEXT_INFO_MOBILE' | translate }}
              </ng-container>
              <ng-template #webTranslation>
                {{ 'FEEDBACK.CONTEXT_INFO_WEB' | translate }}
              </ng-template>
            </div>
          </ion-item>

          <div class="d-flex align-center" *ngIf="this.showMessageCheckbox">
            <ion-item lines="none" class="d-flex flex-1">
              <ion-checkbox slot="start" formControlName="attachResultProtocol"></ion-checkbox>
              <div class="basic-label">
                {{ 'FEEDBACK.ERROR_MESSAGES' | translate }}
              </div>
            </ion-item>
          </div>
        </div>
      </div>
    </form>
    <ng-template #confirmationMessage>
      <div class="d-flex-col gap-16">
        <div>
          {{ 'FEEDBACK.MAIL_THANKS' | translate }}
        </div>
        <div>
          {{ 'FEEDBACK.MAIL_SENT_TO_TEXT' | translate }}
        </div>
        <ion-title>{{ userMail }}</ion-title>
      </div>
    </ng-template>
  </div>
</efp-modal-form>

<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar">
    <ng-container *ngIf="!feedbackSent; else acceptNotice">
      <ion-buttons slot="start" class="d-flex-col mand-btn-container">
        <ion-button
          *ngIf="index !== 0"
          (click)="goToSlide(this.index - 1)"
          class="navigation-button light"
          data-test-selector="feedback-modal-modal-prev-slide">
          <ion-icon slot="icon-only" src="assets/icon/ic_arrow-left.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <div class="hint-status-indicators d-flex flex-4">
        <ion-icon
          (click)="goToSlide(0)"
          data-test-selector="feedback-modal-hint-status-indicator"
          aria-hidden="true"
          src="assets/icon/ellipse.svg"
          [ngClass]="{ active: index === 0 }"></ion-icon>
        <ion-icon
          (click)="goToSlide(1)"
          data-test-selector="feedback-modal-hint-status-indicator"
          aria-hidden="true"
          [ngClass]="{ active: index === 1 }"
          src="assets/icon/ellipse.svg"></ion-icon>
      </div>
      <ion-buttons slot="end" class="d-flex mand-btn-container">
        <ion-button
          *ngIf="index !== 1"
          (click)="goToSlide(this.index + 1)"
          class="navigation-button dark"
          data-test-selector="feedback-modal-next-slide">
          <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
        </ion-button>
        <ion-button
          (click)="onSubmit()"
          *ngIf="index === 1"
          [disabled]="!isFeedbackFormValid"
          class="navigation-button dark"
          data-test-selector="feedback-modal-submits">
          <ion-icon color="white" slot="icon-only" src="assets/icon/ic_Send.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-container>
    <ng-template #acceptNotice>
      <ion-buttons slot="end">
        <ion-button
          (click)="closeModal()"
          class="navigation-button dark"
          data-test-selector="onboarding-modal-next-hint">
          <ion-icon color="white" slot="icon-only" src="assets/icon/check.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-template>
  </ion-toolbar>
</ion-footer>
