/**
 * Class describing a measure of length and it's unit.
 */
import { UnitOfLength } from './UnitOfLength'
import { LengthUtil } from '../utils'
import roundForDisplay = LengthUtil.roundForDisplay

export class Length extends Number {
  readonly valueInUnit: number
  showUnit: boolean

  constructor(valueInCm: number, public readonly unit: UnitOfLength, showUnit: boolean = true) {
    super(valueInCm)
    this.showUnit = showUnit
    this.valueInUnit = LengthUtil.convertCmToUnit(valueInCm, unit)
  }

  toString(): string {
    if (this.unit === 'inch') {
      return LengthUtil.formatCmToInch(this.valueOf())
    } else {
      return this.showUnit
        ? `${roundForDisplay(this.valueInUnit, this.unit)} ${this.unit}`
        : `${roundForDisplay(this.valueInUnit, this.unit)}`
    }
  }
}
