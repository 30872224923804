import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'

export const SEVERITY_ERROR = 'ERROR'
export const SEVERITY_WARNING = 'WARNING'
export const SEVERITY_INFO = 'INFO'

@Injectable({
  providedIn: 'root',
})
export class ResultMessageService {
  private messages$ = new BehaviorSubject<ResultMessage[]>([])

  public readonly errorMessages$ = this.messages$.pipe(
    map((messages) =>
      messages.filter(
        (message) =>
          // ignore tipos error code 3690 with article ID 582659000; calculation is correct; tipos bug
          message.severity === SEVERITY_ERROR &&
          message.message?.indexOf('582659000') === -1 &&
          message.message?.indexOf('3690') === -1
      )
    )
  )

  public readonly warningMessages$ = this.messages$.pipe(
    map((messages) => messages.filter((message) => message.severity === SEVERITY_WARNING))
  )

  public readonly infoMessages$ = this.messages$.pipe(
    map((messages) => messages.filter((message) => message.severity === SEVERITY_INFO))
  )

  public readonly messagesForPDF: ResultMessage[] = []

  public postMessage(message: ResultMessage): void {
    this.messages$.next([...this.messages$.value, message])
    if (
      message.severity !== SEVERITY_INFO &&
      this.messagesForPDF.filter((pdfMess) => pdfMess.message === message.message).length === 0
    ) {
      this.messagesForPDF.push({
        severity: message.severity,
        message: message.message,
      })
    }
  }

  public clearAll(): void {
    this.messages$.next([])
    this.messagesForPDF.splice(0, this.messagesForPDF.length)
  }

  public clearFromSource(sourceId: string): void {
    const remainingLogs = this.messages$.value.filter((log) => log.sourceId !== sourceId)
    this.messages$.next(remainingLogs)
  }
}

export type MessageSeverity = typeof SEVERITY_ERROR | typeof SEVERITY_WARNING | typeof SEVERITY_INFO

export interface ResultMessage {
  severity: MessageSeverity
  message?: string
  messageKey?: string
  sourceId?: string
}
