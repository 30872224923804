import { createStore } from '@ngneat/elf'
import {
  deleteEntitiesByPredicate,
  getAllEntitiesApply,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { PlanAccessoryLineDao } from '../services/dao/plan-accessory-line.dao'
import { PlanAccessoryLine } from '../models/plan/PlanAccessoryLine'
import { PlanAccessory } from '../models/plan/PlanAccessory'
import { ResetAccessoriesService } from '../services/cache/reset-accessory.service'

const planAccessoryLineStore = createStore(
  { name: 'planAccessoryLine' },
  withEntities<PlanAccessoryLine>()
)

const planAccessoryStore = createStore({ name: 'planAccessory' }, withEntities<PlanAccessory>())

@Injectable({
  providedIn: 'root',
})
export class PlanAccessoryLineRepository {
  public readonly planAccessoryLines$ = planAccessoryLineStore.pipe(selectAllEntities())

  constructor(
    private readonly planAccessoryLineDao: PlanAccessoryLineDao,
    private readonly resetAccessoriesService: ResetAccessoriesService
  ) {
    this.resetAccessoriesService.planIdsNeededResetOfAccessories$.subscribe((planIds) => {
      planIds.forEach((planId) => {
        planAccessoryLineStore.update(deleteEntitiesByPredicate((e) => e.planId === planId))
        planAccessoryStore.update(deleteEntitiesByPredicate((e) => e.planId === planId))
      })
    })
  }

  public async createAccessoryLines(
    accessoryLines: Omit<PlanAccessoryLine, 'id'>[]
  ): Promise<PlanAccessoryLine[]> {
    const newAccessoryLines = await this.planAccessoryLineDao.createAccessoryLines(accessoryLines)
    planAccessoryLineStore.update(upsertEntities(newAccessoryLines))
    return newAccessoryLines
  }

  public async updateAccessoryLineAssignments(
    accessoryLines: Pick<PlanAccessoryLine, 'id' | 'accessoriesAsString'>[]
  ): Promise<void> {
    await this.planAccessoryLineDao.updateAccessoryLineAssignments(accessoryLines)
    planAccessoryLineStore.update(upsertEntities(accessoryLines))
  }

  public async getAccessoriesForPlan(planId: number): Promise<PlanAccessory[]> {
    let accessories = planAccessoryStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.planId === planId,
      })
    )

    if (accessories && accessories.length > 0) {
      return accessories
    } else {
      accessories = await this.planAccessoryLineDao.getAccessoriesForPlan(planId)
      planAccessoryStore.update(upsertEntities(accessories))
      return accessories
    }
  }

  public async getAccessoryLinesForPlan(planId: number): Promise<PlanAccessoryLine[]> {
    let accessoryLines = planAccessoryLineStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.planId === planId,
      })
    )

    if (accessoryLines && accessoryLines.length > 0) {
      return accessoryLines
    } else {
      accessoryLines = await this.planAccessoryLineDao.getAccessoryLinesForPlan(planId)
      planAccessoryLineStore.update(upsertEntities(accessoryLines))
      return accessoryLines
    }
  }

  public async deleteAccessoryLinesForPlan(planId: number): Promise<void> {
    await this.planAccessoryLineDao.deleteAccessoryLinesForPlan(planId)
    planAccessoryLineStore.update(deleteEntitiesByPredicate((e) => e.planId === planId))
    planAccessoryStore.update(deleteEntitiesByPredicate((e) => e.planId === planId))
  }
}
