import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core'
import { Capacitor } from '@capacitor/core'

export type ShowType = 'web' | 'mobile'

@Directive({
  selector: '[efpShowOn]',
})
export class EfpShowOnDirective {
  private _isWebPlatform: boolean
  private _hasView = false

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {
    this._isWebPlatform = Capacitor.getPlatform() === 'web'
  }

  @Input() set efpShowOn(showOn: ShowType) {
    const show =
      (showOn === 'web' && this._isWebPlatform) || (showOn === 'mobile' && !this._isWebPlatform)
    if (!this._hasView && show) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      this._hasView = true
    } else if (this._hasView && !show) {
      this.viewContainer.clear()
      this._hasView = false
    }
  }
}
