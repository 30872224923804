import { Injectable } from '@angular/core'
import { PlanOutline } from '../../../models/plan/PlanOutline'
import { DataService } from '../../data.service'
import { PlanOutlineDao } from '../plan-outline.dao'
import { CreatePlanOutlineCommandParam } from '../../../../generated/efp-api'

@Injectable()
export class PlanOutlineSqlDao implements PlanOutlineDao {
  constructor(private readonly dataService: DataService) {}

  async createOne(outline: CreatePlanOutlineCommandParam): Promise<number> {
    const statement =
      'INSERT INTO PlanOutline(planId, outlineType, start_x, start_y, end_x, end_y) VALUES '
    const values = [
      outline.planId,
      outline.outlineType,
      outline.startX,
      outline.startY,
      outline.endX,
      outline.endY,
    ]

    const result = await this.dataService.executeStatement(statement, values)
    return result.insertId
  }

  async create(outlines: CreatePlanOutlineCommandParam[]): Promise<void> {
    if (outlines.length === 0) {
      return
    }

    let statement =
      'INSERT INTO PlanOutline(planId, outlineType, start_x, start_y, end_x, end_y) VALUES '
    const values: unknown[] = []
    outlines.forEach((outline, index) => {
      if (index > 0) {
        statement += ','
      }
      statement += '(?, ?, ?, ?, ?, ?)'
      values.push(
        outline.planId,
        outline.outlineType,
        outline.startX,
        outline.startY,
        outline.endX,
        outline.endY
      )
    })

    await this.dataService.executeStatement(statement, values)
  }

  async findAllOutlinesByPlanId(planId: number): Promise<PlanOutline[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * FROM PlanOutline WHERE planId = ?',
      [planId]
    )
    const outlines: PlanOutline[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      outlines.push({
        id: row.id,
        planId: row.planId,
        outlineType: row.outlineType,
        start: {
          x: row.start_x,
          y: row.start_y,
        },
        end: {
          x: row.end_x,
          y: row.end_y,
        },
      })
    }

    return outlines
  }

  async filteredPlanIdsWithOutlines(planIds: number[]): Promise<number[]> {
    const result = await this.dataService.executeStatement(
      'SELECT planId from PlanOutline WHERE planId in (?)',
      [planIds]
    )
    const planIdsWithOutline: number[] = []
    for (let i = 0; i < result.rows.length; i++) {
      planIdsWithOutline.push(result.rows.item(i).planId)
    }

    return planIdsWithOutline
  }

  async removeAllByPlanId(planId: number): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM PlanOutline WHERE planId = ?', [planId])
  }
}
