import { CreateStockCommandParams } from '../../../generated/efp-api'
import { Stock } from '../../models/stock'

export const mapStockToCreateStockCommandParams = (stock: Stock): CreateStockCommandParams => {
  return {
    name: stock.name,
    date: stock.date.toISOString(),
    articles: stock.articles,
  }
}

export abstract class StockDao {
  public abstract createStockWithArticles(params: CreateStockCommandParams): Promise<Stock>

  public abstract findAll(): Promise<Stock[]>

  public abstract findAllRelatedPlanAndProjectNamesById(stockId: number): Promise<string[]>

  public abstract findOneByIdWithArticles(id: number): Promise<Stock | undefined>

  public abstract delete(stock: Stock): Promise<void>

  public abstract updateName(stock: Stock, name: string): Promise<void>
}
