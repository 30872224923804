import paper from 'paper/dist/paper-core'
import { createWallPath } from '../../utils/mesh/createWallPath'
import {
  detectOverlappingEdges,
  detectPathIntersections,
  detectSmallAnglesAtPath,
} from '../../utils/mesh/geometryValidation'
import {
  calculateOuterCornerPoint,
  intersectLines,
  SNAP_ANGLE_INTERVAL,
  SNAPPING_ERROR_TOLERANCE,
} from '../../utils'
import { CompoundPathWithEdgeMap } from '../edge/CompoundPathWithEdgeMap'
import { Edge } from '../edge/Edge'
import { PlanType } from '../PlanType'
import { Mesh } from './Mesh'
import { MeshPoint } from './MeshPoint'

export class WallMesh extends Mesh {
  public constructor(private readonly paperScope: paper.PaperScope) {
    super()
  }

  generatePath(): CompoundPathWithEdgeMap {
    return createWallPath(this)
  }

  public removeEdge(edge: Edge, skipPreserving = false): boolean {
    if (!skipPreserving) {
      const startCorner = calculateOuterCornerPoint(edge.startPoint)
      const endCorner = calculateOuterCornerPoint(edge.endPoint)
      if (startCorner) {
        edge.startPoint.set(resetAnchorPoint(edge.startPoint, edge, startCorner))
      }
      if (endCorner) {
        edge.endPoint.set(resetAnchorPoint(edge.endPoint, edge, endCorner))
      }
    }

    const pointsToDelete: MeshPoint[] = []
    let deletedAnEdge = false

    this.points.forEach((point) => {
      if (point.edges.has(edge)) {
        point.edges.delete(edge)
        deletedAnEdge = true

        if (point.edges.size === 0) {
          pointsToDelete.push(point)
        }
      }
    })

    pointsToDelete.forEach((point) => {
      this.points.delete(point)
    })

    return deletedAnEdge
  }

  public updateEdgeValidity(): number {
    const edges = [...this.getAllEdges()]
    edges.forEach((edge) => (edge.isValid = true))

    if (edges.length < 2) {
      return 0
    }

    /**
     * Edges that overlap are invalid and must
     * not be visited inside createWallPath.
     */
    const overlappingEdges = detectOverlappingEdges(edges)
    const compoundPath = createWallPath(this)
    const edgeMap = compoundPath.edgeMap

    const intersectedEdges = detectPathIntersections(edges, compoundPath)

    const smallPathAngleEdges = detectSmallAnglesAtPath(edgeMap, compoundPath, PlanType.WALL)

    const invalidEdges = new Set([...intersectedEdges, ...smallPathAngleEdges, ...overlappingEdges])

    invalidEdges.forEach((edge) => {
      edge.isValid = false
    })

    return invalidEdges.size
  }

  public groupConnectedEdges(): WallMesh[] {
    const visited = new Set()
    const edgeGroups: Edge[][] = []

    for (const edge of this.getAllEdges()) {
      if (visited.has(edge)) {
        continue
      }

      const group = new Set<Edge>([edge, ...edge.getAllRecursiveNeighbours()])

      // finding neighbouring disconnected edges for edge case with different wall widths
      // https://dev.azure.com/Umdasch-Group/Doka-ESD-EFP/_wiki/wikis/Doka-ESD-EFP.wiki/4283/Technical-Documentation?anchor=snapping-to-mesh-points-with-different-wall-thickness
      const filteredPoints: MeshPoint[] = [...this.points].filter((p1) =>
        [...this.points].some((p2) => p1 !== p2 && p1.isClose(p2, SNAPPING_ERROR_TOLERANCE))
      )
      const closeNeighbors = [...this.getAllEdges()].filter((other) => {
        const disconnectedPoints = other.getCloseDisconnectedPoints(edge)
        if (disconnectedPoints && !disconnectedPoints.some((p) => filteredPoints.includes(p))) {
          const distance = disconnectedPoints[0].getDistance(disconnectedPoints[1])
          return (
            !other.isSameEdge(edge) &&
            !visited.has(other) &&
            disconnectedPoints &&
            distance > 0 &&
            edge.thickness !== other.thickness
          )
        }
        return false
      })

      closeNeighbors.forEach((neighbor) => {
        group.add(neighbor)
        neighbor.getAllRecursiveNeighbours().forEach((n) => {
          if (!visited.has(n)) {
            group.add(n)
          }
        })
      })

      group.forEach((e) => visited.add(e))
      edgeGroups.push([...group])
    }

    const meshes: WallMesh[] = []
    for (const edgeGroup of edgeGroups) {
      const points = new Set<MeshPoint>([
        ...edgeGroup.map((e) => e.startPoint),
        ...edgeGroup.map((e) => e.endPoint),
      ])

      const mesh = new WallMesh(this.paperScope)
      mesh.points = points
      meshes.push(mesh)
    }

    return meshes
  }
}

const resetAnchorPoint = (
  anchorPoint: MeshPoint,
  removedEdge: Edge,
  corner: paper.Point
): paper.Point => {
  if (anchorPoint.edges.size !== 2) {
    return anchorPoint
  }
  const edges = Array.from(anchorPoint.edges)
  const neighbor = edges[0] === removedEdge ? edges[1] : edges[0]

  const angle = neighbor.getAngle(removedEdge)
  if (
    angle &&
    neighbor.thickness !== removedEdge.thickness &&
    Math.abs(angle) >= 180 - SNAP_ANGLE_INTERVAL / 2
  ) {
    return anchorPoint
  }

  const neighborDirection = neighbor.startPoint.subtract(neighbor.endPoint)
  const neighborNormal = new paper.Point(-neighborDirection.y, neighborDirection.x)

  const neighborCapLine = { start: corner, end: corner.add(neighborNormal) }
  const neighborCentralLine = {
    start: neighbor.startPoint,
    end: neighbor.endPoint,
  }

  return intersectLines(neighborCapLine, neighborCentralLine) ?? anchorPoint
}
