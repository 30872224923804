import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-sidebar-filter-section',
  templateUrl: 'efp-sidebar-filter-section.component.html',
  styleUrls: ['./efp-sidebar-filter-section.component.scss'],
})
export class EfpSidebarFilterSectionComponent {
  @Input() title = ''

  constructor() {}
}
