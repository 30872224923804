import { File, FilePicker } from '@capawesome/capacitor-file-picker'
import { ImportFileType } from './file-handler.service'
import { Translation } from './translation.service'

export abstract class FileService {
  filePickerPlugin = FilePicker

  constructor(protected readonly translation: Translation) {}

  abstract writeBlobToFile(file: Blob, path: string): Promise<string>

  abstract getUri(path: string): Promise<string>

  abstract shareFiles(title: string, text: string, filePaths: string[]): Promise<void>

  public abstract shareOrDownloadBlobFile(content: Blob, path: string, name: string): Promise<void>

  public abstract shareOrDownloadTextFile(
    content: string,
    path: string,
    name: string
  ): Promise<void>

  /**
   * opens file:// or content:// URLs
   */
  abstract readGlobalFile(path: string): Promise<ArrayBuffer>

  abstract readFileAsArrayBuffer(path: string): Promise<ArrayBuffer>

  abstract readTextFile(path: string): Promise<string>

  /**
   * Return a list of files from the directory (not recursive)
   */
  abstract readDir(path: string): Promise<string[] | null>

  /**
   * Write a file and returns the nativeUrl to it after a successful write
   */
  abstract writeTextToFile(path: string, textContent: string): Promise<string>

  abstract removeFile(path: string): Promise<void>

  /**
   * Opens a file picker and returns the selected file
   * @param types the types of file to pick
   * @returns the selected file or undefined, if the user canceled the file picker
   * @throws an error, if the selected file is not of the correct type
   */
  public async pickFile(types: ImportFileType[]): Promise<File | undefined> {
    const mediaTypes: string[] = []
    if (types.includes(ImportFileType.csv)) {
      mediaTypes.push('text/csv')
    }
    if (types.includes(ImportFileType.ma7)) {
      mediaTypes.push('application/octet-stream')
    }

    const fileResult = await this.filePickerPlugin.pickFiles({
      readData: true,
      types: mediaTypes.length > 0 ? mediaTypes : undefined,
    })
    if (fileResult.files.length > 0) {
      const firstFile = fileResult.files[0]

      if (
        (types.length === 1 &&
          types[0] === ImportFileType.efp &&
          !firstFile.name.endsWith('.efp')) ||
        firstFile.data === undefined
      ) {
        throw new Error('IMPORT.ERROR.WRONG_FORMAT')
      } else {
        return firstFile
      }
    }

    return
  }
}
