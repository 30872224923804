<efp-list-header *ngIf="withProfileTitle">
  <div class="d-flex gap-12 title-container">
    <ion-label>{{ 'FAVOURITES.PROFILE.FAVORITE_PROFILE' | translate }}</ion-label>
    <div class="title-divider"></div>
    <ion-label *ngIf="favouriteProfile" class="profile-title">{{
      (!favouriteProfile.isStandard
        ? favouriteProfile.name
        : 'FORMWORK.' + favouriteProfile.formworkSystemId
      ) | translate
    }}</ion-label>
  </div>
</efp-list-header>

<efp-list-header *ngIf="!withProfileTitle">
  <ion-label>{{ 'FAVOURITES.PROFILE.LIST_EXCLUSION_HEADING' | translate }}</ion-label>
</efp-list-header>

<div *ngIf="withProfileTitle" class="d-flex">
  <ion-label class="sub-title">{{
    'FAVOURITES.PROFILE.LIST_EXCLUSION_HEADING' | translate
  }}</ion-label>
</div>

<div class="d-flex-col gap-8" [ngClass]="{ bottomPadding: bottomPadding }">
  <ion-item class="item-container" lines="none" button="true">
    <div class="d-flex flex-1 gap-8">
      <ion-label class="flex-1 label center-vert">
        {{ 'FAVOURITES.PROFILE.ONLY_RENTABLE_ARTICLES' | translate }}
      </ion-label>
      <div class="right-container">
        <ion-label>
          <ion-toggle
            class="fix-padding-right"
            [(ngModel)]="favouriteProfile.useOnlyRentableArticles"
            (ngModelChange)="changedUseOnlyRentableArticles()"></ion-toggle>
        </ion-label>
      </div>
    </div>
  </ion-item>

  <ion-item class="item-container" lines="none" button="true" (click)="navToBlacklist()">
    <div class="d-flex flex-1 gap-8">
      <ion-label class="flex-1 label center-vert">
        {{ 'FAVOURITES.PROFILE.ARTICLE_BLACKLIST' | translate }}
      </ion-label>
      <div *ngIf="articleCount !== undefined" class="right-container">
        <ion-label *ngIf="articleCount == 1" class="article-count-label center-vert">
          {{ articleCount }} {{ 'FAVOURITES.PROFILE.ARTICLE_EXCLUDED_SUFFIX_SINGLE' | translate }}
        </ion-label>
        <ion-label *ngIf="articleCount > 1" class="article-count-label center-vert">
          {{ articleCount }} {{ 'FAVOURITES.PROFILE.ARTICLE_EXCLUDED_SUFFIX_MULTIPLE' | translate }}
        </ion-label>
        <ion-icon src="/assets/icon/ic_edit.svg" color="primary"> </ion-icon>
      </div>
    </div>
  </ion-item>
</div>
