import paper from 'paper/dist/paper-core'

export const enum LineSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const getOtherLineSide = (side: LineSide): LineSide =>
  side === LineSide.LEFT ? LineSide.RIGHT : LineSide.LEFT

export const getLineSide = (
  linePoint1: paper.Point,
  linePoint2: paper.Point,
  evaluationPoint: paper.Point
): LineSide => {
  const evaluationVector = evaluationPoint.subtract(linePoint1)
  const lineVector = linePoint2.subtract(linePoint1)
  const angle = evaluationVector.getDirectedAngle(lineVector)

  return angle > 0 ? LineSide.LEFT : LineSide.RIGHT
}
