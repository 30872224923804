import { FormworkId } from '@efp/api'
import { FavouriteProfile } from '../../models/favourites'

export abstract class FavouriteDao {
  public abstract update(favorite: FavouriteProfile): Promise<number>

  public abstract create(
    favorite: Omit<FavouriteProfile, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<number>

  public abstract deleteById(id: number): Promise<void>

  public abstract findAllByFormworkSystemId(
    formworkSystemId: FormworkId
  ): Promise<FavouriteProfile[]>

  public abstract findOneById(id: number): Promise<FavouriteProfile | undefined>

  public abstract findOneStandardByFormworkSystemId(
    formworkSystemId: FormworkId
  ): Promise<FavouriteProfile | undefined>

  public abstract findAll(): Promise<FavouriteProfile[]>
}
