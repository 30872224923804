/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateStockCommandParams } from '../models/create-stock-command-params';
import { GetAllStocksQuery } from '../models/get-all-stocks-query';
import { PatchStockCommandParams } from '../models/patch-stock-command-params';
import { StockModel } from '../models/stock-model';
import { Unit } from '../models/unit';

@Injectable({ providedIn: 'root' })
export class StocksApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllStocks()` */
  static readonly GetAllStocksPath = '/api/v1/stocks';

  /**
   * Get all stocks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStocks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStocks$Plain$Response(
    params?: {
      command?: GetAllStocksQuery;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StockModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.GetAllStocksPath, 'get');
    if (params) {
      rb.query('command', params.command, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockModel>>;
      })
    );
  }

  /**
   * Get all stocks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStocks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStocks$Plain(
    params?: {
      command?: GetAllStocksQuery;
    },
    context?: HttpContext
  ): Observable<Array<StockModel>> {
    return this.getAllStocks$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StockModel>>): Array<StockModel> => r.body)
    );
  }

  /**
   * Get all stocks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStocks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStocks$Response(
    params?: {
      command?: GetAllStocksQuery;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StockModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.GetAllStocksPath, 'get');
    if (params) {
      rb.query('command', params.command, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockModel>>;
      })
    );
  }

  /**
   * Get all stocks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStocks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStocks(
    params?: {
      command?: GetAllStocksQuery;
    },
    context?: HttpContext
  ): Observable<Array<StockModel>> {
    return this.getAllStocks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StockModel>>): Array<StockModel> => r.body)
    );
  }

  /** Path part for operation `createStock()` */
  static readonly CreateStockPath = '/api/v1/stocks';

  /**
   * Add a new stock.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStock$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStock$Plain$Response(
    params: {
      body: CreateStockCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StockModel>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.CreateStockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockModel>;
      })
    );
  }

  /**
   * Add a new stock.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createStock$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStock$Plain(
    params: {
      body: CreateStockCommandParams
    },
    context?: HttpContext
  ): Observable<StockModel> {
    return this.createStock$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StockModel>): StockModel => r.body)
    );
  }

  /**
   * Add a new stock.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStock()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStock$Response(
    params: {
      body: CreateStockCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StockModel>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.CreateStockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockModel>;
      })
    );
  }

  /**
   * Add a new stock.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createStock$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStock(
    params: {
      body: CreateStockCommandParams
    },
    context?: HttpContext
  ): Observable<StockModel> {
    return this.createStock$Response(params, context).pipe(
      map((r: StrictHttpResponse<StockModel>): StockModel => r.body)
    );
  }

  /** Path part for operation `getStock()` */
  static readonly GetStockPath = '/api/v1/stocks/{stockId}';

  /**
   * Get stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStock$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStock$Plain$Response(
    params: {
      stockId: number;
      includeRelatedPlanAndProjectNames?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StockModel>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.GetStockPath, 'get');
    if (params) {
      rb.path('stockId', params.stockId, {});
      rb.query('includeRelatedPlanAndProjectNames', params.includeRelatedPlanAndProjectNames, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockModel>;
      })
    );
  }

  /**
   * Get stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStock$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStock$Plain(
    params: {
      stockId: number;
      includeRelatedPlanAndProjectNames?: boolean;
    },
    context?: HttpContext
  ): Observable<StockModel> {
    return this.getStock$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StockModel>): StockModel => r.body)
    );
  }

  /**
   * Get stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStock()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStock$Response(
    params: {
      stockId: number;
      includeRelatedPlanAndProjectNames?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StockModel>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.GetStockPath, 'get');
    if (params) {
      rb.path('stockId', params.stockId, {});
      rb.query('includeRelatedPlanAndProjectNames', params.includeRelatedPlanAndProjectNames, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockModel>;
      })
    );
  }

  /**
   * Get stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStock(
    params: {
      stockId: number;
      includeRelatedPlanAndProjectNames?: boolean;
    },
    context?: HttpContext
  ): Observable<StockModel> {
    return this.getStock$Response(params, context).pipe(
      map((r: StrictHttpResponse<StockModel>): StockModel => r.body)
    );
  }

  /** Path part for operation `deleteStock()` */
  static readonly DeleteStockPath = '/api/v1/stocks/{stockId}';

  /**
   * Delete stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStock$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStock$Plain$Response(
    params: {
      stockId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.DeleteStockPath, 'delete');
    if (params) {
      rb.path('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStock$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStock$Plain(
    params: {
      stockId: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteStock$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Delete stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStock()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStock$Response(
    params: {
      stockId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.DeleteStockPath, 'delete');
    if (params) {
      rb.path('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStock(
    params: {
      stockId: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteStock$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /** Path part for operation `patchStock()` */
  static readonly PatchStockPath = '/api/v1/stocks/{stockId}';

  /**
   * Update stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchStock$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchStock$Plain$Response(
    params: {
      stockId: number;
      body: PatchStockCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.PatchStockPath, 'patch');
    if (params) {
      rb.path('stockId', params.stockId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Update stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchStock$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchStock$Plain(
    params: {
      stockId: number;
      body: PatchStockCommandParams
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.patchStock$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Update stock with stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchStock()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchStock$Response(
    params: {
      stockId: number;
      body: PatchStockCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, StocksApiClient.PatchStockPath, 'patch');
    if (params) {
      rb.path('stockId', params.stockId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Update stock with stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchStock$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchStock(
    params: {
      stockId: number;
      body: PatchStockCommandParams
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.patchStock$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

}
