import { inject, Injectable } from '@angular/core'
import {
  CycleBoundariesApiClient,
  CycleBoundaryModel,
  CycleSymbolModel,
  CycleSymbolsApiClient,
} from '@efp/api'
import { CycleBoundary, CycleSymbol } from 'formwork-planner-lib'
import { firstValueFrom } from 'rxjs'
import { CycleDao } from '../cycle.dao'

@Injectable()
export class CycleHttpDao extends CycleDao {
  private readonly cycleBoundariesApiClient = inject(CycleBoundariesApiClient)
  private readonly cycleSymbolsApiClient = inject(CycleSymbolsApiClient)

  private mapModelToCycleSymbol(model: CycleSymbolModel): CycleSymbol {
    return {
      position: { x: model.posX, y: model.posY },
      cycleNumber: model.cycleNumber,
    }
  }

  private mapModelToCycleBoundary(model: CycleBoundaryModel): CycleBoundary {
    return {
      id: model.id,
      start: { x: model.startx, y: model.starty },
      end: { x: model.endx, y: model.endy },
      cycleNumberLeft: model.cycleNumberLeft,
      cycleNumberRight: model.cycleNumberRight,
    }
  }

  async createCycleBoundary(line: CycleBoundaryModel): Promise<number> {
    return firstValueFrom(
      this.cycleBoundariesApiClient.createCycleBoundary({
        body: line,
      })
    )
  }

  async createCycleSymbol(symbol: CycleSymbolModel): Promise<void> {
    await firstValueFrom(
      this.cycleSymbolsApiClient.createCycleSymbol({
        body: symbol,
      })
    )
  }

  async updateCycleNumbers(lines: CycleBoundary[]): Promise<void> {
    await Promise.all(
      lines.map(async (line) => {
        return firstValueFrom(
          this.cycleBoundariesApiClient.updateCycleBoundary({
            id: line.id,
            body: {
              cycleNumberLeft: line.cycleNumberLeft,
              cycleNumberRight: line.cycleNumberRight,
            },
          })
        )
      })
    )
  }

  async updateCycleBoundaryPosition(boundary: CycleBoundary): Promise<void> {
    await firstValueFrom(
      this.cycleBoundariesApiClient.updateCycleBoundary({
        id: boundary.id,
        body: {
          startx: boundary.start.x,
          starty: boundary.start.y,
          endx: boundary.end.x,
          endy: boundary.end.y,
        },
      })
    )
  }

  async findAllCycleBoundariesByPlanId(planId: number): Promise<CycleBoundary[]> {
    const models = await firstValueFrom(
      this.cycleBoundariesApiClient.findAllCycleBoundariesByPlanId({
        planId,
      })
    )

    return models.map((model) => this.mapModelToCycleBoundary(model))
  }

  async findAllCycleSymbolsByPlanId(planId: number): Promise<CycleSymbol[]> {
    const models = await firstValueFrom(
      this.cycleSymbolsApiClient.findAllCycleSymbolsByPlanId({
        planId,
      })
    )

    return models.map((model) => this.mapModelToCycleSymbol(model))
  }

  public async removeAllCycleBoundariesByPlanId(planId: number): Promise<void> {
    await firstValueFrom(this.cycleBoundariesApiClient.deleteCycleBoundariesByPlanId({ planId }))
  }

  public async removeAllSymbolsByPlanId(planId: number): Promise<void> {
    await firstValueFrom(this.cycleSymbolsApiClient.deleteCycleSymbolsByPlanId({ planId }))
  }

  public async deleteCycleBoundary(id: number): Promise<void> {
    await firstValueFrom(this.cycleBoundariesApiClient.deleteCycleBoundary({ boundaryId: id }))
  }
}
