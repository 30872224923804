<form [formGroup]="planSettingsService.planSettingsForm">
  <ion-list *ngIf="plan">
    <efp-list-header [topPadding]="false">
      <ion-label>{{ 'SETTINGS.PLAN_SETTINGS' | translate }}</ion-label>
    </efp-list-header>
    <efp-list-divider-item
      (contentClick)="planNameInput.setFocus()"
      data-test-selector="plansettings-name">
      <ion-label>{{ 'PLAN.NAME' | translate }}</ion-label>
      <ion-input
        #planNameInput
        class="ion-text-right"
        type="text"
        placeholder="plan name"
        formControlName="planName"
        efpInputOverwritingSelect
        slot="end"
        (ngModelChange)="changeName()"
        efpInputKeyboard>
      </ion-input>

      <ion-note note color="danger" *ngIf="planName && planName.dirty && planName.invalid">
        {{ 'PLAN.NAME_ERROR_MESSAGE' | translate }}
      </ion-note>
    </efp-list-divider-item>

    <efp-list-divider-item
      (contentClick)="planTypeSelect.open()"
      data-test-selector="plansettings-plan-type">
      <ion-label>{{ 'PLAN.BUILDING_TYPE' | translate }}</ion-label>
      <ion-select
        #planTypeSelect
        multiple="false"
        placeholder="{{ 'PLAN.BUILDING_TYPE_SELECT' | translate }}"
        formControlName="buildType"
        [disabled]="true"
        slot="end">
        <ion-select-option *ngFor="let type of planType | keyvalue" [value]="type.value"
          >{{ 'PLAN.' + type.value | translate }}
        </ion-select-option>
      </ion-select>
    </efp-list-divider-item>

    <efp-list-divider-item
      button
      (click)="navigateToProjects()"
      [detailIcon]="true"
      *ngIf="projectRepository.projects$ | async"
      data-test-selector="plansettings-projects">
      <ion-label>{{ 'PLAN.PROJECT' | translate }}</ion-label>
      <ion-label slot="end">{{
        projectName != 'default' ? projectName : ('PROJECT.UNASSIGNED_PROJECTS' | translate)
      }}</ion-label>
    </efp-list-divider-item>

    <efp-list-divider-item
      button
      (click)="navigateToStocks()"
      [detailIcon]="true"
      *ngIf="stockRepository.stocks$ | async"
      data-test-selector="plansettings-stocks">
      <ion-label>{{ 'PLAN.STOCK' | translate }}</ion-label>
      <ion-label slot="end">{{
        stockName ? stockName : ('PLAN.STOCK_SELECT' | translate)
      }}</ion-label>
    </efp-list-divider-item>
  </ion-list>
</form>
