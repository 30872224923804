import { Injectable } from '@angular/core'
import { Screenshot } from '../../../models/screenshot'
import { DataService } from '../../data.service'
import { ScreenshotDao, mapModelToScreenshot } from '../screenshot.dao'
import { ScreenshotCommandParams } from '../../../../generated/efp-api'

@Injectable()
export class ScreenshotSqlDao extends ScreenshotDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  async create(screen: ScreenshotCommandParams): Promise<Screenshot> {
    const statement =
      'INSERT INTO Screenshot (name, planId, screenshot, date, defaultView, cycle, width, height) VALUES (?,?,?,?,?,?,?,?)'
    const values = [
      screen.name,
      screen.planId,
      screen.screenshotData,
      screen.date,
      screen.defaultView ? 1 : 0,
      screen.cycle,
      screen.width,
      screen.height,
    ]

    const result = await this.dataService.executeStatement(statement, values)
    const model = {
      ...screen,
      id: result.insertId,
    }
    return mapModelToScreenshot(model)
  }

  public async findAllByPlanId(planId: number): Promise<Screenshot[]> {
    const screens: Screenshot[] = []
    const statement = 'SELECT * FROM Screenshot where planId=?'
    const values = [planId]

    return this.dataService.executeStatement(statement, values).then((rs) => {
      if (rs.rows.length === 0) {
        return screens
      }
      for (let i = 0; i < rs.rows.length; i++) {
        const row = rs.rows.item(i)
        screens[i] = {
          id: row.id,
          name: row.name,
          date: row.date,
          planId: row.planId,
          screenshot: row.screenshot,
          defaultView: row.defaultView === 1,
          cycle: row.cycle,
          width: row.width,
          height: row.height,
        }
      }

      return screens
    })
  }

  public async delete(screenId: number): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM Screenshot WHERE id = ?', [screenId])
  }

  public async deleteAllByPlanId(planId: number): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM Screenshot WHERE planId = ?', [planId])
  }

  public async updateName(id: number, name: string): Promise<void> {
    await this.dataService.executeStatement('UPDATE Screenshot SET name = ? WHERE id = ?', [
      name,
      id,
    ])
  }
}
