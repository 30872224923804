import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { encode } from 'base64-arraybuffer'
import {
  PLAN_FOLDER,
  PROTOCOL_JSON_FILE_NAME,
  RESULT_JSON_FILE_NAME,
  RESULT_PNG_FILE_NAME,
  RESULT_XML_FILE_NAME,
} from '../../constants/files'
import { Plan } from '../../models/plan'
import { PlanResult } from '../../models/planResult'
import { PlanResultProtocol } from '../../models/planResultProtocol'
import { ResultZip } from '../../models/resultZip'
import { FileService } from '../file.service'
import { PlanResultRepository } from '../../repositories/plan-result.repository'
import { ProjectRepository } from '../../repositories/project.repository'

/**
 * Migrate plan results from locally stored ZIP to database.
 *
 * @deprecated Should be removed from the next major version.
 */
@Injectable({
  providedIn: 'root',
})
export class PlanResultMigrationService {
  constructor(
    private readonly projectRepository: ProjectRepository,
    private readonly planResultRepository: PlanResultRepository,
    private readonly fileService: FileService
  ) {}

  /**
   * Load existing plan results from ZIP and insert them into the database.
   *
   * @deprecated Should be removed from the next major version.
   */
  public async migratePlanResultZipToDatabase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      const projects = await this.projectRepository.fetchAll(true)
      for (const project of projects) {
        const calculatedPlans = project.plans.filter(async (it) => {
          return await this.planResultRepository.getIsCalculated(it.id)
        })
        for (const plan of calculatedPlans) {
          await this.migratePlanResultsFromZip(plan)
        }
      }
    }
  }

  private async migratePlanResultsFromZip(plan: Plan): Promise<void> {
    try {
      const hasPlanResult = await this.planResultRepository.getIsCalculated(plan.id)
      if (!hasPlanResult) {
        const folderPath = `${PLAN_FOLDER}/${plan.id}`

        const fileNames = await this.fileService.readDir(folderPath)
        if (!fileNames) {
          console.error(`Failed to read plan file names for plan ${plan.id}`)
          return
        }

        const result = await this.loadResultFromFolder(fileNames, folderPath, plan.id)
        await this.planResultRepository.savePlanResult(result, plan.id)
        try {
          await this.fileService.removeFile(folderPath + '.zip')
        } catch {
          console.error(
            `Failed to remove plan result ZIP for plan ${plan.id} (imported plan have no ZIP)`
          )
        }
      }
    } catch {
      console.error(`Failed to migrate plan results for plan ${plan.id}`)
    }
  }

  private async loadResultFromFolder(
    fileNames: string[],
    planFolder: string,
    planId: number
  ): Promise<PlanResult> {
    if (!fileNames || fileNames.length < 1 || !planId) {
      throw new Error('Provided plan id or result folder is empty')
    }

    const result: PlanResult = { planId }
    for (const filename of fileNames) {
      if (filename === RESULT_XML_FILE_NAME) {
        const fileContent = await this.fileService.readTextFile(
          planFolder + '/' + RESULT_XML_FILE_NAME
        )
        const parser = new DOMParser()
        const resultXML = parser.parseFromString(fileContent, 'text/xml')
        // remove stock to save space
        resultXML.getElementsByTagName('initialStock')[0].innerHTML = ''
        result.resultXML = new XMLSerializer().serializeToString(resultXML)
      } else if (filename === PROTOCOL_JSON_FILE_NAME) {
        const fileContent = await this.fileService.readTextFile(
          planFolder + '/' + PROTOCOL_JSON_FILE_NAME
        )
        result.resultProtocol = <PlanResultProtocol>JSON.parse(fileContent)
      }
      // stock
      else if (filename === RESULT_JSON_FILE_NAME) {
        const fileContent = await this.fileService.readTextFile(
          planFolder + '/' + RESULT_JSON_FILE_NAME
        )
        const resultToParse = <ResultZip>JSON.parse(fileContent)
        result.partList = resultToParse.partlist
      } else if (filename === RESULT_PNG_FILE_NAME) {
        const fileContent = await this.fileService.readFileAsArrayBuffer(
          planFolder + '/' + RESULT_PNG_FILE_NAME
        )
        result.resultBase64Image = encode(fileContent)
      }
    }

    return result
  }
}
