import { Subject } from 'rxjs'
import { DrawSettings } from '../draw-settings'

export abstract class UndoRedoHistory<T> {
  private undoStack: string[] = []
  private redoStack: string[] = []
  private readonly originalEntry: string
  protected readonly drawSettings: DrawSettings

  /**
   * This is fired everytime the current history state is changed.
   * This can happen on undo, redo or when performing actions which are committed to the history.
   */
  public readonly historyChanged = new Subject<void>()

  constructor(initialState: T, drawSettings: DrawSettings) {
    this.originalEntry = this.serialize(initialState)
    this.drawSettings = drawSettings
  }

  addSnapshot(value: T): void {
    const serializedValue = this.serialize(value)
    if (this.undoStack[this.undoStack.length - 1] === serializedValue) {
      return
    }

    this.undoStack.push(serializedValue)
    this.redoStack = []
  }

  peekLastSnapshot(): T {
    const lastSnapShot = this.undoStack[this.undoStack.length - 1] || this.originalEntry

    return this.deserialize(lastSnapShot)
  }

  getSnapshotToUndo(): T {
    const undoneEntry = this.undoStack.pop()
    if (undoneEntry) {
      this.redoStack.push(undoneEntry)
    }
    const newState = this.undoStack[this.undoStack.length - 1] || this.originalEntry

    return this.deserialize(newState)
  }

  getSnapshotToRedo(): T | undefined {
    const redoEntry = this.redoStack.pop()
    if (!redoEntry) {
      return undefined
    }
    this.undoStack.push(redoEntry)

    return this.deserialize(redoEntry)
  }

  hasUndoActions(): boolean {
    return this.undoStack.length > 0
  }

  hasRedoActions(): boolean {
    return this.redoStack.length > 0
  }

  abstract serialize(value: T): string

  abstract deserialize(serializedValue: string): T
}
