export enum ArticleElementType {
  PLT_SCHALPLATTE = 101,
  PLT_TRAEGER = 102,
  PLT_DECKENSTUETZE = 103,
  PLT_FALTSTUETZE = 104,
  PLT_ABSENKKOPF = 105,
  PLT_VIERWEGKOPF = 106,
  PLT_FALTBEIN = 107,
  PLT_HALTEKOPF = 108,
  UNKNOWN = -1,
}
