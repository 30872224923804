import { Component } from '@angular/core'
import { PrimaryMenuService } from '../../../services/primary-menu.service'

@Component({
  selector: 'efp-open-menu-button',
  templateUrl: './open-menu-button.component.html',
  styleUrls: ['./open-menu-button.component.scss'],
})
export class OpenMenuButtonComponent {
  constructor(private readonly primaryMenuService: PrimaryMenuService) {}

  public toggleMenu(): void {
    this.primaryMenuService.open()
  }
}
