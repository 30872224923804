import { WallMesh } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { DrawSettings } from '../../../../models/draw-settings'
import { UndoRedoHistory } from '../../../../models/history/undoRedoHistory'
import { parseSimplifiedWallMesh, serializeMesh } from '../MeshSerializer'

export class WallUndoRedoHistory extends UndoRedoHistory<WallMesh> {
  constructor(
    private readonly paperScope: paper.PaperScope,
    wall: WallMesh,
    drawSettings: DrawSettings
  ) {
    super(wall, drawSettings)
  }

  deserialize(serializedMesh: string): WallMesh {
    return parseSimplifiedWallMesh(this.paperScope, serializedMesh)
  }

  serialize(mesh: WallMesh): string {
    return serializeMesh(mesh)
  }
}
