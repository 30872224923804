import { Injectable } from '@angular/core'
import { AccessoryLineMigrationService } from './accessory-line-migration.service'
import { PlanResultMigrationService } from './plan-result-migration.service'

/**
 * Service to call all migrations which we might need to do from older versions on startup.
 */
@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  constructor(
    private readonly accessoryLineMigrationService: AccessoryLineMigrationService,
    private readonly planResultMigrationService: PlanResultMigrationService
  ) {}

  public async runMigrations(): Promise<void> {
    await this.accessoryLineMigrationService.migrateContourLineToAccessoryLine()
    await this.planResultMigrationService.migratePlanResultZipToDatabase()
  }
}
