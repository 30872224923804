import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FocusAble } from '../length-input/length-input.component'
import { LengthUtil, UnitOfLength } from 'formwork-planner-lib'
import { Capacitor } from '@capacitor/core'
@Component({
  selector: 'efp-keypad',
  templateUrl: 'keypad.component.html',
  styleUrls: ['keypad.component.scss'],
})
export class KeypadComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('efpKeyboardInput') efpKeyboardInput?: FocusAble

  @Input() unit: '°' | UnitOfLength = 'cm'
  @Input() set initialValue(value: string) {
    this.value = value
    this.isInitialValue = true
    this.initialValueForReset = value
    this.setKeypadWebFormValue(value)
  }
  @Input() hasAdditionalContent = false
  @Input() highlightError = false
  @Input() disabled = false

  @Output() readonly keypadChange = new EventEmitter<string>()

  value = ''
  initialValueForReset = ''
  isInitialValue = true
  isWebversion = false

  private readonly destroy$ = new Subject<void>()

  public keypadWebForm = new FormGroup({
    keyboardInput: new FormControl('', {
      validators: [Validators.required, Validators.min(0)],
      nonNullable: true,
    }),
  })

  constructor() {
    this.isWebversion = Capacitor.getPlatform() === 'web'
  }

  ngAfterContentInit(): void {
    this.setKeypadWebFormValue(this.value)
  }

  ngAfterViewInit(): void {
    this.focusInput()

    this.keypadWebForm.controls.keyboardInput.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.value = value.toString()
        } else {
          this.value = '0'
        }

        if (this.isWebversion && this.unit !== '°') {
          const valueInUnit =
            this.unit === 'inch'
              ? LengthUtil.formatCmToInch(parseFloat(this.value))
              : LengthUtil.convertCmToUnit(parseFloat(this.value), this.unit)
          this.keypadChange.next(valueInUnit.toString())
        } else {
          this.keypadChange.next(this.value.toString())
        }
      })
  }

  focusInput(): void {
    void this.efpKeyboardInput?.focus()
  }

  setKeypadWebFormValue(value: string): void {
    if (!this.unit) {
      return
    }

    if (this.unit !== '°') {
      const valueInCm =
        this.unit === 'inch'
          ? LengthUtil.parseInchAsCm(value)
          : LengthUtil.convertUnitToCm(parseFloat(value), this.unit as UnitOfLength)
      this.keypadWebForm.controls.keyboardInput.setValue(valueInCm.toString())
    } else {
      this.keypadWebForm.controls.keyboardInput.setValue(value)
    }
  }

  reset(): void {
    this.value = this.initialValueForReset
    this.isInitialValue = true
    this.keypadWebForm.controls.keyboardInput.setValue(this.initialValueForReset)
    this.keypadChange.next(this.value)
  }

  get decimalCount(): number {
    return this.value.split('.')[1]?.length || 0
  }

  get keypadButtons(): string[] {
    switch (this.unit) {
      case 'inch':
        return ['1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '/', '0']
      case 'mm':
        return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
      default:
        return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0']
    }
  }

  backspace(): void {
    if (this.isInitialValue || this.value.length <= 1) {
      this.value = '0'
      this.isInitialValue = false
    } else {
      this.value = this.value.slice(0, this.value.length - 1)
    }
    this.keypadChange.next(this.value)
  }

  append(digit: string): void {
    if (this.isInitialValue) {
      this.value = '0'
      this.isInitialValue = false
    }
    if (this.decimalCount > 0 && this.unit === 'cm') {
      return
    }
    if (this.decimalCount > 2 && this.unit === 'm') {
      return
    }
    if (digit === '.') {
      if (!this.value.includes('.')) {
        this.value += digit
      }
    } else {
      if (this.value === '0' && digit !== ' ') {
        this.value = digit
      } else {
        this.value += digit
      }
    }
    this.keypadChange.next(this.value)
  }
}
