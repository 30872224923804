import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'efp-list-divider-item',
  templateUrl: './efp-list-divider-item.component.html',
  styleUrls: ['./efp-list-divider-item.component.scss'],
})
export class EfpListDividerItemComponent {
  @Input() color?: string
  @Input() mode?: string
  @Input() detailIcon?: boolean = false

  @Output() readonly contentClick = new EventEmitter<void>()

  constructor() {}

  onItemClick(): void {
    this.contentClick.emit()
  }
}
