/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateCalculationEntryCommand } from '../models/create-calculation-entry-command';
import { CreateFailedCalculationEntryCommand } from '../models/create-failed-calculation-entry-command';

@Injectable({ providedIn: 'root' })
export class AnalyticsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendAddCalculationEntry()` */
  static readonly SendAddCalculationEntryPath = '/api/v1/analytics/AddCalculationEntry';

  /**
   * Add calculation entry in Analytics DB.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAddCalculationEntry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddCalculationEntry$Response(
    params: {
      body: CreateCalculationEntryCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApiClient.SendAddCalculationEntryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add calculation entry in Analytics DB.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAddCalculationEntry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddCalculationEntry(
    params: {
      body: CreateCalculationEntryCommand
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendAddCalculationEntry$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addFailedCalculationEntry()` */
  static readonly AddFailedCalculationEntryPath = '/api/v1/analytics/AddFailedCalculationEntry';

  /**
   * Add failed calculation entry in Analytics DB.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFailedCalculationEntry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFailedCalculationEntry$Response(
    params: {
      body: CreateFailedCalculationEntryCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApiClient.AddFailedCalculationEntryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add failed calculation entry in Analytics DB.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFailedCalculationEntry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFailedCalculationEntry(
    params: {
      body: CreateFailedCalculationEntryCommand
    },
    context?: HttpContext
  ): Observable<void> {
    return this.addFailedCalculationEntry$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
