import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component } from '@angular/core'

@Component({
  selector: 'efp-topbar-filter-container',
  templateUrl: 'efp-topbar-filter-container.component.html',
  styleUrls: ['./efp-topbar-filter-container.component.scss'],
  animations: [
    trigger('openCloseCollapse', [
      state('closed', style({ height: '0' })),
      state('open', style({ height: '*' })),
      transition('open => closed', [animate('0.4s ease-out')]),
      transition('closed => open', [animate('0.4s ease-out')]),
    ]),
  ],
})
export class EfpTopbarFilterContainerComponent {
  public isCollapsed = false

  toggle(): void {
    this.isCollapsed = !this.isCollapsed
  }
}
