import { Injectable } from '@angular/core'
import { Country } from '@efp/api'
import { SNAP_ANGLE_INTERVAL } from 'formwork-planner-lib'
import { imperialCountries } from '../../../models/country'
import { defaultDrawSettings } from '../../../models/draw-settings'
import { SNAP_LENGTH_INTERVAL } from '../../../pages/planner/model/snapping/constants'
import { DataService } from '../../data.service'
import { DevicePreferencesService } from '../../device-preferences.service'
import { FavouritesService } from '../../favourites.service'
import { Translation } from '../../translation.service'
import { UserDao } from '../user.dao'
import { defaultPlanSettingsIdNative } from './app-settings.sql-dao'

@Injectable()
export class UserSqlDao extends UserDao {
  constructor(
    private readonly favouritesService: FavouritesService,
    private readonly dataService: DataService,
    private readonly devicePreferences: DevicePreferencesService,
    private readonly translation: Translation
  ) {
    super()
  }

  async registerUser(): Promise<number> {
    await this.bootstrapData()
    // TODO Do this on the backend only as soon as mobile/web should sync data
    await this.favouritesService.initFavourites()

    return 111
  }

  private async bootstrapData(): Promise<void> {
    const result = await this.dataService.executeStatement('SELECT * FROM Projects', [])
    if (result.rows.length === 0) {
      await this.dataService.executeStatement(
        'INSERT INTO Projects (id, stockId, name, defaultPlanSettings, defaultProject) VALUES (?,?,?,?,?)',
        [1, '', 'Unassigned Projects', 0, 1]
      )
    }

    const country = (await this.devicePreferences.fetchDeviceCountryCode()) ?? Country.At
    const planSettingRows = await this.dataService.executeStatement(
      'SELECT * FROM PlanSettings WHERE id = ?',
      [defaultPlanSettingsIdNative]
    )
    if (planSettingRows.rows.length === 0) {
      const isImperial = imperialCountries.includes(country)

      await this.dataService.executeStatement(
        'INSERT INTO PlanSettings (id, measurementUnit, formworkWall, formworkSlab, angleRastering, lengthRastering, wallThickness, slabThickness, wallHeight, slabHeight, wallFavId, slabFavId) VALUES (?,?,?,?,?,?,?,?,?,?,?,?)',
        [
          defaultPlanSettingsIdNative,
          isImperial ? 'inch' : 'cm',
          'XSTAHLFR',
          'FLEX124',
          SNAP_ANGLE_INTERVAL,
          SNAP_LENGTH_INTERVAL,
          defaultDrawSettings.wallThickness,
          defaultDrawSettings.slabThickness,
          defaultDrawSettings.wallHeight,
          defaultDrawSettings.slabHeight,
          0,
          0,
        ]
      )
    }

    const appSettings = await this.dataService.executeStatement('SELECT * FROM AppSettings', [])
    if (appSettings.rows.length === 0) {
      const defaultLang = this.translation.getCurrentLanguage()
      await this.dataService.executeStatement(
        `
          INSERT INTO AppSettings(language, country, defaultPlanSettingsId,
                                  drawVisibilitySettingsId,
                                  cycleVisibilitySettingsId, accessoryVisibilitySettingsId)
          VALUES (?, ?, ?, ?, ?, ?)`,
        [defaultLang, country, defaultPlanSettingsIdNative, 1, 2, 3]
      )
    }
  }
}
