import { Injectable, inject } from '@angular/core'
import { PlanAccessory } from '../../../models/plan/PlanAccessory'
import { PlanAccessoryLine } from '../../../models/plan/PlanAccessoryLine'
import { PlanAccessoryLineDao } from '../plan-accessory-line.dao'
import { AccessoryLineModel, AccessoryLinesApiClient } from '../../../../generated/efp-api'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PlanAccessoryLineHttpDao implements PlanAccessoryLineDao {
  private readonly apiClient = inject(AccessoryLinesApiClient)

  private mapModelToPlanAccessoryLine(model: AccessoryLineModel): PlanAccessoryLine {
    if (!model.outline) {
      throw new Error('PlanOutline not loaded')
    }

    return {
      id: model.id,
      planId: model.planId,
      outlineId: model.outlineId,
      accessoriesAsString: model.accessoriesAsString,
      start: {
        x: model.outline.startX,
        y: model.outline.startY,
      },
      end: {
        x: model.outline.endX,
        y: model.outline.endY,
      },
    }
  }

  private mapModelToPlanAccessory(model: AccessoryLineModel): PlanAccessory {
    return {
      id: model.id,
      planId: model.planId,
      outlineId: model.outlineId,
      accessoriesAsString: model.accessoriesAsString,
    }
  }

  public async createAccessoryLines(
    accessoryLines: Omit<PlanAccessoryLine, 'id'>[]
  ): Promise<PlanAccessoryLine[]> {
    if (accessoryLines.length === 0) {
      return []
    }

    const response = await firstValueFrom(
      this.apiClient.createAccessoryLines({
        body: accessoryLines.map((line) => ({
          planId: line.planId,
          outlineId: line.outlineId,
          accessoriesAsString: line.accessoriesAsString,
        })),
      })
    )

    return response.map(this.mapModelToPlanAccessoryLine)
  }

  public async updateAccessoryLineAssignments(
    accessoryLines: Pick<PlanAccessoryLine, 'id' | 'accessoriesAsString'>[]
  ): Promise<void> {
    await firstValueFrom(
      this.apiClient.updateAccessoryLine({
        body: accessoryLines.map((line) => ({
          id: line.id,
          accessoriesAsString: line.accessoriesAsString,
        })),
      })
    )
  }

  public async getAccessoriesForPlan(planId: number): Promise<PlanAccessory[]> {
    const response = await firstValueFrom(this.apiClient.getAccessoryLinesByPlan({ planId }))
    return response.map(this.mapModelToPlanAccessory)
  }

  public async getAccessoryLinesForPlan(planId: number): Promise<PlanAccessoryLine[]> {
    const response = await firstValueFrom(
      this.apiClient.getAccessoryLinesByPlan({ planId, includeOutlines: true })
    )
    return response.map(this.mapModelToPlanAccessoryLine)
  }

  public async deleteAccessoryLinesForPlan(planId: number): Promise<void> {
    await firstValueFrom(this.apiClient.deleteAccessoryLinesByPlan({ planId }))
  }
}
