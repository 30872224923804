import { EventEmitter, Injectable } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { PlanType, UnitOfLength } from 'formwork-planner-lib'
import { formworkSystems } from '../constants/formworkSystems'
import { PlanSettings } from '../models/planSettings'
import {
  DEF_SLAB_HEIGHT,
  DEF_SLAB_HEIGHT_IMPERIAL,
  DEF_SLAB_THICKNESS,
  DEF_SLAB_THICKNESS_IMPERIAL,
  DEF_WALL_HEIGHT,
  DEF_WALL_HEIGHT_IMPERIAL,
  DEF_WALL_THICKNESS,
  DEF_WALL_THICKNESS_IMPERIAL,
  MAX_SLAB_HEIGHT,
  MAX_SLAB_HEIGHT_IMPERIAL,
  MAX_SLAB_THICKNESS,
  MAX_SLAB_THICKNESS_IMPERIAL,
  MAX_SNAP_ANGLE_INTERVAL,
  MAX_SNAP_LENGTH_INTERVAL,
  MAX_SNAP_LENGTH_INTERVAL_IMPERIAL,
  MAX_WALL_THICKNESS,
  MAX_WALL_THICKNESS_IMPERIAL,
  MIN_SLAB_HEIGHT,
  MIN_SLAB_HEIGHT_IMPERIAL,
  MIN_SLAB_THICKNESS,
  MIN_SLAB_THICKNESS_IMPERIAL,
  MIN_SNAP_ANGLE_INTERVAL,
  MIN_SNAP_LENGTH_INTERVAL,
  MIN_SNAP_LENGTH_INTERVAL_IMPERIAL,
  MIN_WALL_HEIGHT,
  MIN_WALL_HEIGHT_IMPERIAL,
  MIN_WALL_THICKNESS,
  MIN_WALL_THICKNESS_IMPERIAL,
  SNAP_LENGTH_INTERVAL,
  SNAP_LENGTH_INTERVAL_IMPERIAL,
} from '../pages/planner/model/snapping/constants'
import { AppSettingService } from './app-setting.service'
import { PlanSettingsRepository } from '../repositories/plan-settings.repository'

@Injectable({ providedIn: 'root' })
export class PlanSettingsService {
  public wallFormworkID = ''
  private lastUnit?: UnitOfLength
  public formworkSettingsChanged = new EventEmitter<PlanSettings>()
  public planSettingsChanged = new EventEmitter<PlanSettings>()

  public readonly planSettingsForm = new FormGroup({
    planName: new FormControl('', {
      validators: [Validators.required, Validators.min(1)],
      nonNullable: true,
    }),
    buildType: new FormControl('', { validators: Validators.required, nonNullable: true }),
  })

  public readonly drawSettingsForm = new FormGroup({
    measurementUnit: new FormControl<UnitOfLength>('cm', {
      validators: Validators.required,
      nonNullable: true,
    }),
    lengthRastering: new FormControl(0, {
      validators: [
        Validators.required,
        Validators.min(MIN_SNAP_LENGTH_INTERVAL),
        Validators.max(MAX_SNAP_LENGTH_INTERVAL),
      ],
      nonNullable: true,
    }),
    angleRastering: new FormControl(0, {
      validators: [
        Validators.required,
        Validators.min(MIN_SNAP_ANGLE_INTERVAL),
        Validators.max(MAX_SNAP_ANGLE_INTERVAL),
      ],
      nonNullable: true,
    }),
    wallThickness: new FormControl(0, {
      validators: [
        Validators.required,
        Validators.min(MIN_WALL_THICKNESS),
        Validators.max(MAX_WALL_THICKNESS),
      ],
      nonNullable: true,
    }),
    wallHeight: new FormControl(0, { validators: Validators.required, nonNullable: true }),
    slabThickness: new FormControl(0, {
      validators: [
        Validators.required,
        Validators.min(MIN_SLAB_THICKNESS),
        Validators.max(MAX_SLAB_THICKNESS),
      ],
      nonNullable: true,
    }),
    slabHeight: new FormControl(0, {
      validators: [
        Validators.required,
        Validators.min(MIN_SLAB_HEIGHT),
        Validators.max(MAX_SLAB_HEIGHT),
      ],
      nonNullable: true,
    }),
  })

  constructor(
    private readonly appSettingsService: AppSettingService,
    private readonly planSettingsRepository: PlanSettingsRepository
  ) {
    this.planSettingsForm.controls.buildType.disable()
    this.formworkSettingsChanged.subscribe((val) => {
      this.updateSelectedWallFormworkIdAndMaxHeight(val.formworkWall)
      void this.update(val).then((updatedPlanSettings) =>
        this.planSettingsChanged.emit(updatedPlanSettings)
      )
    })

    this.drawSettingsForm.controls.measurementUnit.valueChanges.subscribe((unit) =>
      this.changePlanSettingUnit(unit)
    )
  }

  /**
   * TODO This keeps a lot of internal state and has dependencies in a lot of components, cleanup!
   */
  public updateSelectedWallFormworkIdAndMaxHeight(newFormworkID: string): void {
    if (this.wallFormworkID !== newFormworkID) {
      this.wallFormworkID = newFormworkID
      this.setMaxHeight()
    }
  }

  public changePlanSettingUnit(measurementUnit: UnitOfLength): void {
    if (measurementUnit !== this.lastUnit) {
      this.lastUnit = measurementUnit
      this.updateFormValidators()
      if (measurementUnit === 'inch') {
        this.drawSettingsForm.patchValue(
          {
            lengthRastering: SNAP_LENGTH_INTERVAL_IMPERIAL,
            wallThickness: DEF_WALL_THICKNESS_IMPERIAL,
            wallHeight: DEF_WALL_HEIGHT_IMPERIAL,
            slabThickness: DEF_SLAB_THICKNESS_IMPERIAL,
            slabHeight: DEF_SLAB_HEIGHT_IMPERIAL,
          },
          { emitEvent: false }
        )
      } else {
        this.drawSettingsForm.patchValue(
          {
            lengthRastering: SNAP_LENGTH_INTERVAL,
            wallThickness: DEF_WALL_THICKNESS,
            wallHeight: DEF_WALL_HEIGHT,
            slabThickness: DEF_SLAB_THICKNESS,
            slabHeight: DEF_SLAB_HEIGHT,
          },
          { emitEvent: false }
        )
      }
      this.setMaxHeight()
      this.drawSettingsForm.updateValueAndValidity()
    }
  }

  public async getDefaultProjectPlanSettingsAndSetLastUnit(): Promise<PlanSettings | undefined> {
    return await this.getPlanSettingsAndSetLastUnit(
      await this.appSettingsService.getDefaultPlanSettingsId()
    )
  }

  public async getPlanSettingsAndSetLastUnit(
    settingsId: number
  ): Promise<PlanSettings | undefined> {
    const planSettings = await this.planSettingsRepository.findOneById(settingsId)
    if (planSettings) {
      this.lastUnit = planSettings.measurementUnit
    }

    return planSettings
  }

  private updateFormValidators(): void {
    this.drawSettingsForm.controls.lengthRastering.setValidators([
      Validators.required,
      Validators.min(
        this.lastUnit === 'inch' ? MIN_SNAP_LENGTH_INTERVAL_IMPERIAL : MIN_SNAP_LENGTH_INTERVAL
      ),
      Validators.max(
        this.lastUnit === 'inch' ? MAX_SNAP_LENGTH_INTERVAL_IMPERIAL : MAX_SNAP_LENGTH_INTERVAL
      ),
    ])
    this.drawSettingsForm.controls.wallThickness.setValidators([
      Validators.required,
      Validators.min(this.lastUnit === 'inch' ? MIN_WALL_THICKNESS_IMPERIAL : MIN_WALL_THICKNESS),
      Validators.max(this.lastUnit === 'inch' ? MAX_WALL_THICKNESS_IMPERIAL : MAX_WALL_THICKNESS),
    ])
    this.drawSettingsForm.controls.slabThickness.setValidators([
      Validators.required,
      Validators.min(this.lastUnit === 'inch' ? MIN_SLAB_THICKNESS_IMPERIAL : MIN_SLAB_THICKNESS),
      Validators.max(this.lastUnit === 'inch' ? MAX_SLAB_THICKNESS_IMPERIAL : MAX_SLAB_THICKNESS),
    ])
    this.drawSettingsForm.controls.slabHeight.setValidators([
      Validators.required,
      Validators.min(this.lastUnit === 'inch' ? MIN_SLAB_HEIGHT_IMPERIAL : MIN_SLAB_HEIGHT),
      Validators.max(this.lastUnit === 'inch' ? MAX_SLAB_HEIGHT_IMPERIAL : MAX_SLAB_HEIGHT),
    ])
  }

  private setMaxHeight(): void {
    const fs = formworkSystems.find((val) => val.id === this.wallFormworkID)
    const maxHeightFS = fs ? (this.lastUnit === 'inch' ? fs.maxHeightImperial : fs.maxHeight) : 0
    this.drawSettingsForm.controls.wallHeight.setValidators([
      Validators.required,
      Validators.min(this.lastUnit === 'inch' ? MIN_WALL_HEIGHT_IMPERIAL : MIN_WALL_HEIGHT),
      Validators.max(maxHeightFS),
    ])
    this.drawSettingsForm.controls.wallHeight.updateValueAndValidity({ emitEvent: false })
  }

  async setFirstSupportedFormworkSystemForAppPlanSettingsIfNecessary(): Promise<void> {
    const appPlanSettings = await this.getDefaultProjectPlanSettingsAndSetLastUnit()
    if (appPlanSettings) {
      await this.setFirstSupportedFormworkSystemIfNecessary(appPlanSettings)
    }
  }

  async setFirstSupportedFormworkSystemIfNecessary(settings: PlanSettings): Promise<void> {
    const supportedFormworkSystems =
      await this.appSettingsService.getSupportedFormworkSystemsForCurrentCountry()
    const supportedWallFormworkSystems = supportedFormworkSystems.filter(
      (system) => system.planType === PlanType.WALL
    )
    const supportedSlabFormworkSystems = supportedFormworkSystems.filter(
      (system) => system.planType === PlanType.SLAB
    )

    const isWallFormworkSystemSupported = supportedFormworkSystems.some(
      (system) => system.id === settings.formworkWall
    )
    const isSlabFormworkSystemSupported = supportedFormworkSystems.some(
      (system) => system.id === settings.formworkSlab
    )

    let settingsHasChanged = false
    if (!isWallFormworkSystemSupported && supportedWallFormworkSystems.length > 0) {
      settings.formworkWall = supportedWallFormworkSystems[0].id
      settingsHasChanged = true
    }

    if (!isSlabFormworkSystemSupported && supportedSlabFormworkSystems.length > 0) {
      settings.formworkSlab = supportedSlabFormworkSystems[0].id
      settingsHasChanged = true
    }

    if (settingsHasChanged) {
      await this.update(settings)
    }
  }

  public async update(planSettings: PlanSettings): Promise<PlanSettings> {
    this.lastUnit = planSettings.measurementUnit

    const updatedPlanSettings = await this.planSettingsRepository.update(planSettings)
    this.planSettingsChanged.emit(updatedPlanSettings)

    return updatedPlanSettings
  }
}
