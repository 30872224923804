import paper from 'paper/dist/paper-core'
import { getLineSide, LineSide, SnappingInfo } from '../../model'

export function getDestinationOuter(
  info: SnappingInfo,
  outerCorner: paper.Point
): paper.Point | undefined {
  const hypotenuse = info.destination.subtract(outerCorner)
  const c = hypotenuse.length
  const a = info.thickness * 0.5
  const b = Math.sqrt(c * c - a * a)
  const candidates = intersectCircles(info.destination, a, outerCorner, b)

  if (!candidates) {
    return undefined
  }

  const side = getLineSide(info.anchor, info.edge.getOtherPoint(info.anchor), info.destination)
  const isAnchorAtNeighborStart = info.edge.startPoint === info.anchor

  const normal = new paper.Point(hypotenuse.y, -hypotenuse.x).normalize().multiply(a)
  if (side === LineSide.LEFT ? isAnchorAtNeighborStart : !isAnchorAtNeighborStart) {
    normal.multiply(-1)
  }

  const vecToCandidate0 = candidates[0].subtract(info.destination)
  const vecToCandidate1 = candidates[1].subtract(info.destination)

  if (
    Math.abs(vecToCandidate0.getDirectedAngle(normal)) <
    Math.abs(vecToCandidate1.getDirectedAngle(normal))
      ? side === LineSide.LEFT
      : side === LineSide.RIGHT
  ) {
    return candidates[0]
  } else {
    return candidates[1]
  }
}

const intersectCircles = (
  m0: paper.Point,
  r0: number,
  m1: paper.Point,
  r1: number
): [paper.Point, paper.Point] | undefined => {
  // adapted from https://stackoverflow.com/a/12221389

  const d = m1.subtract(m0)

  if (d.length > r0 + r1) {
    return undefined
  }

  if (d.length < Math.abs(r0 - r1)) {
    return undefined
  }

  const a = (r0 * r0 - r1 * r1 + d.length * d.length) / (2.0 * d.length)
  const x2 = m0.x + (d.x * a) / d.length
  const y2 = m0.y + (d.y * a) / d.length
  const h = Math.sqrt(r0 * r0 - a * a)
  const rx = -d.y * (h / d.length)
  const ry = d.x * (h / d.length)

  const i0 = new paper.Point(x2 + rx, y2 + ry)
  const i1 = new paper.Point(x2 - rx, y2 - ry)

  return [i0, i1]
}
