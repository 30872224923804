import paper from 'paper/dist/paper-core'
import { AccessoryLine, SimplifiedMesh, Outline, Point2D } from '../../model'
import {
  BOUNDS_END,
  BOUNDS_START,
  convertPointCoordinatesBetweenPaperAndTipos,
  CYCLE_NUMBER,
  LAYING_DIRECTION,
  ROOT_XML_ELEMENT,
  SHEET_START_POINT,
  TIPOS_UNIT_CONVERSION_FACTOR,
  WALL_HEIGHT_BOTTOM,
  WALL_HEIGHT_TOP,
  WALL_LEVEL_SYMBOL,
  WALL_LEVEL_TOP,
  xmlParser,
} from './serializationConstants'

export abstract class MeshXmlWriter {
  protected readonly planDocument: Document

  constructor(initialXml: string) {
    this.planDocument = xmlParser.parseFromString(initialXml, 'application/xml')
    if (
      !this.planDocument.documentElement ||
      this.planDocument.documentElement.tagName !== ROOT_XML_ELEMENT
    ) {
      throw new Error('Initial XML is empty or has invalid root element')
    }
  }

  getXml(): string {
    return this.planDocument.documentElement.outerHTML
  }

  protected writeOutlineBounds(outlines: Outline[]): void {
    const bottomLeft: Point2D = { x: Number.MAX_VALUE, y: Number.MAX_VALUE }
    const topRight: Point2D = { x: Number.MIN_VALUE, y: Number.MIN_VALUE }
    outlines.forEach((outline) => {
      ;[outline.start.x, outline.end.x].forEach((x) => {
        if (x < bottomLeft.x) {
          bottomLeft.x = x
        }
        if (x > topRight.x) {
          topRight.x = x
        }
      })
      ;[outline.start.y, outline.end.y].forEach((y) => {
        if (y < bottomLeft.y) {
          bottomLeft.y = y
        }
        if (y > topRight.y) {
          topRight.y = y
        }
      })
    })

    const boundStart = convertPointCoordinatesBetweenPaperAndTipos(bottomLeft)
    const boundEnd = convertPointCoordinatesBetweenPaperAndTipos(topRight)

    this.setTagValue(BOUNDS_START, getPointXmlString(boundStart))
    this.setTagValue(BOUNDS_END, getPointXmlString(boundEnd))
  }

  writeStartPointAndDirectionToXml(startPoint: paper.Point, layingDirection: paper.Point): void {
    this.ensureTagsExist([ROOT_XML_ELEMENT, 'FloorFieldSymbol', 'symbol', SHEET_START_POINT])
    this.ensureTagsExist([ROOT_XML_ELEMENT, 'FloorFieldSymbol', 'symbol', LAYING_DIRECTION])

    this.setTagValue(
      SHEET_START_POINT,
      getPointXmlString(convertPointCoordinatesBetweenPaperAndTipos(startPoint))
    )
    this.setTagValue(LAYING_DIRECTION, getPointXmlString(layingDirection, true))
  }

  protected getElementBySelector(selector: string): Element {
    const pathContainer = this.planDocument.querySelector(selector)
    if (!pathContainer) {
      throw new Error(`Could not find element with selector '${selector}'.`)
    }

    return pathContainer
  }

  protected setTagValue(selector: string, value: string): void {
    const element = this.planDocument.querySelector(selector)
    if (!element) {
      throw new Error(`Could not find element with selector '${selector}'`)
    }
    element.innerHTML = value
  }

  protected ensureTagsExist(tags: string[]): void {
    const firstElement = this.planDocument.querySelector(tags[0])
    if (!firstElement || !!firstElement.parentElement) {
      throw new Error('XML has wrong root element, cannot insert tags')
    }

    let previousElement = firstElement
    for (let i = 1; i < tags.length; i++) {
      const currentTag = tags[i]
      const element = previousElement.querySelector(currentTag)
      if (!element) {
        const createdElement = this.planDocument.createElement(currentTag)
        previousElement.appendChild(createdElement)
        previousElement = createdElement
      } else {
        previousElement = element
      }
    }
  }

  protected createElementWithValue(tag: string, value: string): HTMLElement {
    const element = this.planDocument.createElement(tag)
    element.innerHTML = value

    return element
  }

  protected writeTiposLinesFromOutline(pathContainer: Element, outlines: Outline[]): void {
    pathContainer.innerHTML = ''
    outlines.forEach((outline) =>
      pathContainer.appendChild(
        this.createLineElement(
          convertPointCoordinatesBetweenPaperAndTipos(outline.start),
          convertPointCoordinatesBetweenPaperAndTipos(outline.end)
        )
      )
    )
  }

  protected writeTiposLinesFromAccessoryLines(
    selector: string,
    accessoryLines: AccessoryLine[]
  ): void {
    const pathContainer = this.getElementBySelector(selector)
    pathContainer.innerHTML = ''

    for (const item of accessoryLines) {
      const firstPoint = convertPointCoordinatesBetweenPaperAndTipos(item.start)
      const secondPoint = convertPointCoordinatesBetweenPaperAndTipos(item.end)
      const accessories = item.accessoriesAsString

      const line = this.createLineElement(firstPoint, secondPoint)
      line.setAttribute('selected', accessories)
      pathContainer.appendChild(line)
    }
  }

  protected createLineElement(startPoint: Point2D, endPoint: Point2D): HTMLElement {
    const line = this.planDocument.createElement('line')
    line.append(
      this.createElementWithValue('startpoint', getPointXmlString(startPoint)),
      this.createElementWithValue('endpoint', getPointXmlString(endPoint))
    )

    return line
  }

  protected createSymbolElement(point: Point2D): HTMLElement {
    const symbol = this.planDocument.createElement('symbol')
    const pointXml = getPointXmlString(convertPointCoordinatesBetweenPaperAndTipos(point))
    symbol.append(
      this.createElementWithValue('refpoint', pointXml),
      this.createElementWithValue('symbolpoint', pointXml)
    )

    return symbol
  }

  protected createCycleSymbolElement(
    position: Point2D,
    cycleNumber: number,
    correctCycleNumber: boolean = true
  ): Node {
    const cycleSymbol = this.createSymbolElement(position)
    // Tipos has reserved the cycle numbers 2 and 3 for slab and platform
    const correctedCycleNumber =
      correctCycleNumber && cycleNumber >= 2 ? cycleNumber + 2 : cycleNumber
    cycleSymbol.appendChild(
      this.createElementWithValue(CYCLE_NUMBER, correctedCycleNumber.toString())
    )

    return cycleSymbol
  }

  protected writeWallLevelSymbols(points: Point2D[], wallHeight: number): void {
    this.ensureTagsExist([ROOT_XML_ELEMENT, WALL_LEVEL_SYMBOL])
    const wallLevelElement = this.getElementBySelector(WALL_LEVEL_SYMBOL)
    wallLevelElement.innerHTML = ''
    points.forEach((paperPoint) => {
      const height = (wallHeight * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(6)

      const symbol = this.createSymbolElement(paperPoint)
      symbol.append(
        this.createElementWithValue(WALL_HEIGHT_TOP, height),
        this.createElementWithValue(WALL_HEIGHT_BOTTOM, (0).toFixed(6))
      )
      wallLevelElement.appendChild(symbol)
    })
  }

  protected writeWallMetadataForOutline(
    outlines: Outline[],
    simpleMesh: SimplifiedMesh,
    height: number
  ): void {
    this.writeOutlineBounds(outlines)
    this.setTagValue(WALL_LEVEL_TOP, (height * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(1))

    const centerPoints = simpleMesh.edges
      .map((edge) => {
        if (edge.startPointIdx !== undefined && edge.endPointIdx !== undefined) {
          return new paper.Point(simpleMesh.points[edge.startPointIdx])
            .add(new paper.Point(simpleMesh.points[edge.endPointIdx]))
            .divide(2)
        } else {
          return undefined
        }
      })
      .filter((it): it is paper.Point => !!it)

    this.writeWallLevelSymbols(centerPoints, height)
  }
}

export const getPointXmlString = (point: Point2D, skipUnitConversion: boolean = false): string => {
  const x = (point.x * (skipUnitConversion ? 1 : TIPOS_UNIT_CONVERSION_FACTOR)).toFixed(6)
  const y = (point.y * (skipUnitConversion ? 1 : TIPOS_UNIT_CONVERSION_FACTOR)).toFixed(6)
  const z = (0).toFixed(6)
  return `${x},${y},${z}`
}
