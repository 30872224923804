import { Component, Input } from '@angular/core'
import { Model } from '../../../pages/planner/model/Model'
import { Mesh } from 'formwork-planner-lib'

@Component({
  selector: 'efp-context-menu-container',
  templateUrl: 'context-menu-container.component.html',
  styleUrls: ['context-menu-container.component.scss'],
})
export class ContextMenuContainerComponent {
  @Input() useCenterView?: boolean = false
  @Input() model?: Model<Mesh>
  @Input() centerContextMenu?: boolean = false
}
