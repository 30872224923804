export enum LogLevel {
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface Log {
  level: LogLevel
  message: string
}
