import { AccessoryPart } from './AccessoryPart'

export class AccessorySelectionChoice {
  public isSelected: boolean

  constructor(public readonly accessory: AccessoryPart, public readonly wasSelected: boolean) {
    this.isSelected = wasSelected
  }

  public toggle(): void {
    this.isSelected = !this.isSelected
  }

  public get added(): boolean {
    return this.isSelected && !this.wasSelected
  }

  public get removed(): boolean {
    return !this.isSelected && this.wasSelected
  }
}
