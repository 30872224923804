import { Injectable } from '@angular/core'
import { Country, UserApiClient } from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { DevicePreferencesService } from '../../device-preferences.service'
import { FavouritesService } from '../../favourites.service'
import { UserDao } from '../user.dao'

@Injectable()
export class UserHttpDao extends UserDao {
  constructor(
    private readonly userApiClient: UserApiClient,
    private readonly favouritesService: FavouritesService,
    private readonly devicePreferences: DevicePreferencesService
  ) {
    super()
  }

  async registerUser(): Promise<number> {
    const userId = await firstValueFrom(
      this.userApiClient.bootstrapCurrentUser({
        body: {
          country: (await this.devicePreferences.fetchDeviceCountryCode()) ?? Country.At,
        },
      })
    )
    await this.favouritesService.initFavourites()

    return userId
  }
}
