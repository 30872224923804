import paper from 'paper/dist/paper-core'
import {
  Edge,
  SNAP_MINIMUM_ANGLE,
  SNAPPING_ERROR_TOLERANCE,
  calculateEdgeOutline,
  getIntersections,
} from 'formwork-planner-lib'
import { EdgeMap } from '../../model/edge/EdgeMap'
import { PlanType } from '../../model/PlanType'

export function detectPathIntersections(
  edges: Edge[],
  compoundPath: paper.CompoundPath
): Set<Edge> {
  const invalidEdges = new Set<Edge>()

  const edgeOutlineMap = new Map<Edge, paper.Path>()
  edges.forEach((edge) => edgeOutlineMap.set(edge, edge.getOutlinePath()))

  const edgeIntersectionMap = new Map<Edge, Map<Edge, paper.CurveLocation[]>>()

  edges.forEach((edge) => {
    const outlinePath = <paper.Path>edgeOutlineMap.get(edge)
    const intersectionEntries = edges
      .filter((e) => e !== edge)
      .map(
        (e) =>
          [e, getIntersections(outlinePath, <paper.Path>edgeOutlineMap.get(e))] as [
            Edge,
            paper.CurveLocation[]
          ]
      )
      .filter(([, intersections]) => intersections.length > 0)

    edgeIntersectionMap.set(edge, new Map(intersectionEntries))
  })

  edges.forEach((edge) => {
    const outlinePath = <paper.Path>edgeOutlineMap.get(edge)
    const allCrossings = compoundPath.getCrossings(outlinePath)

    if (allCrossings.length < 2) {
      return
    }

    const intersectedEdges = <Map<Edge, paper.CurveLocation[]>>edgeIntersectionMap.get(edge)

    const cornerPoints = outlinePath.segments.map((segment) => segment.point)
    const pathCrossings = allCrossings.filter(
      (ix) => !cornerPoints.find((point) => point.isClose(ix.point, SNAPPING_ERROR_TOLERANCE))
    )

    if (intersectedEdges.size === 0 && pathCrossings.length > 0) {
      // edge intersects with a different path
      invalidEdges.add(edge)
      return
    }

    const edgeOutline = calculateEdgeOutline(edge)
    const edgeOutlineOtherLines = [
      new paper.Path([edgeOutline.LEFT.start, edgeOutline.RIGHT.start]),
      new paper.Path([edgeOutline.LEFT.end, edgeOutline.RIGHT.end]),
    ]

    for (const [intersectedEdge, ixs] of intersectedEdges) {
      const connectedPoint = intersectedEdge.getConnectedPoint(edge)
      if (!connectedPoint) {
        // edge intersects with a not connected edge
        invalidEdges.add(edge)
        break
      }

      const edgeBottomLine = edgeOutlineOtherLines.find((line) =>
        line.getLocationOf(connectedPoint)
      )
      const withoutBottomIxs = ixs.filter((ix) => !edgeBottomLine?.getLocationOf(ix.point))

      const intersectedEdgeOutline = calculateEdgeOutline(intersectedEdge)
      const intersectedEdgeOutlineOtherLines = [
        new paper.Path([intersectedEdgeOutline.LEFT.start, intersectedEdgeOutline.RIGHT.start]),
        new paper.Path([intersectedEdgeOutline.LEFT.end, intersectedEdgeOutline.RIGHT.end]),
      ]

      const edgeTopLine = <paper.Path>edgeOutlineOtherLines.find((line) => line !== edgeBottomLine)
      const intersectedEdgeTopLine = <paper.Path>(
        intersectedEdgeOutlineOtherLines.find((line) => !line.getLocationOf(connectedPoint))
      )
      const topIx = withoutBottomIxs.find(
        (ix) =>
          edgeTopLine.getLocationOf(ix.point) || intersectedEdgeTopLine.getLocationOf(ix.point)
      )
      if (topIx) {
        // edge intersects top line of another edge
        invalidEdges.add(edge)
      }
    }
  })

  return invalidEdges
}

export function detectSmallAnglesAtPath(
  edgeMap: EdgeMap,
  compoundPath: paper.CompoundPath,
  target: PlanType
): Set<Edge> {
  const invalidEdges = new Set<Edge>()
  const curves: paper.Curve[][] = []

  compoundPath.children.map((item) => {
    if (item instanceof paper.Path) {
      curves.push(item.segments.map((segment) => segment.curve))
    }
  })

  curves.forEach((pathCurves) => {
    for (let i = 0; i < pathCurves.length; i++) {
      const j = i + 1 >= pathCurves.length ? 0 : i + 1
      const curve1 = pathCurves[i]
      const curve2 = pathCurves[j]

      const curve1Direction = curve1.point1.subtract(curve1.point2)
      const curve2Direction = curve2.point2.subtract(curve2.point1)

      const angle = curve1Direction.getDirectedAngle(curve2Direction)

      const roundedAngle = Math.round(Math.abs(angle))

      if (roundedAngle < SNAP_MINIMUM_ANGLE[target] && Math.abs(angle) > SNAPPING_ERROR_TOLERANCE) {
        const steps = edgeMap.get(curve1.segment2)
        steps?.forEach((step) => invalidEdges.add(step.edge))
      }
    }
  })

  return invalidEdges
}

export function detectOverlappingEdges(edges: Edge[]): Set<Edge> {
  const overlappingEdges = new Set<Edge>()

  const edgeOutlineMap = new Map<Edge, paper.Path>()
  edges.forEach((edge) => edgeOutlineMap.set(edge, edge.getOutlinePath()))

  for (const a of edges) {
    for (const b of edges) {
      if (a === b) {
        continue
      }

      const outlineA = <paper.Path>edgeOutlineMap.get(a)
      const outlineB = <paper.Path>edgeOutlineMap.get(b)
      const ixs = outlineA.getCrossings(outlineB)
      const containedPoints = outlineB.segments
        .map((segment) => segment.point)
        .filter((point) => outlineA.contains(point))

      const outlinePoints = outlineA.getIntersections(outlineB)

      if (
        ((ixs.length >= 4 || containedPoints.length >= 4) && a.isParallelTo(b)) ||
        outlinePoints.length > 2
      ) {
        overlappingEdges.add(a)
        overlappingEdges.add(b)
      }
    }
  }

  return overlappingEdges
}
