import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { arePointsEqual, PlanType, Point2D } from 'formwork-planner-lib'
import { PlanAccessoryLine } from '../../models/plan/PlanAccessoryLine'
import { DataService } from '../data.service'
import { PlanAccessoryLineRepository } from '../../repositories/plan-accessory-line.repository'
import { PlanOutlineRepository } from '../../repositories/plan-outline.repository'

/**
 * @deprecated Only used for migration
 */
interface MigrationContourLine {
  id: number
  planId: number
  start: Point2D
  end: Point2D
  accessories: string | undefined
}

/**
 * Migrate ContourLine table to AccessoryLine table.
 *
 * @deprecated Should be removed from the next major version.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessoryLineMigrationService {
  constructor(
    private readonly planAccessoryLineRepository: PlanAccessoryLineRepository,
    private readonly planOutlineRepository: PlanOutlineRepository,
    private readonly dataService: DataService
  ) {}

  /**
   * Migrate ContourLine table to AccessoryLine table by no longer storing start/end coordinates with accessory assignment,
   * but rather referencing PlanOutline in the database..
   *
   * @deprecated Should be removed from the next major version.
   */
  public async migrateContourLineToAccessoryLine(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      const contourLinesForPlan = new Map<number, MigrationContourLine[]>()
      try {
        const result = await this.dataService.executeStatement('SELECT * FROM ContourLine')
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i)
          const contourLines = contourLinesForPlan.get(row.planId) ?? []
          contourLines.push({
            id: row.id,
            planId: row.planId,
            start: { x: row.start_x, y: row.start_y },
            end: { x: row.end_x, y: row.end_y },
            accessories: row.accessories,
          })
          contourLinesForPlan.set(contourLines[0].planId, contourLines)
        }
      } catch (e: unknown) {
        // Should only fail if the database table was already removed, so nothing to do
      }

      if (contourLinesForPlan.size > 0) {
        for (const contourLinesForPlanElement of contourLinesForPlan) {
          const [planId, contourLines] = contourLinesForPlanElement
          const planOutline = (
            await this.planOutlineRepository.findAllOutlinesByPlanId(planId)
          ).filter((it) => it.outlineType === PlanType.WALL)
          const accesoryLines: Omit<PlanAccessoryLine, 'id'>[] = contourLines
            .map((contourLine) => {
              const matchingOutline = planOutline.find((outline) => {
                return (
                  (arePointsEqual(outline.start, contourLine.start) &&
                    arePointsEqual(outline.end, contourLine.end)) ||
                  (arePointsEqual(outline.start, contourLine.end) &&
                    arePointsEqual(outline.end, contourLine.start))
                )
              })

              if (!matchingOutline) {
                return undefined
              } else {
                return {
                  planId,
                  outlineId: matchingOutline.id,
                  accessoriesAsString: contourLine.accessories ?? '',
                  start: matchingOutline.start,
                  end: matchingOutline.end,
                }
              }
            })
            .filter((it): it is (typeof accesoryLines)[0] => !!it)

          if (accesoryLines.length !== planOutline.length) {
            console.error(
              `Could not migrate accessories for plan ${planId}, contour lines and plan outline do not match`
            )
          } else {
            await this.planAccessoryLineRepository.createAccessoryLines(accesoryLines)
          }
        }
      }

      await this.dataService.executeStatement('DROP TABLE IF EXISTS ContourLine')
    }
  }
}
