import { Injectable } from '@angular/core'
import { ChangedResultPartsCommandParam } from '../../../../generated/efp-api'
import { DataService } from '../../data.service'
import { ChangedResultPart } from '../../part-list-service'
import { ChangedResultPartDao } from '../changed-result-part.dao'

@Injectable()
export class ChangedResultPartSqlDao extends ChangedResultPartDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  public async findAllByPlanId(planId: number): Promise<ChangedResultPart[]> {
    const statement = 'SELECT * FROM ChangedResultParts where planId=? '
    const values = [planId]
    const changedResultParts: ChangedResultPart[] = []

    const result = await this.dataService.executeStatement(statement, values)
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      changedResultParts[i] = {
        id: row.id,
        planId: row.planId,
        articleId: row.articleId,
        amount: row.amount,
      }
    }

    return changedResultParts
  }

  public async deleteAllByPlanId(planId: number): Promise<void> {
    const statement = 'DELETE FROM ChangedResultParts where planId=? '
    const values = [planId]

    await this.dataService.executeStatement(statement, values)
  }

  public async deleteOldAndSaveNewByPlanId(
    planId: number,
    partListParams: ChangedResultPartsCommandParam[]
  ): Promise<ChangedResultPart[]> {
    await this.deleteAllByPlanId(planId)

    if (partListParams.length > 0) {
      await this.createByPlanId(partListParams)
    }

    return this.findAllByPlanId(planId)
  }

  private async createByPlanId(partListParams: ChangedResultPartsCommandParam[]): Promise<void> {
    const changedResultPartPromises = partListParams.map(async (param) => {
      const statement = 'INSERT INTO ChangedResultParts (planId, articleId, amount) VALUES (?,?,?)'
      const values = [param.planId, param.articleId, param.amount]

      return this.dataService.executeStatement(statement, values)
    })

    await Promise.all(changedResultPartPromises)
  }
}
