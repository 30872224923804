import { Injectable, inject } from '@angular/core'
import { SalesContactDao } from '../sales-contact.dao'
import { SalesContactsApiClient } from '../../../../generated/efp-api'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class SalesContactHttpDao extends SalesContactDao {
  private readonly salesContactApiClient = inject(SalesContactsApiClient)

  async create(mail: string): Promise<void> {
    await firstValueFrom(this.salesContactApiClient.createSalesContact({ body: { mail } }))
  }

  async findAllLimit7(): Promise<string[]> {
    const result = await firstValueFrom(this.salesContactApiClient.getAllSalesContacts())

    if (result.length > 6) {
      result.splice(6, result.length - 6)
    }

    const mails = result.map((item) => item.mail)
    return mails
  }
}
