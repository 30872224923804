import { Injectable } from '@angular/core'
import { CreateStockCommandParams, StockModel, StocksApiClient } from '@efp/api'
import { Stock } from '../../../models/stock'
import { StockDao } from '../stock.dao'
import { firstValueFrom, map } from 'rxjs'

@Injectable()
export class StockHttpDao extends StockDao {
  constructor(private readonly stockApiClient: StocksApiClient) {
    super()
  }

  private mapModelToStock(model: StockModel): Stock {
    return {
      id: model.id,
      name: model.name,
      date: new Date(model.date),
      articles: model.articleModels?.map((articleModel) => {
        return {
          id: articleModel.id,
          stockId: articleModel.stockId,
          articleId: articleModel.articleId,
          name: articleModel.name,
          amount: articleModel.amount,
        }
      }),
    }
  }

  async createStockWithArticles(params: CreateStockCommandParams): Promise<Stock> {
    return firstValueFrom(
      this.stockApiClient
        .createStock({
          body: params,
        })
        .pipe(map((model: StockModel) => this.mapModelToStock(model)))
    )
  }

  async delete(stock: Stock): Promise<void> {
    await firstValueFrom(
      this.stockApiClient.deleteStock({
        stockId: stock.id,
      })
    )
  }

  async findAll(): Promise<Stock[]> {
    const stockModels = await firstValueFrom(this.stockApiClient.getAllStocks())
    return stockModels.map((stockModel) => this.mapModelToStock(stockModel))
  }

  async findAllRelatedPlanAndProjectNamesById(stockId: number): Promise<string[]> {
    const stockModel = await firstValueFrom(
      this.stockApiClient.getStock({
        stockId,
        includeRelatedPlanAndProjectNames: true,
      })
    )

    return stockModel.relatedPlanAndProjectNames ?? []
  }

  async findOneByIdWithArticles(id: number): Promise<Stock | undefined> {
    const stockModel = await firstValueFrom(
      this.stockApiClient.getStock({
        stockId: id,
      })
    )

    if (!stockModel) return

    return this.mapModelToStock(stockModel)
  }

  async updateName(stock: Stock, name: string): Promise<void> {
    await firstValueFrom(
      this.stockApiClient.patchStock({
        stockId: stock.id,
        body: {
          name,
        },
      })
    )
  }
}
