import paper from 'paper/dist/paper-core'
import { ANGLE_ERROR_TOLERANCE, SNAP_MINIMUM_ANGLE } from '../../utils'
import { Edge } from '../edge/Edge'
import { EdgeAngle } from '../edge/EdgeAngle'
import { LinePosition } from '../edge/LinePosition'
import { PlanType } from '../PlanType'
import { Mesh } from './Mesh'
import { WallMesh } from './WallMesh'

export class MeshPoint extends paper.Point {
  public edges: Set<Edge>

  private readonly mesh: Mesh
  private readonly target: PlanType

  public constructor(point: { x: number; y: number }, mesh: Mesh) {
    super(point.x, point.y)

    this.mesh = mesh
    this.target = mesh instanceof WallMesh ? PlanType.WALL : PlanType.SLAB
    this.edges = new Set()
  }

  public hasSmallAngle(checkNeighbors: boolean = false): boolean {
    const edgeAngles = this.calculateEdgeAngles()

    if (edgeAngles.length !== 1) {
      for (let i = 0; i < edgeAngles.length; i++) {
        const edgeAngle1 = edgeAngles[i]
        const edgeAngle2 = edgeAngles[(i + 1) % edgeAngles.length]
        let diffAngle = edgeAngle2.angle - edgeAngle1.angle

        if (diffAngle > 180) {
          diffAngle -= 360
        } else if (diffAngle < -180) {
          diffAngle += 360
        }

        if (Math.abs(diffAngle) < SNAP_MINIMUM_ANGLE[this.target] - 0.01) {
          return true
        }
      }
    }

    if (checkNeighbors) {
      return Array.from(this.edges).some((edge) => edge.getOtherPoint(this).hasSmallAngle())
    }

    return false
  }

  calculateEdgeAngles(): EdgeAngle[] {
    const edgeInfos: EdgeAngle[] = []
    this.edges.forEach((edge) => {
      edgeInfos.push({
        edge,
        isStart: edge.startPoint === this,
        angle: edge.calculateAngleFromOrigin(edge.startPoint === this),
      })
    })

    edgeInfos.sort((a, b) => a.angle - b.angle)
    return edgeInfos
  }

  public hasSameEdge(other: MeshPoint): boolean {
    return Array.from(this.edges).some((edge) => other.edges.has(edge))
  }

  public canMergeEdges(ignoreThickness: boolean = false): boolean {
    if (this.edges.size !== 2) {
      return false
    }

    const [edge1, edge2] = [...this.edges]

    if (Math.abs(edge1.thickness - edge2.thickness) > Number.EPSILON && !ignoreThickness) {
      return false
    }

    if (Math.abs(Math.abs(edge1.getAngle(edge2) ?? 0) - 180) > ANGLE_ERROR_TOLERANCE) {
      return false
    }

    return true
  }

  public mergeEdges(): void {
    const [edge1, edge2] = [...this.edges]

    const point1 = edge1.getOtherPoint(this)
    const point2 = edge2.getOtherPoint(this)
    const thickness = edge1.thickness

    point1.edges.delete(edge1)
    point2.edges.delete(edge2)

    this.mesh.points.delete(this)

    const newEdge = new Edge(point1, point2, thickness)
    point1.edges.add(newEdge)
    point2.edges.add(newEdge)
  }

  public get planType(): PlanType {
    return this.target
  }

  /**
   * detaches the given edge from the current point
   * @param edge to be detached
   * @returns the copy of the point that keeps the original edge
   */
  public detachFromEdge(edge: Edge): MeshPoint | undefined {
    const position = edge.getPosition(this)
    if (!position) {
      return undefined
    }

    this.edges.delete(edge)
    const newPoint = this.clone(true)
    newPoint.edges.add(edge)
    if (position === LinePosition.START) {
      edge.startPoint = newPoint
    } else {
      edge.endPoint = newPoint
    }

    return newPoint
  }

  public clone(skipEdges = false): MeshPoint {
    const newPoint = new MeshPoint({ x: this.x, y: this.y }, this.mesh)
    if (!skipEdges) {
      this.edges.forEach((it) => newPoint.edges.add(it))
    }

    return newPoint
  }
}

export const asMeshPoint = (point: MeshPoint | paper.Point, mesh: Mesh): MeshPoint => {
  if (point instanceof MeshPoint) {
    return point
  }

  return new MeshPoint(point, mesh)
}
