import { Injectable } from '@angular/core'
import { CatalogApiClient, CatalogArticle, CatalogArticleGroup, FormworkId } from '@efp/api'
import { createStore, withProps } from '@ngneat/elf'
import {
  addEntities,
  deleteEntities,
  getEntity,
  selectAllEntities,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { firstValueFrom } from 'rxjs'
import { AppSettingService } from '../services/app-setting.service'
import { Translation } from '../services/translation.service'

export type SelectedArticleItem = {
  articleId: string
  amount: number
  name: string
}

export const catalogArticleGroupsStore = createStore(
  { name: 'catalogArticleGroups' },
  withProps<CatalogArticleGroup[]>([])
)

export const selectedCatalogArticlesStore = createStore(
  { name: 'selectedCatalogArticles' },
  withEntities<SelectedArticleItem, 'articleId'>({ idKey: 'articleId' })
)

@Injectable({
  providedIn: 'root',
})
export class CatalogRepository {
  private preselectedArticleIds?: Set<string>

  public readonly catalogArticleGroups$ = catalogArticleGroupsStore.asObservable()

  public readonly selectedCatalogArticles$ = selectedCatalogArticlesStore.pipe(selectAllEntities())

  constructor(
    private readonly catalogApi: CatalogApiClient,
    private readonly translation: Translation,
    private readonly appSettingService: AppSettingService
  ) {
    this.init()
  }

  private selectPreselectedArticles(): void {
    if (this.findAllArticles().length === 0) {
      return
    }

    this.preselectedArticleIds?.forEach((articleId) => {
      this.selectArticle(articleId)
    })
  }

  public init(preselectedArticleIds?: Set<string>): void {
    this.updateState([])
    selectedCatalogArticlesStore.reset()
    this.preselectedArticleIds = preselectedArticleIds

    this.selectPreselectedArticles()
  }

  public async searchCatalog(formworkSystem: FormworkId): Promise<void> {
    this.updateState([])
    const catalogArticleGroups = await this.getArticleGroups(formworkSystem)
    this.updateState(catalogArticleGroups)
    this.selectPreselectedArticles()
  }

  public async getArticleGroups(formworkSystem: FormworkId): Promise<CatalogArticleGroup[]> {
    const language = this.translation.getCurrentLanguage()
    const appSettings = await this.appSettingService.getAppSettings()

    return await firstValueFrom(
      this.catalogApi.getArticleGroupsForFormworkSystem({
        formworkId: formworkSystem,
        isoLanguageCode: language,
        country: appSettings.country,
      })
    )
  }

  public selectArticle(articleId: string): void {
    const article = this.queryArticleById(articleId)
    if (!article) {
      throw new Error('Selected article with id = ' + articleId + ' not found in store!')
    }
    if (!selectedCatalogArticlesStore.query(getEntity(articleId))) {
      selectedCatalogArticlesStore.update(
        addEntities({
          articleId,
          amount: 0,
          name: article.name ?? '',
        })
      )
    } else {
      selectedCatalogArticlesStore.update(deleteEntities(articleId))
    }
  }

  private updateState(catalogArticleGroups: CatalogArticleGroup[]): void {
    catalogArticleGroupsStore.update(() => catalogArticleGroups)
  }

  public setSelectedAmount(articleId: string, amount: number): void {
    const catalogArticle = this.queryArticleById(articleId)

    if (!catalogArticle) {
      throw new Error('Selected article with id = ' + articleId + ' not found in store!')
    }
    const selectedArticleItem: SelectedArticleItem = {
      articleId: catalogArticle.articleId,
      amount,
      name: catalogArticle.name ?? '',
    }

    selectedCatalogArticlesStore.update(
      updateEntities(selectedArticleItem.articleId, selectedArticleItem)
    )
  }

  private queryArticleById(articleId: string): CatalogArticle | undefined {
    const articles = this.findAllArticles()

    return articles.find((a) => a.articleId === articleId)
  }

  private findAllArticles(): CatalogArticle[] {
    const rootGroups = catalogArticleGroupsStore.getValue()
    return rootGroups.flatMap((g) => g.subgroups).flatMap((g) => g.articles)
  }
}
