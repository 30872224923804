import { UndoRedoHistory } from '../../../models/history/undoRedoHistory'
import { AccessoryPart } from './AccessoryPart'

export class LineAccessoryPart {
  readonly id: number
  readonly parts: AccessoryPart[]

  constructor(id: number, parts: AccessoryPart[]) {
    this.id = id
    this.parts = parts
  }
}

export interface AccessoryData {
  lineAccessoryParts: LineAccessoryPart[]
}

export class AccessoryUndoRedoHistory extends UndoRedoHistory<LineAccessoryPart[]> {
  deserialize(serializedValue: string): LineAccessoryPart[] {
    return JSON.parse(serializedValue)
  }

  serialize(value: LineAccessoryPart[]): string {
    return JSON.stringify(value)
  }
}
