import { Point2D } from '../../model/point2d'

export const xmlParser = new DOMParser()
export const ROOT_XML_ELEMENT = 'TiposProjectExport'
export const CURRENT_SCOPE = 'currentScope'
export const FLOOR_BODY_SYMBOL = 'FloorBodySymbol'
export const FLOOR_CYCLE_SYMBOL = 'FloorCycleSymbol'
export const SLAB_CONTOUR = 'SlabContour'
export const SLAB_LEVEL = 'slabLevel'
export const SLAB_THICKNESS = 'slabThickness'
export const WALL_LEVEL_SYMBOL = 'WallLevelSymbol'
export const WALL_HEIGHT_TOP = 'Height_Top'
export const WALL_HEIGHT_BOTTOM = 'Height_Bottom'
export const WALL_LEVEL_TOP = 'wallLevelTop'
export const WALL_CONTOUR = 'WallContour'
export const LINE = 'line'
export const LINE_START_POINT = 'startpoint'
export const LINE_END_POINT = 'endpoint'
export const CYCLE_BOUNDARIES = 'CycleBoundaries'
export const CYCLE_SYMBOL = 'CycleSymbol'
export const CYCLE_NUMBER = 'CYCLE-NUMBER'
export const SHEET_START_POINT = 'FormworkSheatingStartingPoint'
export const LAYING_DIRECTION = 'TransversalBeamDirection'
export const BOUNDS_START = 'MinimumExtrem'
export const BOUNDS_END = 'MaximumExtrem'

// convert cm to mm
export const TIPOS_UNIT_CONVERSION_FACTOR = 10

/**
 * PaperJS has its coordinate-center in the top left corner
 * TIPOS needs the coordinate-center to be in the bottom left
 * Thus, y-axis has to be flipped.
 */
export const convertPointCoordinatesBetweenPaperAndTipos = (point: Point2D): Point2D => ({
  x: point.x,
  y: -point.y,
})
