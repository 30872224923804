// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnumUtil {
  type enumKeyType = string | number

  /**
   * Checks if the given value is part of the enum and returns the value, if so.
   * Returns null if the value is not part of the enum.
   */
  export const tryParseEnumValue = <keyType extends enumKeyType>(
    value: keyType,
    enumType: { [key in keyType]: string }
  ): keyType | null => {
    if (Object.values(enumType).includes(value)) {
      return value
    }

    return null
  }
}
