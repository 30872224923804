import { createStore } from '@ngneat/elf'
import { addEntities, withEntities } from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { Plan } from '../models/plan'
import { PlanCreationDao } from '../services/dao/plan-creation.dao'
import { PlanCreateCommandParams } from '../../generated/efp-api'
import { Project } from '../models/project'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from '../services/applicationInsights.service'

export const planStore = createStore({ name: 'plan' }, withEntities<Plan>())

@Injectable({
  providedIn: 'root',
})
export class PlanCreationRepository {
  constructor(
    private readonly planCreationDao: PlanCreationDao,
    private readonly appInsightsService: ApplicationInsightsService
  ) {}

  public async createPlan(
    params: PlanCreateCommandParams,
    project?: Project | undefined
  ): Promise<Plan> {
    // TODO optimistic create in store
    const newPlan = await this.planCreationDao.createPlan(params, project)

    if (newPlan) {
      planStore.update(addEntities(newPlan))
      this.appInsightsService.addUserEvent(ApplicationInsightsStates.PLAN_CREATED, newPlan.id)
    }
    return newPlan
  }
}
