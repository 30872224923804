import { Component, HostBinding, Input } from '@angular/core'

export enum SidebarPosition {
  LEFT,
  RIGHT,
  BOTH,
}

@Component({
  selector: 'efp-sidebar-container',
  templateUrl: './efp-sidebar-container.component.html',
  styleUrls: ['./efp-sidebar-container.component.scss'],
})
export class EfpSidebarContainerComponent {
  leftSidebarCollapsed = false
  rightSidebarCollapsed = false

  protected readonly SidebarPosition = SidebarPosition

  @Input() sidebarPosition: SidebarPosition = SidebarPosition.LEFT
  @Input() collapsableLeftSidebar?: boolean

  @HostBinding('style')
  get getStyle(): string {
    return 'height: 100%;'
  }

  onLeftCollapseButton(): void {
    this.leftSidebarCollapsed = !this.leftSidebarCollapsed
  }

  onRightCollapseButton(): void {
    this.rightSidebarCollapsed = !this.rightSidebarCollapsed
  }
}
