import paper from 'paper/dist/paper-core'
import { CycleSymbol } from '../model'

/**
 * This class describes an independent cycle symbol for walls paths that don't have cycle boundaries
 */
export class CycleSymbolDrawable {
  public position: paper.Point
  public cycleNumber: number

  constructor(cycleSymbol: CycleSymbol) {
    this.position = new paper.Point(cycleSymbol.position)
    this.cycleNumber = cycleSymbol.cycleNumber
  }

  public toCycleSymbol(): CycleSymbol {
    return {
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      cycleNumber: this.cycleNumber,
    }
  }
}
