import { createStore } from '@ngneat/elf'
import {
  addEntities,
  deleteEntities,
  deleteEntitiesByPredicate,
  getAllEntitiesApply,
  selectAllEntities,
  updateEntities,
  updateEntitiesIds,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { Article } from '../models/article'
import { ArticleDao } from '../services/dao/article.dao'

const articleStore = createStore({ name: 'articles' }, withEntities<Article>())

@Injectable({
  providedIn: 'root',
})
export class ArticleRepository {
  public readonly articles$ = articleStore.pipe(selectAllEntities())

  constructor(private readonly articleDao: ArticleDao) {}

  public createOptimistic(article: Article): Article {
    article.id = articleStore.state.ids.length > 0 ? Math.max(...articleStore.state.ids) + 1 : 1
    articleStore.update(addEntities(article))
    return article
  }

  public updateOptimistic(article: Article, tempId: number): void {
    articleStore.update(updateEntitiesIds(tempId, article.id), updateEntities(article.id, article))
  }

  public deleteOptimistic(tempIds: number[]): void {
    articleStore.update(deleteEntities(tempIds))
  }

  public async create(article: Article): Promise<number> {
    article.id = (await this.articleDao.createArticles([article]))[0]
    articleStore.update(addEntities(article))
    return article.id
  }

  public async delete(article: Article): Promise<void> {
    await this.articleDao.delete(article.id)
    articleStore.update(deleteEntities(article.id))
  }

  public async update(article: Article): Promise<void> {
    await this.articleDao.update(article)
    articleStore.update(updateEntities(article.id, article))
  }

  public async updateAll(articles: Article[]): Promise<void> {
    await this.articleDao.updateAll(articles)
    articleStore.update(upsertEntities(articles))
  }

  public updateStockArticles(stockId: number, articles?: Article[]): void {
    if (!articles) {
      articleStore.update(deleteEntitiesByPredicate((e) => e.stockId === stockId))
      return
    }

    articleStore.update(upsertEntities(articles))
  }

  public async findAllByStockId(stockId: number): Promise<Article[]> {
    let articles = articleStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.stockId === stockId,
      })
    )

    if (!articles || articles.length === 0) {
      articles = await this.articleDao.findAllByStockId(stockId)
      articleStore.update(upsertEntities(articles))
    }

    return articles
  }
}
