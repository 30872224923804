import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import paper from 'paper/dist/paper-core'
import { PlannerStateService } from '../../services'

@Component({
  selector: 'efp-magnifier',
  templateUrl: 'magnifier.component.html',
  styleUrls: ['magnifier.component.scss'],
})
export class MagnifierComponent implements OnChanges, AfterViewInit {
  @Input() targetCanvas?: HTMLCanvasElement
  @Input() pointToMagnify!: paper.Point

  @ViewChild('magnifierCanvas') magnifierCanvas!: ElementRef

  private canvas?: HTMLCanvasElement
  private magnifyingFactor = 1.5

  constructor(private readonly plannerState: PlannerStateService) {}

  ngAfterViewInit(): void {
    this.canvas = this.magnifierCanvas.nativeElement
    this.updateMagnifierCanvas()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const pointChanges = changes.pointToMagnify
    if (pointChanges && pointChanges.currentValue !== pointChanges.previousValue) {
      this.drawMagnification(pointChanges.currentValue)
    }
  }

  private updateMagnifierCanvas(): void {
    if (this.canvas) {
      // Ensure the canvas size matches the visible size
      this.canvas.height = this.canvas.clientHeight
      this.canvas.width = this.canvas.clientWidth
    }
  }

  private drawMagnification(point: paper.Point): void {
    if (this.targetCanvas && this.canvas) {
      const ctx = this.canvas.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        if (point) {
          const clientPoint = this.plannerState.paper.view.projectToView(point)
          const cropWidth = (this.canvas.width / this.magnifyingFactor) * window.devicePixelRatio
          const cropHeight = (this.canvas.height / this.magnifyingFactor) * window.devicePixelRatio

          const cropX = clientPoint.x * window.devicePixelRatio - cropWidth / 2
          const cropY = clientPoint.y * window.devicePixelRatio - cropHeight / 2

          ctx.drawImage(
            this.targetCanvas,
            Math.max(0, cropX),
            Math.max(0, cropY),
            Math.min(this.targetCanvas.width - cropX, cropWidth),
            Math.min(this.targetCanvas.height - cropY, cropHeight),
            0,
            0,
            this.canvas.width,
            this.canvas.height
          )
        }
      }
    }
  }
}
