import { Injectable } from '@angular/core'
import { LengthUtil, UnitOfLength } from 'formwork-planner-lib'

import convertCmToUnit = LengthUtil.convertCmToUnit
import roundForDisplay = LengthUtil.roundForDisplay
import formatCmToInch = LengthUtil.formatCmToInch
import { FormworkId } from '../../generated/efp-api'

@Injectable({
  providedIn: 'root',
})
export class MeasurementLegendService {
  public convertValue(measurementUnit: UnitOfLength = 'cm', value: number = 0): string {
    if (measurementUnit === 'inch') {
      return formatCmToInch(value ?? 0)
    }

    return roundForDisplay(convertCmToUnit(value, measurementUnit), measurementUnit).toString()
  }

  public getMeasurementUnitArticle(formworkId: FormworkId = FormworkId.Xstahlfr): string {
    return formworkId !== FormworkId.XframiS ? 'cm' : 'inch'
  }
}
