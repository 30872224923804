/* tslint:disable */
/* eslint-disable */
export enum FormworkId {
  Xstahlfr = 'XSTAHLFR',
  Xframi = 'XFRAMI',
  Xfrplus = 'XFRPLUS',
  Xreforma = 'XREFORMA',
  Xlight = 'XLIGHT',
  XframiS = 'XFRAMI_S',
  XframaS = 'XFRAMA_S',
  Xalufr = 'XALUFR',
  Xfreco = 'XFRECO',
  Xframini = 'XFRAMINI',
  Flex124 = 'FLEX124',
  Flexs = 'FLEXS',
  Xfr30 = 'XFR30',
  Xreformx = 'XREFORMX'
}
