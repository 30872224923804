import { CreatePlanSettingsCommandParams, FormworkId, UnitOfLength } from '@efp/api'
import { PlanSettings } from '../../models/planSettings'

export interface FormworkFavouritesSettings {
  formwork: FormworkId
  favouritesId: number
  planSettingsId: number
}

export const mapPlanSettingsToCreatePlanSettingsCommandParams = (
  planSettings: PlanSettings
): CreatePlanSettingsCommandParams => {
  return {
    formworkWall: planSettings.formworkWall,
    formworkSlab: planSettings.formworkSlab,
    angleRastering: planSettings.angleRastering,
    lengthRastering: planSettings.lengthRastering,
    wallThickness: planSettings.wallThickness,
    slabThickness: planSettings.slabThickness,
    wallHeight: planSettings.wallHeight,
    slabHeight: planSettings.slabHeight,
    measurementUnit: planSettings.measurementUnit as UnitOfLength,
    wallFavouriteProfileId: planSettings.wallFavId,
    slabFavouriteProfileId: planSettings.slabFavId,
  }
}

export const mapCreatePlanSettingsCommandParamsToPlanSettings = (
  params: CreatePlanSettingsCommandParams,
  id: number
): PlanSettings => {
  return {
    id,
    formworkWall: params.formworkWall,
    formworkSlab: params.formworkSlab,
    angleRastering: params.angleRastering,
    lengthRastering: params.lengthRastering,
    wallThickness: params.wallThickness,
    slabThickness: params.slabThickness,
    wallHeight: params.wallHeight,
    slabHeight: params.slabHeight,
    measurementUnit: params.measurementUnit,
    wallFavId: params.wallFavouriteProfileId ?? -1,
    slabFavId: params.slabFavouriteProfileId ?? -1,
  }
}

export abstract class PlanSettingsDao {
  abstract findOneById(settingsId: number): Promise<PlanSettings | undefined>

  abstract findAllById(planSettingIds: number[]): Promise<PlanSettings[]>

  abstract findAllByFavouriteId(favouriteId: number): Promise<PlanSettings[]>

  abstract create(params: CreatePlanSettingsCommandParams, templateId: number): Promise<number>

  abstract update(planSettings: PlanSettings): Promise<PlanSettings>
}
