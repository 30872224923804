import { Injectable } from '@angular/core'
import { DataService } from '../../data.service'
import { SalesContactDao } from '../sales-contact.dao'

@Injectable()
export class SalesContactSqlDao extends SalesContactDao {
  constructor(private dataService: DataService) {
    super()
  }

  async create(mail: string): Promise<void> {
    await this.dataService.executeStatement('INSERT INTO SalesContact (mail) VALUES (?)', [mail])
  }

  async findAllLimit7(): Promise<string[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * FROM SalesContact ORDER BY id desc limit 7',
      []
    )
    const mails: string[] = []
    for (let i = 0; i < result.rows.length; i++) {
      mails.push(result.rows.item(i).mail)
    }
    return mails
  }
}
