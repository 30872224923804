/* tslint:disable */
/* eslint-disable */
export enum Country {
  At = 'at',
  Ca = 'ca',
  Be = 'be',
  De = 'de',
  It = 'it',
  Qa = 'qa',
  Lv = 'lv',
  Lt = 'lt',
  Nl = 'nl',
  No = 'no',
  Om = 'om',
  Pl = 'pl',
  Pt = 'pt',
  Ro = 'ro',
  Ch = 'ch',
  Sk = 'sk',
  Cz = 'cz',
  Tr = 'tr',
  Hu = 'hu',
  Ae = 'ae',
  Us = 'us',
  Gb = 'gb',
  Fr = 'fr',
  Ph = 'ph',
  Sg = 'sg',
  My = 'my',
  In = 'in',
  Au = 'au',
  Lb = 'lb',
  Eg = 'eg',
  Sa = 'sa',
  Fi = 'fi',
  Se = 'se',
  Dk = 'dk',
  Mx = 'mx',
  Pe = 'pe',
  Br = 'br',
  Cl = 'cl',
  Pa = 'pa',
  Co = 'co',
  Rs = 'rs',
  Bg = 'bg',
  Ee = 'ee',
  Gr = 'gr',
  Kw = 'kw',
  Hr = 'hr',
  Si = 'si',
  Es = 'es',
  Ie = 'ie'
}
