import { BlacklistArticleModel, CreateBlacklistArticleParams } from '../../../generated/efp-api'

export abstract class BlacklistArticleDao {
  public abstract createArticles(
    articles: CreateBlacklistArticleParams[]
  ): Promise<BlacklistArticleModel[]>

  public abstract delete(id: number): Promise<void>

  public abstract findAllByFavouriteProfileId(
    favouriteProfileId: number
  ): Promise<BlacklistArticleModel[]>
}
