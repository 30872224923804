import { Outline } from '../../model'
import { MeshXmlWriter } from './meshXmlWriter'
import {
  CURRENT_SCOPE,
  CYCLE_SYMBOL,
  FLOOR_BODY_SYMBOL,
  FLOOR_CYCLE_SYMBOL,
  ROOT_XML_ELEMENT,
  SLAB_CONTOUR,
  SLAB_LEVEL,
  SLAB_THICKNESS,
  TIPOS_UNIT_CONVERSION_FACTOR,
  WALL_CONTOUR,
  WALL_LEVEL_TOP,
} from './serializationConstants'

export class SlabMeshXmlWriter extends MeshXmlWriter {
  constructor(initialXml: string) {
    super(initialXml)
  }

  writeSlabTiposDataToXml(slabOutline: Outline[], slabHeight: number, slabThickness: number): void {
    this.setTagValue(CURRENT_SCOPE, 'slab')
    this.writeOutlineBounds(slabOutline)
    this.writeSlabHeightAndThicknessToXml(slabHeight, slabThickness)

    const slabContour = this.getElementBySelector(SLAB_CONTOUR)
    this.writeTiposLinesFromOutline(slabContour, slabOutline)

    this.writeFloorSymbolsForPathToTag(slabOutline, 2, slabHeight, slabThickness)
  }

  writeSupportWallDataToXml(
    slabOutline: Outline[],
    wallOutline: Outline[],
    slabHeight: number,
    slabThickness: number
  ): void {
    const wallContour = this.getElementBySelector(WALL_CONTOUR)
    this.writeTiposLinesFromOutline(wallContour, wallOutline)

    const height = slabHeight - slabThickness
    const symbolPoints = slabOutline.map((it) => it.start)
    this.setTagValue(WALL_LEVEL_TOP, (height * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(1))
    // Each slab outline has an accompanying wall edge, so we can use each slab start-point to write wall-symbols
    this.writeWallLevelSymbols(symbolPoints, height)
    this.ensureTagsExist([ROOT_XML_ELEMENT, CYCLE_SYMBOL])
    const symbolElement = this.getElementBySelector(CYCLE_SYMBOL)
    symbolPoints.forEach((symbolPoint) => {
      symbolElement.append(this.createCycleSymbolElement(symbolPoint, 0, false))
    })
  }

  private writeSlabHeightAndThicknessToXml(height: number, thickness: number): void {
    this.setTagValue(SLAB_LEVEL, (height * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(1))

    this.setTagValue(SLAB_THICKNESS, (thickness * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(1))
  }

  private writeFloorSymbolsForPathToTag(
    slabOutline: Outline[],
    cycleNumber: number,
    slabHeight: number,
    slabThickness: number
  ): void {
    this.ensureTagsExist([ROOT_XML_ELEMENT, FLOOR_BODY_SYMBOL])
    this.ensureTagsExist([ROOT_XML_ELEMENT, FLOOR_CYCLE_SYMBOL])
    this.setTagValue(FLOOR_BODY_SYMBOL, '')
    this.setTagValue(FLOOR_CYCLE_SYMBOL, '')
    const floorBodyParent = this.getElementBySelector(FLOOR_BODY_SYMBOL)
    const floorCycleParent = this.getElementBySelector(FLOOR_CYCLE_SYMBOL)

    slabOutline.forEach((outline) => {
      const cycleSymbol = this.createCycleSymbolElement(outline.start, cycleNumber, false)
      floorCycleParent.append(cycleSymbol)

      const bodySymbol = this.createSymbolElement(outline.start)
      const levelString = (slabHeight * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(6)
      const thicknessString = (slabThickness * TIPOS_UNIT_CONVERSION_FACTOR).toFixed(6)
      bodySymbol.append(
        this.createElementWithValue('Level', levelString),
        this.createElementWithValue('Thickness', thicknessString)
      )
      floorBodyParent.append(bodySymbol)
    })
  }
}
