import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ResetOnboardingModalComponent } from '../reset-onboarding-modal.component'

@Component({
  selector: 'efp-reset-onboarding-button',
  templateUrl: './reset-onboarding-button.component.html',
  styleUrls: ['./reset-onboarding-button.component.scss'],
})
export class ResetOnboardingButtonComponent {
  constructor(private readonly modalCtrl: ModalController) {}

  public async open(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ResetOnboardingModalComponent,
      backdropDismiss: false,
      cssClass: 'modal-responsive-max-w-400',
    })
    await modal.present()
    await modal.onDidDismiss()
  }
}
