import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

@Directive({
  selector: '[efpRecreateView]',
})
export class RecreateViewDirective implements OnChanges {
  @Input('efpRecreateView') key: unknown

  viewRef: EmbeddedViewRef<unknown> | undefined

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.key) {
      if (this.viewRef) {
        this.destroyView()
      }

      this.createView()
    }
  }

  private createView(): void {
    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef)
  }

  private destroyView(): void {
    this.viewRef?.destroy()
    this.viewRef = undefined
  }
}
