import { Injectable } from '@angular/core'
import { PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import {
  ACCESSORY_COLOR_1,
  ACCESSORY_COLOR_2,
  ACCESSORY_COLOR_3,
  ACCESSORY_COLOR_4,
  ACCESSORY_COLOR_5,
  ACCESSORY_COLOR_6,
  ACCESSORY_COLOR_7,
} from '../../../constants/colors'
import { formworkSystems } from '../../../constants/formworkSystems'
import { PlanAccessoryLine } from '../../../models/plan/PlanAccessoryLine'
import { Translation } from '../../../services/translation.service'
import { AccessoryLineDisplay } from '../model/AccessoryLineDisplay'
import { AccessoryLinesContainer } from '../model/AccessoryLinesContainer'
import { AccessoryPart } from '../model/AccessoryPart'
import { PlanAccessoryLineRepository } from '../../../repositories/plan-accessory-line.repository'
import { PlanOutlineRepository } from '../../../repositories/plan-outline.repository'

@Injectable({ providedIn: 'root' })
export class AccessoryService {
  private static colors = [
    ACCESSORY_COLOR_1,
    ACCESSORY_COLOR_2,
    ACCESSORY_COLOR_3,
    ACCESSORY_COLOR_4,
    ACCESSORY_COLOR_5,
    ACCESSORY_COLOR_6,
    ACCESSORY_COLOR_7,
  ]

  private static partTranslationPrefix = 'PART.'

  public constructor(
    private readonly planAccessoryLineRepository: PlanAccessoryLineRepository,
    private readonly planOutlineRepository: PlanOutlineRepository,
    private readonly translate: Translation
  ) {}

  private orderAndAssignColor(parts: AccessoryPart[]): void {
    parts.sort((a, b) => {
      const aName = this.translate.translate(AccessoryService.partTranslationPrefix + a.id)
      const bName = this.translate.translate(AccessoryService.partTranslationPrefix + b.id)
      return aName.localeCompare(bName)
    })
    parts.forEach((value, i) => {
      value.orderPosition = i + 1
      value.color = AccessoryService.getColor(i)
    })
  }

  //TODO move to own static file for use together with cycles ?
  private static getColor(i: number): paper.Color {
    return AccessoryService.colors[i % AccessoryService.colors.length]
  }

  /**
   * Returns already created accessory lines for a plan or creates them, if missing.
   */
  public async loadOrGenerateAccessoryLineDrawableForPlan(
    planId: number,
    formworkId: string
  ): Promise<AccessoryLinesContainer> {
    const planAccessoryLines = await this.loadAccessoryLinesForPlanAndFormwork(planId, formworkId)
    if (planAccessoryLines.length === 0) {
      planAccessoryLines.push(...(await this.generateAccessoryLines(planId)))
    }

    const supportedAccessories = this.getSupportedAccessories(formworkId)
    const lineDisplays = planAccessoryLines.map(
      (line) =>
        new AccessoryLineDisplay(
          line.id,
          new paper.Point(line.start),
          new paper.Point(line.end),
          AccessoryService.getAssignedAccessoriesFromString(
            supportedAccessories,
            line.accessoriesAsString
          )
        )
    )

    return new AccessoryLinesContainer(lineDisplays)
  }

  /**
   * Returns already created accessory lines for a plan.
   */
  public async loadAccessoryLinesForPlanAndFormwork(
    planId: number,
    formworkId: string
  ): Promise<PlanAccessoryLine[]> {
    const supportedAccessories = this.getSupportedAccessories(formworkId)
    const accesorylines = await this.planAccessoryLineRepository.getAccessoryLinesForPlan(planId)

    return accesorylines.map((line) => {
      line.accessoriesAsString = AccessoryService.getAssignedAccessoriesFromString(
        supportedAccessories,
        line.accessoriesAsString
      )
        .map((it) => it.id)
        .join(',')

      return line
    })
  }

  public async hasPlanAccessories(planId: number): Promise<boolean> {
    try {
      const planAccessories = await this.planAccessoryLineRepository.getAccessoriesForPlan(planId)

      return (
        planAccessories.length > 0 && planAccessories.some((it) => it.accessoriesAsString !== '')
      )
    } catch (e: unknown) {
      return false
    }
  }

  public static getAssignedAccessoriesFromString(
    supportedAccessories: AccessoryPart[],
    assignedAccessories: string
  ): AccessoryPart[] {
    const assigned = assignedAccessories.split(',')
    return supportedAccessories.filter((supported) => assigned.includes(supported.id))
  }

  public async updateAccessoryLineAssignments(
    accessoryLines: AccessoryLinesContainer
  ): Promise<void> {
    await this.planAccessoryLineRepository.updateAccessoryLineAssignments(
      accessoryLines.lines.map((line) => ({
        id: line.databaseId,
        accessoriesAsString: line.accessoriesAsString,
      }))
    )
  }

  public async removeAccessoriesForPlan(planId: number): Promise<void> {
    await this.planAccessoryLineRepository.deleteAccessoryLinesForPlan(planId)
  }

  public getSupportedAccessories(formworkId: string): AccessoryPart[] {
    const formworkSystem = formworkSystems.find((el) => el.id === formworkId)
    let parts: AccessoryPart[] = []
    if (formworkSystem) {
      parts = formworkSystem.accessories
      this.orderAndAssignColor(parts)
    }

    return parts
  }

  private async generateAccessoryLines(planId: number): Promise<PlanAccessoryLine[]> {
    const outline = await this.planOutlineRepository.findAllOutlinesByPlanId(planId)
    const newAccessoryLines: Omit<PlanAccessoryLine, 'id'>[] = outline
      .filter((it) => it.outlineType === PlanType.WALL)
      .map((it) => ({
        outlineId: it.id,
        planId,
        accessoriesAsString: '',
        start: it.start,
        end: it.end,
      }))

    return this.planAccessoryLineRepository.createAccessoryLines(newAccessoryLines)
  }
}
