import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { IonAccordionGroup } from '@ionic/angular'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AccordionFunctionsService } from '../../../services/accordion-functions.service'

@Component({
  selector: 'efp-accordion-group',
  templateUrl: './accordion-group.component.html',
})
export class AccordionGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() groupNames: Set<unknown> = new Set<unknown>()
  @Input() slug?: string
  @Input() expandFirstGroupByDefault = true
  @Input() expandSpecificAccordions?: string[]

  public initialGroupValue: string[] = []
  public accordionKeys: string[] = []

  @ViewChild('accordionGroup') accordionGroup?: IonAccordionGroup

  private readonly destroy$ = new Subject<void>()

  constructor(public accordionfunctions: AccordionFunctionsService) {}

  ngOnInit(): void {
    this.accordionfunctions.buttonCollapseClick$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.collapseAllAccordions())

    this.accordionfunctions.buttonExpandClick$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.expandAllAccordions())
  }

  ngOnChanges(changes: SimpleChanges): void {
    const groupNamesChanges = changes.groupNames
    const slugChanges = changes.slug
    if (
      groupNamesChanges.currentValue !== groupNamesChanges.previousValue ||
      slugChanges.currentValue !== slugChanges.previousValue
    ) {
      this.accordionKeys = this.getAccordionValues(this.groupNames, this.slug)

      if (this.expandSpecificAccordions) {
        this.initialGroupValue = this.expandSpecificAccordions
        this.expandFirstGroupByDefault = false
      } else if (this.expandFirstGroupByDefault) {
        this.initialGroupValue = this.accordionKeys.slice(0, 1)
      } else {
        this.initialGroupValue = []
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  getAccordionValues(groupArray: Set<unknown>, slug?: string): string[] {
    const accordionKeys: string[] = []
    groupArray.forEach((id) => {
      const prefix = slug ? slug + '-' : ''
      const accKey = prefix + id
      accordionKeys.push(accKey)
    })
    return accordionKeys
  }

  collapseAllAccordions = (): void => {
    if (this.accordionGroup) this.accordionGroup.value = undefined
  }

  expandAllAccordions = (): void => {
    if (this.accordionGroup) this.accordionGroup.value = this.accordionKeys
  }
}
