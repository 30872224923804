import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { Length, UnitOfLength } from '../../model'

@Component({
  selector: 'efp-scale-bar',
  templateUrl: 'scale-bar.component.html',
  styleUrls: ['scale-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleBarComponent implements OnChanges {
  @Input() zoomInMeters = 0

  @Input() scaleUnit: UnitOfLength = 'cm'

  @Input() scaleLabel: string | undefined

  public zoomScale = this.calculateZoomScale()

  ngOnChanges(): void {
    this.zoomScale = this.calculateZoomScale()
  }

  private calculateZoomScale(): Length {
    return new Length(this.zoomInMeters * 100, this.scaleUnit)
  }
}
