import { inject, Injectable } from '@angular/core'
import { ChangedResultPartsApiClient, ChangedResultPartsCommandParam } from '@efp/api'
import { PartListItem } from '../../../pages/result/components/part-list/model/partListItem'
import { ChangedResultPart } from '../../part-list-service'
import { ChangedResultPartDao } from '../changed-result-part.dao'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class ChangedResultPartHttpDao extends ChangedResultPartDao {
  private readonly changedResultPartApiClient = inject(ChangedResultPartsApiClient)

  public async findAllByPlanId(planId: number): Promise<ChangedResultPart[]> {
    return firstValueFrom(this.changedResultPartApiClient.getChangedResultPartsByPlan({ planId }))
  }

  public async deleteAllByPlanId(planId: number): Promise<void> {
    return firstValueFrom(
      this.changedResultPartApiClient.deleteChangedResultPartsByPlan({ planId })
    )
  }

  async deleteOldAndSaveNewByPlanId(
    _: number,
    partListParams: ChangedResultPartsCommandParam[]
  ): Promise<ChangedResultPart[]> {
    return firstValueFrom(
      this.changedResultPartApiClient.deleteOldAndSaveNewByPlanId({
        body: partListParams,
      })
    )
  }

  public static mapPartListToChangedResultPartsCommand(
    planId: number,
    partList: PartListItem[]
  ): ChangedResultPartsCommandParam[] {
    return partList.map((partListItem) => {
      return {
        planId,
        articleId: partListItem.part.articleId,
        amount: partListItem.orderAmount,
      }
    })
  }
}
