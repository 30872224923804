/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PlanCreateCommandParams } from '../models/plan-create-command-params';
import { PlanUpdateCommandParams } from '../models/plan-update-command-params';
import { PlansModel } from '../models/plans-model';

@Injectable({ providedIn: 'root' })
export class PlansApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllPlans()` */
  static readonly GetAllPlansPath = '/api/v1/plans';

  /**
   * Find Plans with multiple Options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlans$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlans$Plain$Response(
    params?: {
      planId?: number;
      projectId?: number;
      settingsId?: number;
      favouriteId?: number;
      stockId?: number;
      amount?: number;
      querySerialized?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PlansModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetAllPlansPath, 'get');
    if (params) {
      rb.query('planId', params.planId, {});
      rb.query('projectId', params.projectId, {});
      rb.query('settingsId', params.settingsId, {});
      rb.query('favouriteId', params.favouriteId, {});
      rb.query('stockId', params.stockId, {});
      rb.query('amount', params.amount, {});
      rb.query('querySerialized', params.querySerialized, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlansModel>>;
      })
    );
  }

  /**
   * Find Plans with multiple Options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlans$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlans$Plain(
    params?: {
      planId?: number;
      projectId?: number;
      settingsId?: number;
      favouriteId?: number;
      stockId?: number;
      amount?: number;
      querySerialized?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<PlansModel>> {
    return this.getAllPlans$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlansModel>>): Array<PlansModel> => r.body)
    );
  }

  /**
   * Find Plans with multiple Options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlans$Response(
    params?: {
      planId?: number;
      projectId?: number;
      settingsId?: number;
      favouriteId?: number;
      stockId?: number;
      amount?: number;
      querySerialized?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PlansModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetAllPlansPath, 'get');
    if (params) {
      rb.query('planId', params.planId, {});
      rb.query('projectId', params.projectId, {});
      rb.query('settingsId', params.settingsId, {});
      rb.query('favouriteId', params.favouriteId, {});
      rb.query('stockId', params.stockId, {});
      rb.query('amount', params.amount, {});
      rb.query('querySerialized', params.querySerialized, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlansModel>>;
      })
    );
  }

  /**
   * Find Plans with multiple Options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlans(
    params?: {
      planId?: number;
      projectId?: number;
      settingsId?: number;
      favouriteId?: number;
      stockId?: number;
      amount?: number;
      querySerialized?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<PlansModel>> {
    return this.getAllPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlansModel>>): Array<PlansModel> => r.body)
    );
  }

  /** Path part for operation `createPlan()` */
  static readonly CreatePlanPath = '/api/v1/plans';

  /**
   * Add plan entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlan$Plain$Response(
    params: {
      body: PlanCreateCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.CreatePlanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Add plan entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlan$Plain(
    params: {
      body: PlanCreateCommandParams
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.createPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /**
   * Add plan entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlan()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlan$Response(
    params: {
      body: PlanCreateCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.CreatePlanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Add plan entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlan$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlan(
    params: {
      body: PlanCreateCommandParams
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.createPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /** Path part for operation `getPlan()` */
  static readonly GetPlanPath = '/api/v1/plans/{planId}';

  /**
   * Get plan per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlan$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlan$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Get plan per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlan$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlan$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.getPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /**
   * Get plan per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Get plan per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.getPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /** Path part for operation `deletePlan()` */
  static readonly DeletePlanPath = '/api/v1/plans/{planId}';

  /**
   * Delete plan entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.DeletePlanPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete plan entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deletePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchPlan()` */
  static readonly PatchPlanPath = '/api/v1/plans/{planId}';

  /**
   * Update plan entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPlan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPlan$Plain$Response(
    params: {
      planId: number;
      resetCalculation?: boolean;
      body: PlanUpdateCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.PatchPlanPath, 'patch');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.query('resetCalculation', params.resetCalculation, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Update plan entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPlan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPlan$Plain(
    params: {
      planId: number;
      resetCalculation?: boolean;
      body: PlanUpdateCommandParams
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.patchPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /**
   * Update plan entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPlan()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPlan$Response(
    params: {
      planId: number;
      resetCalculation?: boolean;
      body: PlanUpdateCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.PatchPlanPath, 'patch');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.query('resetCalculation', params.resetCalculation, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Update plan entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPlan$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPlan(
    params: {
      planId: number;
      resetCalculation?: boolean;
      body: PlanUpdateCommandParams
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.patchPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /** Path part for operation `getPlansCount()` */
  static readonly GetPlansCountPath = '/api/v1/plans/count';

  /**
   * Counts plans or plans per stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansCount$Plain$Response(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetPlansCountPath, 'get');
    if (params) {
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Counts plans or plans per stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansCount$Plain(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.getPlansCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Counts plans or plans per stockId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansCount$Response(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlansApiClient.GetPlansCountPath, 'get');
    if (params) {
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Counts plans or plans per stockId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansCount(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.getPlansCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
