import { Injectable } from '@angular/core'
import { Router, RoutesRecognized } from '@angular/router'
import { filter, pairwise } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private previousUrl: string | null
  private currentUrl: string | null

  constructor(private router: Router) {
    this.currentUrl = this.router.url
    this.previousUrl = null

    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events) => {
        this.previousUrl = (events[0] as RoutesRecognized).urlAfterRedirects
        this.currentUrl = (events[1] as RoutesRecognized).urlAfterRedirects
      })
  }

  public getPreviousUrl(): string | null {
    return this.previousUrl
  }

  public getPreviousUrlWithoutQuery(): string | null {
    return this.previousUrl != null ? this.previousUrl.split('?')[0] : null
  }
}
