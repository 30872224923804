import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { OnboardingRepository } from '../../../../repositories/onboarding.repository'
import { OnboardingHintSeriesKey } from '../../../../models/onboarding/onboarding-hint-series-key'
import { OnboardingService } from '../../../../services/onboarding.service'
import { Router } from '@angular/router'

export enum ResetOnboardingOptions {
  RESET_ALL = 'RESET_ALL',
  RESET_SPEC = 'RESET_SPEC',
}

@Component({
  selector: 'efp-reset-onboarding-modal',
  templateUrl: './reset-onboarding-modal.component.html',
})
export class ResetOnboardingModalComponent implements OnInit {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly onboardingRepository: OnboardingRepository,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router
  ) {}
  public resetOptions = ResetOnboardingOptions

  public resetOnboardingTrackingForm = new FormGroup({
    resetOptions: new FormControl(ResetOnboardingOptions.RESET_ALL, {
      validators: [Validators.required],
      nonNullable: true,
    }),
  })
  private pageKey = this.router.url.split('/')[1]
  private seenOnboardingSeriesKeysOfPage?: OnboardingHintSeriesKey[]
  public onboardingSeriesKeysOfPage?: OnboardingHintSeriesKey[]

  async ngOnInit(): Promise<void> {
    await this.findOnboardingKeysToDelete()
  }

  public async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  public async onSubmit(): Promise<void> {
    const value = this.resetOnboardingTrackingForm.value.resetOptions

    if (value === ResetOnboardingOptions.RESET_ALL) await this.resetAllOnboardingTrackings()
    else {
      if (this.seenOnboardingSeriesKeysOfPage) {
        await Promise.all(
          this.seenOnboardingSeriesKeysOfPage.map(async (key) => {
            await this.onboardingRepository.deleteSeriesByKey(key)
          })
        )
      }
      this.triggerOnboardingHintsOfPage()
      await this.closeModal()
    }
  }

  public async resetAllOnboardingTrackings(): Promise<void> {
    await this.onboardingRepository.deleteAllOnboardingTracking()
    this.triggerOnboardingHintsOfPage()
    await this.closeModal()
  }

  private triggerOnboardingHintsOfPage(): void {
    if (this.onboardingSeriesKeysOfPage) {
      this.onboardingSeriesKeysOfPage.map((key) => {
        this.onboardingService.trigger(key)
      })
    }
  }

  private async findOnboardingKeysToDelete(): Promise<void> {
    this.onboardingSeriesKeysOfPage = await this.onboardingService.getOnboardingSeriesKeysForPage(
      this.pageKey
    )
    const seenOnboardings = await this.onboardingRepository.getCurrentOnboardingTracking()
    this.seenOnboardingSeriesKeysOfPage = this.onboardingSeriesKeysOfPage.filter((key) =>
      seenOnboardings.includes(key)
    )
  }
}
