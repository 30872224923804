import {
  OnboardingHintSeries,
  OnboardingPageKeysConfig,
} from '../../models/onboarding/onboarding-hint'
import {
  ONBOARDING_CHECK_AND_SHARE_RESULT_NATIVE,
  ONBOARDING_CHECK_AND_SHARE_RESULT_WEB,
} from './onboarding-check-and-share-result'
import {
  ONBOARDING_PLANNER_FIRST_STEPS_NATIVE,
  ONBOARDING_PLANNER_FIRST_STEPS_WEB,
} from './onboarding-first-planning-steps'
import {
  ONBOARDING_REPOSITION_T_WALL_NATIVE,
  ONBOARDING_REPOSITION_T_WALL_WEB,
} from './onboarding-reposition-t-wall'

export const ONBOARDING_CONFIG: OnboardingHintSeries[] = [
  ONBOARDING_PLANNER_FIRST_STEPS_WEB,
  ONBOARDING_PLANNER_FIRST_STEPS_NATIVE,
  ONBOARDING_CHECK_AND_SHARE_RESULT_WEB,
  ONBOARDING_CHECK_AND_SHARE_RESULT_NATIVE,
  ONBOARDING_REPOSITION_T_WALL_WEB,
  ONBOARDING_REPOSITION_T_WALL_NATIVE,
]

export const ONBOARDING_PAGE_KEYS_CONFIG: OnboardingPageKeysConfig = {
  web: {
    planner: [ONBOARDING_PLANNER_FIRST_STEPS_WEB.id, ONBOARDING_REPOSITION_T_WALL_WEB.id],
    result: [ONBOARDING_CHECK_AND_SHARE_RESULT_WEB.id],
  },
  native: {
    planner: [ONBOARDING_PLANNER_FIRST_STEPS_NATIVE.id, ONBOARDING_REPOSITION_T_WALL_NATIVE.id],
    result: [ONBOARDING_CHECK_AND_SHARE_RESULT_NATIVE.id],
  },
}
