/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ArticleModel } from '../models/article-model';
import { CreateArticleParams } from '../models/create-article-params';
import { Unit } from '../models/unit';
import { UpdateAllArticleParams } from '../models/update-all-article-params';
import { UpdateArticleParams } from '../models/update-article-params';

@Injectable({ providedIn: 'root' })
export class ArticlesApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllArticles()` */
  static readonly GetAllArticlesPath = '/api/v1/articles';

  /**
   * Get all articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllArticles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles$Plain$Response(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.GetAllArticlesPath, 'get');
    if (params) {
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleModel>>;
      })
    );
  }

  /**
   * Get all articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllArticles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles$Plain(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<Array<ArticleModel>> {
    return this.getAllArticles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleModel>>): Array<ArticleModel> => r.body)
    );
  }

  /**
   * Get all articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles$Response(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.GetAllArticlesPath, 'get');
    if (params) {
      rb.query('stockId', params.stockId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleModel>>;
      })
    );
  }

  /**
   * Get all articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles(
    params?: {
      stockId?: number;
    },
    context?: HttpContext
  ): Observable<Array<ArticleModel>> {
    return this.getAllArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleModel>>): Array<ArticleModel> => r.body)
    );
  }

  /** Path part for operation `createArticles()` */
  static readonly CreateArticlesPath = '/api/v1/articles';

  /**
   * Add new articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArticles$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArticles$Plain$Response(
    params: {
      body: Array<CreateArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<number>>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.CreateArticlesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<number>>;
      })
    );
  }

  /**
   * Add new articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArticles$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArticles$Plain(
    params: {
      body: Array<CreateArticleParams>
    },
    context?: HttpContext
  ): Observable<Array<number>> {
    return this.createArticles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /**
   * Add new articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArticles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArticles$Response(
    params: {
      body: Array<CreateArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<number>>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.CreateArticlesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<number>>;
      })
    );
  }

  /**
   * Add new articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArticles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createArticles(
    params: {
      body: Array<CreateArticleParams>
    },
    context?: HttpContext
  ): Observable<Array<number>> {
    return this.createArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `patchAllArticles()` */
  static readonly PatchAllArticlesPath = '/api/v1/articles';

  /**
   * Patch all existing articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAllArticles$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAllArticles$Plain$Response(
    params: {
      body: Array<UpdateAllArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.PatchAllArticlesPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch all existing articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAllArticles$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAllArticles$Plain(
    params: {
      body: Array<UpdateAllArticleParams>
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.patchAllArticles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Patch all existing articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAllArticles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAllArticles$Response(
    params: {
      body: Array<UpdateAllArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.PatchAllArticlesPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch all existing articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAllArticles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAllArticles(
    params: {
      body: Array<UpdateAllArticleParams>
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.patchAllArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /** Path part for operation `deleteArticle()` */
  static readonly DeleteArticlePath = '/api/v1/articles/{id}';

  /**
   * Delete existing article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArticle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle$Plain$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.DeleteArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete existing article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteArticle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle$Plain(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteArticle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Delete existing article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.DeleteArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete existing article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /** Path part for operation `updateArticle()` */
  static readonly UpdateArticlePath = '/api/v1/articles/{id}';

  /**
   * Update existing article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArticle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArticle$Plain$Response(
    params: {
      id: number;
      body: UpdateArticleParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.UpdateArticlePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Update existing article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArticle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArticle$Plain(
    params: {
      id: number;
      body: UpdateArticleParams
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.updateArticle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Update existing article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArticle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArticle$Response(
    params: {
      id: number;
      body: UpdateArticleParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, ArticlesApiClient.UpdateArticlePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Update existing article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArticle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArticle(
    params: {
      id: number;
      body: UpdateArticleParams
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.updateArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

}
