import {
  addEntities,
  deleteEntities,
  deleteEntitiesByPredicate,
  getAllEntitiesApply,
  getEntity,
  selectAllEntities,
  setEntities,
  updateEntities,
  updateEntitiesIds,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { createStore } from '@ngneat/elf'
import { CreateStockCommandParams } from '@efp/api'
import { Stock } from '../models/stock'
import { ArticleDao } from '../services/dao/article.dao'
import { StockDao } from '../services/dao/stock.dao'
import { ArticleRepository } from './article.repository'
import { Article } from '../models/article'

export const stockStore = createStore({ name: 'stock' }, withEntities<Stock>())

@Injectable({
  providedIn: 'root',
})
export class StockRepository {
  private hasLoadedAll = false

  public readonly stocks$ = stockStore.pipe(selectAllEntities())

  constructor(
    private readonly stockDao: StockDao,
    private readonly articleRepository: ArticleRepository,
    private readonly articleDao: ArticleDao
  ) {}

  public async createStockWithArticles(
    params: CreateStockCommandParams,
    useOptimisticCreation: boolean
  ): Promise<Stock | null> {
    if (useOptimisticCreation) {
      const tempId = stockStore.state.ids.length > 0 ? Math.max(...stockStore.state.ids) + 1 : 1
      const articles: Article[] | undefined = params.articles?.map((a) => {
        return this.articleRepository.createOptimistic({
          id: -1,
          stockId: tempId,
          articleId: a.articleId,
          name: a.name,
          amount: a.amount,
        })
      })

      const newStock: Stock = {
        id: tempId,
        name: params.name,
        date: new Date(),
        articles,
      }

      stockStore.update(addEntities(newStock))

      try {
        const createdStock = await this.stockDao.createStockWithArticles(params)
        if (createdStock.id !== tempId) {
          stockStore.update(
            updateEntitiesIds(tempId, createdStock.id),
            updateEntities(createdStock.id, createdStock)
          )
        }

        createdStock.articles?.forEach((article) => {
          const tempArticle = articles?.filter(
            (updatedArticles) => updatedArticles.articleId === article.articleId
          )
          if (!tempArticle || tempArticle.length !== 1) {
            throw new Error('Article not found')
          }

          if (article.id !== tempArticle[0].id) {
            this.articleRepository.updateOptimistic(article, tempArticle[0].id)
          }
        })

        return createdStock
      } catch (e: unknown) {
        stockStore.update(deleteEntities(tempId))
        const tempArticleIds = articles?.map((a) => a.id)
        if (tempArticleIds) {
          this.articleRepository.deleteOptimistic(tempArticleIds)
        }
        throw e
      }
    } else {
      const stock = await this.stockDao.createStockWithArticles(params)
      stockStore.update(addEntities(stock))
      this.articleRepository.updateStockArticles(stock.id, stock.articles)
      return stock
    }
  }

  public async fetchAll(): Promise<Stock[]> {
    let stocks = stockStore.query(getAllEntitiesApply({}))

    if (stocks && stocks.length > 0 && this.hasLoadedAll) {
      stocks = await Promise.all(
        stocks.map(async (it) => {
          it.articles = await this.articleRepository.findAllByStockId(it.id)
          return it
        })
      )
      stockStore.update(upsertEntities(stocks))
      return stocks
    } else {
      stocks = await this.stockDao.findAll()
      stockStore.update(setEntities(stocks))
      this.hasLoadedAll = true
      return stocks
    }
  }

  public async findAllRelatedPlanAndProjectNamesById(stockId: number): Promise<string[]> {
    return this.stockDao.findAllRelatedPlanAndProjectNamesById(stockId)
  }

  public async createArticlesFromCatalogArticles(
    stockId: number,
    catalogArticles: Article[]
  ): Promise<number[]> {
    const articleIds = await this.articleDao.createArticles(catalogArticles)
    await this.fetchStockWithArticles(stockId)
    return articleIds
  }

  public async loadByIdWithArticles(id: number): Promise<Stock | undefined> {
    let stock = stockStore.query(getEntity(id))

    if (!stock) {
      stock = await this.fetchStockWithArticles(id)
    } else {
      stock.articles = await this.articleRepository.findAllByStockId(stock.id)
    }

    return stock
  }

  private async fetchStockWithArticles(id: number): Promise<Stock | undefined> {
    const stock = await this.stockDao.findOneByIdWithArticles(id)

    if (stock) {
      stockStore.update(upsertEntities(stock))
      this.articleRepository.updateStockArticles(stock.id, stock.articles)
    } else {
      stockStore.update(deleteEntitiesByPredicate((e) => e.id === id))
    }
    return stock
  }

  public async delete(stock: Stock): Promise<void> {
    await this.stockDao.delete(stock)
    stockStore.update(deleteEntities(stock.id))
  }

  public async updateName(stock: Stock, name: string): Promise<void> {
    await this.stockDao.updateName(stock, name)
    stockStore.update(updateEntities(stock.id, { name }))
  }
}
