import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'efp-request-sync-error-message-popup',
  templateUrl: './request-sync-error-message-popup.component.html',
  styleUrls: ['./request-sync-error-message-popup.component.scss'],
})
export class RequestSyncErrorMessagePopupComponent {
  @Output() readonly dismissed = new EventEmitter<void>()

  constructor() {}

  onDismiss(): void {
    this.dismissed.emit()
  }
}
