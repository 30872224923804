import paper from 'paper/dist/paper-core'
import { Edge } from '../edge/Edge'
import { Mesh } from '../mesh/Mesh'
import { asMeshPoint, MeshPoint } from '../mesh/MeshPoint'

export interface SnapEdgeInfo {
  point: paper.Point
  edge: Edge
}

export class ExtendedMeshPoint extends MeshPoint {
  movedPoint: MeshPoint
  insertionPoint: paper.Point
  staticPoint?: MeshPoint

  constructor(
    point: { x: number; y: number },
    mesh: Mesh,
    movedPoint: MeshPoint,
    insertionPoint: paper.Point,
    staticPoint?: MeshPoint
  ) {
    super(point, mesh)
    this.movedPoint = movedPoint
    this.insertionPoint = insertionPoint
    this.staticPoint = staticPoint
  }
}

export type MeshSnapResult = MeshPoint | SnapEdgeInfo | undefined

export const isSnapEdgeInfo = (point: MeshSnapResult): point is SnapEdgeInfo =>
  !!point && (point as SnapEdgeInfo).edge !== undefined

export const isMeshPoint = (point: MeshSnapResult): point is MeshPoint =>
  !!point && (point as MeshPoint).edges !== undefined

export const toExtendedMeshPoint = (
  point: MeshPoint | paper.Point,
  mesh: Mesh,
  movedPoint: MeshPoint,
  insertionPoint: paper.Point,
  staticPoint?: MeshPoint
): ExtendedMeshPoint =>
  new ExtendedMeshPoint(asMeshPoint(point, mesh), mesh, movedPoint, insertionPoint, staticPoint)

export type SnapResult = ExtendedMeshPoint | MeshPoint | paper.Point | undefined

export const isExtendedMeshPoint = (point: SnapResult): point is ExtendedMeshPoint =>
  !!point && (point as ExtendedMeshPoint).movedPoint !== undefined
