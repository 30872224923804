import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { SharedModule } from '../../shared/shared.module'
import { CatalogPage } from './catalog.page'
import { CatalogListComponent } from './components/catalog-list/catalog-list.component'
import { CatalogSearchComponent } from './components/catalog-search/catalog-search.component'

@NgModule({
  imports: [CommonModule, IonicModule, SharedModule, TranslateModule, ReactiveFormsModule],
  exports: [CatalogPage],
  declarations: [CatalogPage, CatalogSearchComponent, CatalogListComponent],
  providers: [],
})
export class CatalogModule {}
