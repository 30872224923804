<div class="d-flex ion-justify-content-between mobile">
  <ion-button
    tabindex="-1"
    fill="clear"
    class="actionButton"
    (click)="cancel()"
    data-test-selector="bottom-menu-cancel"
    >{{ 'GENERAL.CANCEL' | translate }}</ion-button
  >
  <ion-button
    tabindex="-1"
    fill="clear"
    class="actionButton"
    (click)="applyChanges()"
    [disabled]="applyChangesDisabled"
    data-test-selector="bottom-menu-apply">
    {{ 'GENERAL.APPLY' | translate }}
  </ion-button>
</div>
<div class="d-flex ion-justify-content-end web">
  <ion-button
    tabindex="-1"
    fill="outline"
    class="actionButton ion-padding-end"
    (click)="cancel()"
    data-test-selector="bottom-menu-cancel"
    >{{ 'GENERAL.CANCEL' | translate }}</ion-button
  >
  <ion-button
    tabindex="-1"
    fill="solid"
    class="actionButton"
    (click)="applyChanges()"
    [disabled]="applyChangesDisabled"
    data-test-selector="bottom-menu-apply">
    {{ 'GENERAL.APPLY' | translate }}
  </ion-button>
</div>
