import { inject, Injectable } from '@angular/core'
import {
  CreatePlanSettingsCommandParams,
  PlanSettingsApiClient,
  PlanSettingsModel,
  UnitOfLength,
} from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { PlanSettings } from '../../../models/planSettings'
import { PlanSettingsDao } from '../plan-settings.dao'

@Injectable()
export class PlanSettingsHttpDao extends PlanSettingsDao {
  private readonly planSettingsApiClient = inject(PlanSettingsApiClient)

  private mapModelToPlanSettings(model: PlanSettingsModel): PlanSettings {
    return {
      id: model.id,
      angleRastering: model.angleRastering,
      formworkSlab: model.formworkSlab,
      formworkWall: model.formworkWall,
      lengthRastering: model.lengthRastering,
      measurementUnit: model.measurementUnit,
      slabFavId: model.slabFavouriteProfileId ?? null,
      slabHeight: model.slabHeight,
      slabThickness: model.slabThickness,
      wallFavId: model.wallFavouriteProfileId ?? null,
      wallHeight: model.wallHeight,
      wallThickness: model.wallThickness,
    }
  }

  async create(params: CreatePlanSettingsCommandParams, templateId: number): Promise<number> {
    return await firstValueFrom(
      this.planSettingsApiClient.createPlanSettings({
        body: {
          templateId,
          planSettings: params,
        },
      })
    )
  }

  async findAllByFavouriteId(favouriteId: number): Promise<PlanSettings[]> {
    const planSettingsModels = await firstValueFrom(
      this.planSettingsApiClient.getAllBySettingIds({
        favouriteId,
      })
    )

    return planSettingsModels.map((model) => this.mapModelToPlanSettings(model))
  }

  async findAllById(planSettingIds: number[]): Promise<PlanSettings[]> {
    const planSettingsModels = await firstValueFrom(
      this.planSettingsApiClient.getAllBySettingIds({
        settingsIds: planSettingIds,
      })
    )

    return planSettingsModels.map((model) => this.mapModelToPlanSettings(model))
  }

  async findOneById(settingsId: number): Promise<PlanSettings | undefined> {
    const planSettingsModel = await firstValueFrom(
      this.planSettingsApiClient.getPlanSettings({
        planSettingsId: settingsId,
      })
    )

    return this.mapModelToPlanSettings(planSettingsModel)
  }

  async update(planSettings: PlanSettings): Promise<PlanSettings> {
    const planSettingsModel = await firstValueFrom(
      this.planSettingsApiClient.updatePlanSettings({
        planSettingsId: planSettings.id,
        body: {
          formworkWall: planSettings.formworkWall,
          formworkSlab: planSettings.formworkSlab,
          angleRastering: planSettings.angleRastering,
          lengthRastering: planSettings.lengthRastering,
          wallThickness: planSettings.wallThickness,
          slabThickness: planSettings.slabThickness,
          wallHeight: planSettings.wallHeight,
          slabHeight: planSettings.slabHeight,
          measurementUnit: planSettings.measurementUnit as UnitOfLength,
          wallFavouriteProfileId: planSettings.wallFavId,
          slabFavouriteProfileId: planSettings.slabFavId,
        },
      })
    )

    return this.mapModelToPlanSettings(planSettingsModel)
  }
}
