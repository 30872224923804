import { Edge, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import {
  DEFAULT_TOUCH_AREA_COLOR,
  ERROR_TOUCH_AREA_COLOR,
  HOVER_COLOR,
  HOVER_OUTLINE_COLOR,
  SLAB_TOUCH_AREA_COLOR,
} from '../../../../constants/colors'
import { Hoverable } from '../../model/paper/Hoverable'

const SLAB_STROKE_WIDTH = 20
const HIDDEN_OPACITY = 0.001
const SHOWN_OPACITY = 1

export class HighlightArea extends paper.Path implements Hoverable {
  public isSelected = false

  public constructor(public readonly edge: Edge, private readonly planType: PlanType) {
    super()

    this.edge = edge

    if (planType === PlanType.WALL) {
      this.add(...edge.getOutlinePath().segments)
      this.strokeColor = this.edge.isValid ? HOVER_OUTLINE_COLOR : ERROR_TOUCH_AREA_COLOR
    } else {
      this.add(
        ...new Edge(this.edge.startPoint, this.edge.endPoint, SLAB_STROKE_WIDTH).getOutlinePath()
          .segments
      )
    }

    this.closed = true
    this.visible = true
    this.updateColor()
  }

  public changeSelection(): void {
    switch (this.isSelected) {
      case true:
        this.hide()
        break
      case false:
        this.show()
        break
      default:
        break
    }
  }

  public hide(): void {
    this.isSelected = false
    this.updateColor()
  }

  public show(): void {
    this.isSelected = true
    this.updateColor()
  }

  public hover(): void {
    if (!this.isSelected) {
      this.fillColor = HOVER_COLOR
      if (this.planType === PlanType.SLAB) {
        this.strokeColor = HOVER_COLOR
      }
      this.opacity = SHOWN_OPACITY
    }
  }

  public unhover(): void {
    if (!this.isSelected) {
      this.hide()
    }
  }

  private updateColor(): void {
    if (!this.edge.isValid) {
      this.opacity = SHOWN_OPACITY
      this.strokeColor = this.fillColor = ERROR_TOUCH_AREA_COLOR
      this.strokeColor = ERROR_TOUCH_AREA_COLOR
    } else if (this.isSelected) {
      this.opacity = SHOWN_OPACITY
      this.fillColor =
        this.planType === PlanType.WALL ? DEFAULT_TOUCH_AREA_COLOR : SLAB_TOUCH_AREA_COLOR
    } else {
      this.opacity = HIDDEN_OPACITY
    }
  }
}
