import { NgModule } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { AppSettingDao } from './app-settings.dao'
import { ArticleDao } from './article.dao'
import { ChangedResultPartDao } from './changed-result-part.dao'
import { CycleDao } from './cycle.dao'
import { FavouriteDao } from './favourite.dao'
import { AppSettingHttpDao } from './http/app-settings.http-dao'
import { ArticleHttpDao } from './http/article.http-dao'
import { ChangedResultPartHttpDao } from './http/changed-result-part.http-dao'
import { CycleHttpDao } from './http/cycle.http-dao'
import { FavouriteHttpDao } from './http/favourite.http-dao'
import { OnboardingHttpDao } from './http/onboarding.http-dao'
import { PlanAccessoryLineHttpDao } from './http/plan-accessory-line.http-dao'
import { PlanCreationHttpDao } from './http/plan-creation.http-dao'
import { PlanOutlineHttpDao } from './http/plan-outline.http-dao'
import { PlanResultHttpDao } from './http/plan-result.http-dao'
import { PlanSettingsHttpDao } from './http/plan-settings.http-dao'
import { PlanVisibilitySettingsHttpDao } from './http/plan-visibility-settings.http-dao'
import { PlanHttpDao } from './http/plan.http-dao'
import { ProjectHttpDao } from './http/project.http-dao'
import { SalesContactHttpDao } from './http/sales-contact.http-dao'
import { ScreenshotHttpDao } from './http/screenshot.http-dao'
import { StockHttpDao } from './http/stock.http-dao'
import { UserHttpDao } from './http/user.http-dao'
import { OnboardingDao } from './onboarding.dao'
import { PlanAccessoryLineDao } from './plan-accessory-line.dao'
import { PlanCreationDao } from './plan-creation.dao'
import { PlanOutlineDao } from './plan-outline.dao'
import { PlanResultDao } from './plan-result.dao'
import { PlanSettingsDao } from './plan-settings.dao'
import { PlanVisibilitySettingsDao } from './plan-visibility-settings.dao'
import { PlanDao } from './plan.dao'
import { ProjectDao } from './project.dao'
import { SalesContactDao } from './sales-contact.dao'
import { ScreenshotDao } from './screenshot.dao'
import { AppSettingSqlDao } from './sql/app-settings.sql-dao'
import { ArticleSqlDao } from './sql/article.sql-dao'
import { ChangedResultPartSqlDao } from './sql/changed-result-part.sql-dao'
import { CycleSqlDao } from './sql/cycle.sql-dao'
import { FavouriteSqlDao } from './sql/favourite.sql-dao'
import { OnboardingSqlDao } from './sql/onboarding.sql-dao'
import { PlanAccessoryLineSqlDao } from './sql/plan-accessory-line.sql-dao'
import { PlanCreationSqlDao } from './sql/plan-creation.sql-dao'
import { PlanOutlineSqlDao } from './sql/plan-outline.sql-dao'
import { PlanResultSqlDao } from './sql/plan-result.sql-dao'
import { PlanSettingsSqlDao } from './sql/plan-settings.sql-dao'
import { PlanVisibilitySettingsSqlDao } from './sql/plan-visibility-settings.sql-dao'
import { PlanSqlDao } from './sql/plan.sql-dao'
import { ProjectSqlDao } from './sql/project.sql-dao'
import { SalesContactSqlDao } from './sql/sales-contact.sql-dao'
import { ScreenshotSqlDao } from './sql/screenshot.sql-dao'
import { StockSqlDao } from './sql/stock.sql-dao'
import { UserSqlDao } from './sql/user.sql-dao'
import { StockDao } from './stock.dao'
import { UserDao } from './user.dao'
import { PlanResultResetCalculationSqlDao } from './sql/plan-result-reset-calculation.sql-dao'
import { PlanResultResetCalculationDao } from './plan-result-reset-calculation.dao'
import { PlanResultResetCalculationHttpDao } from './http/plan-result-reset-calculation.http-dao'
import { BlacklistArticleDao } from './blacklist-article.dao'
import { BlacklistArticleHttpDao } from './http/blacklist-article.http-dao'
import { BlacklistArticleSqlDao } from './sql/blacklist-article.sql-dao'

const isNative = Capacitor.isNativePlatform()

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: AppSettingDao,
      useClass: isNative ? AppSettingSqlDao : AppSettingHttpDao,
    },
    {
      provide: UserDao,
      useClass: isNative ? UserSqlDao : UserHttpDao,
    },
    {
      provide: FavouriteDao,
      useClass: isNative ? FavouriteSqlDao : FavouriteHttpDao,
    },
    {
      provide: PlanSettingsDao,
      useClass: isNative ? PlanSettingsSqlDao : PlanSettingsHttpDao,
    },
    {
      provide: PlanVisibilitySettingsDao,
      useClass: isNative ? PlanVisibilitySettingsSqlDao : PlanVisibilitySettingsHttpDao,
    },
    {
      provide: PlanDao,
      useClass: isNative ? PlanSqlDao : PlanHttpDao,
    },
    {
      provide: PlanCreationDao,
      useClass: isNative ? PlanCreationSqlDao : PlanCreationHttpDao,
    },
    {
      provide: ProjectDao,
      useClass: isNative ? ProjectSqlDao : ProjectHttpDao,
    },
    {
      provide: ArticleDao,
      useClass: isNative ? ArticleSqlDao : ArticleHttpDao,
    },
    {
      provide: StockDao,
      useClass: isNative ? StockSqlDao : StockHttpDao,
    },
    {
      provide: CycleDao,
      useClass: isNative ? CycleSqlDao : CycleHttpDao,
    },
    {
      provide: PlanOutlineDao,
      useClass: isNative ? PlanOutlineSqlDao : PlanOutlineHttpDao,
    },
    {
      provide: PlanAccessoryLineDao,
      useClass: isNative ? PlanAccessoryLineSqlDao : PlanAccessoryLineHttpDao,
    },
    {
      provide: ChangedResultPartDao,
      useClass: isNative ? ChangedResultPartSqlDao : ChangedResultPartHttpDao,
    },
    {
      provide: SalesContactDao,
      useClass: isNative ? SalesContactSqlDao : SalesContactHttpDao,
    },
    {
      provide: ScreenshotDao,
      useClass: isNative ? ScreenshotSqlDao : ScreenshotHttpDao,
    },
    {
      provide: PlanResultDao,
      useClass: isNative ? PlanResultSqlDao : PlanResultHttpDao,
    },
    {
      provide: PlanResultResetCalculationDao,
      useClass: isNative ? PlanResultResetCalculationSqlDao : PlanResultResetCalculationHttpDao,
    },
    {
      provide: OnboardingDao,
      useClass: isNative ? OnboardingSqlDao : OnboardingHttpDao,
    },
    {
      provide: BlacklistArticleDao,
      useClass: isNative ? BlacklistArticleSqlDao : BlacklistArticleHttpDao,
    },
  ],
})
export class DaoModule {}
