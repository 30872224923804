<ion-content>
  <ion-item
    lines="none"
    *ngIf="isPlanner"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.AUXILIARY_GUIDELINES)"
    data-test-selector="planner-visibility-menu-auxiliary-guidelines">
    <ion-icon slot="start" src="assets/icon/guidelines.svg"></ion-icon>
    <ion-label>{{ 'SETTINGS.VISIBILITY.GUIDELINES' | translate }}</ion-label>
    <ion-toggle [checked]="planVisibilitySettings.showAuxiliaryLines"></ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    *ngIf="inDeformationMode"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.GRIP_POINTS)"
    data-test-selector="planner-visibility-menu-grid-points">
    <ion-icon slot="start" src="assets/icon/meshpoints.svg"></ion-icon>
    <ion-label>{{ 'SETTINGS.VISIBILITY.MESHPOINTS' | translate }}</ion-label>
    <ion-toggle [checked]="planVisibilitySettings.showGripPoints"></ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.ANGLES)"
    data-test-selector="planner-visibility-menu-angles">
    <ion-icon slot="start" src="assets/icon/angle.svg"></ion-icon>
    <ion-label>{{ 'SETTINGS.VISIBILITY.ANGLES' | translate }}</ion-label>
    <ion-toggle [checked]="planVisibilitySettings.showAngles"></ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.DIMENSIONS)"
    data-test-selector="planner-visibility-menu-dimensions">
    <ion-icon slot="start" src="assets/icon/dimensions.svg"></ion-icon>
    <ion-label>{{ 'SETTINGS.VISIBILITY.DIMENSIONS' | translate }}</ion-label>
    <ion-toggle [checked]="planVisibilitySettings.showDimensions"></ion-toggle>
  </ion-item>
  <ion-item
    *ngIf="!isAccessories && isMobile"
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.MAGNIFIER)"
    data-test-selector="planner-visibility-menu-magnifier">
    <ion-icon slot="start" src="assets/icon/magnifier.svg"></ion-icon>
    <ion-label>{{ 'SETTINGS.MAGNIFIER' | translate }}</ion-label>
    <ion-toggle [checked]="planVisibilitySettings.showMagnifier"></ion-toggle>
  </ion-item>
</ion-content>
