import { Injectable } from '@angular/core'
import { PlanResultApiClient } from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { PlanResultResetCalculationDao } from '../plan-result-reset-calculation.dao'

@Injectable()
export class PlanResultResetCalculationHttpDao extends PlanResultResetCalculationDao {
  constructor(private readonly planResultApiClient: PlanResultApiClient) {
    super()
  }

  /**
   * Remove plan results, updates the current plan navigation step and remove changed result parts & screenshots.
   */
  public async resetCalculation(planId: number): Promise<void> {
    await firstValueFrom(this.planResultApiClient.deletePlanResult({ planId }))
  }
}
