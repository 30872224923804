import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import paper from 'paper/dist/paper-core'
import { animationFrameScheduler, Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators'
import { DimensionChange } from '../../directives'
import { PlannerInteractionEvent, UnitOfLength } from '../../model'
import { PlannerStateService } from '../../services'
import { Margins, ZoomService } from '../../services/zoom.service'
import { runInZone } from '../../utils/zone.util'

@Component({
  selector: 'efp-planner',
  templateUrl: 'planner.component.html',
  styleUrls: ['planner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannerComponent implements OnDestroy, OnChanges {
  @ViewChild('plannerCanvas') plannerCanvas!: ElementRef

  @Input() set initialize(value: boolean) {
    if (value && !this.initialized) {
      this.initPlanner()
    } else if (!value && this.initialized) {
      this.resetPlanner()
    }
  }

  @Input() showMagnifier = false
  @Input() showScale = false
  @Input() scaleUnit: UnitOfLength = 'cm'
  @Input() scaleLabel = 'Scale'
  @Input() updateMagnifier?: Subject<void>
  @Input() flipY = false
  @Input() middleMouseButtonAsPrimary = false

  @Input() set minZoomLevel(value: number) {
    this.zoom.minZoomLevel = value
  }

  @Input() set maxZoomLevel(value: number) {
    this.zoom.maxZoomLevel = value
  }

  /**
   * Emits once Paper.js is initialized and drawing is ready.
   */
  @Output() readonly ready = new EventEmitter<void>()

  @Output() readonly interactionDown = new EventEmitter<PlannerInteractionEvent>()
  @Output() readonly interactionDragged = new EventEmitter<PlannerInteractionEvent>()
  @Output() readonly interactionUp = new EventEmitter<PlannerInteractionEvent>()
  @Output() readonly interactionMoved = new EventEmitter<PlannerInteractionEvent>()

  /**
   * Fired if the current zoom level changes. Contains the current zoom in meters.
   */
  @Output() readonly zoomChanged = new EventEmitter<number>()
  @Output() readonly panChanged = new EventEmitter<paper.Point>()

  initialized = false
  pointToMagnify = new paper.Point(0, 0)

  public zoomInMeters$: Observable<number>

  public get canvasBounds(): paper.Rectangle {
    return this.zoom.view?.bounds ?? new paper.Rectangle(0, 0, 0, 0)
  }

  public get canvasZoom(): number {
    return this.zoom.view?.zoom ?? 1
  }

  private zoom = new ZoomService()
  private isZooming = false
  private isDrawing = false
  private width?: number
  private height?: number
  private updateMagnifierSubscription?: Subscription
  private readonly destroy$ = new Subject<void>()

  constructor(public plannerState: PlannerStateService, private readonly ngZone: NgZone) {
    this.zoomInMeters$ = this.zoom.zoomInMeters$.pipe(
      throttleTime(50, animationFrameScheduler, { leading: true, trailing: true }),
      runInZone(this.ngZone)
    )

    this.zoom.zoomInMeters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((zoomInMeters) => this.zoomChanged.next(zoomInMeters))
    this.zoom.pan$.pipe(takeUntil(this.destroy$)).subscribe((pan) => this.panChanged.next(pan))
    this.plannerState.interactionDown$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.onMouseDown(event))
    this.plannerState.interactionDragged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.onMouseDragged(event))
    this.plannerState.interactionUp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.onMouseUp(event))
    this.plannerState.interactionMoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.onMouseMoved(event))
    this.plannerState.ready$.pipe(takeUntil(this.destroy$)).subscribe(() => this.ready.next())
  }

  ngOnChanges(changes: SimpleChanges): void {
    const updateMagnifier = changes.updateMagnifier
    if (updateMagnifier && this.updateMagnifier) {
      this.updateMagnifierSubscription?.unsubscribe()
      this.updateMagnifierSubscription = this.updateMagnifier
        .pipe(takeUntil(this.destroy$), debounceTime(50, animationFrameScheduler))
        .subscribe(() => (this.pointToMagnify = this.pointToMagnify.clone()))
    }
    if (changes.flipY) {
      this.flipView()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  updateCanvasSize(e: DimensionChange): void {
    this.width = e.width
    this.height = e.height

    if (this.plannerState.paper?.view) {
      this.plannerState.paper.view.viewSize = new paper.Size(this.width, this.height)
    }
  }

  private initPlanner(): void {
    this.plannerState.init(
      this.plannerCanvas.nativeElement,
      (bounds, margins) => this.centerView(bounds, margins),
      (zoomInMeters, pan) => this.setZoomAndPan(zoomInMeters, pan),
      this.middleMouseButtonAsPrimary
    )

    this.zoom.init(this.plannerState.paper.view)
    this.initView()
    this.flipView()

    this.initialized = true
  }

  private initView(): void {
    if (this.width != null && this.width !== 0 && this.height != null && this.height !== 0) {
      this.plannerState.paper.view.viewSize = new paper.Size(this.width, this.height)
    }
  }

  private flipView(): void {
    if (this.plannerState.paper?.view) {
      this.plannerState.paper.view.scale(1, this.flipY ? -1 : 1)
      this.zoom.flipY = this.flipY
    }
  }

  private centerView(modelBounds: paper.Rectangle, margins?: Margins): void {
    this.zoom.resetScale(modelBounds, margins)
  }

  private setZoomAndPan(zoomInMeters: number, pan?: paper.Point): void {
    this.zoom.setZoomAndPan(zoomInMeters, pan)
  }

  private resetPlanner(): void {
    this.initialized = false
    this.zoom.reset()
    this.plannerState.resetPaper()
  }

  private onMouseDown(event: PlannerInteractionEvent): void {
    this.interactionDown.next(event)
  }

  private onMouseMoved(event: PlannerInteractionEvent): void {
    this.interactionMoved.next(event)
  }

  private onMouseDragged(event: PlannerInteractionEvent): void {
    if (!event.primaryInteraction && !this.isDrawing) {
      this.isZooming = true
    } else if (!this.isZooming) {
      this.isDrawing = true
      this.pointToMagnify = event.point

      this.zoom.isActive = false
      this.interactionDragged.next(event)
    }
  }

  private onMouseUp(event: PlannerInteractionEvent): void {
    if (this.isZooming && !this.isDrawing) {
      this.isZooming = false
    } else {
      this.zoom.isActive = true
      this.isZooming = false

      this.interactionUp.next(event)
    }

    this.isDrawing = false
  }
}
