<ion-content>
  <ion-item
    *ngFor="let item of items"
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(item)"
    data-test-selector="highlight-menu-3d">
    <ion-label>{{ item.Translation }}</ion-label>
    <ion-toggle [checked]="item.Highlighted"></ion-toggle>
  </ion-item>
</ion-content>
