import { Component, EventEmitter, Output } from '@angular/core'
import { ImportFileType } from '../../../services/file-handler.service'
import { FileService } from '../../../services/file.service'

@Component({
  selector: 'efp-stock-file-upload',
  templateUrl: './stock-file-upload.component.html',
  styleUrls: ['./stock-file-upload.component.scss'],
})
export class StockFileUploadComponent {
  @Output() readonly fileUpload = new EventEmitter<{ fileName: string; content: string }>()

  public fileHover = false
  constructor(private readonly fileService: FileService) {}

  onDragEnter(event: DragEvent): void {
    event.preventDefault()
    this.fileHover = true
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault()
    this.fileHover = false
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault()
  }

  async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault()
    this.fileHover = false
    if (event.dataTransfer?.files) {
      await this.readFileList(event.dataTransfer?.files)
    }
  }

  async pickFile(): Promise<void> {
    const file = await this.fileService.pickFile([ImportFileType.csv, ImportFileType.ma7])
    if (file?.data) {
      const decodedFromBase64 = atob(file.data)
      this.fileUpload.emit({ fileName: file.name, content: decodedFromBase64 })
    }
  }

  private async readFileList(fileList: FileList): Promise<void> {
    if (fileList?.length) {
      const reader = new FileReader()
      const fileTextResult = new Promise<string>((resolve, reject) => {
        reader.onload = (evt) => {
          resolve(<string>evt.target?.result)
        }
        reader.onerror = (error) => {
          reject(error)
        }
      })
      reader.readAsText(fileList[0], 'UTF-8')

      const fileText = await fileTextResult
      if (fileText) {
        this.fileUpload.emit({ fileName: fileList[0].name, content: fileText })
      }
    }
  }
}
