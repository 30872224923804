import { Manager, Pinch } from 'hammerjs'
import paper from 'paper/dist/paper-core'
import { BehaviorSubject, Observable } from 'rxjs'

const MOBILE_MAX_WIDTH = 500
const MOBILE_INITIAL_ZOOM = 2
const TABLET_INITIAL_ZOOM = 1.5

export class ZoomService {
  public isActive = true
  public minZoomLevel = 0.2
  public maxZoomLevel = 6
  public flipY = false

  private mc?: HammerManager
  public view?: paper.View
  private lastEventScale = 1
  private lastEventPosition?: paper.Point = undefined
  private pinchStartMatrix?: paper.Matrix = undefined
  private pinchStartMatrixInverted?: paper.Matrix = undefined
  private pinchCenterPoint?: paper.Point = undefined
  private zoomInMetersChanged = new BehaviorSubject<number>(1)
  private panChanged = new BehaviorSubject<paper.Point>(new paper.Point(0, 0))
  private offsetTop = 0
  private initialZoom: number | undefined
  private initialPan: paper.Point | undefined

  get zoomInMeters$(): Observable<number> {
    return this.zoomInMetersChanged
  }

  get pan$(): Observable<paper.Point> {
    return this.panChanged
  }

  constructor() {}

  public init(view: paper.View): void {
    this.reset()
    this.view = view
    const canvas = view.element
    const mc = new Manager(canvas)
    this.mc = mc
    mc.add(new Pinch())

    mc.get('pinch').set({ enable: true })
    mc.on('pinchstart', this.pinchStart)
    mc.on('pinchmove', this.onMove)
    mc.on('pinch', this.pinch)
    mc.on('pinchend', this.pinchEnd)

    canvas.addEventListener('mousedown', this.mouseDown)
    canvas.addEventListener('mousemove', this.mouseMove)
    canvas.addEventListener('wheel', this.wheel)

    const boundingBox = canvas.getBoundingClientRect()
    this.offsetTop = boundingBox.top

    if (this.initialZoom) {
      this.setZoomAndPan(this.initialZoom, this.initialPan)
    }
  }

  reset(): void {
    this.mc?.destroy()
    this.mc = undefined
    this.view?.element.removeEventListener('mousemove', this.mouseMove)
    this.view?.element.removeEventListener('mousedown', this.mouseDown)
    this.view?.element.removeEventListener('wheel', this.wheel)
    this.view = undefined
    this.lastEventPosition = undefined
  }

  public resetScale(boundingBox?: paper.Rectangle, margins?: Margins): void {
    if (this.view) {
      const correctedMargins = {
        marginTop: margins?.marginTop ?? 0,
        marginRight: margins?.marginRight ?? 0,
        marginBottom: margins?.marginBottom ?? 0,
        marginLeft: margins?.marginLeft ?? 0,
      } as Required<Margins>

      const centerMarginModifier = new paper.Point(0, 0)
        .subtract(new paper.Point(0, correctedMargins.marginTop))
        .subtract(new paper.Point(correctedMargins.marginLeft, 0))
        .add(new paper.Point(0, correctedMargins.marginBottom))
        .add(new paper.Point(correctedMargins.marginRight, 0))
        .divide(2)

      if (boundingBox) {
        const newZoom = 1 / this.getBoundScale(this.view, boundingBox, correctedMargins)
        this.view.zoom = Math.max(this.minZoomLevel, Math.min(newZoom, this.maxZoomLevel))
        this.view.center = boundingBox.center.add(centerMarginModifier.multiply(1 / this.view.zoom))
      } else {
        this.view.zoom = 1 / this.getInitialScale(this.view)
        this.view.center = new paper.Point(0, 0).add(
          centerMarginModifier.multiply(1 / this.view.zoom)
        )
      }

      this.updateZoomInMeters(this.view)
      this.updatePan(this.view)
    }
  }

  public setZoomAndPan(zoomInMeters: number, pan?: paper.Point): void {
    if (this.view) {
      if (pan) {
        this.view.center = pan
        this.view.zoom = 1 / zoomInMeters
      } else {
        this.view.center = new paper.Point(0, 0)
        this.view.zoom = 1 / zoomInMeters
      }

      this.updateZoomInMeters(this.view)
      this.updatePan(this.view)
    } else {
      this.initialZoom = zoomInMeters
      this.initialPan = pan
    }
  }

  private readonly mouseMove = (e: MouseEvent): void => {
    if (e.buttons === 2) {
      this.onMove(e)
    }
  }

  private readonly mouseDown = (e: MouseEvent): void => {
    if (e.buttons === 2) {
      this.onDown(e)
    }
  }

  private readonly pinchStart = (e: HammerInput | MouseEvent): void => {
    this.initZoom(this.getCenterPoint(e))
  }

  private readonly pinch = (e: HammerInput): void => {
    if (!this.isActive || !this.view) {
      return
    }

    this.zoom(this.view, e.scale, this.getCenterPoint(e))
  }

  private readonly wheel = (e: WheelEvent): void => {
    if (this.view) {
      this.initZoom(this.getCenterPoint(e))
      this.resetLastEventScaleOnDirectionChange(e)

      const relativeScale = -e.deltaY / 1000 + this.lastEventScale
      this.zoom(this.view, relativeScale, new paper.Point(e.offsetX, e.offsetY))
    }
  }

  private readonly pinchEnd = (): void => {
    this.lastEventPosition = undefined

    if (this.view) {
      this.updateZoomInMeters(this.view)
      this.updatePan(this.view)
    }
  }

  private readonly onMove = (e: HammerInput | MouseEvent): void => {
    if (!this.isActive || !this.view) {
      return
    }

    const center = (e as HammerInput).center ?? {
      x: (e as MouseEvent).clientX,
      y: (e as MouseEvent).clientY,
    }

    if (!this.lastEventPosition) {
      this.lastEventPosition = new paper.Point(center)
    }

    const delta = new paper.Point(
      (this.lastEventPosition?.x - center.x) / this.view.zoom,
      (this.lastEventPosition?.y - center.y) / this.view.zoom
    )
    if (this.flipY) {
      delta.y = -delta.y
    }
    this.lastEventPosition = new paper.Point(center)

    this.view.center = this.view.center.add(delta)
    this.updatePan(this.view)
  }

  private readonly onDown = (e: MouseEvent): void => {
    this.lastEventPosition = new paper.Point(e.clientX, e.clientY)
  }

  private getBoundScale(view: paper.View, boundingBox: paper.Rectangle, margins: Margins): number {
    const correctedHeight =
      view.element.clientHeight - (margins.marginTop ?? 0) - (margins.marginBottom ?? 0)
    const correctedWidth =
      view.element.clientWidth - (margins.marginLeft ?? 0) - (margins.marginRight ?? 0)

    if (window.innerWidth === 0 || correctedHeight === 0) {
      return 1
    }

    const heightRatio = boundingBox.height / correctedHeight
    const widthRatio = boundingBox.width / correctedWidth

    return Math.max(widthRatio, heightRatio)
  }

  private getInitialScale(view: paper.View): number {
    const minDeviceSide = Math.min(view.element.clientWidth, view.element.clientHeight)
    const isSmallDevice = minDeviceSide <= MOBILE_MAX_WIDTH

    return isSmallDevice ? MOBILE_INITIAL_ZOOM : TABLET_INITIAL_ZOOM
  }

  private initZoom(centerPoint: paper.Point): void {
    if (this.view != null) {
      this.pinchStartMatrix = this.view.matrix.clone()
      this.pinchStartMatrixInverted = this.pinchStartMatrix.inverted()
      this.pinchCenterPoint = this.view.viewToProject(centerPoint)
    }
  }

  private zoom(view: paper.View, eventScale: number, centerPoint: paper.Point): void {
    if (
      this.pinchStartMatrix != null &&
      this.pinchStartMatrixInverted != null &&
      this.pinchCenterPoint != null
    ) {
      const centerPointProjected = centerPoint.transform(this.pinchStartMatrixInverted)
      const delta = centerPointProjected.subtract(this.pinchCenterPoint).divide(eventScale)

      const oldMatrix = view.matrix.clone()
      view.matrix = this.pinchStartMatrix
        .clone()
        .scale(eventScale, this.pinchCenterPoint)
        .translate(delta)

      if (view.zoom < this.minZoomLevel || view.zoom > this.maxZoomLevel) {
        view.matrix = oldMatrix
      }
    }

    this.lastEventScale = eventScale
    this.updateZoomInMeters(view)
    this.updatePan(view)
  }

  private updateZoomInMeters(view: paper.View): void {
    this.zoomInMetersChanged.next(+(1 / view.zoom).toFixed(2))
  }

  private updatePan(view: paper.View): void {
    this.panChanged.next(view.center)
  }

  private resetLastEventScaleOnDirectionChange(e: WheelEvent): void {
    if ((this.lastEventScale < 1 && e.deltaY > 0) || (this.lastEventScale > 1 && e.deltaY < 0)) {
      this.lastEventScale = 1
    }
  }

  private getCenterPoint(e: HammerInput | MouseEvent): paper.Point {
    const touchCenter = (e as HammerInput).center

    if (touchCenter != null) {
      return new paper.Point(touchCenter.x, touchCenter.y - this.offsetTop)
    }

    const center = (e as HammerInput).center ?? {
      x: (e as MouseEvent).offsetX,
      y: (e as MouseEvent).offsetY,
    }

    return new paper.Point(center)
  }
}

export interface Margins {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}
