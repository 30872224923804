import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc'
import { catchError, Observable, Subject, throwError } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { AuthenticationRepository } from '../../repositories/authentication-repository.service'

@Injectable()
export class HttpUnauthorizedRequestInterceptor implements HttpInterceptor {
  private refreshingToken = false
  private refreshTokenResult = new Subject<TokenResponse>()
  constructor(
    private oAuthService: OAuthService,
    private authenticationRepository: AuthenticationRepository
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (!environment.applicationReady) {
      return next.handle(req)
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (!this.refreshingToken) {
              this.refreshingToken = true
              this.oAuthService
                .refreshToken()
                .then((t) => {
                  this.refreshingToken = false
                  this.refreshTokenResult.next(t)
                })
                .catch((reason) => {
                  this.refreshTokenResult.error(reason)
                  void this.authenticationRepository.logoutAndRedirectToLogin()
                })
            }

            return this.refreshTokenResult.pipe(
              take(1),
              switchMap((t) => {
                const header = 'Bearer ' + t.access_token
                const headers = req.headers.set('Authorization', header)
                const newRequest = req.clone({ headers })
                return next.handle(newRequest)
              })
            )
          }
        }
        return throwError(() => new Error(error))
      })
    )
  }
}
