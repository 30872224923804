<div class="sync-container" [ngSwitch]="httpRequestSyncService.synchronizationState$ | async">
  <ng-container *ngSwitchCase="'SYNC_RUNNING'">
    <div class="sync-text">{{ 'SYNC.RUNNING' | translate }}</div>
    <ion-spinner class="sync-icon" name="circular"></ion-spinner>
  </ng-container>

  <ng-container *ngSwitchCase="'SYNC_SAVED'">
    <div class="sync-text">{{ 'SYNC.SAVED' | translate }}</div>
    <ion-icon class="sync-icon" src="/assets/icon/check-small.svg"></ion-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'SYNC_FAILED'">
    <div class="sync-text sync-failed-icon">{{ 'SYNC.FAILED' | translate }}</div>
    <ion-icon class="sync-icon" src="/assets/icon/warning-red.svg"></ion-icon>
  </ng-container>
</div>
