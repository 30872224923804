import { SlabMesh } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { DrawSettings } from '../../../../models/draw-settings'
import { UndoRedoHistory } from '../../../../models/history/undoRedoHistory'
import { parseSimplifiedSlabMesh, serializeMesh } from '../MeshSerializer'

export class SlabUndoRedoHistory extends UndoRedoHistory<SlabMesh> {
  constructor(
    private readonly paperScope: paper.PaperScope,
    slab: SlabMesh,
    drawSettings: DrawSettings
  ) {
    super(slab, drawSettings)
  }

  deserialize(serializedMesh: string): SlabMesh {
    return parseSimplifiedSlabMesh(this.paperScope, serializedMesh)
  }

  serialize(mesh: SlabMesh): string {
    return serializeMesh(mesh)
  }
}
