import { Injectable } from '@angular/core'
import { createStore } from '@ngneat/elf'
import {
  addEntities,
  deleteEntitiesByPredicate,
  getAllEntitiesApply,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { ScreenshotCommandParams } from '../../generated/efp-api'
import { Screenshot } from '../models/screenshot'
import { ScreenshotDao } from '../services/dao/screenshot.dao'

export const screenshotStore = createStore({ name: 'screenshot' }, withEntities<Screenshot>())

@Injectable({
  providedIn: 'root',
})
export class ScreenshotRepository {
  constructor(private readonly screenshotDao: ScreenshotDao) {}

  public async create(params: ScreenshotCommandParams): Promise<Screenshot> {
    const screenshot = await this.screenshotDao.create(params)
    screenshotStore.update(addEntities(screenshot))
    return screenshot
  }

  public async findAllByPlanId(planId: number): Promise<Screenshot[]> {
    let planScreenshots = screenshotStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.planId === planId,
      })
    )

    if (!planScreenshots || planScreenshots.length === 0) {
      planScreenshots = await this.screenshotDao.findAllByPlanId(planId)
      screenshotStore.update(upsertEntities(planScreenshots))
    }

    return planScreenshots
  }

  public async delete(screenId: number): Promise<void> {
    await this.screenshotDao.delete(screenId)
    screenshotStore.update(deleteEntitiesByPredicate((e) => e.id === screenId))
  }

  public async deleteAllByPlanId(planId: number): Promise<void> {
    await this.screenshotDao.deleteAllByPlanId(planId)
    screenshotStore.update(deleteEntitiesByPredicate((e) => e.planId === planId))
  }

  public async updateName(id: number, name: string): Promise<void> {
    await this.screenshotDao.updateName(id, name)
    screenshotStore.update(updateEntities(id, { name }))
  }
}
