import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: 'ion-input[efpInputOverwritingSelect]',
})
export class InputOverwritingSelectDirective {
  private element: ElementRef

  constructor(element: ElementRef) {
    this.element = element
  }

  @HostListener('ionFocus', ['$event'])
  selectOnFocus($event: FocusEvent): void {
    const ionInput = $event.target as HTMLIonInputElement
    void ionInput.getInputElement().then((input) => {
      setTimeout(() => {
        if (input === document.activeElement) {
          input.select()
        }
      }, 1)
    })
  }
}
