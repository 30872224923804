import { UnitOfLength } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { DEFAULT_LABEL_COLOR } from '../../../../constants/colors'
import { MeasurementLabel } from './MeasurementLabel'

export class WidthLabel extends MeasurementLabel {
  constructor(
    from: paper.Point,
    to: paper.Point,
    fontSize: number,
    strokeWidth: number,
    public readonly bold: boolean,
    public readonly unit: UnitOfLength
  ) {
    super(from, to, fontSize, strokeWidth, bold, unit)
    if (unit === 'inch') {
      this.text.rotate(this.textRotation < 0 ? 90 : -90)
    }
    this.moveText(this.centerPoint)
  }

  public isClickable = true

  protected generateLengthPath(pointFrom: paper.Point, pointTo: paper.Point): paper.CompoundPath {
    const compoundPath = new paper.CompoundPath({})
    compoundPath.strokeWidth = this.strokeWidth
    compoundPath.strokeColor = DEFAULT_LABEL_COLOR

    const lengthVector = pointTo.subtract(pointFrom)
    const gapSize = (this.text.fontSize as number) / 2
    const lineLength = (lengthVector.length - this.textHeight - 4 * gapSize) / 2
    const gapVector = lengthVector.normalize(gapSize)
    const lineVector = lengthVector.normalize(lineLength)

    if (lineLength > gapSize / 2) {
      const line1 = new paper.Path()
      line1.add(pointFrom.add(gapVector))
      line1.lineBy(lineVector)

      const line2 = new paper.Path()
      line2.add(pointTo.subtract(gapVector))
      line2.lineBy(lineVector.multiply(-1))

      compoundPath.addChild(line1)
      compoundPath.addChild(line2)
      this.addChild(compoundPath)
    }
    return compoundPath
  }

  public get textHeight(): number {
    return this.unit === 'inch' ? this.textBounds.height : this.textBounds.width
  }

  public get textLength(): number {
    return this.unit === 'inch' ? this.textBounds.width : this.textBounds.height
  }
}
