<ng-container *ngIf="this.httpRequestSyncService.synchronizationState$ | async as syncState">
  <ion-button
    [disabled]="
      !history?.hasUndoActions() || syncState === SynchronizationState.SYNC_RUNNING || selectionOpen
    "
    efpTooltip
    [tooltipTitle]="'TOOLBAR.UNDO' | translate"
    (click)="undo.emit()"
    data-test-selector="planner-undo">
    <ion-icon slot="icon-only" src="assets/icon/undo.svg"></ion-icon>
  </ion-button>
  <ion-button
    [disabled]="
      !history?.hasRedoActions() || syncState === SynchronizationState.SYNC_RUNNING || selectionOpen
    "
    efpTooltip
    [tooltipTitle]="'TOOLBAR.REDO' | translate"
    (click)="redo.emit()"
    data-test-selector="planner-redo">
    <ion-icon slot="icon-only" src="assets/icon/redo.svg"></ion-icon>
  </ion-button>
</ng-container>
