import { Capacitor } from '@capacitor/core'
import { Edge, LinePosition, Mesh, MeshPoint, PlannerInteractionEvent } from 'formwork-planner-lib'
import { Model } from '../model/Model'
import { RenderService } from '../services/render.service'
import { CursorType, SelectionService } from '../services/selection.service'

/**
 * Implementation of interactions possible on the planner-canvas in deformation mode.
 */
export class DeformationMode {
  constructor(
    private readonly model: Model<Mesh>,
    private readonly renderService: RenderService<Model<Mesh>>,
    private readonly selectionService: SelectionService
  ) {}

  private selectedEdges: Edge[] = []
  private movedEdges: Edge[] = []
  private isMoving = false

  onMouseDragged(event: PlannerInteractionEvent): void {
    this.renderService.setAuxiliaryGuidelines([])
    if (!this.isMoving) {
      const snapResult = this.model.mesh.snapPointToEdgeOrPoint(event.downPoint)
      if (snapResult) {
        this.selectionService.setMouseCursor(CursorType.GRABBING)
        this.isMoving = true
        this.selectedEdges = this.selectionService.getSelectedEdges()
      }
    }

    if (this.isMoving) {
      this.moveToPoint(event.downPoint, event.point)
    } else if (!Capacitor.isNativePlatform()) {
      this.selectionService.drawSelectionRectangle(event.downPoint, event.point)
    }
  }

  onMouseUp(_: PlannerInteractionEvent, keepSelection: boolean = false): void {
    this.renderService.setAuxiliaryGuidelines([])
    this.selectionService.clearSelectionRectangle(true, keepSelection)
    this.model.finalize()
    if (this.isMoving) {
      this.selectionService.setMouseCursor(CursorType.GRAB)
      this.selectedEdges = this.movedEdges
      this.selectionService.setSelectedEdges(this.movedEdges)
    }
    this.isMoving = false
    this.movedEdges = []
    this.renderService.removeAngleIndicator()
  }

  private moveToPoint(downPoint: paper.Point, destination: paper.Point): void {
    const edges: Edge[] = this.selectedEdges.concat()
    const result = this.model.move(downPoint, destination, edges)

    if (result) {
      if (Array.isArray(result)) {
        // edges moved, highlight them
        this.movedEdges = result
        this.selectionService.setSelectedEdges(result)
        this.renderService.calculateAuxiliaryLines(result, true, false)
      } else {
        // Endpoint moved, result is line for angleIndicator
        const line = result
        this.renderService.indicateAngle(line.start, line.end)
        this.renderService.calculateAuxiliaryLines(
          Array.from((line[LinePosition.END] as MeshPoint).edges),
          true,
          true
        )
      }
    } else {
      // connected point or nothing moved
      this.renderService.removeAngleIndicator()
    }
  }
}
