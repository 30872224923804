import { AfterViewInit, Component, Input, NgZone, OnDestroy, ViewChild } from '@angular/core'
import { Device } from '@awesome-cordova-plugins/device/ngx'
import { NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer'
import { PinchOnMobileSupport } from './pinch-on-mobile-support'

@Component({
  selector: 'efp-pdf-viewer',
  templateUrl: 'pdf-viewer.component.html',
  styleUrls: ['pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
  @Input() src?: string | ArrayBuffer | Blob | Uint8Array | URL

  @ViewChild(NgxExtendedPdfViewerComponent)
  readonly ngxPdfViewer!: NgxExtendedPdfViewerComponent
  private pinchMobileSupport!: PinchOnMobileSupport

  useLegacyPdfJS = this.isLegacy()

  constructor(private ngZone: NgZone, private device: Device) {}

  /**
   * checks if rendering is needed (not all visible pages rendered) and renders
   */
  checkAndRender(): void {
    // eslint-disable-next-line
    const pdfViewerApplication = (window as any).PDFViewerApplication
    const visiblePageIds = [...pdfViewerApplication.pdfViewer._getVisiblePages().ids]
    const pages = pdfViewerApplication.pdfViewer._pages
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allVisiblePagesRendered = visiblePageIds.every((vp: any) =>
      // eslint-disable-next-line
      pages.some((p: any) => p.id === vp && p.renderingState === 3)
    )

    if (!allVisiblePagesRendered) {
      // trigger rendering
      pdfViewerApplication.zoomReset()
    }
  }

  ngAfterViewInit(): void {
    this.pinchMobileSupport = new PinchOnMobileSupport(this.ngZone)
  }

  private isLegacy(): boolean {
    return this.device.platform === 'iOS' && parseInt(this.device.version, 10) < 15
  }

  ngOnDestroy(): void {
    this.pinchMobileSupport.destroyPinchZoom()
  }
}
