import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { FormworkId } from '@efp/api'
import { AlertController, ModalController, ToastController } from '@ionic/angular'
import { FavoritesLanguageConfig } from '../constants/favouritesLangJSON'
import { supportedSystems } from '../constants/formworkSystems'
import {
  FavouriteProfile,
  FavouriteProfileBasis,
  FavouriteProfileShare,
  FavouriteSubType,
  FavouriteSystem,
  FavouriteSystemStileWrapper,
  FavouriteType,
} from '../models/favourites'
import { FormworkSystem } from '../models/formworkSystem'
import { Plan } from '../models/plan'
import { Project } from '../models/project'
import { FavouritesSystemsPage } from '../pages/favourites/favourites-systems/favourites-systems.page'
import { AppSettingsRepository } from '../repositories/app-settings.repository'
import { FavouriteRepository } from '../repositories/favourite.repository'
import { PlanSettingsRepository } from '../repositories/plan-settings.repository'
import { PlanRepository } from '../repositories/plan.repository'
import { ProjectRepository } from '../repositories/project.repository'
import { ModalBaseComponent } from '../shared/components/modal-base/modal-base.component'
import { deepCopy } from '../utils/deepCopy'
import { XmlWriter } from '../utils/xmlWriter'
import { AppSettingService } from './app-setting.service'
import { DataService } from './data.service'
import { ErrorPopupService } from './error-popup.service'
import { FileHandlerService, ImportFileType } from './file-handler.service'
import { FileService } from './file.service'
import { LoadingSpinnerService } from './loading-spinner.service'
import { defaultLanguage, Translation } from './translation.service'
import { DefaultFavourites } from '../utils/favourites/defaultFavourites'
import { BlacklistArticleRepository } from '../repositories/blacklist-article.repository'
import { FAVOURITES_VERSION } from '../constants/versions'

const IGNORED_IMPORT_ERRORS = ['pickFiles canceled.']

export interface FavouriteUsingObjects {
  plans: Plan[]
  projects: Project[]
  isUsedByAppSettings: boolean
  alertMessageObjectList: string
}

@Injectable({
  providedIn: 'root',
})
export class FavouritesService {
  // TODO Why is this here?!
  public allFavourites: FavouriteProfile[] = []

  private translations!: FavoritesLanguageConfig
  private currentLanguage!: string

  constructor(
    private dataService: DataService,
    private readonly settingsService: AppSettingService,
    private readonly fileService: FileService,
    private readonly translate: Translation,
    private readonly errorPopupService: ErrorPopupService,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly modalCtrl: ModalController,
    private readonly alertCtrl: AlertController,
    private readonly toastController: ToastController,
    private readonly planSettingsRepository: PlanSettingsRepository,
    private readonly appSettingService: AppSettingService,
    private readonly planRepository: PlanRepository,
    private readonly projectRepository: ProjectRepository,
    private readonly favouriteRepository: FavouriteRepository,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly blacklistArticleRepository: BlacklistArticleRepository
  ) {}

  public async initFavourites(): Promise<void> {
    // Exists should always be true, as we pass a flag to create any missing ones
    await this.checkIfStandardFavouritesExist(true)
    await this.loadAllFavourites()
  }

  private getDefaultFormworkSystemValues(): FavouriteSystemStileWrapper {
    const favJson: FavouriteSystemStileWrapper = DefaultFavourites.Instance().FavouritesArticles

    // filter out unsupported systems
    favJson.Schalungsstile.Schalsystem = favJson.Schalungsstile.Schalsystem.filter(
      (system) => supportedSystems.indexOf(system.ID) !== -1
    )

    return favJson
  }

  public async loadAllFavourites(): Promise<void> {
    this.allFavourites = await this.favouriteRepository.findAll()
  }

  public async initTranslation(): Promise<void> {
    this.translations = DefaultFavourites.Instance().FavouritesTranslations
    const appSettings = await this.appSettingsRepository.getAppSettings()
    if (
      appSettings.language === 'en' &&
      (appSettings.country === 'us' || appSettings.country === 'gb')
    ) {
      this.currentLanguage = appSettings.language + '-' + appSettings.country
    } else if (appSettings.language === 'en') {
      // we need to set a default value in EN (one & only exception)
      this.currentLanguage = defaultLanguage
    } else {
      this.currentLanguage = appSettings.language
    }
  }

  public async duplicateFavourite(
    favourite: FavouriteProfile
  ): Promise<FavouriteProfile | undefined> {
    let newFavouriteName: string | undefined = favourite.isStandard
      ? this.translate.translate('FORMWORK.' + favourite.formworkSystemId)
      : favourite.name
    newFavouriteName += ' - ' + this.translate.translate('FAVOURITES.DUPLICATED')

    newFavouriteName = await this.presentRenameDialog(newFavouriteName)

    // if newFavouriteName === undefined; User clicked on cancel!
    if (newFavouriteName) {
      const newFavourite: FavouriteProfile = {
        ...favourite,
        id: 0,
        isStandard: false,
        name: newFavouriteName ?? '',
        values: deepCopy(favourite.values),
        basis: {
          // Copy the values at the time of duplication, so the new copy can be reset to the state at time of duplication
          values: deepCopy(favourite.values),
        },
      }

      return this.loadingSpinner.doWithLoadingSpinner(async () => {
        newFavourite.id = await this.createFavourite(newFavourite)

        const blacklistArticles = await this.blacklistArticleRepository.findAllByFavouriteProfileId(
          favourite.id
        )
        const updatedArticles = blacklistArticles.map((article) => {
          return {
            ...article,
            favouriteProfileId: newFavourite.id,
          }
        })
        await this.blacklistArticleRepository.create(updatedArticles)

        return newFavourite
      })
    } else {
      return undefined
    }
  }

  public async resetFavourite(favourite: FavouriteProfile): Promise<void> {
    let basisValues: FavouriteSystemStileWrapper
    // Due to an issue in creating the defaults in the backend in an earlier version,
    // the basis might directly contain the values without any wrapper!
    if ('Schalungsstile' in favourite.basis) {
      basisValues = favourite.basis as unknown as FavouriteSystemStileWrapper
    } else if ('values' in favourite.basis) {
      basisValues = favourite.basis.values
    } else {
      throw new Error('Formwork has wrong basis format, cannot be reset')
    }

    await this.updateFavourite({
      ...favourite,
      // Ensure we make a copy of the values and don't modify the original objects
      values: deepCopy(basisValues),
      basis: {
        values: deepCopy(basisValues),
      },
    })
  }

  public async updateFavourite(favorite: FavouriteProfile): Promise<void> {
    const id = await this.favouriteRepository.update(favorite)
    await this.loadAllFavourites()

    return id
  }

  public async createFavourite(
    favorite: Omit<FavouriteProfileShare, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<number> {
    const id = await this.favouriteRepository.create(favorite)
    if (favorite.blacklistArticles) {
      const updatedArticles = favorite.blacklistArticles.map((article) => {
        return {
          ...article,
          favouriteProfileId: id,
        }
      })
      await this.blacklistArticleRepository.create(updatedArticles)
    }

    await this.loadAllFavourites()

    return id
  }

  public async deleteFavourite(id: number): Promise<void> {
    await this.favouriteRepository.delete(id)
    await this.loadAllFavourites()
  }

  public async getFavouriteUsingObjects(
    favouriteProfile: FavouriteProfile
  ): Promise<FavouriteUsingObjects> {
    let usingPlans: Plan[] = []
    let usingProjects: Project[] = []
    let isUsedByAppSettings = false

    usingPlans = await this.planRepository.findAllByFavouriteId(favouriteProfile.id)
    usingProjects = await this.projectRepository.findAllByFavouriteId(favouriteProfile.id)

    // --- Settings ---
    isUsedByAppSettings = usingProjects.some((it) => it.defaultProject)

    // --- Alert Message Object List ---

    let planNamesHTML = ''
    let projectNamesHTML = ''
    let appSettingsHTML = ''

    if (usingPlans.length > 0) {
      planNamesHTML = `<p>${this.translate.translate('FAVOURITES.DELETE_PLAN_TITLE')}:<ul>`
      planNamesHTML += usingPlans.map((plan) => `<li>${plan.name}</li>`).join('')
      planNamesHTML += '</ul></p>'
    }

    if (usingProjects.length > 0) {
      projectNamesHTML = `<p>${this.translate.translate('FAVOURITES.DELETE_PROJECT_TITLE')}:<ul>`
      projectNamesHTML += usingProjects.map((project) => `<li>${project.name}</li>`).join('')
      projectNamesHTML += '</ul></p>'
    }

    if (isUsedByAppSettings) {
      appSettingsHTML = `<p><ul><li>${this.translate.translate(
        'FAVOURITES.DELETE_SETTINGS_TITLE'
      )}</ul></li></p>`
    }

    const htmlAffectObjectsList = `${appSettingsHTML}${projectNamesHTML}${planNamesHTML}`

    return {
      plans: usingPlans,
      projects: usingProjects,
      isUsedByAppSettings,
      alertMessageObjectList: htmlAffectObjectsList,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFavouriteProfileFromRow(row: any): FavouriteProfile {
    return {
      id: row.id,
      name: row.name,
      isStandard: row.isStandard,
      values: JSON.parse(row.favouritesJson),
      basis: row.basisJson ? JSON.parse(row.basisJson) : undefined,
      formworkSystemId: row.formworkSystemId ?? undefined,
      useOnlyRentableArticles: row.useOnlyRentableArticles,
      formworkVersion: row.formworkVersion,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    }
  }

  public async checkIfStandardFavouritesExist(createMissing: boolean = false): Promise<boolean> {
    const allFormWorkSystems = this.settingsService.getAllFormworkSystems()
    if (Capacitor.isNativePlatform()) {
      await this.migrateOldFavourites(allFormWorkSystems)
    }
    const checkPromises = allFormWorkSystems.map(async (system) =>
      this.checkIfStandardFavouriteExist(system.id, createMissing)
    )
    const checkResults = await Promise.all(checkPromises)

    return checkResults.every((it) => !!it)
  }

  private async checkIfStandardFavouriteExist(
    formworkId: FormworkId,
    createMissing: boolean
  ): Promise<boolean> {
    const defaultValues = this.getDefaultFormworkSystemValues()

    const standardFavourite = await this.favouriteRepository.findOneStandardByFormworkSystemId(
      formworkId
    )
    if (standardFavourite) {
      return true
    } else if (!createMissing) {
      return false
    } else {
      const date = new Date()
      const newStandardFavouriteBasis: FavouriteProfileBasis = {
        values: defaultValues,
      }
      const newStandardFavourite: FavouriteProfile = {
        id: 1,
        name: formworkId,
        formworkSystemId: formworkId,
        isStandard: true,
        updatedAt: date,
        createdAt: date,
        values: defaultValues,
        basis: newStandardFavouriteBasis,
        useOnlyRentableArticles: false,
        formworkVersion: FAVOURITES_VERSION,
      }

      await this.favouriteRepository.create(newStandardFavourite)
      return true
    }
  }

  /**
   * Migrate Favourites
   *
   * @deprecated Should be removed from the next major version.
   */
  private async migrateOldFavourites(allFormWorkSystems: FormworkSystem[]): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      throw new Error('Migration can only be done on native platforms')
    }

    const oldFavouriteStatement = 'SELECT * FROM Favourites WHERE formworkSystemId IS NULL'
    const oldFavouriteResult = await this.dataService.executeStatement(oldFavouriteStatement)
    if (oldFavouriteResult.rows.length === 0) {
      return
    }
    // In the old favourite system, there was only one saved favourite with id=1
    const oldFavouriteRow = oldFavouriteResult.rows.item(0)
    const oldFavouriteProfile = this.getFavouriteProfileFromRow(oldFavouriteRow)

    const newProfileIds = await Promise.all(
      allFormWorkSystems.map(async (system) => {
        const newClone = Object.create(oldFavouriteProfile)
        newClone.formworkSystemId = system.id
        newClone.name = this.translate.translate('FAVOURITES.PROFILE.MIGRATED_NAME')
        const newId = await this.createFavourite(newClone)
        return {
          id: newId,
          systemId: system.id,
        }
      })
    )

    const plansStatement = 'SELECT * FROM Plans WHERE favId=0'
    const planResult = await this.dataService.executeStatement(plansStatement)
    for (let i = 0; i < planResult.rows.length; i++) {
      const planRow = planResult.rows.item(i)

      const settingsResult = await this.planSettingsRepository.findAllById([planRow.settingsId])
      if (settingsResult.length > 0) {
        const planSettings = settingsResult[0]
        planSettings.wallFavId = newProfileIds.filter(
          (newProfile) => newProfile.systemId === planSettings.formworkWall
        )[0].id
        planSettings.slabFavId = newProfileIds.filter(
          (newProfile) => newProfile.systemId === planSettings.formworkSlab
        )[0].id

        await this.dataService.executeStatement(
          'UPDATE PlanSettings SET wallFavId=?, slabFavId=? WHERE id=?',
          [planSettings.wallFavId, planSettings.slabFavId, planSettings.id]
        )
      }
    }

    const statement = 'DELETE FROM Favourites WHERE formworkSystemId IS NULL'
    await this.dataService.executeStatement(statement)
  }

  public translateKey(key: string): string {
    // used for favTranslate Pipe

    if (!this.translations) {
      this.translations = DefaultFavourites.Instance().FavouritesTranslations
    }

    const translation = this.translations.translations.find(
      (x) => x.ID.toUpperCase() === key.toUpperCase()
    )

    const currentLanguage = this.currentLanguage.replace('us', 'US').replace('gb', 'GB')
    let translatedKey = translation ? translation[currentLanguage] : undefined

    if (!translatedKey) {
      translatedKey = translation ? translation['en-GB'] : undefined
    }
    if (!translatedKey) {
      translatedKey = translation ? translation['de'] : key
    }

    return translatedKey
  }

  public async shareFavourite(favourite: FavouriteProfile): Promise<void> {
    const blacklistArticles = await this.blacklistArticleRepository.findAllByFavouriteProfileId(
      favourite.id
    )

    const shareFavourite: FavouriteProfileShare = {
      ...favourite,
      blacklistArticles,
    }
    const json = JSON.stringify(shareFavourite)
    const favouriteFileName = !favourite.isStandard
      ? favourite.name
      : this.translate.translate('FORMWORK.' + favourite.formworkSystemId)

    await this.shareOrDownloadFile(json, `${favouriteFileName}.favefp`)
  }

  public async shareTiposXML(favourite: FavouriteProfile): Promise<void> {
    const favXml: string =
      '<?xml version="1.0" encoding="utf-8"?>' + XmlWriter.OBJtoXML(favourite.values)
    const favouriteFileName = !favourite.isStandard
      ? favourite.name
      : this.translate.translate('FORMWORK.' + favourite.formworkSystemId)

    await this.shareOrDownloadFile(favXml, `${favouriteFileName}.fav`)
  }

  private async shareOrDownloadFile(content: string, filename: string): Promise<void> {
    await this.fileService.shareOrDownloadTextFile(content, `favourites/${filename}`, filename)
  }

  public async importFavouriteFromData(
    fileData: ArrayBuffer,
    url: string,
    formworksystem?: FormworkId
  ): Promise<number | undefined> {
    try {
      const favouriteId = await this.loadingSpinner.doWithLoadingSpinner(async (loading) => {
        const text = new TextDecoder('UTF-8').decode(fileData).trim()
        let favouriteProfile: FavouriteProfileShare | undefined

        const favType = FileHandlerService.getFileType(fileData, url)

        if (favType === ImportFileType.fav || favType === ImportFileType.favefp) {
          // The url is on iOS not decoded and includes %20 instead of spaces as example
          const decodedUri = decodeURIComponent(url)
          const urlArray = decodedUri.split('/')
          const fileName: string = urlArray[urlArray.length - 1].split('.')[0]

          favouriteProfile = await this.createFavouriteFromString(
            text,
            fileName,
            favType,
            formworksystem,
            loading
          )
        } else {
          throw new Error('IMPORT.ERROR.NO_FAV_FORMAT')
        }

        if (!favouriteProfile) {
          return
        }

        return this.createFavourite(favouriteProfile)
      })

      const toast = await this.toastController.create({
        message: this.translate.translate('IMPORT.FAVOURITE.SUCCESS'),
        position: 'bottom',
        duration: 2000,
        cssClass: 'ion-text-center',
        color: 'primary',
      })
      await toast.present()

      return favouriteId
    } catch (err: unknown) {
      if (err instanceof Error && !IGNORED_IMPORT_ERRORS.includes(err.message)) {
        const errMessage = this.translate.translate(err.message)
        await this.errorPopupService.showError(errMessage)
      }

      return undefined
    }
  }

  // eslint-disable-next-line complexity
  private async createFavouriteFromString(
    text: string,
    filename: string,
    favType: ImportFileType,
    formworkSystem: FormworkId | undefined,
    loading: HTMLIonLoadingElement
  ): Promise<FavouriteProfileShare | undefined> {
    const Schalsystem: FavouriteSystem[] = []
    let formworkSystemId = formworkSystem
    let favouriteName: string | undefined = filename

    let favouriteProfile: FavouriteProfileShare
    const favouriteValues: FavouriteSystemStileWrapper = {
      Schalungsstile: {
        Schalsystem,
      },
    }

    if (favType === ImportFileType.fav) {
      const xmlWithoutHeader = text.split('<?xml version="1.0" encoding="utf-8"?>')[1]

      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlWithoutHeader, 'application/xml')
      const schalungsstile = xmlDoc.firstElementChild

      if (schalungsstile && schalungsstile.children.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < schalungsstile.children.length; i++) {
          const schalsystem = schalungsstile.children[i]
          const schalSystemObj: { [key: string]: unknown } = {}
          const schalungsthema: FavouriteType[] = []

          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let j = 0; j < schalsystem.children.length; j++) {
            const schalSystemChild = schalsystem.children[j]

            if (schalSystemChild.nodeName === 'ID') {
              schalSystemObj['ID'] = schalSystemChild.textContent
            }

            if (schalSystemChild.nodeName === 'Hersteller') {
              schalSystemObj['Hersteller'] = schalSystemChild.textContent
            }

            if (schalSystemChild.nodeName === 'Schalungsthema') {
              const schalungsThemaObj: { [key: string]: unknown } = {}
              const loesungen: FavouriteSubType[] = []

              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let t = 0; t < schalSystemChild.children.length; t++) {
                const schalungsThema = schalSystemChild.children[t]

                if (schalungsThema.nodeName === 'ID') {
                  schalungsThemaObj['ID'] = schalungsThema.textContent
                }

                if (schalungsThema.nodeName === 'Bezeichnung') {
                  schalungsThemaObj['Bezeichnung'] = schalungsThema.textContent
                }

                if (schalungsThema.nodeName === 'Loesung') {
                  const schalungsLoesungObj: { [key: string]: unknown } = {}

                  // eslint-disable-next-line @typescript-eslint/prefer-for-of
                  for (let l = 0; l < schalungsThema.children.length; l++) {
                    const schalungsLoesung = schalungsThema.children[l]

                    if (schalungsLoesung.nodeName === 'ID') {
                      schalungsLoesungObj['ID'] = schalungsLoesung.textContent
                    }

                    if (schalungsLoesung.nodeName === 'Bezeichnung') {
                      schalungsLoesungObj['Bezeichnung'] = schalungsLoesung.textContent
                    }
                  }

                  loesungen.push(schalungsLoesungObj as unknown as FavouriteSubType)
                }
              }

              schalungsThemaObj['Loesung'] = loesungen
              schalungsthema.push(schalungsThemaObj as unknown as FavouriteType)
            }

            schalSystemObj['Schalungsthema'] = schalungsthema
          }

          Schalsystem.push(schalSystemObj as unknown as FavouriteSystem)
        }
      }

      if (!formworkSystemId) {
        await loading.dismiss()
        formworkSystemId = await this.presentFormworkSystemSelectionModal()
        await loading.present()
      }

      if (!formworkSystemId) {
        throw new Error('IMPORT.ERROR.NO_FAVOURITE_PROFILE_FORMWORKSYSTEM')
      }

      favouriteProfile = {
        id: 1,
        name: favouriteName,
        createdAt: new Date(),
        updatedAt: new Date(),
        isStandard: false,
        formworkSystemId,
        values: deepCopy(favouriteValues),
        basis: {
          values: deepCopy(favouriteValues),
        },
        useOnlyRentableArticles: false,
        formworkVersion: FAVOURITES_VERSION,
      }

      if (Capacitor.isNativePlatform()) {
        const deviceInfo = await Device.getInfo()

        // We get different and not useable urls from the capacitor plugin on android devices
        if (deviceInfo.platform === 'android') {
          favouriteName = this.translate.translate('FORMWORK.' + favouriteProfile.formworkSystemId)
        }
      }
    } else {
      favouriteProfile = JSON.parse(text)
      favouriteProfile.basis.values = deepCopy(favouriteProfile.values)

      if (favouriteProfile.isStandard) {
        favouriteProfile.name = this.translate.translate(
          'FORMWORK.' + favouriteProfile.formworkSystemId
        )
        favouriteProfile.isStandard = false
      }

      favouriteName = favouriteProfile.name
    }

    favouriteName += ' - ' + this.translate.translate('FAVOURITES.IMPORTED')
    await loading.dismiss()
    favouriteName = await this.presentRenameDialog(favouriteName)
    await loading.present()
    if (!favouriteName) {
      return
    }
    favouriteProfile.name = favouriteName

    return favouriteProfile
  }

  public async presentFormworkSystemSelectionModal(): Promise<FormworkId | undefined> {
    const supportedFormworSystems =
      await this.appSettingService.getSupportedFormworkSystemsForCurrentCountry()
    const supportedFormworkSystemIds = supportedFormworSystems.map((system) => system.id)

    const favJson: FavouriteSystemStileWrapper = DefaultFavourites.Instance().FavouritesArticles
    // filter out unsupported systems
    const filteredFavouriteFormworkSystems = favJson.Schalungsstile.Schalsystem.filter(
      (system) => supportedFormworkSystemIds.indexOf(system.ID) !== -1
    )

    const modal = await this.modalCtrl.create({
      component: ModalBaseComponent,
      cssClass: 'fullscreen',
      componentProps: {
        rootPage: FavouritesSystemsPage,
        rootParams: {
          favouriteFormworksystems: filteredFavouriteFormworkSystems,
        },
      },
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.system) {
      return data.system.ID
    } else {
      return
    }
  }

  public async presentRenameDialog(favouriteName: string): Promise<string | undefined> {
    const alertModal = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      header: this.translate.translate('IMPORT.FORMWORK_PROFILE.NAME_SELECTION_TITLE'),
      inputs: [
        {
          type: 'text',
          name: 'favouriteName',
          placeholder: favouriteName,
          value: favouriteName,
        },
      ],
      buttons: [
        {
          text: this.translate.translate('GENERAL.CANCEL'),
          handler: (data) => {
            data.favouriteName = undefined
            return
          },
        },
        {
          text: this.translate.translate('GENERAL.OK'),
          handler: (data) => {
            return data.favouriteName
          },
        },
      ],
    })

    await alertModal.present()
    const { data } = await alertModal.onWillDismiss()
    return data.values.favouriteName
  }
}
