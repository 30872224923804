import { AuthConfig } from 'angular-oauth2-oidc'
import { DeployEnvironment } from '../app/models/deploy-environment'
import { Environment } from 'feedbackapiclient'

export const environment = {
  deployment: DeployEnvironment.PRODUCTION,
  debugDrawing: false,
  backendUrl: 'https://azwe-app-dev-acc-efp.azurewebsites.net',
  applicationReady: false,
  // TODO Really the same as dev?
  appInsightsConnectionString:
    // eslint-disable-next-line max-len
    'InstrumentationKey=c71796d3-72a3-4f34-9aad-31dd2bdbae79;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/',
}

export const authConfigTrialUser = {
  trialUserClientId: '9ed3f619-a8a2-4272-a95b-a63c1fb17943',
  trialUserSecret: '14YVQ5t6xKuREBp2mONUFmcINQ6657kmfamgKevcytY=',
  tokenEndpoint: 'https://login.doka.com/connect/token',
}

export const authConfigWeb: AuthConfig = {
  issuer: 'https://login.doka.com',
  redirectUri: 'https://efp.doka.com',
  strictDiscoveryDocumentValidation: false,
  clientId: '4abd465d-8b7f-4934-b46c-3cc229537131',
  responseType: 'code',
  scope: 'openid profile email offline_access efp.doka.com/legacyformwork dfds.doka.com/feedback',
  oidc: true,
  showDebugInformation: true,
}

export const authConfigNative: AuthConfig = {
  issuer: 'https://login.doka.com',
  redirectUri: 'com.doka.efp://oauth',
  strictDiscoveryDocumentValidation: false,
  clientId: '579b1616-0cde-47e4-9f23-a3600659a7f5',
  responseType: 'code',
  scope: 'openid profile email offline_access efp.doka.com/legacyformwork dfds.doka.com/feedback',
  oidc: true,
  showDebugInformation: true,
  logoutUrl: 'https://login.doka.com/connect/endsession',
}

export const feedbackEnvironment: string = Environment.prod
