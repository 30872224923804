import { Length, PlanType, UnitOfLength } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import {
  BACKGROUND_COLOR,
  DEFAULT_LABEL_COLOR,
  HOVER_OUTLINE_COLOR,
} from '../../../../constants/colors'
import { MeasureType } from '../../../../models/measureType'
import { EdgeAttributes } from '../../types/edgeAttributes'
import { InputLabel } from './InputLabel'

export const LENGTH_LABEL_TICK_SIZE = 3
export const CLICK_AREA_HIDDEN_OPACITY = 1e-7

export abstract class MeasurementLabel extends InputLabel {
  readonly length: Length
  public readonly centerPoint: paper.Point
  protected readonly path: paper.CompoundPath
  protected readonly text: paper.PointText
  protected readonly clickArea: paper.Path
  protected textBounds: paper.Rectangle

  public get inputOverlayPosition(): paper.Point {
    return this.clickArea.position
  }

  public hide(): void {
    this.text.visible = false
    this.clickArea.visible = false
  }

  public hover(): void {
    this.text.fillColor = HOVER_OUTLINE_COLOR
    this.path.strokeColor = HOVER_OUTLINE_COLOR
    this.clickArea.opacity = 1
  }

  public unhover(): void {
    this.clickArea.opacity = CLICK_AREA_HIDDEN_OPACITY
    this.text.fillColor = DEFAULT_LABEL_COLOR
    this.path.strokeColor = DEFAULT_LABEL_COLOR
  }

  protected constructor(
    from: paper.Point,
    to: paper.Point,
    fontSize: number,
    strokeWidth: number,
    public readonly bold: boolean,
    public readonly unit: UnitOfLength
  ) {
    super()
    this.strokeWidth = strokeWidth
    this.centerPoint = from.subtract(from.subtract(to).multiply(0.5))
    this.length = new Length(to.subtract(from).length, unit, false)

    // creating and positioning text
    this.text = new paper.PointText({
      content: this.length.toString(),
      fillColor: DEFAULT_LABEL_COLOR,
      justification: 'center',
      fontSize,
    })
    this.textBounds = this.text.bounds.clone()

    let textAngle = to.subtract(from).angle
    if (Math.abs(textAngle) > 90.001) {
      textAngle += 180
      this.text.rotate(textAngle)
    } else {
      this.text.rotate(textAngle)
    }

    if (bold) {
      this.text.fontWeight = 'bold'
    }

    // creating path
    this.path = this.generateLengthPath(from, to)

    // creating click area
    this.clickArea = new paper.Path.Rectangle(
      new paper.Rectangle(this.textBounds.clone().scale(2)),
      new paper.Size(strokeWidth * 2, strokeWidth * 2)
    ) // radius has to be zoom dependent as well
    this.clickArea.fillColor = BACKGROUND_COLOR
    this.clickArea.strokeColor = HOVER_OUTLINE_COLOR
    this.clickArea.strokeWidth = 1
    this.clickArea.opacity = 0.0000001
    this.clickArea.rotate(textAngle)

    this.addChild(this.clickArea)
    this.addChild(this.text)
  }

  get textRotation(): number {
    return this.text.rotation
  }

  getMeasureType(edgeAttributes: EdgeAttributes | undefined, buildingType: PlanType): MeasureType {
    if (!edgeAttributes) {
      return MeasureType.outerLength
    } else {
      const selectedLength = Math.round(this.length.valueOf() ?? 0)
      if (Math.round(edgeAttributes.thickness) === selectedLength) {
        return MeasureType.width
      } else if (
        buildingType === PlanType.WALL &&
        Math.round(edgeAttributes.innerLength) === selectedLength
      ) {
        return MeasureType.innerLength
      } else {
        return MeasureType.outerLength
      }
    }
  }

  protected abstract generateLengthPath(
    pointFrom: paper.Point,
    pointTo: paper.Point
  ): paper.CompoundPath

  protected moveText(to: paper.Point): void {
    this.text.bounds.center = to
    this.clickArea.bounds.center = to
  }
}
