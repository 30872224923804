import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Article } from '../../../models/article'
import { ArticleItemPage } from '../article-item/article-item.component'

export type ArticleForList = Pick<Article, 'articleId' | 'name'> & {
  demand?: number
  stock?: number
  difference?: number
  orderAmount?: number
  cycleUsage?: number[]
  usedCycleNumbers?: Set<number>
  amount?: number
}

@Component({
  selector: 'efp-article-list',
  templateUrl: './article-list.component.html',
})
export class ArticleListComponent {
  @Input() filteredArticles: ArticleForList[] = []
  @Input() selectedArticleIds: string[] = []
  @Input() articleForm?: FormGroup<{ [key: string]: FormControl<number> }>
  @Input() deletable = false
  @Input() articleItemPage!: ArticleItemPage
  @Input() disabledArticleIds?: Set<string>
  @Input() showArticleNumber = true
  @Input() showCalculation = false
  @Input() showCycleQuantity = false
  @Input() cycleNumber: number | null = null
  @Input() showArticleAmount = true

  @Output() readonly deleteClicked = new EventEmitter<string>()
  @Output() readonly selectClicked = new EventEmitter<string>()

  deleteArticle(articleId: string): void {
    this.deleteClicked.emit(articleId)
  }

  selectArticle(articleId: string): void {
    this.selectClicked.emit(articleId)
  }

  /**
   * checks if item is already in other relevant list (like current stock)
   * @param articleId
   * @returns
   */
  checkIfDisabled(articleId: string): boolean {
    const matchingItem = this.disabledArticleIds?.has(articleId)
    if (matchingItem) return matchingItem
    return false
  }

  trackByArticleId(_index: number, article: ArticleForList): string {
    return article.articleId
  }
}
