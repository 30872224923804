import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { DeployEnvironment } from './app/models/deploy-environment'
import { environment } from './environments/environment'
import { DefaultFavourites } from './app/utils/favourites/defaultFavourites'

if (environment.deployment !== DeployEnvironment.LOCAL) {
  enableProdMode()
}

DefaultFavourites.Instance().Init()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
