import { Country, FormworkId } from '@efp/api'
import { PlanType } from 'formwork-planner-lib'
import { FormworkSystem } from '../models/formworkSystem'
import { getAccessoryById } from './accessories'

/**
 * @deprecated This should be retrieved from a backend and not hardcoded
 */
export const formworkSystems = [
  // SLAB
  new FormworkSystem(
    FormworkId.Xstahlfr,
    [
      getAccessoryById('ZBELSTZFRA'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKONSOL90'),
      getAccessoryById('ZBGEGENXPFR'),
      getAccessoryById('ZBGEGENSGFR'),
      getAccessoryById('ZBBETBOFRA'),
      getAccessoryById('ZBRA-ABSTW'),
    ],
    [],
    PlanType.WALL,
    660,
    659.765
  ), // reset height when 3D is ready --> 810
  new FormworkSystem(
    FormworkId.Xframi,
    [
      getAccessoryById('ZBJUSTSPIN'),
      getAccessoryById('ZBKONSOL60'),
      getAccessoryById('ZBSTANDKON'),
      getAccessoryById('ZBGEGENXPFI'),
      getAccessoryById('ZBRA-ABSTWI'),
    ],
    [],
    PlanType.WALL,
    600,
    599.44
  ),
  new FormworkSystem(
    FormworkId.Xfrplus,
    [
      getAccessoryById('ZBELSTZFRA'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKONSOL90'),
      getAccessoryById('ZBGEGENXPFR'),
      getAccessoryById('ZBGEGENSGFR'),
      getAccessoryById('ZBBETBOFRA'),
      getAccessoryById('ZBRA-ABSTW'),
    ],
    [],
    PlanType.WALL,
    660,
    659.765
  ), // reset height when 3D is ready --> 810
  new FormworkSystem(
    FormworkId.Xreforma,
    [getAccessoryById('ZBELSTZREF'), getAccessoryById('ZBKONREFOR')],
    [],
    PlanType.WALL,
    660,
    659.765
  ),
  new FormworkSystem(
    FormworkId.Xlight,
    [
      getAccessoryById('ZBJUSPIXLI'),
      getAccessoryById('ZBKON60XLI'),
      getAccessoryById('ZBRA-ABSTWX'),
    ],
    [],
    PlanType.WALL,
    450,
    449.58
  ),
  new FormworkSystem(
    FormworkId.XframiS,
    [
      getAccessoryById('ZBJUSTSP-S'),
      getAccessoryById('ZBKONS60-S'),
      getAccessoryById('ZBGEGENXPFI'),
    ],
    [Country.Us],
    PlanType.WALL,
    548,
    547.37
  ), // reset height when 3D is ready --> 820
  new FormworkSystem(
    FormworkId.XframaS,
    [
      getAccessoryById('ZBELSFRA-S'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKONSOL90'),
      getAccessoryById('ZBGEGENSGFR'),
      getAccessoryById('ZBBETBOFRA'),
    ],
    [],
    PlanType.WALL,
    405,
    403.87
  ), // reset height when 3D is ready --> 1080
  new FormworkSystem(
    FormworkId.Xalufr,
    [
      getAccessoryById('ZBELSTZFRA'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKON90-AL'),
      getAccessoryById('ZBGEGENXPFR'),
      getAccessoryById('ZBBETBOFRA'),
      getAccessoryById('ZBRA-ABSTW'),
    ],
    [],
    PlanType.WALL,
    540,
    539.75
  ),
  new FormworkSystem(
    FormworkId.Xfreco,
    [
      getAccessoryById('ZBELSTZFRA'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKONSOL90'),
      getAccessoryById('ZBGEGENXPFR'),
      getAccessoryById('ZBGEGENSGFR'),
      getAccessoryById('ZBBETBOFRA'),
    ],
    [],
    PlanType.WALL,
    600,
    599.44
  ),
  new FormworkSystem(
    FormworkId.Xframini,
    [getAccessoryById('ZBELSTZFINI'), getAccessoryById('ZBKONFINI')],
    [],
    PlanType.WALL,
    450,
    449.58
  ),
  new FormworkSystem(
    FormworkId.Xfr30,
    [
      getAccessoryById('ZBELSTZFRA'),
      getAccessoryById('ZBBETBUFRA'),
      getAccessoryById('ZBKONSOL90'),
      getAccessoryById('ZBGEGENXPFR'),
      getAccessoryById('ZBGEGENSGFR'),
      getAccessoryById('ZBBETBOFRA'),
    ],
    [],
    PlanType.WALL,
    600,
    599.44
  ),
  new FormworkSystem(
    FormworkId.Xreformx,
    [getAccessoryById('ZBELSTZREX'), getAccessoryById('ZBKONREFOR')],
    [],
    PlanType.WALL,
    660,
    659.765
  ),
  new FormworkSystem(FormworkId.Flex124, [], [], PlanType.SLAB, 0, 0),
  new FormworkSystem(FormworkId.Flexs, [], [], PlanType.SLAB, 0, 0),
] as const

/**
 * @deprecated This should be retrieved from a backend and not hardcoded
 */
export const supportedSystems: Readonly<FormworkId[]> = [
  FormworkId.Xstahlfr,
  FormworkId.Xfrplus,
  FormworkId.Xframi,
  FormworkId.Xlight,
  FormworkId.XframiS,
  FormworkId.XframaS,
  FormworkId.Xalufr,
  FormworkId.Xreforma,
  FormworkId.Xframini,
  FormworkId.Xfreco,
  FormworkId.Flex124,
  FormworkId.Flexs,
  FormworkId.Xfr30,
  FormworkId.Xreformx,
]
