import { Part } from '../../../../../models/part'

export class PartListItem implements Pick<Part, 'articleId' | 'name' | 'amount'> {
  public orderAmount: number
  public difference: number

  public get articleId(): string {
    return this.part.articleId
  }

  public get name(): string {
    return this.part.name
  }

  public get amount(): number {
    return this.part.amount
  }

  public get stock(): number {
    return this.part.stock
  }

  public get demand(): number {
    return this.part.demand
  }

  public get cycleUsage(): number[] {
    return this.part.cycleUsage
  }

  public get usedCycleNumbers(): Set<number> {
    return this.part.usedCycleNumbers
  }

  constructor(public part: Part) {
    this.difference = part.demand - part.stock
    this.orderAmount = this.difference > 0 ? this.difference : 0
  }

  public compareTo(other: PartListItem): number {
    return this.difference - other.difference
  }
}

export interface PartListGroup {
  groupApiId: string
  name: string
  partListItems: PartListItem[]
}

export interface PartListGroupResult {
  partListGroups: PartListGroup[]
  partListGroupNames: Set<string>
}
