import { Injectable } from '@angular/core'
import { PlanResultApiClient, PlanResultImageModel, PlanResultThumbnailModel } from '@efp/api'
import { PlanResult } from '../../../models/planResult'
import {
  PlanResultDao,
  mapPlanResultModelToPlanResult,
  mapPlanResultToCreatePlanResultCommandParam,
} from '../plan-result.dao'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class PlanResultHttpDao extends PlanResultDao {
  constructor(private readonly planResultApiClient: PlanResultApiClient) {
    super()
  }

  public async savePlanResult(result: Omit<PlanResult, 'planId'>, planId: number): Promise<void> {
    if (!result || !planId) {
      throw new Error('Provided plan id or result ZIP are empty')
    }

    await firstValueFrom(
      this.planResultApiClient.createPlanResult({
        body: mapPlanResultToCreatePlanResultCommandParam({
          ...result,
          planId,
        }),
      })
    )
  }

  public async getPlanResult(planId: number): Promise<PlanResult | undefined> {
    const planResult = await firstValueFrom(this.planResultApiClient.getPlanResult({ planId }))
    return planResult ? mapPlanResultModelToPlanResult(planResult) : undefined
  }

  public async getPlanResultImage(planId: number): Promise<PlanResultImageModel | undefined> {
    return firstValueFrom(this.planResultApiClient.getPlanResultImage({ planId }))
  }

  public async getPlanResultThumbnail(
    planId: number
  ): Promise<PlanResultThumbnailModel | undefined> {
    return firstValueFrom(this.planResultApiClient.getPlanResultThumbnail({ planId }))
  }

  public async getIsCalculated(planId: number): Promise<boolean> {
    return firstValueFrom(this.planResultApiClient.getPlanResultCalculated({ planId }))
  }

  /**
   * Remove plan results, updates the current plan navigation step and remove changed result parts & screenshots.
   */
  public async resetCalculation(planId: number): Promise<void> {
    await firstValueFrom(this.planResultApiClient.deletePlanResult({ planId }))
  }

  // only for native migrations from planResult
  public async savePlanResultFromFolder(): Promise<void> {
    return
  }
}
