import { Type } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryRouteComponent = (
  snapshot: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: Type<any>
): boolean => {
  if (snapshot.component === component) {
    return true
  }

  return snapshot.children.find((child) => queryRouteComponent(child, component)) != null
}
