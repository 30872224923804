import { Injectable } from '@angular/core'
import { startWith, pairwise, filter, map } from 'rxjs'
import { PlanSettings } from '../../models/planSettings'
import { planStore } from '../../repositories/plan-creation.repository'
import { getEntityByPredicate } from '@ngneat/elf-entities'
import { PlanSettingsRepository } from '../../repositories/plan-settings.repository'

@Injectable({
  providedIn: 'root',
})
export class ResetAccessoriesService {
  public planIdsNeededResetOfAccessories$ = this.planSettingsRepository.planSettings$.pipe(
    startWith(null),
    pairwise(),
    filter(
      (
        prevAndCurrentValue: [PlanSettings[] | null, PlanSettings[] | null]
      ): prevAndCurrentValue is [PlanSettings[], PlanSettings[]] =>
        !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
    ),
    map(([previousSettings, currentSettings]) => {
      const settingsIdToBeRecalculated: number[] = []

      currentSettings.forEach((currentSetting) => {
        const previousSetting = previousSettings.find((e) => e.id === currentSetting.id)

        if (this.isResetOfAccessoriesNeeded(currentSetting, previousSetting)) {
          settingsIdToBeRecalculated.push(currentSetting.id)
        }
      })

      const planIds: number[] = []
      settingsIdToBeRecalculated.forEach((settingId) => {
        const plan = planStore.query(
          getEntityByPredicate(({ settingsId }) => settingsId === settingId)
        )

        if (plan) {
          planIds.push(plan.id)
        }
      })
      return planIds
    })
  )

  constructor(private readonly planSettingsRepository: PlanSettingsRepository) {}

  /* 
    Keep method synched with backend & sql daos
  */
  private isResetOfAccessoriesNeeded(
    currentSettings: PlanSettings,
    previousSettings?: PlanSettings
  ): boolean {
    // If formwork changed, reset
    if (
      previousSettings &&
      (previousSettings.formworkWall !== currentSettings.formworkWall ||
        previousSettings.formworkSlab !== currentSettings.formworkSlab)
    ) {
      return true
    }

    return false
  }
}
