import { Injectable } from '@angular/core'
import { Screenshot } from '../../models/screenshot'
import { ScreenshotCommandParams, ScreenshotModel } from '../../../generated/efp-api'

export const mapScreenshotToScreenshotModel = (screenshot: Screenshot): ScreenshotModel => {
  return {
    cycle: screenshot.cycle,
    date: screenshot.date.toISOString(),
    defaultView: screenshot.defaultView,
    height: screenshot.height,
    id: screenshot.id,
    name: screenshot.name,
    planId: screenshot.planId,
    screenshotData: screenshot.screenshot,
    width: screenshot.width,
  }
}

export const mapModelToScreenshot = (model: ScreenshotModel): Screenshot => {
  return {
    cycle: model.cycle,
    date: new Date(model.date),
    defaultView: model.defaultView,
    height: model.height,
    id: model.id,
    name: model.name,
    planId: model.planId,
    screenshot: model.screenshotData,
    useInPdf: model.defaultView,
    width: model.width,
  }
}

@Injectable()
export abstract class ScreenshotDao {
  abstract create(params: ScreenshotCommandParams): Promise<Screenshot>

  abstract findAllByPlanId(planId: number): Promise<Screenshot[]>

  abstract delete(screenId: number): Promise<void>

  abstract deleteAllByPlanId(planId: number): Promise<void>

  abstract updateName(id: number, name: string): Promise<void>
}
