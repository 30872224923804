import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { AccessoryLineSelection } from '../../../planner/types/accessoryLineSelection'

@Component({
  selector: 'efp-accessory-line-picker',
  templateUrl: './accessory-line-picker.component.html',
})
export class AccessoryLinePickerComponent implements OnInit {
  readonly accessoryLineSelection = AccessoryLineSelection

  constructor(public popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  setSelection(value: AccessoryLineSelection): void {
    void this.popoverCtrl.dismiss(value)
  }
}
