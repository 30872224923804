import { Injectable } from '@angular/core'
import { NavStep } from '../../../models/navSteps'
import { Plan } from '../../../models/plan'
import { DataService } from '../../data.service'
import { PlanDao } from '../plan.dao'
import { CycleDao } from '../cycle.dao'
import { PlanOutlineDao } from '../plan-outline.dao'
import { PlanAccessoryLineDao } from '../plan-accessory-line.dao'
import { PlanResultResetCalculationDao } from '../plan-result-reset-calculation.dao'

@Injectable()
export class PlanSqlDao extends PlanDao {
  constructor(
    private readonly dataService: DataService,
    private readonly cycleDao: CycleDao,
    private readonly planOutlineDao: PlanOutlineDao,
    private readonly planAccessoryLineDao: PlanAccessoryLineDao,
    private readonly planResultResetCalculationDao: PlanResultResetCalculationDao
  ) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractPlanFromRow(row: any): Plan {
    return {
      id: row.id,
      name: row.name,
      date: row.date,
      lastUsed: row.lastUsed,
      buildingType: row.buildingType,
      stockId: Number.parseInt(row.stockId, 10) || null,
      projectId: row.projectId,
      settingsId: row.settingsId,
      currentStep: row.currentStep,
      serializedMesh: row.serializedMesh,
    }
  }

  private extractPlansFromResult(result: SQLResultSet): Plan[] {
    const plans: Plan[] = []
    if (result.rows.length > 0) {
      for (let i = 0; i < result.rows.length; i++) {
        const row = result.rows.item(i)
        const plan = this.extractPlanFromRow(row)
        plans.push(plan)
      }
    }
    return plans
  }

  public static async insertPlan(
    dataService: DataService,
    plan: Omit<Plan, 'id' | 'date'>
  ): Promise<Plan> {
    const date = new Date()
    const statement = `INSERT INTO Plans (name,
                                          date,
                                          lastUsed,
                                          buildingType,
                                          stockId,
                                          projectId,
                                          settingsId,
                                          currentStep,
                                          serializedMesh)
                        VALUES (?,?,?,?,?,?,?,?,?)`
    const values = [
      plan.name,
      date,
      plan.lastUsed,
      plan.buildingType,
      plan.stockId,
      plan.projectId,
      plan.settingsId,
      plan.currentStep,
      plan.serializedMesh ? plan.serializedMesh : '',
    ]

    const result = await dataService.executeStatement(statement, values)
    return {
      buildingType: plan.buildingType,
      currentStep: plan.currentStep,
      date,
      id: result.insertId,
      lastUsed: plan.lastUsed,
      name: plan.name,
      projectId: plan.projectId,
      settingsId: plan.settingsId,
      stockId: plan.stockId,
      serializedMesh: plan.serializedMesh,
    }
  }

  /**
   * Updates the given plan data in the database.
   *
   * If the used stock-list has been changed and the plan was already calculated, calculation will be reset.
   */
  public async updatePlan(
    plan: Pick<Plan, 'id' | 'name' | 'date' | 'buildingType' | 'stockId' | 'projectId' | 'lastUsed'>
  ): Promise<Plan> {
    const oldPlan = await this.findOne(plan.id)
    if (!oldPlan) {
      throw new Error(`PlanDao.updatePlan - Plan with id ${plan.id} not found`)
    }
    const stockChanged = oldPlan.stockId !== plan.stockId

    if (stockChanged) {
      await this.planResultResetCalculationDao.resetCalculation(plan.id)
    }

    const statement =
      'UPDATE Plans SET name = ?, date = ?, buildingType = ?, stockId = ?, projectId = ? WHERE id = ?'
    const values = [plan.name, new Date(), plan.buildingType, plan.stockId, plan.projectId, plan.id]

    await this.dataService.executeStatement(statement, values)

    const newPlan = await this.findOne(plan.id)
    if (!newPlan) {
      throw new Error(`PlanDao.updatePlan - Plan with id ${plan.id} not found`)
    }
    return newPlan
  }

  public async updateSerializedMesh(id: number, serializedMesh?: string): Promise<Plan> {
    await this.dataService.executeStatement(
      'UPDATE Plans SET serializedMesh = ?, date = ? WHERE id = ?',
      [serializedMesh, new Date(), id]
    )
    await this.planResultResetCalculationDao.resetCalculation(id)

    const newPlan = await this.findOne(id)
    if (!newPlan) {
      throw new Error(`PlanDao.updatePlan - Plan with id ${id} not found`)
    }
    return newPlan
  }

  public async updateCurrentStep(planId: number, currentStep: NavStep): Promise<Plan> {
    const statement = 'UPDATE Plans SET currentStep = ?, lastUsed = ? WHERE id = ?'
    const values = [currentStep, new Date(), planId]
    await this.dataService.executeStatement(statement, values)

    const newPlan = await this.findOne(planId)
    if (!newPlan) {
      throw new Error(`PlanDao.updateCurrentStep - Plan with id ${planId} not found`)
    }
    return newPlan
  }

  public async findOne(planId: number): Promise<Plan | undefined> {
    const statement = 'SELECT * FROM Plans where id=?'
    const values = [planId]

    let plan: Plan | undefined = undefined
    const result = await this.dataService.executeStatement(statement, values)
    if (result.rows.length === 1) {
      plan = this.extractPlanFromRow(result.rows.item(0))
    }

    return plan
  }

  public async findOneBySettingsId(settingsId: number): Promise<Plan | undefined> {
    const statement = 'SELECT * FROM Plans where settingsId=?'
    const values = [settingsId]

    let plan: Plan | undefined = undefined
    const result = await this.dataService.executeStatement(statement, values)
    if (result.rows.length === 1) {
      plan = this.extractPlanFromRow(result.rows.item(0))
    }

    return plan
  }

  public async findAllByProjectId(projectId: number): Promise<Plan[]> {
    const statement = 'SELECT * FROM Plans where projectId=?'
    const values = [projectId]

    const result = await this.dataService.executeStatement(statement, values)
    return this.extractPlansFromResult(result)
  }
  public async findAllByFavouriteId(favouriteId: number): Promise<Plan[]> {
    const statement =
      'SELECT p.* FROM Plans p JOIN PlanSettings ps ON p.settingsId = ps.id WHERE ps.wallFavId = ? OR ps.slabFavId = ? '

    const result = await this.dataService.executeStatement(statement, [favouriteId, favouriteId])

    return this.extractPlansFromResult(result)
  }

  public async findAllByStockId(stockId: number): Promise<Plan[]> {
    const statement = 'SELECT * FROM Plans WHERE stockId=?'
    const values = [stockId]

    const result = await this.dataService.executeStatement(statement, values)
    return this.extractPlansFromResult(result)
  }

  public async findAllWithSerializedMesh(): Promise<Plan[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * FROM Plans WHERE serializedMesh IS NOT NULL'
    )
    return this.extractPlansFromResult(result)
  }

  public async findRecentPlans(amount: number): Promise<Plan[]> {
    const result = await this.dataService.executeStatement('SELECT * FROM Plans')
    const plans = this.extractPlansFromResult(result)
    return plans
      .sort((a, b) => {
        return +new Date(b.lastUsed) - +new Date(a.lastUsed)
      })
      .slice(0, plans.length > amount ? amount : plans.length)
  }

  public async findAllPlans(): Promise<Plan[]> {
    const result = await this.dataService.executeStatement('SELECT * FROM Plans')
    return this.extractPlansFromResult(result)
  }

  public async count(): Promise<number> {
    const statement = 'SELECT COUNT(*) AS count FROM Plans'

    const result = await this.dataService.executeStatement(statement)
    if (result.rows.length === 1) {
      return result.rows.item(0).count
    } else {
      throw new Error('PlanDao.count - Unexpected result')
    }
  }

  public async countStocksById(stockId: number): Promise<number> {
    const statement = 'SELECT COUNT(*) AS count FROM Plans WHERE id = ? AND stockId NOT NULL'
    const values = [stockId]

    const result = await this.dataService.executeStatement(statement, values)
    return result.rows.item(0).count
  }

  public async deleteWithAllRelated(plan: Plan): Promise<void> {
    await this.planResultResetCalculationDao.resetCalculation(plan.id)
    await this.cycleDao.removeAllCycleBoundariesByPlanId(plan.id)
    await this.cycleDao.removeAllSymbolsByPlanId(plan.id)
    await this.planAccessoryLineDao.deleteAccessoryLinesForPlan(plan.id)
    await this.planOutlineDao.removeAllByPlanId(plan.id)

    await this.dataService.executeStatement('DELETE FROM Plans WHERE id = ?', [plan.id])
    await this.dataService.executeStatement('DELETE FROM PlanSettings WHERE id = ?', [
      plan.settingsId,
    ])
  }
}
