import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-modal-base',
  templateUrl: './modal-base.component.html',
})
export class ModalBaseComponent implements OnInit {
  rootPage: unknown
  rootParams: undefined | { [key: string]: unknown }

  constructor() {}

  ngOnInit(): void {}
}
