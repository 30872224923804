import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'efp-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
})
export class ModalFormComponent {
  constructor(public readonly modalCtrl: ModalController) {}

  public async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
}
