import { FavoritesLanguageConfig } from '../../constants/favouritesLangJSON'
import { FavouriteSystemStileWrapper } from '../../models/favourites'

import favouritesArticles from '../../../assets/favouritesArticlesJSON.json'
import favouritesTranslation from '../../../assets/favouritesTranslationJSON.json'
import { FAVOURITES_VERSION } from '../../constants/versions'

export class DefaultFavourites {
  private static instance: DefaultFavourites

  private favouritesArticlesJSON: FavouriteSystemStileWrapper | undefined = undefined
  private favouritesTranslationJSON: FavoritesLanguageConfig | undefined = undefined

  public static version = FAVOURITES_VERSION

  private constructor() {}

  public static Instance(): DefaultFavourites {
    if (!DefaultFavourites.instance) {
      DefaultFavourites.instance = new DefaultFavourites()
    }
    return DefaultFavourites.instance
  }

  public Init(): void {
    this.favouritesArticlesJSON = favouritesArticles as FavouriteSystemStileWrapper
    this.favouritesTranslationJSON = favouritesTranslation as FavoritesLanguageConfig
  }

  public get FavouritesArticles(): FavouriteSystemStileWrapper {
    return this.favouritesArticlesJSON ?? ({} as FavouriteSystemStileWrapper)
  }

  public get FavouritesTranslations(): FavoritesLanguageConfig {
    return this.favouritesTranslationJSON ?? ({} as FavoritesLanguageConfig)
  }
}
