import { Injectable } from '@angular/core'
import { ResultStep } from '../models/resultStep'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from './applicationInsights.service'

@Injectable({
  providedIn: 'root',
})
export class ResultTabService {
  private allowTabChange = true
  private resultStep: ResultStep = ResultStep.NONE

  constructor(private readonly appInsightsService: ApplicationInsightsService) {}

  getResultStep(): ResultStep {
    return this.resultStep
  }

  setResultStep(tab: ResultStep, planId?: number): void {
    this.resultStep = tab
    if (tab && planId) {
      this.appInsightsService.addUserEvent(
        ApplicationInsightsStates.PLAN_STEP_CHANGED + ' ' + tab,
        planId
      )
    }
  }

  // 59842: wait for 3d Viewer to finish loading
  setAllowTabChange(allowChange: boolean): void {
    this.allowTabChange = allowChange
  }

  getAllowTabChange(): boolean {
    return this.allowTabChange
  }
}
