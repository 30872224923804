/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccessoryLineModel } from '../models/accessory-line-model';
import { CreateAccessoryLineCommandParam } from '../models/create-accessory-line-command-param';
import { UpdateAccessoryLineCommandParam } from '../models/update-accessory-line-command-param';

@Injectable({ providedIn: 'root' })
export class AccessoryLinesApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createAccessoryLines()` */
  static readonly CreateAccessoryLinesPath = '/api/v1/accessory-lines';

  /**
   * Add a new accessoryLine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccessoryLines$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccessoryLines$Plain$Response(
    params: {
      body: Array<CreateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.CreateAccessoryLinesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Add a new accessoryLine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccessoryLines$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccessoryLines$Plain(
    params: {
      body: Array<CreateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.createAccessoryLines$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /**
   * Add a new accessoryLine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccessoryLines()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccessoryLines$Response(
    params: {
      body: Array<CreateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.CreateAccessoryLinesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Add a new accessoryLine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccessoryLines$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAccessoryLines(
    params: {
      body: Array<CreateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.createAccessoryLines$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /** Path part for operation `updateAccessoryLine()` */
  static readonly UpdateAccessoryLinePath = '/api/v1/accessory-lines';

  /**
   * Update existing accessoryLine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccessoryLine$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessoryLine$Plain$Response(
    params: {
      body: Array<UpdateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.UpdateAccessoryLinePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Update existing accessoryLine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccessoryLine$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessoryLine$Plain(
    params: {
      body: Array<UpdateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.updateAccessoryLine$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /**
   * Update existing accessoryLine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccessoryLine()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessoryLine$Response(
    params: {
      body: Array<UpdateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.UpdateAccessoryLinePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Update existing accessoryLine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccessoryLine$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessoryLine(
    params: {
      body: Array<UpdateAccessoryLineCommandParam>
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.updateAccessoryLine$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /** Path part for operation `getAccessoryLinesByPlan()` */
  static readonly GetAccessoryLinesByPlanPath = '/api/v1/plans/{planId}/accessory-lines';

  /**
   * Get accessoryLines per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessoryLinesByPlan$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessoryLinesByPlan$Plain$Response(
    params: {
      planId: number;
      includeOutlines?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.GetAccessoryLinesByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.query('includeOutlines', params.includeOutlines, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Get accessoryLines per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessoryLinesByPlan$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessoryLinesByPlan$Plain(
    params: {
      planId: number;
      includeOutlines?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.getAccessoryLinesByPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /**
   * Get accessoryLines per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessoryLinesByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessoryLinesByPlan$Response(
    params: {
      planId: number;
      includeOutlines?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AccessoryLineModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.GetAccessoryLinesByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.query('includeOutlines', params.includeOutlines, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessoryLineModel>>;
      })
    );
  }

  /**
   * Get accessoryLines per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessoryLinesByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessoryLinesByPlan(
    params: {
      planId: number;
      includeOutlines?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AccessoryLineModel>> {
    return this.getAccessoryLinesByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccessoryLineModel>>): Array<AccessoryLineModel> => r.body)
    );
  }

  /** Path part for operation `deleteAccessoryLinesByPlan()` */
  static readonly DeleteAccessoryLinesByPlanPath = '/api/v1/plans/{planId}/accessory-lines';

  /**
   * Delete accessoryLines per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccessoryLinesByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessoryLinesByPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccessoryLinesApiClient.DeleteAccessoryLinesByPlanPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete accessoryLines per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccessoryLinesByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessoryLinesByPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAccessoryLinesByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
