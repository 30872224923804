import { Injectable } from '@angular/core'
import { AuthenticationRepository } from '../repositories/authentication-repository.service'
import { PlanRepository } from '../repositories/plan.repository'

@Injectable({
  providedIn: 'root',
})
export class LimitService {
  private isTestUser = false

  get canCreateProject(): boolean {
    return !this.isTestUser
  }

  async canCreatePlan(): Promise<boolean> {
    return !this.isTestUser || (await this.planRepository.count()) === 0
  }

  constructor(
    private readonly planRepository: PlanRepository,
    private readonly authenticationRepository: AuthenticationRepository
  ) {
    this.authenticationRepository.testAuthenticated$.subscribe((isUsingTestAuth) => {
      this.isTestUser = isUsingTestAuth
    })
  }
}
