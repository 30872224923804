import { Injectable } from '@angular/core'
// eslint-disable-next-line no-restricted-imports
import { saveAs } from 'file-saver'
import { FileService } from '../file.service'
import { Translation } from '../translation.service'

@Injectable()
export class BrowserFileService extends FileService {
  private readonly storage = new Map<string, Blob>()

  constructor(translation: Translation) {
    super(translation)
  }

  public async writeBlobToFile(file: Blob, path: string): Promise<string> {
    await this.writeFile(path, file)

    return window.URL.createObjectURL(file)
  }

  async shareFiles(title: string, text: string, filePaths: string[]): Promise<void> {
    throw new Error('Not implemented ' + title + text + filePaths)
  }

  async shareOrDownloadBlobFile(content: Blob, _: string, name: string): Promise<void> {
    saveAs(content, name)
  }

  async shareOrDownloadTextFile(content: string, _: string, name: string): Promise<void> {
    const blobContent = new Blob([content], {})
    saveAs(blobContent, name)
  }

  public async getUri(path: string): Promise<string> {
    return path
  }

  async readGlobalFile(path: string): Promise<ArrayBuffer> {
    return this.readFileAsArrayBuffer(path)
  }

  public async readFileAsArrayBuffer(path: string): Promise<ArrayBuffer> {
    return this.getFile(path).arrayBuffer()
  }

  async readTextFile(path: string): Promise<string> {
    return this.getFile(path).text()
  }

  async readDir(_: string): Promise<null> {
    return null
  }

  async writeTextToFile(path: string, textContent: string): Promise<string> {
    await this.writeFile(path, new Blob([textContent]))
    return path
  }

  async removeFile(path: string): Promise<void> {
    this.storage.delete(path)
  }

  private getFile(path: string): Blob {
    const file = this.storage.get(path)
    if (!file) {
      throw Error('File does not exist')
    }

    return file
  }

  private async writeFile(path: string, content: Blob): Promise<void> {
    this.storage.set(path, content)
  }
}
