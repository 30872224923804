import paper from 'paper/dist/paper-core'
import {
  Edge,
  LinePosition,
  getLineSide,
  getOtherLineSide,
  LineSide,
  MeshPoint,
  SnappingInfo,
} from '../../model'
import { EdgeAngle } from '../../model/edge/EdgeAngle'
import { xor } from '../boolean'
import { intersectLines } from './intersectLines'

export function calculateOuterCornerPoint(
  anchor: MeshPoint,
  info?: SnappingInfo
): paper.Point | undefined {
  const edgeAngles = anchor.calculateEdgeAngles()

  let angle = -1
  let edgeAngle1: EdgeAngle | undefined
  let edgeAngle2: EdgeAngle | undefined
  if (edgeAngles.length > 1) {
    for (let i = 0; i < edgeAngles.length; i++) {
      const currentEdgeAngle1 = edgeAngles[i]
      const currentEdgeAngle2 = edgeAngles[(i + 1) % edgeAngles.length]

      const angle1 = currentEdgeAngle1.angle
      const angle2 = currentEdgeAngle2.angle

      const currentAngle = angle2 < angle1 ? angle2 + 360 - angle1 : angle2 - angle1

      if (currentAngle > angle) {
        angle = currentAngle
        edgeAngle1 = currentEdgeAngle1
        edgeAngle2 = currentEdgeAngle2
      }
    }
  }

  if (edgeAngle1 && edgeAngle2) {
    if (angle > 180) {
      return calculateOuterCornerBetweenTwoEdges(edgeAngle1.edge, edgeAngle2.edge)
    }
  }

  if (info) {
    const outerCornerSide = getLineSide(anchor, info.edge.getOtherPoint(anchor), info.destination)
    const isAnchorAtNeighborStart = info.edge.startPoint === anchor
    const neighborSide = isAnchorAtNeighborStart
      ? getOtherLineSide(outerCornerSide)
      : outerCornerSide
    const neighborLine = info.edge.getLineOnSide(neighborSide)

    return isAnchorAtNeighborStart ? neighborLine.start : neighborLine.end
  }

  return undefined
}

function calculateOuterCornerBetweenTwoEdges(edge1: Edge, edge2: Edge): paper.Point | undefined {
  const connectedPoint = edge1.getConnectedPoint(edge2)
  if (!connectedPoint) {
    return undefined
  }

  const position1 = edge1.getPosition(connectedPoint)
  const position2 = edge2.getPosition(connectedPoint)

  if (!position1) {
    return undefined
  }

  const direction1 = edge1.getDirection(position1)
  const direction2 = edge2.getDirection(position2)

  const angle = edge1.getAngle(edge2)

  if (!angle) {
    return undefined
  }

  const cornerSide = xor(angle > 0, position1 === LinePosition.END) ? LineSide.LEFT : LineSide.RIGHT
  const estimatedCornerLine = edge1.getLineOnSide(cornerSide)
  const estimatedCornerPoint = estimatedCornerLine[position1]

  if (Math.abs(Math.abs(direction1.angle - direction2.angle) - 180) < 0.01) {
    return estimatedCornerPoint
  }

  const normal1 = new paper.Point(direction1.y, -direction1.x).normalize(edge1.thickness / 2)
  const normal2 = new paper.Point(-direction2.y, direction2.x).normalize(edge2.thickness / 2)

  const leftCornerPoint = intersectLines(
    {
      start: edge1.startPoint.add(normal1),
      end: edge1.endPoint.add(normal1),
    },
    {
      start: edge2.startPoint.add(normal2),
      end: edge2.endPoint.add(normal2),
    }
  )
  const rightCornerPoint = intersectLines(
    {
      start: edge1.startPoint.subtract(normal1),
      end: edge1.endPoint.subtract(normal1),
    },
    {
      start: edge2.startPoint.subtract(normal2),
      end: edge2.endPoint.subtract(normal2),
    }
  )

  if (!leftCornerPoint || !rightCornerPoint) {
    return undefined
  }

  if (
    estimatedCornerPoint.subtract(leftCornerPoint).length <
    estimatedCornerPoint.subtract(rightCornerPoint).length
  ) {
    return leftCornerPoint
  } else {
    return rightCornerPoint
  }
}
