import { CycleBoundaryDrawable, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { SlabModel } from '../model/SlabModel'
import { RenderService } from './render.service'
import { SelectionService } from './selection.service'

const DEFAULT_SLAB_STYLE = {
  strokeColor: new paper.Color('black'),
  strokeWidth: 1,
} as paper.Style
const INACTIVE_SLAB_STYLE = { strokeWidth: 0 } as paper.Style

export class SlabRenderService extends RenderService<SlabModel> {
  public constructor(
    model: SlabModel,
    paperScope: paper.PaperScope,
    planVisibilitySettings: PlanVisibilitySettings,
    selectionService?: SelectionService,
    public inactive = false
  ) {
    super(model, paperScope, PlanType.SLAB, planVisibilitySettings, selectionService)
  }

  protected getMeshStyle(): paper.Style {
    return this.inactive ? INACTIVE_SLAB_STYLE : DEFAULT_SLAB_STYLE
  }

  protected refreshLabels(cycleBoundaries?: CycleBoundaryDrawable[]): void {
    const path = this.previewPath ?? this.path

    this.labelService.generateAngleLabelsForPath(path, true)
    this.labelService.generateLengthLabelsForPath(
      path,
      false,
      this.model.drawSetting.measurementUnit,
      cycleBoundaries
    )
  }
}
