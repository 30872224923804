import { Injectable } from '@angular/core'
import { OnboardingTrackingApiClient } from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { OnboardingDao } from '../onboarding.dao'

@Injectable()
export class OnboardingHttpDao extends OnboardingDao {
  constructor(private onboardingClient: OnboardingTrackingApiClient) {
    super()
  }

  async createOnboardingTracking(onboardingHintSeriesKey: string): Promise<string[]> {
    await firstValueFrom(
      this.onboardingClient.createOnboardingTracking({ onboardingHintKey: onboardingHintSeriesKey })
    )
    return this.findAllTrackings()
  }

  async findAllTrackings(): Promise<string[]> {
    return firstValueFrom(this.onboardingClient.getOnboardingTracking())
  }

  public async deleteByKey(key: string): Promise<void> {
    return firstValueFrom(
      this.onboardingClient.deleteOnboardingTracking({ onboardingHintSeriesKey: key })
    )
  }

  public async deleteAll(): Promise<void> {
    return firstValueFrom(this.onboardingClient.deleteAllOnboardingTrackings())
  }
}
