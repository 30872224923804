import {
  asMeshPoint,
  Edge,
  MeshPoint,
  PlanType,
  SlabMesh,
  SNAPPING_DISTANCE,
  SNAPPING_ERROR_TOLERANCE,
  SnapResult,
  TransformationLengthDirection,
  TransformationThicknessDirection,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { DrawSettings } from '../../../models/draw-settings'
import { UndoRedoHistory } from '../../../models/history/undoRedoHistory'
import { flatMap } from '../../../utils/flatMap'
import { Line2D } from '../../../utils/geometry/Line2D'
import { EdgeAttributes } from '../types/edgeAttributes'
import { SlabUndoRedoHistory } from './history/SlabUndoRedoHistory'
import { parseSimplifiedSlabMesh, serializeMesh } from './MeshSerializer'
import { Model } from './Model'
import { snapFreePoint, snapSingleNeighborAnchoredPoint } from './snapping/snapPoint'
import { WallModel } from './WallModel'

/**
 * Contains mesh data for a wall and slab model, as well as an undo/redo history.
 */
export class SlabModel extends Model<SlabMesh> {
  constructor(
    paperScope: paper.PaperScope,
    drawSetting: DrawSettings,
    cachedHistory?: UndoRedoHistory<SlabMesh>
  ) {
    super(paperScope, drawSetting, cachedHistory)
  }

  // creates the paths for a wall that is below the slab edges to support the slab
  createWallForSlab(): paper.CompoundPath {
    const slabPaths = this.createPath()
    const wallPaths = this.createWallPath(this.drawSetting.wallThickness * 2)
    const resultPaths = new paper.CompoundPath({ children: [] })

    // take one side of the created wall and use it together with the slabPath (the middle of the generated wall)
    // as the outline of the wall under the slab
    slabPaths.children.forEach((slabPath) => {
      wallPaths.children.forEach((wallPath) => {
        if (
          (slabPath as paper.Path).curves.every(
            (curve) => wallPath.contains(curve.point1) && slabPath.contains(curve.point2)
          )
        ) {
          resultPaths.addChild(slabPath.clone())
          resultPaths.addChild(wallPath.clone())
        }
      })
    })

    return resultPaths
  }

  private createWallPath(thickness: number): paper.CompoundPath {
    const wallModel = new WallModel(this.paperScope, {
      ...this.drawSetting,
      wallHeight: this.drawSetting.slabHeight - this.drawSetting.slabThickness,
      wallThickness: thickness,
    })

    /*
       Clone SlabModel to prevent side effects because of reference types in the mesh object (MeshPoints & Edges)
       and a changing realLength side effect in the createWallPath method (processStep).
    */
    const clonedModel = this.clone()
    wallModel.mesh.points = clonedModel.mesh.points
    wallModel.getAllEdges().forEach((edge) => (edge.thickness = thickness))

    const edges = this.getAllEdges()
    const edgeLengths = edges.map((edge) => edge.realLength)

    /// Set the realLength because it's not part of the clone method and necessary for further calculations
    const wallModelEdges = wallModel.getAllEdges()
    for (let i = 0; i < edgeLengths.length; i++) {
      wallModelEdges[i].realLength = edgeLengths[i]
    }

    return wallModel.createPath(false)
  }

  clone(): Model<SlabMesh> {
    const clonedMesh = parseSimplifiedSlabMesh(this.paperScope, serializeMesh(this.mesh))
    const clonedModel = new SlabModel(this.paperScope, this.drawSetting)
    clonedModel.mesh = clonedMesh
    clonedModel.finalize()
    return clonedModel
  }

  getSurroundingLines(edges: Edge[], generateOuterEdges: boolean = true): Line2D[] {
    return flatMap(
      edges.map((edge) => {
        const startPointVector = edge.endPoint.subtract(edge.startPoint).normalize().rotate(90.0)
        const endPointVector = edge.endPoint.subtract(edge.startPoint).normalize().rotate(-90.0)

        const lines: Line2D[] = []
        if (generateOuterEdges) {
          lines.push(new Line2D(edge.startPoint, edge.endPoint))
        }

        if (edge.startPoint.edges.size === 1) {
          const startPoint1 = edge.startPoint.add(startPointVector)
          const endPoint1 = edge.startPoint.add(endPointVector)
          lines.push(new Line2D(startPoint1, endPoint1))
        }

        if (edge.endPoint.edges.size === 1) {
          const startPoint2 = edge.endPoint.add(startPointVector)
          const endPoint2 = edge.endPoint.add(endPointVector)
          lines.push(new Line2D(startPoint2, endPoint2))
        }

        return lines
      })
    )
  }

  protected createEmptyMesh(): SlabMesh {
    return new SlabMesh(this.paperScope)
  }

  protected createUndoRedoHistory(): UndoRedoHistory<SlabMesh> {
    return new SlabUndoRedoHistory(this.paperScope, this.mesh, this.drawSetting)
  }

  protected generatePath(): paper.CompoundPath {
    return this.mesh.generatePath()
  }

  protected getEdgeAttributes(edge: Edge): EdgeAttributes {
    return {
      outerLength: edge.length(),
      innerLength: edge.length(),
      thickness: this.drawSetting.slabThickness,
    }
  }

  protected updateEdgeThickness(_: Edge, __: number, ___: TransformationThicknessDirection): void {
    // Do nothing for slab
  }

  protected rasterOnEdge(point: paper.Point, edge: Edge): paper.Point {
    // rastering lengths
    const vector1 = edge.startPoint.subtract(point)
    const distance1 = vector1.length
    const correctedDistance1 =
      Math.round(distance1 / this.drawSetting.lengthRastering) * this.drawSetting.lengthRastering
    const correction1 = correctedDistance1 - distance1
    const correctionVector1 = vector1.normalize(correction1)
    const rasteredPoint1 = point.subtract(correctionVector1)

    const vector2 = edge.endPoint.subtract(point)
    const distance2 = vector2.length
    const correctedDistance2 =
      Math.round(distance2 / this.drawSetting.lengthRastering) * this.drawSetting.lengthRastering
    const correction2 = correctedDistance2 - distance2
    const correctionVector2 = vector2.normalize(correction2)
    const rasteredPoint2 = point.subtract(correctionVector2)

    return rasteredPoint1.getDistance(point) < rasteredPoint2.getDistance(point)
      ? rasteredPoint1
      : rasteredPoint2
  }

  protected updateEdgeLength(
    edge: Edge,
    outerLength: number,
    lengthDirection: TransformationLengthDirection
  ): void {
    edge.changeLength(outerLength, lengthDirection)
  }

  protected createEdgeInMesh(
    startPoint: MeshPoint | paper.Point,
    endPoint: MeshPoint | paper.Point,
    _: number,
    skipSnapping: boolean,
    __: boolean,
    intoEdge?: Edge
  ): Edge | undefined {
    const snappedStartMeshPoint = this.mesh.snapPointToMeshPoints(startPoint)
    const snappedEndMeshPoint = this.mesh.snapPointToMeshPoints(endPoint)

    let startMeshPoint: MeshPoint

    if (
      snappedStartMeshPoint &&
      snappedStartMeshPoint.edges.size <= 1 &&
      (!(startPoint instanceof MeshPoint) || startPoint.edges.size <= 1)
    ) {
      // snap to existing points
      startMeshPoint = snappedStartMeshPoint
    } else {
      // take the point as is
      startMeshPoint = asMeshPoint(startPoint, this.mesh)
    }

    let endMeshPoint: MeshPoint

    if (
      snappedEndMeshPoint &&
      snappedEndMeshPoint.edges.size <= 1 &&
      (!(endPoint instanceof MeshPoint) || endPoint.edges.size <= 1)
    ) {
      // snap to existing points
      endMeshPoint = snappedEndMeshPoint
    } else if (!skipSnapping) {
      // snap angle and length
      const neighborEdge = [...startMeshPoint.edges][0]
      let snappedPoint = neighborEdge
        ? snapSingleNeighborAnchoredPoint(
            startMeshPoint,
            endPoint,
            neighborEdge,
            PlanType.SLAB,
            this.drawSetting.angleRastering,
            this.drawSetting.lengthRastering,
            0,
            startMeshPoint,
            intoEdge
          )?.point
        : snapFreePoint(
            endPoint,
            startMeshPoint,
            PlanType.SLAB,
            this.drawSetting.angleRastering,
            this.drawSetting.lengthRastering
          )

      let closeMeshPoint: MeshPoint | undefined
      if (snappedPoint != null) {
        const direction = snappedPoint.subtract(startMeshPoint).normalize()
        const snappedGuidelinePoint = this.snapPointToAuxiliaryGuideline(
          snappedPoint,
          direction,
          this.getAllEdges()
        )
        if (snappedGuidelinePoint != null) {
          snappedPoint = snappedGuidelinePoint
        }
        closeMeshPoint = this.mesh.snapPointToMeshPoints(snappedPoint, SNAPPING_ERROR_TOLERANCE)
      }
      endMeshPoint = asMeshPoint(endPoint, this.mesh)
      endMeshPoint.set(snappedPoint)
      if (closeMeshPoint) {
        endMeshPoint = closeMeshPoint
      }
    } else {
      // take the point as is
      endMeshPoint = asMeshPoint(endPoint, this.mesh)
    }

    if (startMeshPoint.hasSameEdge(endMeshPoint)) {
      return undefined
    }

    return this.mesh.setEdge(startMeshPoint, endMeshPoint, 0, intoEdge)
  }

  protected snapToEdgeOrMeshPoint(
    movedPoint: paper.Point,
    originalPoint: MeshPoint,
    edges: Edge[]
  ): SnapResult {
    const snapToEdgeResult = this.mesh.snapPointToEdge(movedPoint, edges)
    if (!snapToEdgeResult) {
      return undefined
    }

    let snapToMeshPoint: MeshPoint | undefined
    const distanceToStart = snapToEdgeResult.edge.startPoint.getDistance(snapToEdgeResult.point)
    const distanceToEnd = snapToEdgeResult.edge.endPoint.getDistance(snapToEdgeResult.point)
    if (distanceToStart <= SNAPPING_DISTANCE || distanceToEnd <= SNAPPING_DISTANCE) {
      snapToMeshPoint =
        distanceToStart <= distanceToEnd
          ? snapToEdgeResult.edge.startPoint
          : snapToEdgeResult.edge.endPoint
      if (snapToMeshPoint !== originalPoint) {
        if (snapToMeshPoint.edges.size > 1) {
          return movedPoint
        }
        return snapToMeshPoint
      }
    }

    if (originalPoint.edges.size === 2) {
      return this.rasterOnEdge(snapToEdgeResult.point, snapToEdgeResult.edge)
    }

    return movedPoint
  }

  protected updatedEdgesAfterMove(_: Edge[]): Edge[] {
    this.mesh.points.forEach((point) => {
      const closePoint = Array.from(this.mesh.points).find(
        (other) => point !== other && point.isClose(other, SNAPPING_ERROR_TOLERANCE)
      )
      if (closePoint) {
        this.mergePoints(point, closePoint)
      }
    })
    return []
  }
}
