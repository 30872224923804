<lib-efpViewer></lib-efpViewer>
<efp-measurement-legend
  [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
  [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
  [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
  [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
  [buildingType]="plan ? plan.buildingType : undefined"
  [additionalSpacingBottom]="plan !== undefined && plan.buildingType === planType.WALL"
  [result]="true"
  [formwork]="planSettings ? planSettings.formworkWall : undefined"></efp-measurement-legend>
<ng-container
  [efpOnboardingTrigger]="checkAndShareResultOnboardingHint()"
  [triggeredBy]="TriggerType.COMPONENT_RENDERED">
  <efp-onboarding-hints [onboardingHintSeriesKey]="checkAndShareResultOnboardingHint()">
  </efp-onboarding-hints>
</ng-container>
<efp-bottom-menu-container *ngIf="cycleMenuText">
  <efp-primary-planner-toolbar>
    <div class="efp-toggle-buttons">
      <ion-button
        efpTooltip
        [tooltipTitle]="'TOOLBAR.PERSPECTIVE_VIEW' | translate"
        (click)="switchCameraMode(CameraMode.PERSPECTIVE)"
        [disabled]="cameraMode === CameraMode.PERSPECTIVE">
        <ion-icon slot="icon-only" src="assets/icon/grid_perspective.svg"></ion-icon>
      </ion-button>
      <ion-button
        efpTooltip
        [tooltipTitle]="'TOOLBAR.ORTHOGONAL_VIEW' | translate"
        [disabled]="cameraMode === CameraMode.ORTHOGRAPHIC"
        (click)="switchCameraMode(CameraMode.ORTHOGRAPHIC)">
        <ion-icon slot="icon-only" src="assets/icon/grid_orthographic.svg"></ion-icon>
      </ion-button>
    </div>
    <ion-button
      (click)="createScreenshot()"
      efpTooltip
      [tooltipTitle]="'TOOLBAR.CREATE_2D_VIEW' | translate">
      <ion-icon slot="icon-only" src="assets/icon/screenshot.svg"></ion-icon>
    </ion-button>
    <ion-buttons *ngIf="cycleMenuText && this.viewer.toolbarControl.isWallMode">
      <ng-content></ng-content>
      <ng-container>
        <ion-button
          efpTooltip
          [tooltipTitle]="'TOOLBAR.HIGHLIGHT' | translate"
          class="expandable-btn"
          (click)="presentHighlightPopover($event)"
          data-test-selector="highlight-menu-3d">
          <ion-icon slot="icon-only" src="assets/icon/marker.svg"></ion-icon>
        </ion-button>
      </ng-container>
    </ion-buttons>
    <efp-toolbar-divider></efp-toolbar-divider>
    <ion-buttons>
      <ng-content></ng-content>
      <ng-container>
        <ion-button
          efpTooltip
          [tooltipTitle]="'TOOLBAR.VISIBILITY_SETTINGS' | translate"
          class="expandable-btn"
          (click)="presentVisibilityPopover($event)"
          data-test-selector="visibility-menu-3d">
          <ion-icon slot="icon-only" src="assets/icon/visibility.svg"></ion-icon>
        </ion-button>
      </ng-container>
    </ion-buttons>
    <ion-button
      (click)="resetCamera()"
      efpTooltip
      [tooltipTitle]="'TOOLBAR.CENTER_VIEW' | translate">
      <ion-icon slot="icon-only" src="assets/icon/focus.svg"></ion-icon>
    </ion-button>
  </efp-primary-planner-toolbar>
</efp-bottom-menu-container>
<div class="pillMenu" *ngIf="cycleMenuText && this.viewer.toolbarControl.isWallMode">
  <ion-button class="back" (click)="decrementCycle()" [disabled]="cycleIsPlan">
    <ion-icon slot="icon-only" src="assets/icon/arrow-back-outline.svg"></ion-icon>
  </ion-button>
  <ion-button class="menu" expand="block" (click)="presentCyclePopover($event)">
    <ng-container *ngIf="!this.isOpen">
      <span>{{ cycleMenuText }}</span>
      <ion-icon
        class="arrow-up"
        slot="icon-only"
        src="assets/icon/mini-arrow-up.svg"
        *ngIf="cycleMenuText"></ion-icon>
    </ng-container>
    <ng-container *ngIf="this.isOpen">
      <ion-icon slot="icon-only" src="assets/icon/ic_close.svg"></ion-icon>
    </ng-container>
  </ion-button>
  <ion-button class="forward" (click)="incrementCycle()" [disabled]="cycleIsDoneOrAll">
    <ion-icon slot="icon-only" src="assets/icon/arrow-forward-outline.svg"></ion-icon>
  </ion-button>
</div>
