import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AGBGuard } from './services/agb.guard'
import { AuthGuard } from './services/auth.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'homepage',
    loadChildren: async () => (await import('./pages/homepage/homepage.module')).HomepagePageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'projectoverview',
    loadChildren: async () =>
      (await import('./pages/projectoverview/projectoverview.module')).ProjectoverviewPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'projectdetail',
    loadChildren: async () =>
      (await import('./pages/projectoverview/projectdetail/projectdetail.module'))
        .ProjectdetailPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'favourites',
    loadChildren: async () =>
      (await import('./pages/favourites/favourites.module')).FavouritesPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'favourites-overview',
    loadChildren: async () =>
      (await import('./pages/favourites/favourites-overview/favourites-overview.module'))
        .FavouritesOverviewModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'stock',
    loadChildren: async () => (await import('./pages/stock/stock.module')).StockPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'stockdetail',
    loadChildren: async () =>
      (await import('./pages/stock/stockdetail/stockdetail.module')).StockdetailPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: async () =>
      (await import('./pages/settings/appsettings/appsettings.module')).SettingsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'drawingsettings',
    loadChildren: async () =>
      (await import('./pages/settings/drawingsettings/drawingsettings.module'))
        .DrawingsettingsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'contact',
    loadChildren: async () => (await import('./pages/contact/contact.module')).ContactPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'agb',
    loadChildren: async () => (await import('./pages/agb/agb.module')).AGBPageModule,
  },
  {
    path: 'privacy',
    loadChildren: async () => (await import('./pages/privacy/privacy.module')).PrivacyPageModule,
  },
  {
    path: 'agb-check',
    loadChildren: async () =>
      (await import('./pages/agb-check/agb-check.module')).AgbCheckPageModule,
  },
  {
    path: 'introduction',
    loadChildren: async () =>
      (await import('./pages/introduction/introduction.module')).IntroductionPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'planner',
    loadChildren: async () => (await import('./pages/planner/planner.module')).PlannerPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'accessory',
    loadChildren: async () =>
      (await import('./pages/accessory/accessory.module')).AccessoryPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'cycles',
    loadChildren: async () => (await import('./pages/cycles/cycles.module')).CyclesPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'result',
    loadChildren: async () => (await import('./pages/result/result.module')).ResultPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'plansettings',
    loadChildren: async () =>
      (await import('./pages/settings/plansettings/plansettings.module')).PlansettingsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'licenses',
    loadChildren: async () => (await import('./pages/licenses/licenses.module')).LicensesPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: async () => (await import('./pages/login/login.module')).LoginPageModule,
    canActivate: [AGBGuard],
  },
  {
    path: 'raffle',
    loadChildren: async () => (await import('./pages/raffle/raffle.module')).RafflePageModule,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
