import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'efp-custom-segment-button',
  templateUrl: './custom-segment-button.component.html',
  styleUrls: ['./custom-segment-button.component.scss'],
})
export class CustomSegmentButtonComponent {
  @Input() isSelected = false
  @Input() isDisabled = false
  @Output() readonly selected = new EventEmitter<void>()

  constructor() {}

  select(): void {
    if (!this.isSelected) {
      this.isSelected = true
      this.selected.emit()
    }
  }
}
