import paper from 'paper/dist/paper-core'
import { Line } from '../../model'
import { SNAPPING_ERROR_TOLERANCE } from '../snapping/snappingConstants'

export const intersectLines = (
  line1: Line,
  line2: Line,
  line1IsSegment: boolean = false,
  line2IsSegment: boolean = false
): paper.Point | undefined => {
  const direction1 = line1.end.subtract(line1.start)
  const direction2 = line2.end.subtract(line2.start)

  const det = direction1.x * direction2.y - direction2.x * direction1.y

  if (Math.abs(det) > SNAPPING_ERROR_TOLERANCE) {
    const p =
      (direction2.x * (line1.start.y - line2.start.y) -
        direction2.y * (line1.start.x - line2.start.x)) /
      det

    const q =
      (direction1.x * (line1.start.y - line2.start.y) -
        direction1.y * (line1.start.x - line2.start.x)) /
      det

    if ((!line1IsSegment || (p >= 0 && p <= 1)) && (!line2IsSegment || (q >= 0 && q <= 1))) {
      return line1.start.add(direction1.multiply(p))
    }
  }

  return undefined
}
