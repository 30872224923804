import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'overlay-editor-container',
  templateUrl: './overlay-editor-container.component.html',
  styleUrls: ['./overlay-editor-container.component.scss'],
})
export class EditorOverlayContainerComponent {
  @Input() formGroup!: FormGroup
  @Input() hasDifferentButtonAlignments = false
  @Input() valid = true
}
