/**
 * Returns points for Path.Arc
 *
 * @param l1 line segment
 * @param l2 line segment
 * @param radius scale for unit vector
 */
import { Line } from 'formwork-planner-lib'

export const calculateAngleArcPoints = (
  l1: Line,
  l2: Line,
  radius: number
): {
  through: paper.Point
  center: paper.Point
  from: paper.Point
  to: paper.Point
} => {
  const AB = l1.start.subtract(l1.end)
  const CB = l2.end.subtract(l2.start)

  const normalizedAB = AB.normalize()
  const normalizedCB = CB.normalize()

  const from = l2.start.add(normalizedCB.multiply(radius))
  const to = l1.end.add(normalizedAB.multiply(radius))
  const bisection = normalizedAB.add(normalizedCB).normalize().multiply(radius)
  const through = l1.end.add(bisection)
  const center = l1.end.add(bisection.multiply(0.5))

  return { from, to, through, center }
}
