import { Injectable, inject } from '@angular/core'
import { PlanOutline } from '../../../models/plan/PlanOutline'
import { PlanOutlineDao } from '../plan-outline.dao'
import { CreatePlanOutlineCommandParam, PlanOutlinesApiClient } from '../../../../generated/efp-api'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class PlanOutlineHttpDao implements PlanOutlineDao {
  private readonly apiClient = inject(PlanOutlinesApiClient)

  async create(params: CreatePlanOutlineCommandParam[]): Promise<void> {
    await firstValueFrom(this.apiClient.createPlanOutlines({ body: params }))
  }

  async findAllOutlinesByPlanId(planId: number): Promise<PlanOutline[]> {
    const response = await firstValueFrom(this.apiClient.getPlanOutlinesByPlan({ planId }))
    return response.map(PlanOutlineDao.mapModelToPlanOutline)
  }

  async filteredPlanIdsWithOutlines(planIds: number[]): Promise<number[]> {
    const planOutlines = await Promise.all(planIds.map(this.findAllOutlinesByPlanId))

    const planIdsWithOutline: number[] = []
    planOutlines.forEach((outlines) => {
      if (outlines.length > 0) {
        planIdsWithOutline.push(outlines[0].planId)
      }
    })

    return planIdsWithOutline
  }

  async removeAllByPlanId(planId: number): Promise<void> {
    await firstValueFrom(this.apiClient.deletePlanOutlinesByPlan({ planId }))
  }
}
