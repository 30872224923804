import { Injectable } from '@angular/core'
import { PlanCreateCommandParams } from '@efp/api'
import { Project } from '../../models/project'
import { Plan } from '../../models/plan'

@Injectable()
export abstract class PlanCreationDao {
  protected constructor() {}

  public abstract createPlan(
    params: PlanCreateCommandParams,
    project?: Project | undefined
  ): Promise<Plan>
}
