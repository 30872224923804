/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PatchScreenshotParams } from '../models/patch-screenshot-params';
import { ScreenshotCommandParams } from '../models/screenshot-command-params';
import { ScreenshotModel } from '../models/screenshot-model';

@Injectable({ providedIn: 'root' })
export class ScreenshotsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendAddScreenshotEntry()` */
  static readonly SendAddScreenshotEntryPath = '/api/v1/screenshots';

  /**
   * Add a new screenshot.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAddScreenshotEntry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddScreenshotEntry$Plain$Response(
    params: {
      body: ScreenshotCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.SendAddScreenshotEntryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new screenshot.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAddScreenshotEntry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddScreenshotEntry$Plain(
    params: {
      body: ScreenshotCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.sendAddScreenshotEntry$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new screenshot.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAddScreenshotEntry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddScreenshotEntry$Response(
    params: {
      body: ScreenshotCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.SendAddScreenshotEntryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new screenshot.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAddScreenshotEntry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAddScreenshotEntry(
    params: {
      body: ScreenshotCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.sendAddScreenshotEntry$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `deleteScreenshot()` */
  static readonly DeleteScreenshotPath = '/api/v1/screenshots/{screenshotId}';

  /**
   * Delete screenshot.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScreenshot()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScreenshot$Response(
    params: {
      screenshotId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.DeleteScreenshotPath, 'delete');
    if (params) {
      rb.path('screenshotId', params.screenshotId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete screenshot.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteScreenshot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScreenshot(
    params: {
      screenshotId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteScreenshot$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateScreenshot()` */
  static readonly UpdateScreenshotPath = '/api/v1/screenshots/{screenshotId}';

  /**
   * Update existing screenshot.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScreenshot$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScreenshot$Plain$Response(
    params: {
      screenshotId: number;
      body: PatchScreenshotParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ScreenshotModel>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.UpdateScreenshotPath, 'patch');
    if (params) {
      rb.path('screenshotId', params.screenshotId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScreenshotModel>;
      })
    );
  }

  /**
   * Update existing screenshot.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateScreenshot$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScreenshot$Plain(
    params: {
      screenshotId: number;
      body: PatchScreenshotParams
    },
    context?: HttpContext
  ): Observable<ScreenshotModel> {
    return this.updateScreenshot$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScreenshotModel>): ScreenshotModel => r.body)
    );
  }

  /**
   * Update existing screenshot.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScreenshot()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScreenshot$Response(
    params: {
      screenshotId: number;
      body: PatchScreenshotParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ScreenshotModel>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.UpdateScreenshotPath, 'patch');
    if (params) {
      rb.path('screenshotId', params.screenshotId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScreenshotModel>;
      })
    );
  }

  /**
   * Update existing screenshot.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateScreenshot$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScreenshot(
    params: {
      screenshotId: number;
      body: PatchScreenshotParams
    },
    context?: HttpContext
  ): Observable<ScreenshotModel> {
    return this.updateScreenshot$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScreenshotModel>): ScreenshotModel => r.body)
    );
  }

  /** Path part for operation `getScreenshotsByPlan()` */
  static readonly GetScreenshotsByPlanPath = '/api/v1/plans/{planId}/screenshots';

  /**
   * Get screenshots per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScreenshotsByPlan$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenshotsByPlan$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ScreenshotModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.GetScreenshotsByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScreenshotModel>>;
      })
    );
  }

  /**
   * Get screenshots per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScreenshotsByPlan$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenshotsByPlan$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<ScreenshotModel>> {
    return this.getScreenshotsByPlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScreenshotModel>>): Array<ScreenshotModel> => r.body)
    );
  }

  /**
   * Get screenshots per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScreenshotsByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenshotsByPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ScreenshotModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.GetScreenshotsByPlanPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScreenshotModel>>;
      })
    );
  }

  /**
   * Get screenshots per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScreenshotsByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenshotsByPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<ScreenshotModel>> {
    return this.getScreenshotsByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScreenshotModel>>): Array<ScreenshotModel> => r.body)
    );
  }

  /** Path part for operation `deleteScreenshotsByPlan()` */
  static readonly DeleteScreenshotsByPlanPath = '/api/v1/plans/{planId}/screenshots';

  /**
   * Delete screenshots per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScreenshotsByPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScreenshotsByPlan$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ScreenshotsApiClient.DeleteScreenshotsByPlanPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete screenshots per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteScreenshotsByPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScreenshotsByPlan(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteScreenshotsByPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
