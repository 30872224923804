<form [formGroup]="settingsForm">
  <ion-list class="d-flex-col gap-12">
    <div *ngIf="!exclusiveBuildingType || exclusiveBuildingType === 'WALL'">
      <efp-list-header>
        <ion-label>
          {{ 'SETTINGS.FORMWORK_SETTINGS' | translate }} {{ 'PLAN.WALL' | translate }}
        </ion-label>
      </efp-list-header>

      <div class="d-flex-col gap-12">
        <div>
          <div class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap">
            <div class="label-container flex-1 d-flex">
              <ion-label>{{ 'SETTINGS.WALL_FORMWORK' | translate }}</ion-label>
            </div>
            <ion-select
              tabindex="-1"
              formControlName="formworkWall"
              class="flex-1"
              justify="start"
              fill="outline"
              interface="popover"
              label-placement="start"
              [interfaceOptions]="{ side: 'bottom', size: 'cover', cssClass: 'select-popover' }"
              cancelText="{{ 'GENERAL.CANCEL' | translate }}"
              placeholder="{{ 'SETTINGS.SELECT_WALL_FORMWORK' | translate }}">
              <ion-select-option
                *ngFor="let formwork of formworkSystemService.wallFormworkSystems"
                [disabled]="formworkSystemService.isInvalidFormworkSystem(formwork.id)"
                [value]="formwork.id"
                attr.data-test-selector="formworksettings-wall-select-{{ formwork.id }}"
                >{{ 'FORMWORK.' + formwork.id | translate }}
              </ion-select-option>
            </ion-select>
          </div>
          <ion-note note color="danger" *ngIf="isWallFormworkInvalid">
            {{ 'FORMWORK_SETTINGS.INVALID_FORMWORK_SELECTED' | translate }}
          </ion-note>
        </div>

        <div *ngIf="wallFavouriteProfile" data-test-selector="formworksettings-wall-favourites">
          <div class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap">
            <div class="label-container flex-1 d-flex">
              <ion-label> {{ 'SETTINGS.FAVOURITES_SETTINGS_WALL' | translate }}</ion-label>
            </div>
            <ion-select
              formControlName="wallFavouriteProfile"
              class="flex-1"
              justify="start"
              fill="outline"
              aria-label="Fruit"
              toggleIcon="caret-down-sharp"
              interface="popover"
              label-placement="start"
              [interfaceOptions]="{ side: 'bottom', size: 'cover', cssClass: 'select-popover' }"
              cancelText="{{ 'GENERAL.CANCEL' | translate }}"
              tabindex="-1"
              multiple="false"
              *ngIf="wallFavouriteProfile">
              <ion-select-option
                *ngFor="let favouriteProfile of this.availableFavouriteWallProfiles"
                [value]="favouriteProfile.id"
                attr.data-test-selector="formworksettings-wallFavProfile-select-{{
                  favouriteProfile.id
                }}">
                {{
                  (favouriteProfile.isStandard
                    ? 'FORMWORK.' + favouriteProfile.formworkSystemId
                    : favouriteProfile.name
                  ) | translate
                }}</ion-select-option
              >
            </ion-select>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!exclusiveBuildingType || exclusiveBuildingType === 'SLAB'">
      <efp-list-header>
        <ion-label
          >{{ 'SETTINGS.FORMWORK_SETTINGS' | translate }} {{ 'PLAN.SLAB' | translate }}</ion-label
        >
      </efp-list-header>

      <div class="d-flex-col gap-12">
        <div>
          <div
            class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap"
            data-test-selector="formworksettings-slab-select">
            <div class="label-container flex-1 d-flex">
              <ion-label>{{ 'SETTINGS.CEILING_FORMWORK' | translate }}</ion-label>
            </div>
            <ion-select
              formControlName="formworkSlab"
              class="flex-1"
              justify="start"
              fill="outline"
              aria-label="Fruit"
              toggleIcon="caret-down-sharp"
              interface="popover"
              label-placement="start"
              [interfaceOptions]="{ size: 'cover' }"
              tabindex="-1"
              multiple="false"
              placeholder="{{ 'SETTINGS.SELECT_CEILING_FORMWORK' | translate }}">
              <ion-select-option
                *ngFor="let formwork of formworkSystemService.slabFormworkSystems"
                [disabled]="formworkSystemService.isInvalidFormworkSystem(formwork.id)"
                [value]="formwork.id"
                attr.data-test-selector="formworksettings-slab-select-{{ formwork.id }}"
                >{{ 'FORMWORK.' + formwork.id | translate }}</ion-select-option
              ></ion-select
            >
          </div>
          <ion-note note color="danger" *ngIf="isSlabFormworkInvalid">
            {{ 'FORMWORK_SETTINGS.INVALID_FORMWORK_SELECTED' | translate }}
          </ion-note>
        </div>
        <div
          class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap"
          *ngIf="slabFavouriteProfile"
          data-test-selector="formworksettings-slab-favourites">
          <div class="label-container flex-1 d-flex">
            <ion-label>
              {{ 'SETTINGS.FAVOURITES_SETTINGS_SLAB' | translate }}
            </ion-label>
          </div>
          <ion-select
            formControlName="slabFavouriteProfile"
            class="flex-1"
            justify="start"
            fill="outline"
            toggleIcon="caret-down-sharp"
            interface="popover"
            label-placement="start"
            [interfaceOptions]="{ size: 'cover' }"
            *ngIf="slabFavouriteProfile"
            slot="end">
            <ion-select-option
              *ngFor="let favouriteProfile of this.availableFavouriteSlabProfiles"
              [value]="favouriteProfile.id"
              attr.data-test-selector="formworksettings-slabFavProfile-select-{{
                favouriteProfile.id
              }}">
              {{
                (favouriteProfile.isStandard
                  ? 'FORMWORK.' + favouriteProfile.formworkSystemId
                  : favouriteProfile.name
                ) | translate
              }}
            </ion-select-option>
          </ion-select>
        </div>
      </div>
    </div>
  </ion-list>
</form>
