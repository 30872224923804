import { Injectable } from '@angular/core'
import { DataService } from '../../data.service'
import { ScreenshotDao } from '../screenshot.dao'
import { ChangedResultPartDao } from '../changed-result-part.dao'
import { PlanType } from '../../../shared/formwork-planner'
import { PlanResultResetCalculationDao } from '../plan-result-reset-calculation.dao'
import { NavStep } from '../../../models/navSteps'

@Injectable()
export class PlanResultResetCalculationSqlDao extends PlanResultResetCalculationDao {
  constructor(
    private readonly dataService: DataService,
    private readonly screenshotDao: ScreenshotDao,
    private readonly changedResultPartDao: ChangedResultPartDao
  ) {
    super()
  }

  private async getIsCalculated(planId: number): Promise<boolean> {
    const result = await this.dataService.executeStatement(
      `SELECT *
       FROM PlanResult
       WHERE planId = ?`,
      [planId]
    )
    if (result.rows.length === 1) {
      return true
    } else {
      return false
    }
  }

  private async updateCurrentPlanStep(planId: number, currentStep: NavStep): Promise<void> {
    const statement = 'UPDATE Plans SET currentStep = ?, lastUsed = ? WHERE id = ?'
    const values = [currentStep, new Date(), planId]
    await this.dataService.executeStatement(statement, values)
  }

  /**
   * Sets the plan calculation state to false, updates the current navigation step and remove changed result parts.
   */
  public async resetCalculation(planId: number): Promise<void> {
    const resultSet = await this.dataService.executeStatement(
      'SELECT currentStep, buildingType FROM Plans WHERE id = ?',
      [planId]
    )
    const calculated = await this.getIsCalculated(planId)
    let currentStep = resultSet.rows.item(0).currentStep
    const buildingType: PlanType = resultSet.rows.item(0).buildingType
    if (calculated) {
      if (currentStep === 'result') {
        if (buildingType === PlanType.WALL) {
          currentStep = 'cycles'
        } else {
          currentStep = 'planner'
        }
      }
    }

    await this.dataService.executeStatement(`DELETE FROM PlanResult WHERE planId = ?`, [planId])
    await this.updateCurrentPlanStep(planId, currentStep)
    await this.changedResultPartDao.deleteAllByPlanId(planId)
    await this.screenshotDao.deleteAllByPlanId(planId)
  }
}
