import paper from 'paper/dist/paper-core'

export interface Point2D {
  x: number
  y: number
}

export const arePointsEqual = (a: Point2D, b: Point2D): boolean => {
  return a.x === b.x && a.y === b.y
}

export const paperPointToPoint2D = (point: paper.Point): Point2D => ({
  x: point.x,
  y: point.y,
})
