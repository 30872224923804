import { Injectable } from '@angular/core'
// eslint-disable-next-line no-restricted-imports
import { LoadingController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  constructor(private readonly loadingController: LoadingController) {}

  public async doWithLoadingSpinner<T>(
    operation: (spinner: HTMLIonLoadingElement) => Promise<T>
  ): Promise<T> {
    const loading = await this.loadingController.create({
      backdropDismiss: false,
      translucent: true,
    })
    try {
      await loading.present()
      return await operation(loading)
    } finally {
      await loading.dismiss()
    }
  }
}
