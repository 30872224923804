<ion-backdrop
  *ngIf="this.primaryMenuService.sidebarShow"
  tappable="true"
  (ionBackdropTap)="closeMenu()"></ion-backdrop>

<div
  efpKeyboardShortcut
  (escShortcut)="closeMenu()"
  class="efp-collapsable-menu sidebar-slider"
  [class.sidebar-slide-in]="this.primaryMenuService.sidebarShow">
  <efp-primary-menu [collapsable]="true"></efp-primary-menu>
</div>
