import {
  CycleBoundaryDrawable,
  CycleSymbolDrawable,
  Edge,
  MeshPoint,
  PlanType,
  SNAPPING_ERROR_TOLERANCE,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { CYCLE_COLOR_MAPPING, WALL_COLOR } from '../../../constants/colors'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { parseSimplifiedWallMesh, serializeMesh } from '../model/MeshSerializer'
import { WallModel } from '../model/WallModel'
import { RenderService } from './render.service'
import { SelectionService } from './selection.service'

const DEFAULT_WALL_STYLE = {
  strokeColor: new paper.Color('black'),
  strokeWidth: 1,
  dashArray: [] as number[],
  fillRule: 'evenodd',
  fillColor: WALL_COLOR,
} as paper.Style
const INACTIVE_WALL_STYLE = {
  strokeColor: new paper.Color('black'),
  strokeWidth: 1,
  dashArray: [2, 4],
} as paper.Style

const DEFAULT_CYCLE_NUMBER = 1
const FLOOD_FILLING_POINT_DISTANCE = 1

export class WallRenderService extends RenderService<WallModel> {
  public constructor(
    model: WallModel,
    paperScope: paper.PaperScope,
    planVisibilitySettings: PlanVisibilitySettings,
    selectionService?: SelectionService,
    public inactive = false
  ) {
    super(model, paperScope, PlanType.WALL, planVisibilitySettings, selectionService)
  }

  protected getMeshStyle(): paper.Style {
    return this.inactive ? INACTIVE_WALL_STYLE : DEFAULT_WALL_STYLE
  }

  protected refreshLabels(cycleBoundaries?: CycleBoundaryDrawable[], selectedTWall?: Edge): void {
    const path = this.previewPath ?? this.path
    this.labelService.generateAngleLabelsForPath(path, false)
    this.labelService.generateLengthLabelsForPath(
      path,
      true,
      this.model.drawSetting.measurementUnit,
      cycleBoundaries,
      selectedTWall
    )

    const model = this.previewModel ?? this.model
    this.labelService.generateWidthLabelsForConnectedWalls(
      model.getConnectingAndCloseDisconnectedMeshPoints(),
      model.drawSetting.measurementUnit
    )
  }

  public applyFloodFill(
    cycleBoundaries: CycleBoundaryDrawable[],
    cycleSymbols: CycleSymbolDrawable[],
    fillLayer: paper.Layer
  ): void {
    if (this.path.children == null) {
      return
    }

    const cycleMesh = parseSimplifiedWallMesh(this.paperScope, serializeMesh(this.model.mesh))

    for (const boundary of cycleBoundaries) {
      const boundaryPoint = boundary.getCenter()
      const edge = cycleMesh.findClosestEdge(boundaryPoint)

      if (!edge) {
        continue
      }

      const boundaryMeshEndPoint = new MeshPoint(boundaryPoint, cycleMesh)
      const boundaryMeshStartPoint = new MeshPoint(boundaryPoint, cycleMesh)

      const newStartEdge = new Edge(edge.startPoint, boundaryMeshEndPoint, edge.thickness)
      const newEndEdge = new Edge(boundaryMeshStartPoint, edge.endPoint, edge.thickness)

      const distanceToStart = newStartEdge.length()
      const distanceToEnd = newEndEdge.length()

      if (distanceToStart > SNAPPING_ERROR_TOLERANCE) {
        cycleMesh.points.add(boundaryMeshEndPoint)
        boundaryMeshEndPoint.edges.add(newStartEdge)
        edge.startPoint.edges.add(newStartEdge)
        edge.startPoint.edges.delete(edge)
      }

      if (distanceToEnd > SNAPPING_ERROR_TOLERANCE) {
        cycleMesh.points.add(boundaryMeshStartPoint)
        boundaryMeshStartPoint.edges.add(newEndEdge)
        edge.endPoint.edges.add(newEndEdge)
        edge.endPoint.edges.delete(edge)
      }
    }

    const cycleMeshPaths = cycleMesh.groupConnectedEdges().map((mesh) => mesh.generatePath())

    for (const boundary of cycleBoundaries) {
      const leftCycleMesh = cycleMeshPaths.find((path) =>
        path.contains(boundary.getLeftPositionFromCenter(FLOOD_FILLING_POINT_DISTANCE))
      )
      const rightCycleMesh = cycleMeshPaths.find((path) =>
        path.contains(boundary.getRightPositionFromCenter(FLOOD_FILLING_POINT_DISTANCE))
      )

      if (leftCycleMesh != null && Number.isNaN(+leftCycleMesh.data)) {
        leftCycleMesh.data = boundary.cycleNumberLeft.toString()
      }

      if (rightCycleMesh != null && Number.isNaN(+rightCycleMesh.data)) {
        rightCycleMesh.data = boundary.cycleNumberRight.toString()
      }
    }

    for (const symbol of cycleSymbols) {
      const currentCycleMesh = cycleMeshPaths.find((path) => path.contains(symbol.position))

      if (currentCycleMesh != null && Number.isNaN(+currentCycleMesh.data)) {
        currentCycleMesh.data = symbol.cycleNumber.toString()
      }
    }

    cycleMeshPaths.forEach((path) => {
      const cycleNumber = Number.isNaN(+path.data) ? DEFAULT_CYCLE_NUMBER : path.data
      path.data = cycleNumber

      path.style = {
        ...DEFAULT_WALL_STYLE,
        fillRule: 'evenodd',
        fillColor: WallRenderService.getCycleColor(cycleNumber),
      }

      fillLayer.addChild(path)
    })
  }

  private static getCycleColor(cycleNumber: number): paper.Color {
    const maxCycleNumbers = Object.keys(CYCLE_COLOR_MAPPING).length
    return new paper.Color(CYCLE_COLOR_MAPPING[`${cycleNumber % maxCycleNumbers}`] ?? '')
  }
}
