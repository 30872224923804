import { Edge, Mesh, PlannerInteractionEvent } from 'formwork-planner-lib'
import { Model } from '../model/Model'
import { RenderService } from '../services/render.service'

/**
 * Implementation of interactions possible on the planner-canvas in drawing mode.
 */
export class DrawMode {
  constructor(
    private readonly model: Model<Mesh>,
    private readonly renderService: RenderService<Model<Mesh>>
  ) {}

  onMouseDragged(event: PlannerInteractionEvent): Edge | undefined {
    this.renderService.setAuxiliaryGuidelines([])
    if (event.dragDirection.length > this.model.drawSetting.wallThickness) {
      const edge = this.model.createEdge(event.downPoint, event.point)

      if (edge?.startPoint.edges.size === 1) {
        this.renderService.indicateAngle(edge.startPoint, edge.endPoint)
      } else {
        this.renderService.removeAngleIndicator()
      }

      if (edge != null) {
        this.renderService.calculateAuxiliaryLines([edge], false, true)
      }
      return edge
    }
    return undefined
  }

  onMouseUp(_: PlannerInteractionEvent): void {
    this.renderService.setAuxiliaryGuidelines([])
    this.model.finalize()
    this.renderService.removeAngleIndicator()
  }
}
