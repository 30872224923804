import { OnboardingHintSeries } from '../../models/onboarding/onboarding-hint'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'

export const ONBOARDING_REPOSITION_T_WALL_WEB: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.REPOSITION_T_WALL_WEB,
  hints: [
    {
      title: 'ONBOARDING.REPOSITION_T_WALL.WEB.TITLE',
      textDescriptions: ['ONBOARDING.REPOSITION_T_WALL.WEB.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/onboarding-reposition-t-wall-web.mp4',
      steps: [
        'ONBOARDING.REPOSITION_T_WALL.WEB.STEP1',
        'ONBOARDING.REPOSITION_T_WALL.WEB.STEP2',
        'ONBOARDING.REPOSITION_T_WALL.WEB.STEP3',
        'ONBOARDING.REPOSITION_T_WALL.WEB.STEP4',
      ],
    },
  ],
}

export const ONBOARDING_REPOSITION_T_WALL_NATIVE: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.REPOSITION_T_WALL_NATIVE,
  hints: [
    {
      title: 'ONBOARDING.REPOSITION_T_WALL.NATIVE.TITLE',
      textDescriptions: ['ONBOARDING.REPOSITION_T_WALL.NATIVE.TEXT_DESCRIPTION'],
      videoUrl: 'assets/videos/onboarding-reposition-t-wall-native.mp4',
      steps: [
        'ONBOARDING.REPOSITION_T_WALL.NATIVE.STEP1',
        'ONBOARDING.REPOSITION_T_WALL.NATIVE.STEP2',
        'ONBOARDING.REPOSITION_T_WALL.NATIVE.STEP3',
        'ONBOARDING.REPOSITION_T_WALL.NATIVE.STEP4',
      ],
    },
  ],
}
