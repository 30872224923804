import { createStore } from '@ngneat/elf'
import {
  deleteAllEntities,
  getAllEntities,
  getAllEntitiesApply,
  setEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { ChangedResultPart } from '../services/part-list-service'
import { ChangedResultPartDao } from '../services/dao/changed-result-part.dao'
import { ChangedResultPartsCommandParam } from '../../generated/efp-api'

export const changedResultPartStore = createStore(
  { name: 'changedResultPart' },
  withEntities<ChangedResultPart>()
)

@Injectable({
  providedIn: 'root',
})
export class ChangedResultPartRepository {
  constructor(private readonly changedResultPartDao: ChangedResultPartDao) {}

  public async findAllByPlanId(planId: number): Promise<ChangedResultPartsCommandParam[]> {
    let parts = changedResultPartStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.planId === planId,
      })
    )

    if (!parts || parts.length === 0) {
      parts = await this.changedResultPartDao.findAllByPlanId(planId)
      this.replacePlanPartsWithNew(planId, parts)
    }

    return parts
  }

  public async deleteAllByPlanId(planId: number): Promise<void> {
    await this.changedResultPartDao.deleteAllByPlanId(planId)
    changedResultPartStore.update(deleteAllEntities())
  }

  public async deleteOldAndSaveNewByPlanId(
    planId: number,
    partListParams: ChangedResultPartsCommandParam[]
  ): Promise<void> {
    const newParts = await this.changedResultPartDao.deleteOldAndSaveNewByPlanId(
      planId,
      partListParams
    )
    this.replacePlanPartsWithNew(planId, newParts)
  }

  private replacePlanPartsWithNew(planId: number, newParts: ChangedResultPart[]): void {
    const storePartsWithoutPlan = changedResultPartStore
      .query(getAllEntities())
      .filter((e) => e.planId !== planId)
    storePartsWithoutPlan.push(...newParts)

    changedResultPartStore.update(setEntities(storePartsWithoutPlan))
  }
}
