import { Injectable } from '@angular/core'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
// eslint-disable-next-line no-restricted-imports
import { Share } from '@capacitor/share'
import { decode } from 'base64-arraybuffer'
import write_blob from 'capacitor-blob-writer'
import { FileService } from '../file.service'
import { Translation } from '../translation.service'

@Injectable()
export class NativeFileService extends FileService {
  constructor(translation: Translation) {
    super(translation)
  }

  async writeBlobToFile(file: Blob, path: string): Promise<string> {
    return write_blob({
      path,
      directory: Directory.Data,
      blob: file,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      on_fallback: (error) => console.error(error),
      recursive: true,
    })
  }

  async shareFiles(title: string, text: string, filePaths: string[]): Promise<void> {
    await Share.share({
      title,
      text,
      files: filePaths,
    })
  }

  async shareOrDownloadBlobFile(content: Blob, path: string, _: string): Promise<void> {
    await this.writeBlobToFile(content, path)
    const uri = await this.getUri(path)
    try {
      await this.shareViaUrl(uri)
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : JSON.stringify(e)
      console.error('Share error', msg)
    }
  }

  async shareOrDownloadTextFile(content: string, path: string): Promise<void> {
    await this.writeTextToFile(path, content)
    const uri = await this.getUri(path)
    try {
      await this.shareViaUrl(uri)
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : JSON.stringify(e)
      console.error('Share error', msg)
    }
  }

  async getUri(path: string): Promise<string> {
    const fileUri = await Filesystem.getUri({
      directory: Directory.Data,
      path,
    })

    return fileUri.uri
  }

  async readGlobalFile(path: string): Promise<ArrayBuffer> {
    return Filesystem.readFile({ path }).then((result) => decode(result.data.toString()))
  }

  async readFileAsArrayBuffer(path: string): Promise<ArrayBuffer> {
    return Filesystem.readFile({
      path,
      directory: Directory.Data,
    }).then((result) => decode(result.data.toString()))
  }

  async readTextFile(path: string): Promise<string> {
    return Filesystem.readFile({
      path,
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    }).then((result) => result.data.toString())
  }

  async readDir(path: string): Promise<string[]> {
    return Filesystem.readdir({
      path,
      directory: Directory.Data,
    }).then((result) => result.files.map((file) => file.name))
  }

  async writeTextToFile(path: string, textContent: string): Promise<string> {
    const result = await Filesystem.writeFile({
      path,
      directory: Directory.Data,
      data: textContent,
      encoding: Encoding.UTF8,
      recursive: true,
    })

    return result.uri
  }

  async removeFile(path: string): Promise<void> {
    return Filesystem.deleteFile({
      path,
      directory: Directory.Data,
    })
  }

  private async shareViaUrl(url: string): Promise<void> {
    await Share.share({
      title: this.translation.translate('PLAN.SHARE_MESSAGE'),
      url,
      dialogTitle: this.translation.translate('PLAN.SHARE_MESSAGE'),
    })
  }
}
