import { OnboardingHintSeries } from '../../models/onboarding/onboarding-hint'
import { OnboardingHintSeriesKey } from '../../models/onboarding/onboarding-hint-series-key'

export const ONBOARDING_CHECK_AND_SHARE_RESULT_WEB: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.CHECK_AND_SHARE_RESULT_WEB,
  hints: [
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.RESULT_CONTENTS.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.RESULT_CONTENTS.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.RESULT_CONTENTS.STEP_2',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.RESULT_CONTENTS.STEP_3',
      ],
      videoUrl: 'assets/images/onboarding-check-and-share-result-hint-1-web.png',
      textDescriptions: ['ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.RESULT_CONTENTS.TEXT_DESCRIPTION'],
    },
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.STEP_2',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.STEP_3',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.STEP_4',
      ],
      videoUrl: 'assets/images/onboarding-check-and-share-result-hint-2-web.png',
      textDescriptions: ['ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.CHANGING_RESULT.TEXT_DESCRIPTION'],
    },
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.HIGHLIGHT_ELEMENTS.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.HIGHLIGHT_ELEMENTS.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.HIGHLIGHT_ELEMENTS.STEP_2',
      ],
      videoUrl: 'assets/videos/onboarding-check-and-share-result-hint-3-web.mp4',
      textDescriptions: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.WEB.HIGHLIGHT_ELEMENTS.TEXT_DESCRIPTION',
      ],
    },
  ],
}

export const ONBOARDING_CHECK_AND_SHARE_RESULT_NATIVE: OnboardingHintSeries = {
  id: OnboardingHintSeriesKey.CHECK_AND_SHARE_RESULT_NATIVE,
  hints: [
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.RESULT_CONTENTS.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.RESULT_CONTENTS.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.RESULT_CONTENTS.STEP_2',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.RESULT_CONTENTS.STEP_3',
      ],
      videoUrl: 'assets/images/onboarding-check-and-share-result-hint-1-native.png',
      textDescriptions: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.RESULT_CONTENTS.TEXT_DESCRIPTION',
      ],
    },
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.STEP_2',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.STEP_3',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.STEP_4',
      ],
      videoUrl: 'assets/videos/onboarding-check-and-share-result-hint-2-native.mp4',
      textDescriptions: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.CHANGING_RESULT.TEXT_DESCRIPTION',
      ],
    },
    {
      title: 'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.HIGHLIGHT_ELEMENTS.TITLE',
      steps: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.HIGHLIGHT_ELEMENTS.STEP_1',
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.HIGHLIGHT_ELEMENTS.STEP_2',
      ],
      videoUrl: 'assets/videos/onboarding-check-and-share-result-hint-3-native.mp4',
      textDescriptions: [
        'ONBOARDING.CHECK_AND_SHARE_RESULT.NATIVE.HIGHLIGHT_ELEMENTS.TEXT_DESCRIPTION',
      ],
    },
  ],
}
