import { inject, Injectable } from '@angular/core'
import {
  BlacklistArticleModel,
  BlacklistArticlesApiClient,
  CreateBlacklistArticleParams,
} from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { BlacklistArticleDao } from '../blacklist-article.dao'

@Injectable()
export class BlacklistArticleHttpDao extends BlacklistArticleDao {
  private readonly blacklistArticlesApiClient = inject(BlacklistArticlesApiClient)

  async findAllByFavouriteProfileId(favouriteProfileId: number): Promise<BlacklistArticleModel[]> {
    return firstValueFrom(
      this.blacklistArticlesApiClient.getBlacklistAllArticles({
        favouriteProfileId,
      })
    )
  }

  async delete(id: number): Promise<void> {
    await firstValueFrom(
      this.blacklistArticlesApiClient.deleteBlacklistArticle({
        id,
      })
    )
  }

  async createArticles(articles: CreateBlacklistArticleParams[]): Promise<BlacklistArticleModel[]> {
    return await firstValueFrom(
      this.blacklistArticlesApiClient.createBlacklistArticles({
        body: articles,
      })
    )
  }
}
