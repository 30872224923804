/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BlacklistArticleModel } from '../models/blacklist-article-model';
import { CreateBlacklistArticleParams } from '../models/create-blacklist-article-params';
import { Unit } from '../models/unit';

@Injectable({ providedIn: 'root' })
export class BlacklistArticlesApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBlacklistAllArticles()` */
  static readonly GetBlacklistAllArticlesPath = '/api/v1/blacklist-articles';

  /**
   * Get all blacklist articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlacklistAllArticles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlacklistAllArticles$Plain$Response(
    params?: {
      favouriteProfileId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BlacklistArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.GetBlacklistAllArticlesPath, 'get');
    if (params) {
      rb.query('favouriteProfileId', params.favouriteProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BlacklistArticleModel>>;
      })
    );
  }

  /**
   * Get all blacklist articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBlacklistAllArticles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlacklistAllArticles$Plain(
    params?: {
      favouriteProfileId?: number;
    },
    context?: HttpContext
  ): Observable<Array<BlacklistArticleModel>> {
    return this.getBlacklistAllArticles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlacklistArticleModel>>): Array<BlacklistArticleModel> => r.body)
    );
  }

  /**
   * Get all blacklist articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlacklistAllArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlacklistAllArticles$Response(
    params?: {
      favouriteProfileId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BlacklistArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.GetBlacklistAllArticlesPath, 'get');
    if (params) {
      rb.query('favouriteProfileId', params.favouriteProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BlacklistArticleModel>>;
      })
    );
  }

  /**
   * Get all blacklist articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBlacklistAllArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlacklistAllArticles(
    params?: {
      favouriteProfileId?: number;
    },
    context?: HttpContext
  ): Observable<Array<BlacklistArticleModel>> {
    return this.getBlacklistAllArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlacklistArticleModel>>): Array<BlacklistArticleModel> => r.body)
    );
  }

  /** Path part for operation `createBlacklistArticles()` */
  static readonly CreateBlacklistArticlesPath = '/api/v1/blacklist-articles';

  /**
   * Add new blacklist articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBlacklistArticles$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBlacklistArticles$Plain$Response(
    params: {
      body: Array<CreateBlacklistArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BlacklistArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.CreateBlacklistArticlesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BlacklistArticleModel>>;
      })
    );
  }

  /**
   * Add new blacklist articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBlacklistArticles$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBlacklistArticles$Plain(
    params: {
      body: Array<CreateBlacklistArticleParams>
    },
    context?: HttpContext
  ): Observable<Array<BlacklistArticleModel>> {
    return this.createBlacklistArticles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlacklistArticleModel>>): Array<BlacklistArticleModel> => r.body)
    );
  }

  /**
   * Add new blacklist articles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBlacklistArticles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBlacklistArticles$Response(
    params: {
      body: Array<CreateBlacklistArticleParams>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BlacklistArticleModel>>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.CreateBlacklistArticlesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BlacklistArticleModel>>;
      })
    );
  }

  /**
   * Add new blacklist articles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBlacklistArticles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBlacklistArticles(
    params: {
      body: Array<CreateBlacklistArticleParams>
    },
    context?: HttpContext
  ): Observable<Array<BlacklistArticleModel>> {
    return this.createBlacklistArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlacklistArticleModel>>): Array<BlacklistArticleModel> => r.body)
    );
  }

  /** Path part for operation `deleteBlacklistArticle()` */
  static readonly DeleteBlacklistArticlePath = '/api/v1/blacklist-articles/{id}';

  /**
   * Delete existing blacklist article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBlacklistArticle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlacklistArticle$Plain$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.DeleteBlacklistArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete existing blacklist article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBlacklistArticle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlacklistArticle$Plain(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteBlacklistArticle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Delete existing blacklist article.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBlacklistArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlacklistArticle$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, BlacklistArticlesApiClient.DeleteBlacklistArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete existing blacklist article.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBlacklistArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlacklistArticle(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteBlacklistArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

}
