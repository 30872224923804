import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core'

@Directive({
  selector: '[efpWatchParentDimensions]',
})
export class WatchParentDimensionsDirective implements AfterViewInit, OnDestroy {
  @Output() readonly dimensionsChange = new EventEmitter<DimensionChange>(true)

  private readonly parent: HTMLElement
  private width = 0
  private height = 0

  private resizeObserver?: ResizeObserver

  constructor(elementRef: ElementRef, private ngZone: NgZone) {
    this.parent = elementRef.nativeElement.parentElement
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(this.update.bind(this))
      this.resizeObserver.observe(this.parent)
    })

    this.update()
  }

  private update(): void {
    const newWidth = this.parent.clientWidth
    const newHeight = this.parent.clientHeight

    if (newWidth !== this.width || newHeight !== this.height) {
      this.width = newWidth
      this.height = newHeight

      this.ngZone.run(() =>
        this.dimensionsChange.next({
          width: this.parent.clientWidth,
          height: this.parent.clientHeight,
        })
      )
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.parent)
    }
  }
}

export class DimensionChange {
  width: number
  height: number

  constructor(width: number, height: number) {
    this.width = width
    this.height = height
  }
}
