import { Injectable } from '@angular/core'
import { AbstractControl, ValidatorFn } from '@angular/forms'
import { FormworkSystem } from '../models/formworkSystem'
import { PlanSettings } from '../models/planSettings'
import { PlanType } from '../shared/formwork-planner/model/PlanType'
import { AppSettingService } from './app-setting.service'

@Injectable({
  providedIn: 'root',
})
export class FormworkSystemService {
  private allFormworkSystems: FormworkSystem[] = []
  private formworkSystems: FormworkSystem[] = []

  private invalidWallFormworkSystem: FormworkSystem | null = null
  private invalidSlabFormworkSystem: FormworkSystem | null = null

  public wallFormworkSystems: FormworkSystem[] = []
  public slabFormworkSystems: FormworkSystem[] = []

  constructor(private readonly settingService: AppSettingService) {
    this.allFormworkSystems = this.settingService.getAllFormworkSystems()
  }

  public async updateAvailableFormworkSystems(planSettings?: PlanSettings): Promise<void> {
    this.formworkSystems = await this.settingService.getSupportedFormworkSystemsForCurrentCountry()
    this.ensureSelectedFormworkIsPresent(planSettings)
  }

  public isInvalidFormworkSystem(id: string): boolean {
    if (this.invalidWallFormworkSystem && id === this.invalidWallFormworkSystem.id) {
      return true
    }
    return !!(this.invalidSlabFormworkSystem && id === this.invalidSlabFormworkSystem.id)
  }

  public validateFormworkExists(planType: PlanType): ValidatorFn {
    return (control: AbstractControl) => {
      let availableSystems: FormworkSystem[]
      if (planType === PlanType.WALL) {
        availableSystems = this.wallFormworkSystems
      } else {
        availableSystems = this.slabFormworkSystems
      }

      const selectedId = control.value

      const isValid =
        !this.isInvalidFormworkSystem(selectedId) &&
        (availableSystems?.some((fs) => fs.id === selectedId) ?? false)

      return isValid
        ? null
        : {
            validateFormworkExists: {
              valid: false,
            },
          }
    }
  }

  private ensureSelectedFormworkIsPresent(planSettings?: PlanSettings): void {
    this.invalidWallFormworkSystem = null
    this.invalidSlabFormworkSystem = null

    if (planSettings) {
      const selectedSlabSystem = this.allFormworkSystems.find(
        (it) => it.id === planSettings.formworkSlab
      )
      if (selectedSlabSystem && !this.formworkSystems.includes(selectedSlabSystem)) {
        this.formworkSystems.push(selectedSlabSystem)
        this.invalidWallFormworkSystem = selectedSlabSystem
      }
      const selectedWallSystem = this.allFormworkSystems.find(
        (it) => it.id === planSettings.formworkWall
      )
      if (selectedWallSystem && !this.formworkSystems.includes(selectedWallSystem)) {
        this.formworkSystems.push(selectedWallSystem)
        this.invalidSlabFormworkSystem = selectedWallSystem
      }
    }

    this.wallFormworkSystems = this.formworkSystems.filter((it) => it.planType === PlanType.WALL)
    this.slabFormworkSystems = this.formworkSystems.filter((it) => it.planType === PlanType.SLAB)
  }
}
