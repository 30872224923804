import { Country, FormworkId } from '@efp/api'
import { PlanType } from 'formwork-planner-lib'
import { AccessoryPart } from '../pages/accessory/model/AccessoryPart'

export class FormworkSystem {
  constructor(
    public readonly id: FormworkId,
    public readonly accessories: AccessoryPart[],
    public readonly supportedCountries: Country[],
    public readonly planType: PlanType,
    public readonly maxHeight: number,
    public readonly maxHeightImperial: number
  ) {}
}
