import { Pipe, PipeTransform } from '@angular/core'
import { FavouritesService } from '../../../services/favourites.service'

@Pipe({ name: 'favTranslate' })
export class FavouritesPipe implements PipeTransform {
  constructor(private favService: FavouritesService) {}

  transform(key: string): string {
    return this.favService.translateKey(key)
  }
}
