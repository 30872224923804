import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-list-accordion',
  templateUrl: './list-accordion.component.html',
  styleUrls: ['./list-accordion.component.scss'],
})
export class ListAccordionComponent {
  @Input() accordionValue?: string
}
