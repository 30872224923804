/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { FavouriteProfileCommandParams } from '../models/favourite-profile-command-params';
import { FavouriteProfilesModel } from '../models/favourite-profiles-model';
import { FormworkId } from '../models/formwork-id';

@Injectable({ providedIn: 'root' })
export class FavouriteProfilesApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAll()` */
  static readonly GetAllPath = '/api/v1/favourite-profiles';

  /**
   * Returns all favourites.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain$Response(
    params?: {
      formworkSystemId?: FormworkId;
      isStandard?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FavouriteProfilesModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.GetAllPath, 'get');
    if (params) {
      rb.query('formworkSystemId', params.formworkSystemId, {});
      rb.query('isStandard', params.isStandard, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FavouriteProfilesModel>>;
      })
    );
  }

  /**
   * Returns all favourites.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Plain(
    params?: {
      formworkSystemId?: FormworkId;
      isStandard?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<FavouriteProfilesModel>> {
    return this.getAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FavouriteProfilesModel>>): Array<FavouriteProfilesModel> => r.body)
    );
  }

  /**
   * Returns all favourites.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(
    params?: {
      formworkSystemId?: FormworkId;
      isStandard?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FavouriteProfilesModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.GetAllPath, 'get');
    if (params) {
      rb.query('formworkSystemId', params.formworkSystemId, {});
      rb.query('isStandard', params.isStandard, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FavouriteProfilesModel>>;
      })
    );
  }

  /**
   * Returns all favourites.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(
    params?: {
      formworkSystemId?: FormworkId;
      isStandard?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<FavouriteProfilesModel>> {
    return this.getAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FavouriteProfilesModel>>): Array<FavouriteProfilesModel> => r.body)
    );
  }

  /** Path part for operation `createavouriteProfile()` */
  static readonly CreateavouriteProfilePath = '/api/v1/favourite-profiles';

  /**
   * Add a new favouriteProfile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createavouriteProfile$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createavouriteProfile$Plain$Response(
    params: {
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.CreateavouriteProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new favouriteProfile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createavouriteProfile$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createavouriteProfile$Plain(
    params: {
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createavouriteProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new favouriteProfile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createavouriteProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createavouriteProfile$Response(
    params: {
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.CreateavouriteProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new favouriteProfile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createavouriteProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createavouriteProfile(
    params: {
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createavouriteProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getFavouriteProfile()` */
  static readonly GetFavouriteProfilePath = '/api/v1/favourite-profiles/{favouriteId}';

  /**
   * Get favouriteProfile per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouriteProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteProfile$Plain$Response(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FavouriteProfilesModel>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.GetFavouriteProfilePath, 'get');
    if (params) {
      rb.path('favouriteId', params.favouriteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FavouriteProfilesModel>;
      })
    );
  }

  /**
   * Get favouriteProfile per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFavouriteProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteProfile$Plain(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<FavouriteProfilesModel> {
    return this.getFavouriteProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FavouriteProfilesModel>): FavouriteProfilesModel => r.body)
    );
  }

  /**
   * Get favouriteProfile per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouriteProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteProfile$Response(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FavouriteProfilesModel>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.GetFavouriteProfilePath, 'get');
    if (params) {
      rb.path('favouriteId', params.favouriteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FavouriteProfilesModel>;
      })
    );
  }

  /**
   * Get favouriteProfile per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFavouriteProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteProfile(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<FavouriteProfilesModel> {
    return this.getFavouriteProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FavouriteProfilesModel>): FavouriteProfilesModel => r.body)
    );
  }

  /** Path part for operation `updateFavouriteProfile()` */
  static readonly UpdateFavouriteProfilePath = '/api/v1/favourite-profiles/{favouriteId}';

  /**
   * Update existing favouriteProfile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFavouriteProfile$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFavouriteProfile$Plain$Response(
    params: {
      favouriteId: number;
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FavouriteProfilesModel>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.UpdateFavouriteProfilePath, 'put');
    if (params) {
      rb.path('favouriteId', params.favouriteId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FavouriteProfilesModel>;
      })
    );
  }

  /**
   * Update existing favouriteProfile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFavouriteProfile$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFavouriteProfile$Plain(
    params: {
      favouriteId: number;
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<FavouriteProfilesModel> {
    return this.updateFavouriteProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FavouriteProfilesModel>): FavouriteProfilesModel => r.body)
    );
  }

  /**
   * Update existing favouriteProfile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFavouriteProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFavouriteProfile$Response(
    params: {
      favouriteId: number;
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FavouriteProfilesModel>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.UpdateFavouriteProfilePath, 'put');
    if (params) {
      rb.path('favouriteId', params.favouriteId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FavouriteProfilesModel>;
      })
    );
  }

  /**
   * Update existing favouriteProfile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFavouriteProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFavouriteProfile(
    params: {
      favouriteId: number;
      body: FavouriteProfileCommandParams
    },
    context?: HttpContext
  ): Observable<FavouriteProfilesModel> {
    return this.updateFavouriteProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FavouriteProfilesModel>): FavouriteProfilesModel => r.body)
    );
  }

  /** Path part for operation `deleteFavouriteProfile()` */
  static readonly DeleteFavouriteProfilePath = '/api/v1/favourite-profiles/{favouriteId}';

  /**
   * Delete favouriteProfile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFavouriteProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFavouriteProfile$Response(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FavouriteProfilesApiClient.DeleteFavouriteProfilePath, 'delete');
    if (params) {
      rb.path('favouriteId', params.favouriteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete favouriteProfile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFavouriteProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFavouriteProfile(
    params: {
      favouriteId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteFavouriteProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
