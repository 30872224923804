import { Component, HostListener, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { NavController, Platform } from '@ionic/angular'
import { combineLatest, Subscription } from 'rxjs'
import { filter, throttleTime } from 'rxjs/operators'
import { register } from 'swiper/element/bundle'
import { environment } from '../environments/environment'
import { AuthenticationRepository } from './repositories/authentication-repository.service'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from './services/applicationInsights.service'
import { AuthenticationService } from './services/authentication.service'
import { DevicePreferencesService } from './services/device-preferences.service'
import { FileHandlerService } from './services/file-handler.service'
import { HttpRequestSyncService, SynchronizationState } from './services/http-request-sync.service'
import { PlanSettingsService } from './services/plan-settings.service'
import { Translation } from './services/translation.service'

register()

@Component({
  selector: 'efp-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isErrorModalOpen = false

  private tokenSub?: Subscription
  private synchronizationState = SynchronizationState.SYNC_SAVED

  constructor(
    private readonly translateService: Translation,
    private readonly devicePreferences: DevicePreferencesService,
    private readonly fileHandlerService: FileHandlerService,
    private readonly authService: AuthenticationService,
    private readonly authRepository: AuthenticationRepository,
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly platform: Platform,
    private readonly planSettingsService: PlanSettingsService,
    private readonly router: Router,
    private readonly navCtrl: NavController,
    private readonly httpRequestSyncService: HttpRequestSyncService
  ) {
    if (Capacitor.isPluginAvailable('App')) {
      void App.addListener('appUrlOpen', (urlOpen) => {
        void (async () => {
          this.fileHandlerService.registerFile(urlOpen.url)
          const hasConfirmedAGBs = await this.devicePreferences.fetchHasConfirmedAGBs()

          if (hasConfirmedAGBs) {
            await this.fileHandlerService.handleFile()
          }
        })()
      })

      this.platform.backButton.subscribe(() => {
        const url = this.router.url
        if (
          url.indexOf('/homepage') !== -1 ||
          url.indexOf('/login') !== -1 ||
          url.indexOf('/agb-check') !== -1
        ) {
          void App.exitApp().then()
        } else {
          this.navCtrl.back()
        }
      })

      // manual refresh of token because the automatic token refresh of the library doesn´t seem to work after the app is paused
      this.tokenSub?.unsubscribe()
      this.tokenSub = this.platform.resume.subscribe(() => {
        void this.authService.refreshToken()
      })
    }
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.authRepository.authenticated$,
      this.authRepository.testAuthenticated$,
    ]).subscribe(([loggedIn, testLoggedIn]) => {
      if (loggedIn || testLoggedIn) {
        void this.refreshDataOnLogin()
      }
    })

    this.httpRequestSyncService.synchronizationState$.subscribe((value) => {
      this.synchronizationState = value
    })

    this.initSynchronizationErrorListener()
    await this.initApplication()
  }

  public closeErrorModal(): void {
    this.isErrorModalOpen = false
    window.location.reload()
  }

  private initSynchronizationErrorListener(): void {
    this.httpRequestSyncService.synchronizationState$
      .pipe(
        filter((x) => x === SynchronizationState.SYNC_FAILED),
        throttleTime(5000)
      )
      .subscribe(() => {
        this.isErrorModalOpen = true
      })
  }

  private async initApplication(): Promise<void> {
    // Set the language
    await this.translateService.initTranslation(this.authService.isOAuthLoggedIn)
    await this.authService.configureOauth2()
    const hasConfirmedAGBs = await this.devicePreferences.fetchHasConfirmedAGBs()
    environment.applicationReady = true

    // Init auth or open agbs
    if (hasConfirmedAGBs) {
      await this.fileHandlerService.handleFile()
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTabDuringSync(): boolean {
    if (this.synchronizationState === SynchronizationState.SYNC_RUNNING) {
      return false
    }
    return true
  }

  private async refreshDataOnLogin(): Promise<void> {
    await this.translateService.updateLanguageFromAppSettings()
    await this.planSettingsService.setFirstSupportedFormworkSystemForAppPlanSettingsIfNecessary()

    this.appInsightsService.enableApplicationInsights()
    this.appInsightsService.addUserEvent(
      (await this.authService.isDokaUser())
        ? ApplicationInsightsStates.INTERNAL_USER
        : ApplicationInsightsStates.EXTERNAL_USER
    )
  }
}
