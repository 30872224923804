import {
  deleteEntities,
  getAllEntitiesApply,
  getEntity,
  selectAllEntities,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { createStore } from '@ngneat/elf'
import { Project } from '../models/project'
import { ProjectDao } from '../services/dao/project.dao'
import { ProjectCommandParams } from '../../generated/efp-api'
import { PlanRepository } from './plan.repository'

export const projectStore = createStore({ name: 'project' }, withEntities<Project>())

@Injectable({
  providedIn: 'root',
})
export class ProjectRepository {
  private hasLoadedAll = false

  public readonly projects$ = projectStore.pipe(selectAllEntities())

  constructor(
    private readonly projectDao: ProjectDao,
    private readonly planRepository: PlanRepository
  ) {}

  public async findAllByFavouriteId(favouriteId: number): Promise<Project[]> {
    const projects = await this.projectDao.findAllByFavouriteId(favouriteId)
    projectStore.update(upsertEntities(projects))
    return projects
  }

  public async findOne(projectId: number, includePlans: boolean): Promise<Project | undefined> {
    let project = projectStore.query(getEntity(projectId))

    if (!project) {
      project = await this.projectDao.findOne(projectId, includePlans)

      if (project) {
        projectStore.update(upsertEntities(project))
      }
    }

    if (includePlans && project) {
      project.plans = await this.planRepository.findAllByProjectId(project.id)
    }

    return project
  }

  public async fetchAll(includePlans: boolean): Promise<Project[]> {
    let projects = projectStore.query(getAllEntitiesApply({}))

    if (projects && projects.length > 0 && this.hasLoadedAll) {
      projects = await Promise.all(
        projects.map(async (it) => {
          if (includePlans) {
            it.plans = await this.planRepository.findAllByProjectId(it.id)
          }
          return it
        })
      )
      projectStore.update(upsertEntities(projects))

      return projects
    } else {
      projects = await this.projectDao.findAll(includePlans)
      projectStore.update(setEntities(projects))
      this.hasLoadedAll = true

      return projects
    }
  }

  public async findAllByStockId(stockId: number): Promise<Project[]> {
    const projects = await this.projectDao.findAllByStockId(stockId)
    projectStore.update(upsertEntities(projects))
    return projects
  }

  public async create(params: ProjectCommandParams): Promise<number> {
    // TODO: optimistic creating or backend return project
    this.hasLoadedAll = false
    return this.projectDao.create(params)
  }

  public async update(project: Project): Promise<void> {
    await this.projectDao.update(project)
    projectStore.update(updateEntities(project.id, project))
  }

  public async delete(project: Project): Promise<void> {
    await this.projectDao.delete(project)
    projectStore.update(deleteEntities(project.id))
  }
}
