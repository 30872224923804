import { createStore } from '@ngneat/elf'
import {
  addEntities,
  getAllEntitiesApply,
  getEntity,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { CreatePlanSettingsCommandParams } from '../../generated/efp-api'
import {
  PlanSettingsDao,
  mapCreatePlanSettingsCommandParamsToPlanSettings,
} from '../services/dao/plan-settings.dao'
import { PlanSettings } from '../models/planSettings'
import { deepCopy } from '../utils/deepCopy'

const planSettingsStore = createStore({ name: 'planSettings' }, withEntities<PlanSettings>())

@Injectable({
  providedIn: 'root',
})
export class PlanSettingsRepository {
  public readonly planSettings$ = planSettingsStore.pipe(selectAllEntities())

  constructor(private readonly planSettingsDao: PlanSettingsDao) {}

  public async findOneById(settingsId: number): Promise<PlanSettings | undefined> {
    let planSettings = planSettingsStore.query(getEntity(settingsId))

    if (!planSettings) {
      planSettings = await this.planSettingsDao.findOneById(settingsId)

      if (planSettings) {
        planSettingsStore.update(upsertEntities(planSettings))
      }
    }

    return deepCopy(planSettings)
  }

  public async findAllById(planSettingIds: number[]): Promise<PlanSettings[]> {
    let planSettings = planSettingsStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => planSettingIds.includes(e.id),
      })
    )

    if (planSettings && planSettings.length === planSettingIds.length) {
      return planSettings
    } else {
      planSettings = await this.planSettingsDao.findAllById(planSettingIds)
      planSettingsStore.update(upsertEntities(planSettings))
      return planSettings
    }
  }

  public async findAllByFavouriteId(favouriteId: number): Promise<PlanSettings[]> {
    const planSettings = await this.planSettingsDao.findAllByFavouriteId(favouriteId)
    planSettingsStore.update(upsertEntities(planSettings))
    return planSettings
  }

  public async create(
    params: CreatePlanSettingsCommandParams,
    templateId: number
  ): Promise<number> {
    const id = await this.planSettingsDao.create(params, templateId)
    const newPlanSettings = mapCreatePlanSettingsCommandParamsToPlanSettings(params, id)

    planSettingsStore.update(addEntities(newPlanSettings))

    return id
  }

  // TODO: (#83399) This should currently only used from the update function in the PlanSettingsService
  // In the future we should refactor the PlanSettingsService for a more reactive approach to manage planner variables like the measurement unit
  public async update(planSettings: PlanSettings): Promise<PlanSettings> {
    planSettingsStore.update(upsertEntities(planSettings))

    const updatedPlanSettings = await this.planSettingsDao.update(planSettings)

    planSettingsStore.update(upsertEntities(updatedPlanSettings))
    return updatedPlanSettings
  }
}
