<ion-button
  slot="icon-only"
  shape="round"
  fill="clear"
  class="icon-button"
  (click)="open()"
  data-test-selector="open-reset-onboarding-modal">
  <ion-icon
    color="primary"
    src="assets/icon/ic_info.svg"
    data-test-selector="info-box-open"></ion-icon>
</ion-button>
