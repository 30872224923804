/*
Deals with everything regarding the assignment of accessories to the side of an edge.
Represents one straight line of the outer shell of the construct, regardless of edges and mesh-points.
 */
import paper from 'paper/dist/paper-core'
import { ACCESSORY_CIRCLE_STROKE_COLOR, PRIMARY_COLOR } from '../../../constants/colors'
import { HIGHLIGHTED_STROKE_WIDTH } from '../../planner/model/snapping/constants'
import {
  ACCESSORY_CIRCLE_RADIUS,
  ACCESSORY_CIRCLE_STROKE_WIDTH,
  ACCESSORY_LABEL_FONT_SIZE,
  ACCESSORY_LABEL_VERTICAL_OFFSET,
} from '../accessory-constants'
import { AccessoryPart } from './AccessoryPart'

export class AccessoryLineDisplay extends paper.Group {
  private accessories: AccessoryPart[] = []
  private line: paper.Path
  private circles: paper.Path.Circle[] = []
  private labels: paper.PointText[] = []

  constructor(
    public readonly databaseId: number,
    public start: paper.Point,
    public end: paper.Point,
    accessories: AccessoryPart[]
  ) {
    super()
    this.line = this.createHighlightLine()
    this.accessories = accessories
    this.drawAccessories()
  }

  public toggleSelection(): void {
    this.line.visible = !this.line.visible
  }

  public setSelection(sel: boolean): void {
    this.line.visible = sel
  }

  get isSelected(): boolean {
    return this.line.visible
  }

  // returns the minimum distance from the given point to the AccessoryLine
  public minimumDistance(point: paper.Point): number {
    return this.line.getNearestPoint(point).getDistance(point)
  }

  public setAccessories(accessories: AccessoryPart[]): void {
    this.accessories = accessories
    this.drawAccessories()
  }

  public getAccessories(): AccessoryPart[] {
    return this.accessories
  }

  public hasAccessory(accessory: AccessoryPart): boolean {
    return this.accessories.some((assigned) => assigned.matches(accessory))
  }

  public hasAccessories(): boolean {
    return this.accessories.length !== 0
  }

  private createHighlightLine(): paper.Path {
    this.line?.remove()
    const line = new paper.Path.Line(this.start, this.end)
    line.strokeColor = PRIMARY_COLOR
    line.strokeWidth = HIGHLIGHTED_STROKE_WIDTH
    line.visible = this.selected
    this.line = line
    this.addChild(line)
    return line
  }

  private drawAccessories(): void {
    this.circles.forEach((circle) => circle.remove())
    this.circles = []
    this.labels.forEach((label) => label.remove())
    this.labels = []

    this.accessories.forEach((accessory, i) => {
      const circle = new paper.Path.Circle(this.getCirclePosition(i), ACCESSORY_CIRCLE_RADIUS)
      circle.strokeColor = ACCESSORY_CIRCLE_STROKE_COLOR
      circle.strokeWidth = ACCESSORY_CIRCLE_STROKE_WIDTH
      circle.fillColor = accessory.color
      this.addChild(circle)
      this.circles.push(circle)

      const label = new paper.PointText(
        circle.position.add(new paper.Point(0, ACCESSORY_LABEL_VERTICAL_OFFSET))
      )
      label.content = accessory.orderPosition.toString()
      label.justification = 'center'
      label.fontSize = ACCESSORY_LABEL_FONT_SIZE
      this.addChild(label)
      this.labels.push(label)
    })
  }

  private getCirclePosition(index: number): paper.Point {
    const amount = this.accessories.length
    const circleDiameter = 2 * ACCESSORY_CIRCLE_RADIUS + ACCESSORY_CIRCLE_STROKE_WIDTH

    const line = this.end.subtract(this.start)
    const length = this.start.getDistance(this.end)
    const middle = this.start.add(line.normalize(length / 2))
    const first = middle.add(line.normalize(circleDiameter * (amount / 2 - 0.5)))

    return first.subtract(line.normalize(circleDiameter * index))
  }

  public get accessoriesAsString(): string {
    return this.accessories.map((it) => it.id).join(',')
  }

  public get length(): number {
    return this.start.getDistance(this.end)
  }
}
