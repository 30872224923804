import { inject, Injectable } from '@angular/core'
import { FavouriteProfilesApiClient, FavouriteProfilesModel, FormworkId } from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { FavouriteProfile } from '../../../models/favourites'
import { FavouriteDao } from '../favourite.dao'
import { OLD_FAVOURITES_VERSION } from '../../../constants/versions'

@Injectable()
export class FavouriteHttpDao extends FavouriteDao {
  private readonly favouriteApiClient = inject(FavouriteProfilesApiClient)

  private mapModelToFavouriteProfile(model: FavouriteProfilesModel): FavouriteProfile {
    return {
      id: model.id,
      createdAt: new Date(model.createdAt),
      updatedAt: new Date(model.updatedAt),
      name: model.name,
      isStandard: model.isStandard,
      basis: JSON.parse(model.basisJson),
      values: JSON.parse(model.favouritesJson),
      formworkSystemId: model.formworkSystemId,
      useOnlyRentableArticles: model.useOnlyRentableArticles,
      formworkVersion: model.formworkVersion,
    }
  }

  async create(
    favorite: Omit<FavouriteProfile, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<number> {
    return firstValueFrom(
      this.favouriteApiClient.createavouriteProfile({
        body: {
          name: favorite.name,
          isStandard: favorite.isStandard,
          favouritesJson: JSON.stringify(favorite.values),
          basisJson: JSON.stringify(favorite.basis),
          formworkSystemId: favorite.formworkSystemId,
          useOnlyRentableArticles: favorite.useOnlyRentableArticles,
          formworkVersion: favorite.formworkVersion ?? OLD_FAVOURITES_VERSION,
        },
      })
    )
  }

  async deleteById(id: number): Promise<void> {
    await firstValueFrom(
      this.favouriteApiClient.deleteFavouriteProfile({
        favouriteId: id,
      })
    )
  }

  async findAll(): Promise<FavouriteProfile[]> {
    const favouriteModels = await firstValueFrom(this.favouriteApiClient.getAll())

    return favouriteModels.map((model) => this.mapModelToFavouriteProfile(model))
  }

  async findAllByFormworkSystemId(formworkSystemId: FormworkId): Promise<FavouriteProfile[]> {
    const favouriteModels = await firstValueFrom(
      this.favouriteApiClient.getAll({
        formworkSystemId,
      })
    )

    return favouriteModels.map((model) => this.mapModelToFavouriteProfile(model))
  }

  async findOneById(id: number): Promise<FavouriteProfile | undefined> {
    const favouriteModel = await firstValueFrom(
      this.favouriteApiClient.getFavouriteProfile({
        favouriteId: id,
      })
    )

    return this.mapModelToFavouriteProfile(favouriteModel)
  }

  async findOneStandardByFormworkSystemId(
    formworkSystemId: FormworkId
  ): Promise<FavouriteProfile | undefined> {
    const favouriteModels = await firstValueFrom(
      this.favouriteApiClient.getAll({
        formworkSystemId,
        isStandard: true,
      })
    )

    if (favouriteModels.length === 0) {
      return undefined
    } else {
      return this.mapModelToFavouriteProfile(favouriteModels[0])
    }
  }

  async update(favorite: FavouriteProfile): Promise<number> {
    const updatedProfile = await firstValueFrom(
      this.favouriteApiClient.updateFavouriteProfile({
        favouriteId: favorite.id,
        body: {
          name: favorite.name,
          isStandard: favorite.isStandard,
          favouritesJson: JSON.stringify(favorite.values),
          basisJson: JSON.stringify(favorite.basis),
          formworkSystemId: favorite.formworkSystemId,
          useOnlyRentableArticles: favorite.useOnlyRentableArticles,
          formworkVersion: favorite.formworkVersion ?? OLD_FAVOURITES_VERSION,
        },
      })
    )

    return updatedProfile.id
  }
}
