<ion-buttons>
  <ion-button
    (click)="centerView()"
    *ngIf="model"
    efpTooltip
    [tooltipTitle]="'TOOLBAR.CENTER_VIEW' | translate"
    data-test-selector="planner-center-view">
    <ion-icon slot="icon-only" src="assets/icon/focus.svg"></ion-icon>
  </ion-button>
</ion-buttons>
