/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Unit } from '../models/unit';

@Injectable({ providedIn: 'root' })
export class OnboardingTrackingApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createOnboardingTracking()` */
  static readonly CreateOnboardingTrackingPath = '/api/v1/onboarding-tracking/{onboardingHintKey}';

  /**
   * Creates a new tracking for the authenticated user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOnboardingTracking$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOnboardingTracking$Plain$Response(
    params: {
      onboardingHintKey: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.CreateOnboardingTrackingPath, 'post');
    if (params) {
      rb.path('onboardingHintKey', params.onboardingHintKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new tracking for the authenticated user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOnboardingTracking$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOnboardingTracking$Plain(
    params: {
      onboardingHintKey: string;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.createOnboardingTracking$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Creates a new tracking for the authenticated user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOnboardingTracking()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOnboardingTracking$Response(
    params: {
      onboardingHintKey: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.CreateOnboardingTrackingPath, 'post');
    if (params) {
      rb.path('onboardingHintKey', params.onboardingHintKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new tracking for the authenticated user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOnboardingTracking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOnboardingTracking(
    params: {
      onboardingHintKey: string;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.createOnboardingTracking$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /** Path part for operation `getOnboardingTracking()` */
  static readonly GetOnboardingTrackingPath = '/api/v1/onboarding-tracking';

  /**
   * Returns a list of all onboarding the user has already seen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnboardingTracking$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnboardingTracking$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.GetOnboardingTrackingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Returns a list of all onboarding the user has already seen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOnboardingTracking$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnboardingTracking$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getOnboardingTracking$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * Returns a list of all onboarding the user has already seen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnboardingTracking()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnboardingTracking$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.GetOnboardingTrackingPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Returns a list of all onboarding the user has already seen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOnboardingTracking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnboardingTracking(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.getOnboardingTracking$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `deleteAllOnboardingTrackings()` */
  static readonly DeleteAllOnboardingTrackingsPath = '/api/v1/onboarding-tracking';

  /**
   * Deletes all Onboarding series entries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllOnboardingTrackings()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllOnboardingTrackings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.DeleteAllOnboardingTrackingsPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes all Onboarding series entries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllOnboardingTrackings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllOnboardingTrackings(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAllOnboardingTrackings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOnboardingTracking()` */
  static readonly DeleteOnboardingTrackingPath = '/api/v1/onboarding-tracking/{onboardingHintSeriesKey}';

  /**
   * Delete Onboarding series entry by OnboardingHintSeriesKey.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOnboardingTracking()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOnboardingTracking$Response(
    params: {
      onboardingHintSeriesKey: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OnboardingTrackingApiClient.DeleteOnboardingTrackingPath, 'delete');
    if (params) {
      rb.path('onboardingHintSeriesKey', params.onboardingHintSeriesKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Onboarding series entry by OnboardingHintSeriesKey.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOnboardingTracking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOnboardingTracking(
    params: {
      onboardingHintSeriesKey: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteOnboardingTracking$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
