import { Component, Input, OnInit } from '@angular/core'
import { OnboardingHint } from '../../../../models/onboarding/onboarding-hint'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'efp-onboarding-hint-template',
  templateUrl: './onboarding-hint-template.component.html',
})
export class OnboardingHintTemplateComponent implements OnInit {
  public hintIndex = 0

  @Input() hints!: OnboardingHint[]
  public currentHint?: OnboardingHint

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (this.hints) this.currentHint = this.hints[this.hintIndex]
  }

  public setCurrentHint(index: number): void {
    if (this.hints) this.currentHint = this.hints[index]
  }

  public numberOfHints(): number {
    return this.hints?.length ?? 0
  }

  public goToHint(targetHintIndex: number): void {
    this.hintIndex = targetHintIndex
    this.setCurrentHint(this.hintIndex)
  }

  public nextHint(): void {
    if (this.hasNextHint()) {
      this.hintIndex++
      this.setCurrentHint(this.hintIndex)
    }
  }

  public hasNextHint(): boolean {
    return !!this.hints && this.hintIndex < this.hints.length - 1
  }

  public prevHint(): void {
    if (this.hasPrev()) {
      this.hintIndex--
      this.setCurrentHint(this.hintIndex)
    }
  }

  public hasPrev(): boolean {
    return this.hintIndex > 0
  }

  private async closeModal(role?: string): Promise<void> {
    await this.modalCtrl.dismiss(role)
  }

  public async finish(): Promise<void> {
    await this.closeModal('finish')
  }

  public async cancel(): Promise<void> {
    await this.closeModal('cancel')
  }
}
