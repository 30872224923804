<efp-app-header
  class="no-header-shadow"
  [isNavStep]="true"
  navLink="/homepage"
  title="{{ plan?.name }}"
  [planId]="planId"
  [webCollapsableEnabled]="true"
  [showTitleOnWeb]="true">
  <ng-container buttons *ngIf="plan">
    <ion-button
      [disabled]="efpExportService.isLoading"
      fill="clear"
      (click)="efpExportService.showShareDialog(plan)">
      <ion-icon slot="icon-only" src="assets/icon/share.svg"></ion-icon>
    </ion-button>
  </ng-container>
</efp-app-header>
<efp-sidebar-container [sidebarPosition]="SidebarPosition.RIGHT">
  <div
    class="drawing-area-container"
    efpKeyboardShortcut
    (deletionShortcut)="onDelete()"
    (numberShortcut)="changeAccessoryKeyboardListener($event)"
    (ctrlZShortcut)="onUndo()"
    (ctrlYShortcut)="onRedo()"
    (ctrlAShortcut)="triggerSelectallKeyboardShortcut()"
    (escShortcut)="triggerDeselectallKeyboardShortcut()"
    (iShortcut)="triggerInvertKeyboardShortcut()">
    <efp-planner
      [initialize]="pageReady"
      [showScale]="false"
      [scaleUnit]="unit"
      [scaleLabel]="'PLAN.SCALE' | translate"
      (ready)="onPlannerInitialized()"
      (interactionDragged)="onMouseDragged($event)"
      (interactionUp)="onMouseUp($event)"
      (zoomChanged)="onZoomUpdated($event)"
      (panChanged)="onPanUpdated($event)">
    </efp-planner>
    <efp-measurement-legend
      [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
      [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
      [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
      [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
      [buildingType]="plan ? plan.buildingType : undefined"></efp-measurement-legend>
  </div>

  <ng-container *ngIf="hasLegend()">
    <div *ngIf="!showLegend" class="legend legend-collapsed">
      <ion-button (click)="toggleLegend()" fill="clear">
        {{ 'ACCESSORY.LEGEND' | translate }}
        <ion-icon src="/assets/icon/chevron-down.svg"></ion-icon>
      </ion-button>
    </div>

    <div *ngIf="showLegend" class="legend legend-expanded">
      <ion-button (click)="toggleLegend()" class="collapse-button" fill="clear">
        <ion-icon color="black" src="/assets/icon/chevron-up.svg"></ion-icon>
      </ion-button>
      <div class="part-list">
        <div *ngFor="let part of usedAccessoryParts()" class="part ion-padding-horizontal">
          <span class="dot" [style]="{ 'background-color': part.color.toCSS(true) }">
            {{ part.orderPosition }}
          </span>
          <span class="part-label ion-padding-horizontal">
            {{ part.id | favTranslate }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>

  <efp-bottom-menu-container *ngIf="plannerInitialized && model && renderService && plan">
    <efp-primary-planner-toolbar
      *ngIf="planVisibilitySettings"
      [renderService]="renderService"
      [model]="model"
      [planVisibilitySettings]="planVisibilitySettings">
      <efp-undo-redo
        [history]="history"
        [selectionOpen]="showSelector"
        (undo)="onUndo()"
        (redo)="onRedo()">
      </efp-undo-redo>
      <efp-accessory-context-menu
        [planVisibilitySettings]="planVisibilitySettings"
        [disableAddButton]="!enableAddButton"
        [accessoryLines]="accessoryLines.accessoryLines"
        (openAccessorySelection)="openAccessorySelection()"
        (deleteAccessories)="onDeleteAccessories()"
        [renderService]="renderService"
        [selectionOpen]="showSelector"
        [history]="history"
        [selectAllKeyboardShortcutEventEmitterInput]="selectAllKeyboardShortcutEventEmitter"
        [deselectAllKeyboardShortcutEventEmitterInput]="deselectAllKeyboardShortcutEventEmitter"
        [invertKeyboardShortcutEventEmitterInput]="invertKeyboardShortcutEventEmitter">
      </efp-accessory-context-menu>
    </efp-primary-planner-toolbar>
  </efp-bottom-menu-container>
  <efp-context-menu-center-button-container
    [useCenterView]="true"
    [centerContextMenu]="true"
    [model]="model">
    <efp-accessory-selector
      *ngIf="showSelector"
      [accessoryChoices]="accessoryChoices"
      (closeSelector)="closeAccessorySelector()"
      (submitChanges)="saveSelectedAccessories()">
    </efp-accessory-selector>
  </efp-context-menu-center-button-container>
  <ng-container *ngIf="planSettings && plan" rightSidebar>
    <efp-navbar
      *efpHideOnMobile="showSelector"
      currentStep="accessory"
      [planId]="plan.id"
      [planType]="plan.buildingType"
      [planSettings]="planSettings">
    </efp-navbar>
  </ng-container>
</efp-sidebar-container>
