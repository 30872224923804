<form [formGroup]="formGroup" class="overlay-editor-container">
  <div
    [ngClass]="{
      differentButtonAlignments: hasDifferentButtonAlignments,
      sameButtonAlignments: !hasDifferentButtonAlignments
    }">
    <ng-content select="[overlay-button]"></ng-content>
  </div>
  <div class="overlay-editor-input" [ngClass]="valid ? '' : 'invalid'">
    <ng-content select="[overlay-input]"> </ng-content>
  </div>
  <div *ngIf="!valid" class="overlay-warning">
    <ng-content select="[overlay-warning]"></ng-content>
  </div>
</form>
