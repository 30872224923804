import { Injectable } from '@angular/core'
import { Mesh, PlannerStateService } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { Margins } from '../shared/formwork-planner/services/zoom.service'
import { Model } from '../pages/planner/model/Model'
import {
  NAVBAR_HEIGHT,
  SCALE_BAR_OFFSET_TOP,
  ZOOM_LABEL_OFFSET,
} from '../pages/planner/model/snapping/constants'

@Injectable({
  providedIn: 'root',
})
export class ZoomAndPanService {
  constructor() {}

  private zoom: number | undefined
  private pan: paper.Point | undefined
  private isInitialized = false

  public setZoom(value: number | undefined): void {
    this.zoom = value
  }

  public getZoom(): number {
    return this.zoom ?? 1
  }

  public setPan(pan: paper.Point): void {
    this.pan = pan
  }

  public initializeZoomAndPan(picasso: PlannerStateService, model: Model<Mesh>): void {
    if (this.zoom) {
      picasso.setZoomAndPan(this.zoom, this.pan)
    } else if (!this.isInitialized) {
      this.centerView(picasso, model, {
        marginBottom: NAVBAR_HEIGHT * 2 + ZOOM_LABEL_OFFSET,
      })
    }
    this.isInitialized = true
  }

  public centerView(
    picasso: PlannerStateService,
    model: Model<Mesh> | undefined,
    margins?: Margins
  ): void {
    const correctedMargin = {
      marginTop: (margins?.marginTop ?? 0) + ZOOM_LABEL_OFFSET + SCALE_BAR_OFFSET_TOP,
      marginRight: (margins?.marginRight ?? 0) + ZOOM_LABEL_OFFSET,
      marginBottom: (margins?.marginBottom ?? 0) + ZOOM_LABEL_OFFSET,
      marginLeft: (margins?.marginLeft ?? 0) + ZOOM_LABEL_OFFSET,
    } as Required<Margins>

    const boundingBox = model?.getBoundingBox()
    if (boundingBox) {
      picasso.centerView(boundingBox, correctedMargin)
    }
  }

  public reset(): void {
    this.zoom = undefined
    this.pan = undefined
    this.isInitialized = false
  }
}
