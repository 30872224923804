import { ACCESSORY_WALL_MIN_LENGTH } from '../accessory-constants'
import { AccessoryLineDisplay } from './AccessoryLineDisplay'

export class AccessoryLinesContainer {
  public readonly accessoryLines: AccessoryLineDisplay[]
  public readonly lines: AccessoryLineDisplay[]

  constructor(lines: AccessoryLineDisplay[]) {
    this.lines = lines
    this.accessoryLines = lines.filter((line) => line.length > ACCESSORY_WALL_MIN_LENGTH)
  }
}
