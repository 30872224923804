import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router, RoutesRecognized } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { authConfigNative, authConfigWeb, environment } from '../../../environments/environment'
import { HttpCancelService } from '../../services/http-cancel.service'

export const REQUEST_CANCEL_WHITELIST = ['v1/calculations']
export const REQUEST_CANCEL_BLACKLIST = ['v1/favourite-profiles']

@Injectable()
export class HttpCancelRequestInterceptor implements HttpInterceptor {
  constructor(router: Router, private httpCancelService: HttpCancelService) {
    router.events.subscribe((event) => {
      // An event triggered at the end of the activation part of the Resolve phase of routing.
      if (environment.applicationReady && event instanceof RoutesRecognized) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests()
      }
    })
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (this.handleRequest(req)) {
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests))
    } else {
      return next.handle(req)
    }
  }

  private hasLocalPatterns(req: HttpRequest<unknown>): boolean {
    // local requests starting with any of localReqPatterns shouldn't be cancelled
    const localReqPatterns = ['.', './', '/']

    if (!Capacitor.isNativePlatform() && authConfigWeb.issuer != null) {
      localReqPatterns.push(authConfigWeb.issuer)
    } else if (Capacitor.isNativePlatform() && authConfigNative.issuer != null) {
      localReqPatterns.push(authConfigNative.issuer)
    }

    return localReqPatterns.some((str) => req.url.startsWith(str))
  }

  private handleRequest(req: HttpRequest<unknown>): boolean {
    const hasLocalPatterns = this.hasLocalPatterns(req)
    const syncRequestShouldBeHandled = REQUEST_CANCEL_WHITELIST.some((r) => req.url.includes(r))
    const getRequestShouldNotBeHandled = REQUEST_CANCEL_BLACKLIST.some((r) => req.url.includes(r))

    return (
      syncRequestShouldBeHandled ||
      (!hasLocalPatterns && !getRequestShouldNotBeHandled && req.method === 'GET')
    )
  }
}
