export interface DefaultBranch {
  COUNTRY_CODE: string
  BRANCH: string
}

export const COUNTRY_BRANCH_MAPPING = Object.freeze([
  {
    COUNTRY_CODE: 'AT',
    BRANCH: '111',
  },
  {
    COUNTRY_CODE: 'BE',
    BRANCH: '300',
  },
  {
    COUNTRY_CODE: 'BG',
    BRANCH: '515',
  },
  {
    COUNTRY_CODE: 'CA',
    BRANCH: '440',
  },
  {
    COUNTRY_CODE: 'HR',
    BRANCH: '520',
  },
  {
    COUNTRY_CODE: 'CZ',
    BRANCH: '540',
  },
  {
    COUNTRY_CODE: 'DK',
    BRANCH: '575',
  },
  {
    COUNTRY_CODE: 'FI',
    BRANCH: '590',
  },
  {
    COUNTRY_CODE: 'FR',
    BRANCH: '500',
  },
  {
    COUNTRY_CODE: 'DE',
    BRANCH: '241',
  },
  {
    COUNTRY_CODE: 'GR',
    BRANCH: '350',
  },
  {
    COUNTRY_CODE: 'HU',
    BRANCH: '560',
  },
  {
    COUNTRY_CODE: 'IT',
    BRANCH: '330',
  },
  {
    COUNTRY_CODE: 'LV',
    BRANCH: '303',
  },
  {
    COUNTRY_CODE: 'LT',
    BRANCH: '432',
  },
  {
    COUNTRY_CODE: 'NL',
    BRANCH: '290',
  },
  {
    COUNTRY_CODE: 'NO',
    BRANCH: '580',
  },
  {
    COUNTRY_CODE: 'OM',
    BRANCH: '346',
  },
  {
    COUNTRY_CODE: 'PE',
    BRANCH: '435',
  },
  {
    COUNTRY_CODE: 'PL',
    BRANCH: '286',
  },
  {
    COUNTRY_CODE: 'PT',
    BRANCH: '320',
  },
  {
    COUNTRY_CODE: 'RO',
    BRANCH: '510',
  },
  {
    COUNTRY_CODE: 'RS',
    BRANCH: '470',
  },
  {
    COUNTRY_CODE: 'SK',
    BRANCH: '545',
  },
  {
    COUNTRY_CODE: 'SI',
    BRANCH: '530',
  },
  {
    COUNTRY_CODE: 'SE',
    BRANCH: '570',
  },
  {
    COUNTRY_CODE: 'CH',
    BRANCH: '292',
  },
  {
    COUNTRY_CODE: 'TR',
    BRANCH: '390',
  },
  {
    COUNTRY_CODE: 'UA',
    BRANCH: '287',
  },
  {
    COUNTRY_CODE: 'AE',
    BRANCH: '340',
  },
  {
    COUNTRY_CODE: 'GB',
    BRANCH: '550',
  },
  {
    COUNTRY_CODE: 'US',
    BRANCH: '417',
  },
  {
    COUNTRY_CODE: 'QA',
    BRANCH: '345',
  },
  {
    COUNTRY_CODE: 'AU',
    BRANCH: '420',
  },
  {
    COUNTRY_CODE: 'BR',
    BRANCH: '380',
  },
  {
    COUNTRY_CODE: 'CL',
    BRANCH: '364',
  },
  {
    COUNTRY_CODE: 'CO',
    BRANCH: '437',
  },
  {
    COUNTRY_CODE: 'EE',
    BRANCH: '297',
  },
  {
    COUNTRY_CODE: 'ES',
    BRANCH: '320',
  },
  {
    COUNTRY_CODE: 'EG',
    BRANCH: '352',
  },
  {
    COUNTRY_CODE: 'IE',
    BRANCH: '555',
  },
  {
    COUNTRY_CODE: 'IN',
    BRANCH: '362',
  },
  {
    COUNTRY_CODE: 'KW',
    BRANCH: '150',
  },
  {
    COUNTRY_CODE: 'LB',
    BRANCH: '345',
  },
  {
    COUNTRY_CODE: 'MX',
    BRANCH: '447',
  },
  {
    COUNTRY_CODE: 'MY',
    BRANCH: '492',
  },
  {
    COUNTRY_CODE: 'PA',
    BRANCH: '446',
  },
  {
    COUNTRY_CODE: 'PH',
    BRANCH: '372',
  },
  {
    COUNTRY_CODE: 'SA',
    BRANCH: '278',
  },
  {
    COUNTRY_CODE: 'SG',
    BRANCH: '494',
  },
])
