import { Injectable } from '@angular/core'
import { Article } from '../../../models/article'
import { DataService } from '../../data.service'
import { ArticleDao } from '../article.dao'

@Injectable()
export class ArticleSqlDao extends ArticleDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): Article {
    return {
      id: row.id,
      amount: row.amount,
      articleId: row.articleId.toString(),
      name: row.name,
      stockId: row.stockId,
    }
  }

  public async findAllByStockId(stockId: number): Promise<Article[]> {
    const articles: Article[] = []
    const statement = 'SELECT * FROM Article WHERE stockId = ?'
    const values = [stockId]
    const articleResult = await this.dataService.executeStatement(statement, values)
    if (articleResult.rows.length === 0) {
      return articles
    }
    for (let i = 0; i < articleResult.rows.length; i++) {
      const row = articleResult.rows.item(i)
      articles[i] = this.parseFromRow(row)
    }
    return articles
  }

  public async delete(id: number): Promise<void> {
    const statement = 'DELETE FROM Article WHERE id = ?'
    const values = [id]
    await this.dataService.executeStatement(statement, values)
  }

  public async update(article: Article): Promise<void> {
    const statement = 'UPDATE Article SET amount = ? WHERE stockId = ? AND articleId = ?'
    const values = [article.amount, article.stockId, article.articleId]
    await this.dataService.executeStatement(statement, values)
  }

  public async updateAll(articles: Article[]): Promise<void> {
    const updateArticlePromises: Promise<void>[] = articles.map(async (article: Article) => {
      await this.update(article)
    })
    await Promise.all(updateArticlePromises)
  }

  async createArticles(articles: Article[]): Promise<number[]> {
    const statement = 'INSERT INTO Article (stockId, articleId, name, amount) VALUES (?,?,?,?)'
    const articleIds: number[] = []
    for (const article of articles) {
      const values = [article.stockId, article.articleId, article.name, article.amount]
      const articleResult = await this.dataService.executeStatement(statement, values)
      articleIds.push(articleResult.insertId)
    }
    return articleIds
  }
}
