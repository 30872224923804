export interface FavouriteSystemTypesList {
  id: string
  primaryList: string[]
  secondaryList: string[]
}

export const favouritesSystemTypeList: FavouriteSystemTypesList[] = [
  {
    id: 'XSTAHLFR',
    primaryList: ['XSTAHLFR_A5', 'XSTAHLFR_A4', 'XSTAHLFR_A6', 'XSTAHLFR_A8', 'XSTAHLFR_A14'],
    secondaryList: [
      'XSTAHLFR_A8',
      'XSTAHLFR_A14',
      'XSTAHLFR_A15',
      'XSTAHLFR_A2',
      'XSTAHLFR_A7',
      'XSTAHLFR_A9',
      'XSTAHLFR_A1',
      'XSTAHLFR_A10',
      'XSTAHLFR_A11',
      'XSTAHLFR_A12',
      'XSTAHLFR_A13',
    ],
  },
  {
    id: 'XFRPLUS',
    primaryList: ['XFRPLUS_A7', 'XFRPLUS_A1', 'XFRPLUS_A2', 'XFRPLUS_A3', 'XFRPLUS_A4'],
    secondaryList: [
      'XFRPLUS_A8',
      'XFRPLUS_A12',
      'XFRPLUS_A9',
      'XFRPLUS_A11',
      'XFRPLUS_A5',
      'XFRPLUS_A6',
      'XFRPLUS_A10',
      'XFRPLUS_A13',
    ],
  },
  {
    id: 'XFR30',
    primaryList: ['XFR30_A1', 'XFR30_A2', 'XFR30_A3', 'XFR30_A4', 'XFR30_A7'],
    secondaryList: ['XFR30_A5', 'XFR30_A8', 'XFR30_A9'],
  },
  {
    id: 'XFRECO',
    primaryList: ['XFRECO_A2', 'XFRECO_A3', 'XFRECO_A4', 'XFRECO_A5', 'XFRECO_A1'],
    secondaryList: ['XFRECO_A8', 'XFRECO_A9'],
  },
  {
    id: 'XALUFR',
    primaryList: ['XALUFR_A17', 'XALUFR_A6', 'XALUFR_A16', 'XALUFR_A8', 'XALUFR_A14'],
    secondaryList: [
      'XALUFR_A15',
      'XALUFR_A2',
      'XALUFR_A4',
      'XALUFR_A7',
      'XALUFR_A9',
      'XALUFR_A1',
      'XALUFR_A10',
      'XALUFR_A11',
      'XALUFR_A12',
      'XALUFR_A13',
    ],
  },
  {
    id: 'XFRAMA_S',
    primaryList: ['XFRAMA_S_A3', 'XFRAMA_S_A4', 'XFRAMA_S_A9', 'XFRAMA_S_A2', 'XFRAMA_S_A6'],
    secondaryList: [
      'XFRAMA_S_A11',
      'XFRAMA_S_A8',
      'XFRAMA_S_A1',
      'XFRAMA_S_A5',
      'XFRAMA_S_A7',
      'XFRAMA_S_A10',
    ],
  },
  {
    id: 'XFRAMI',
    primaryList: ['XFRAMI_A6', 'XFRAMI_A12', 'XFRAMI_A1', 'XFRAMI_A3', 'XFRAMI_A7'],
    secondaryList: [
      'XFRAMI_A10',
      'XFRAMI_A5',
      'XFRAMI_A2',
      'XFRAMI_A9',
      'XFRAMI_A4',
      'XFRAMI_A8',
      'XFRAMI_A11',
    ],
  },
  {
    id: 'XFRAMI_S',
    primaryList: ['XFRAMI_S_A8', 'XFRAMI_S_A7', 'XFRAMI_S_A4', 'XFRAMI_S_A5', 'XFRAMI_S_A9'],
    secondaryList: ['XFRAMI_S_A10', 'XFRAMI_S_A2', 'XFRAMI_S_A3', 'XFRAMI_S_A6', 'XFRAMI_S_A1'],
  },
  {
    id: 'XLIGHT',
    primaryList: ['XLIGHT_A8', 'XLIGHT_A2', 'XLIGHT_A7', 'XLIGHT_A4', 'XLIGHT_A5'],
    secondaryList: ['XLIGHT_A3', 'XLIGHT_A9', 'XLIGHT_A1', 'XLIGHT_A6', 'XLIGHT_A10'],
  },
  {
    id: 'XREFORMX',
    primaryList: ['XREFORMX_A1', 'XREFORMX_A6', 'XREFORMX_A2', 'XREFORMX_A3', 'XREFORMX_A10'],
    secondaryList: ['XREFORMX_A7', 'XREFORMX_A4', 'XREFORMX_A5', 'XREFORMX_A12'],
  },
  {
    id: 'XREFORMA',
    primaryList: ['XREFORMA_A1', 'XREFORMA_A6', 'XREFORMA_A2', 'XREFORMA_A3', 'XREFORMA_A10'],
    secondaryList: ['XREFORMA_A7', 'XREFORMA_A4', 'XREFORMA_A5', 'XREFORMA_A11', 'XREFORMA_A12'],
  },
  {
    id: 'XFRAMINI',
    primaryList: ['XFRAMINI_A4', 'XFRAMINI_A1', 'XFRAMINI_A7', 'XFRAMINI_A5', 'XFRAMINI_A6'],
    secondaryList: [
      'XFRAMINI_A9',
      'XFRAMINI_A3',
      'XFRAMINI_A2',
      'XFRAMINI_A8',
      'XFRAMINI_A10',
      'XFRAMINI_A11',
      'XFRAMINI_A12',
    ],
  },
  {
    id: 'FLEX124',
    primaryList: ['FLEX124__A7', 'FLEX124__A8', 'FLEX124__A9', 'FLEX124__A12', 'FLEX124__A15'],
    secondaryList: [
      'FLEX124__A2',
      'FLEX124__A3',
      'FLEX124__A4',
      'FLEX124__A5',
      'FLEX124__A6',
      'FLEX124__A11',
      'FLEX124__A16',
      'FLEX124__A17',
    ],
  },
  {
    id: 'FLEXS',
    primaryList: ['FLEXS__A6', 'FLEXS__A13', 'FLEXS__A14', 'FLEXS__A8'],
    secondaryList: ['FLEXS__A12', 'FLEXS__A15', 'FLEXS__A16', 'FLEXS__A17'],
  },
]
