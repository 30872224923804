import { Injectable } from '@angular/core'
import { AlertButton, AlertController } from '@ionic/angular'
import { Translation } from './translation.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorPopupService {
  constructor(private readonly alert: AlertController, private readonly translate: Translation) {}

  async showError(message: string, onDismiss?: () => void, onRetry?: () => void): Promise<void> {
    let pressedRetry = false

    let buttons: AlertButton[] = [{ text: this.translate.translate('GENERAL.OK') }]

    if (onRetry) {
      buttons = [
        { text: this.translate.translate('GENERAL.CANCEL') },
        {
          text: this.translate.translate('GENERAL.RETRY'),
          handler: () => {
            pressedRetry = true
            onRetry()
          },
        },
      ]
    }

    const alert = await this.alert.create({
      cssClass: 'alertStyle',
      header: this.translate.translate('GENERAL.ERROR'),
      message,
      buttons,
      backdropDismiss: false,
    })

    await alert.present()
    await alert.onDidDismiss()

    if (onDismiss && !pressedRetry) {
      onDismiss()
    }
  }

  async showPlannerErrors(errors: string[]): Promise<void> {
    let message = ''
    if (errors.length === 1) {
      message = errors[0]
    } else {
      message = '<ul>'
      errors.forEach((error) => (message += '<li>' + error + '</li>'))
      message += '</ul'
    }

    const alert = await this.alert.create({
      cssClass: ['alertStyle'],
      header: this.translate.translate('GENERAL.ERROR'),
      message,
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.translate('GENERAL.OK'),
          role: 'cancel',
        },
      ],
    })

    return alert.present()
  }
}
