<ion-input
  #lengthInput
  *ngIf="valueInUnit !== undefined && !shouldResizeInput"
  [style.min-width.px]="minWidth"
  [ngModel]="valueInUnit"
  (ngModelChange)="onValueChanged($event)"
  [disabled]="disabled"
  efpInputOverwritingSelect
  type="{{ isImperial ? 'text' : 'number' }}"
  placeholder="0"
  inputmode="{{ isImperial ? 'text' : 'decimal' }}"
  [ngClass]="{
    'ion-text-right': textAlign === 'right',
    'ion-text-left': textAlign === 'left',
    'ion-text-center': textAlign === 'center'
  }"
  efpInputKeyboard>
</ion-input>

<ion-input
  #lengthInput
  *ngIf="valueInUnit !== undefined && shouldResizeInput"
  efpInputKeyboard
  [ngModel]="valueInUnit"
  (ngModelChange)="onValueChanged($event)"
  [disabled]="disabled"
  efpInputOverwritingSelect
  type="{{ isImperial ? 'text' : 'number' }}"
  placeholder="0"
  inputmode="{{ isImperial ? 'text' : 'decimal' }}"
  [style.width]="inputWidth"
  [ngClass]="{
    'ion-text-right': textAlign === 'right',
    'ion-text-left': textAlign === 'left',
    'ion-text-center': textAlign === 'center'
  }">
</ion-input>

<ion-label *ngIf="!isImperial && showUnit" color="primary" class="measureUnit">{{
  unit
}}</ion-label>
