import { Injectable } from '@angular/core'
import { AppSettingsModel, Country } from '@efp/api'
import { DataService } from '../../data.service'
import { AppSettingDao } from '../app-settings.dao'

export const defaultPlanSettingsIdNative = 0

@Injectable()
export class AppSettingSqlDao extends AppSettingDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  async updateLanguage(newLang: string): Promise<void> {
    await this.dataService.executeStatement('UPDATE AppSettings SET language=?', [newLang])
  }

  async updateCountry(newCountry: Country): Promise<void> {
    // We assume that the country is always lowercased on other parts of the app
    await this.dataService.executeStatement('UPDATE AppSettings SET country=?', [
      newCountry.toLowerCase(),
    ])
  }

  async findLanguage(): Promise<string> {
    const result = await this.dataService.executeStatement('SELECT language FROM AppSettings')
    return result.rows.item(0).language
  }

  async getAppSettings(): Promise<AppSettingsModel> {
    const result = await this.dataService.executeStatement('SELECT * from AppSettings')
    const row = result.rows.item(0)
    const appSettings: AppSettingsModel = {
      id: row.id,
      language: row.language,
      country: row.country,
      defaultPlanSettingsId: defaultPlanSettingsIdNative,
      drawVisibilitySettingsId: row.drawVisibilitySettingsId,
      cycleVisibilitySettingsId: row.cycleVisibilitySettingsId,
      accessoryVisibilitySettingsId: row.accessoryVisibilitySettingsId,
    }

    return appSettings
  }
}
