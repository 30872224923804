import { Observable, OperatorFunction } from 'rxjs'
import { NgZone } from '@angular/core'

export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => {
    return new Observable((observer) => {
      const onNext = (value: T): void => zone.run(() => observer.next(value))
      const onError = (e: Error): void => zone.run(() => observer.error(e))
      const onComplete = (): void => zone.run(() => observer.complete())
      return source.subscribe(onNext, onError, onComplete)
    })
  }
}
