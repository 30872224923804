import { Component, Input } from '@angular/core'
import { OnboardingHint } from '../../../../models/onboarding/onboarding-hint'

@Component({
  selector: 'efp-onboarding-hint-content',
  templateUrl: './onboarding-hint-content.component.html',
  styleUrls: ['./onboarding-hint-content.component.scss'],
})
export class OnboardingHintContentComponent {
  @Input() onboardingHint!: OnboardingHint

  public isVideo(): boolean {
    return this.onboardingHint.videoUrl?.endsWith('.mp4') ?? false
  }
}
