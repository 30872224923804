import paper from 'paper/dist/paper-core'
import { Edge, SnapEdgeInfo } from '../../model'
import { SNAPPING_DISTANCE } from './snappingConstants'

const closestPointOnEdge = (p: paper.Point, edge: Edge): paper.Point | undefined => {
  if (p === edge.startPoint || p === edge.endPoint) {
    return undefined
  }

  const a = edge.startPoint
  const b = edge.endPoint

  const atob = { x: b.x - a.x, y: b.y - a.y }
  const atop = { x: p.x - a.x, y: p.y - a.y }
  const len = atob.x * atob.x + atob.y * atob.y
  const dot = atop.x * atob.x + atop.y * atob.y
  const t = Math.min(1, Math.max(0, dot / len))
  return new paper.Point(a.x + atob.x * t, a.y + atob.y * t)
}

export const snapToEdge = (
  point: paper.Point,
  allEdges: Iterable<Edge>,
  ignoreDistance: boolean
): SnapEdgeInfo | undefined => {
  let bestEdge: Edge | undefined
  let bestPoint: paper.Point | undefined
  let bestDistance = Infinity

  for (const edge of Array.from(allEdges)) {
    const closestPoint = closestPointOnEdge(point, edge)

    if (closestPoint === undefined) {
      continue
    }

    // remove half thickness to measure distance to wall outline instead of the middle line
    const distance = closestPoint.getDistance(point) - edge.thickness / 2
    if (distance < bestDistance) {
      bestEdge = edge
      bestPoint = closestPoint
      bestDistance = distance
    }
  }

  if (bestEdge !== undefined && bestPoint !== undefined) {
    if (ignoreDistance || bestDistance <= SNAPPING_DISTANCE) {
      return { point: bestPoint, edge: bestEdge }
    }
  }

  return undefined
}
