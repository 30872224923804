import { inject, Injectable } from '@angular/core'
import { ArticlesApiClient } from '@efp/api'
import { Article } from '../../../models/article'
import { ArticleDao } from '../article.dao'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class ArticleHttpDao extends ArticleDao {
  private readonly articleApiClient = inject(ArticlesApiClient)

  async findAllByStockId(stockId: number): Promise<Article[]> {
    return firstValueFrom(
      this.articleApiClient.getAllArticles({
        stockId,
      })
    )
  }

  async delete(id: number): Promise<void> {
    await firstValueFrom(
      this.articleApiClient.deleteArticle({
        id,
      })
    )
  }

  async update(article: Article): Promise<void> {
    await firstValueFrom(
      this.articleApiClient.updateArticle({
        id: article.id,
        body: article,
      })
    )
  }

  async updateAll(articles: Article[]): Promise<void> {
    await firstValueFrom(
      this.articleApiClient.patchAllArticles({
        body: articles.map((article) => {
          return {
            amount: article.amount,
            articleId: article.articleId,
            id: article.id,
            name: article.name,
            stockId: article.stockId,
          }
        }),
      })
    )
  }

  async createArticles(articles: Article[]): Promise<number[]> {
    return await firstValueFrom(
      this.articleApiClient.createArticles({
        body: articles,
      })
    )
  }
}
