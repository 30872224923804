import { PlanType } from 'formwork-planner-lib'
import {
  MAX_SLAB_THICKNESS,
  MAX_SLAB_THICKNESS_IMPERIAL,
  MAX_WALL_THICKNESS,
  MAX_WALL_THICKNESS_IMPERIAL,
  MIN_SLAB_THICKNESS,
  MIN_SLAB_THICKNESS_IMPERIAL,
  MIN_WALL_THICKNESS,
  MIN_WALL_THICKNESS_IMPERIAL,
  SLAB_LENGTH_MAX,
  SLAB_LENGTH_MIN,
  WALL_LENGTH_MAX,
  WALL_LENGTH_MIN,
} from '../../model/snapping/constants'

export const LIMITS: Record<
  PlanType,
  Record<'IMPERIAL' | 'METRIC', Record<'LENGTH' | 'THICKNESS', Record<'MIN' | 'MAX', number>>>
> = {
  [PlanType.WALL]: {
    ['IMPERIAL']: {
      ['LENGTH']: {
        ['MIN']: WALL_LENGTH_MIN,
        ['MAX']: WALL_LENGTH_MAX,
      },
      ['THICKNESS']: {
        ['MIN']: MIN_WALL_THICKNESS_IMPERIAL,
        ['MAX']: MAX_WALL_THICKNESS_IMPERIAL,
      },
    },
    ['METRIC']: {
      ['LENGTH']: {
        ['MIN']: WALL_LENGTH_MIN,
        ['MAX']: WALL_LENGTH_MAX,
      },
      ['THICKNESS']: {
        ['MIN']: MIN_WALL_THICKNESS,
        ['MAX']: MAX_WALL_THICKNESS,
      },
    },
  },
  [PlanType.SLAB]: {
    ['IMPERIAL']: {
      ['LENGTH']: {
        ['MIN']: SLAB_LENGTH_MIN,
        ['MAX']: SLAB_LENGTH_MAX,
      },
      ['THICKNESS']: {
        ['MIN']: MIN_SLAB_THICKNESS_IMPERIAL,
        ['MAX']: MAX_SLAB_THICKNESS_IMPERIAL,
      },
    },
    ['METRIC']: {
      ['LENGTH']: {
        ['MIN']: SLAB_LENGTH_MIN,
        ['MAX']: SLAB_LENGTH_MAX,
      },
      ['THICKNESS']: {
        ['MIN']: MIN_SLAB_THICKNESS,
        ['MAX']: MAX_SLAB_THICKNESS,
      },
    },
  },
}
