import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { Browser } from '@capacitor/browser'
import { PrimaryMenuService } from '../../../services/primary-menu.service'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { Translation } from '../../../services/translation.service'
import { CONTACT_EMAIL } from '../../../constants/emails'
import { FeedbackComponent } from '../feedback/feedback.component'

@Component({
  selector: 'efp-primary-menu',
  templateUrl: './primary-menu.component.html',
  styleUrls: ['./primary-menu.component.scss'],
})
export class PrimaryMenuComponent {
  constructor(
    public readonly translate: Translation,
    private readonly router: Router,
    private readonly primaryMenuService: PrimaryMenuService,
    private readonly socialSharing: SocialSharing,
    private readonly alertCtrl: AlertController,
    private readonly modalCtrl: ModalController
  ) {}

  @Input() collapsable?: boolean

  public async navTo(route: string[]): Promise<void> {
    await this.router.navigate(route)
  }

  public openUserInformation(): void {
    void Browser.open({
      url: 'https://www.doka.com/at/solutions/overview/anwenderinformation-downloads-schalung',
    })
    this.closeMenu()
  }

  closeMenu(): void {
    this.primaryMenuService.close()
  }

  async startFeedback(): Promise<void> {
    this.closeMenu()

    const modal = await this.modalCtrl.create({
      component: FeedbackComponent,
      backdropDismiss: false,
      cssClass: 'onboarding-modal',
    })
    await modal.present()
    await modal.onDidDismiss()
  }

  public contact(): void {
    const title = this.translate.translate('CONTACT.MAIL_TITLE')

    this.socialSharing
      .canShareViaEmail()
      .then(async () => this.socialSharing.shareViaEmail('', title, [CONTACT_EMAIL]))
      .catch(() => {
        void this.alertCtrl
          .create({
            header: this.translate.translate('CONTACT.ALERT_TITLE'),
            message: this.translate.translate('CONTACT.ALERT_TEXT', {
              email: CONTACT_EMAIL,
            }),
            buttons: [
              {
                text: this.translate.translate('GENERAL.OK'),
                role: 'cancel',
              },
            ],
          })
          .then(async (alert) => alert.present())
      })

    this.closeMenu()
  }
}
