import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'efp-web-mobile-button-bar',
  templateUrl: 'efp-web-mobile-button-bar.component.html',
  styleUrls: ['efp-web-mobile-button-bar.component.scss'],
})
export class EfpWebMobileButtonBarComponent {
  @Input() applyChangesDisabled = false

  @Output() readonly canceled = new EventEmitter<void>()
  @Output() readonly applied = new EventEmitter<void>()

  public cancel(): void {
    this.canceled.next()
  }

  public applyChanges(): void {
    this.applied.next()
  }
}
