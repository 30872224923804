import paper from 'paper/dist/paper-core'
import { createSlabPath } from '../../utils/mesh/createSlabPath'
import { detectOverlappingEdges } from '../../utils/mesh/geometryValidation'
import { intersectLines, SNAPPING_ERROR_TOLERANCE } from '../../utils'
import { Edge } from '../edge/Edge'
import { Mesh } from './Mesh'
import { MeshPoint } from './MeshPoint'

export class SlabMesh extends Mesh {
  public constructor(private readonly paperScope: paper.PaperScope) {
    super()
  }

  public getLooseEnds(mesh: Mesh): MeshPoint[] {
    const endPoints: MeshPoint[] = []
    mesh.points.forEach((point) => {
      if (point.edges.size === 1) {
        endPoints.push(point)
      }
    })
    return endPoints
  }

  generatePath(): paper.CompoundPath {
    return createSlabPath(this)
  }

  public removeEdge(edge: Edge, _: boolean): boolean {
    const pointsToDelete: MeshPoint[] = []
    let deletedAnEdge = false

    this.points.forEach((point) => {
      if (point.edges.has(edge)) {
        point.edges.delete(edge)
        deletedAnEdge = true

        if (point.edges.size === 0) {
          pointsToDelete.push(point)
        }
      }
    })

    pointsToDelete.forEach((point) => {
      this.points.delete(point)
    })

    return deletedAnEdge
  }

  public updateEdgeValidity(): number {
    const edges = [...this.getAllEdges()]
    edges.forEach((edge) => (edge.isValid = true))

    if (edges.length < 2) {
      return 0
    }

    const overlappingEdges = detectOverlappingEdges(edges)
    const invalidEdges: Set<Edge> = new Set<Edge>(overlappingEdges)

    edges.forEach((edge) => {
      edges.forEach((otherEdge) => {
        if (edge === otherEdge) {
          return
        }

        if (edge.getConnectedPoint(otherEdge)) {
          const angle = <number>edge.getAngle(otherEdge)
          const angleResidue = Math.abs(angle % 90)
          if (
            !(
              angleResidue < SNAPPING_ERROR_TOLERANCE ||
              angleResidue > 90 - SNAPPING_ERROR_TOLERANCE
            )
          ) {
            invalidEdges.add(edge)
            invalidEdges.add(otherEdge)
          }
        } else if (
          intersectLines(
            { start: edge.startPoint, end: edge.endPoint },
            { start: otherEdge.startPoint, end: otherEdge.endPoint },
            true,
            true
          )
        ) {
          invalidEdges.add(edge)
          invalidEdges.add(otherEdge)
        }
      })
    })

    this.points.forEach((point) => {
      if (point.hasSmallAngle()) {
        point.edges.forEach((edge) => invalidEdges.add(edge))
      }
    })

    invalidEdges.forEach((edge) => {
      edge.isValid = false
    })

    return invalidEdges.size
  }
}
