import { ROOT_XML_ELEMENT, xmlParser } from '../constants/tiposXml'

export interface XMLAttribute {
  name: string
  value: string
}

export class XmlWriter {
  protected readonly document: Document

  constructor(initialXml: string) {
    this.document = xmlParser.parseFromString(initialXml, 'application/xml')
    if (
      !this.document.documentElement ||
      this.document.documentElement.tagName !== ROOT_XML_ELEMENT
    ) {
      throw new Error('Initial XML is empty or has invalid root element')
    }
  }

  getXml(): string {
    return this.document.documentElement.outerHTML
  }

  public ensureTagsExist(tags: string[]): void {
    const firstElement = this.document.querySelector(tags[0])
    if (!firstElement || !!firstElement.parentElement) {
      throw new Error('XML has wrong root element, cannot insert tags')
    }

    let previousElement = firstElement
    for (let i = 1; i < tags.length; i++) {
      const currentTag = tags[i]
      const element = previousElement.querySelector(currentTag)
      if (!element) {
        const createdElement = this.document.createElement(currentTag)
        previousElement.appendChild(createdElement)
        previousElement = createdElement
      } else {
        previousElement = element
      }
    }
  }

  public setTagChild(selector: string, child: HTMLElement): void {
    const element = this.getElementBySelector(selector)
    element.appendChild(child)
  }

  public setTagContent(selector: string, content: string): void {
    const element = this.getElementBySelector(selector)
    element.textContent = content
  }

  public createElementWithAttributes(tag: string, attributes: XMLAttribute[]): HTMLElement {
    const element = this.document.createElement(tag)

    for (const attribute of attributes) {
      element.setAttribute(attribute.name, attribute.value)
    }

    return element
  }

  public createElementWithChilds(tag: string, childs: HTMLElement[]): HTMLElement {
    const element = this.document.createElement(tag)

    childs.forEach((child) => {
      element.appendChild(child)
    })

    return element
  }

  // used to convert json into xml

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static OBJtoXML(obj: any): string {
    // simple & working from: https://stackoverflow.com/questions/48788722/json-to-xml-using-javascript
    let xml = ''
    for (const prop of Object.keys(obj)) {
      xml += obj[prop] instanceof Array ? '' : '<' + prop + '>'
      if (obj[prop] instanceof Array) {
        for (const array of Object.keys(obj[prop])) {
          xml += '<' + prop + '>'
          xml += this.OBJtoXML(new Object(obj[prop][array]))
          xml += '</' + prop + '>'
        }
      } else if (typeof obj[prop] === 'object') {
        xml += this.OBJtoXML(new Object(obj[prop]))
      } else {
        xml += obj[prop]
      }
      xml += obj[prop] instanceof Array ? '' : '</' + prop + '>'
    }
    xml = xml.replace(/<\/?[0-9]{1,}>/g, '')

    return xml
  }
  public static XMLtoOBJ(xml: string): unknown {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xml, 'application/xml')
    const firstObj: { [key: string]: unknown } = {}
    const firstElement = xmlDoc.firstElementChild

    if (firstElement && firstElement.children.length > 0) {
      const childObjectArray: { [key: string]: unknown }[] = []
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < firstElement.children.length; i++) {
        const childObj: { [key: string]: unknown } = {}
        const child = firstElement.children[i]
        const key = child.nodeName

        childObj[key] = this.XMLtoOBJ(child.outerHTML)
        childObjectArray.push(childObj)
      }
      firstObj[firstElement.nodeName] = childObjectArray
    } else if (firstElement) {
      firstObj[firstElement.nodeName] = firstElement.nodeValue
    }

    return firstObj
  }

  protected getElementBySelector(selector: string): Element {
    const pathContainer = this.document.querySelector(selector)
    if (!pathContainer) {
      throw new Error(`Could not find element with selector '${selector}'.`)
    }

    return pathContainer
  }
}
