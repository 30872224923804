import { CycleBoundary, CycleSymbol } from 'formwork-planner-lib'
import { CycleBoundaryCreateCommandParam, CycleSymbolModel } from '../../../generated/efp-api'

export const mapCycleBoundaryToCycleCreateModel = (
  boundary: Omit<CycleBoundary, 'id'>,
  planId: number
): CycleBoundaryCreateCommandParam => {
  return {
    startx: boundary.start.x,
    starty: boundary.start.y,
    endx: boundary.end.x,
    endy: boundary.end.y,
    cycleNumberLeft: boundary.cycleNumberLeft,
    cycleNumberRight: boundary.cycleNumberRight,
    planId,
  }
}

export abstract class CycleDao {
  abstract createCycleBoundary(line: CycleBoundaryCreateCommandParam): Promise<number>

  abstract createCycleSymbol(symbol: CycleSymbolModel): Promise<void>

  abstract updateCycleNumbers(lines: CycleBoundary[]): Promise<void>

  abstract updateCycleBoundaryPosition(boundary: CycleBoundary): Promise<void>

  abstract findAllCycleBoundariesByPlanId(planId: number): Promise<CycleBoundary[]>

  abstract findAllCycleSymbolsByPlanId(planId: number): Promise<CycleSymbol[]>

  public abstract removeAllCycleBoundariesByPlanId(planId: number): Promise<void>

  public abstract removeAllSymbolsByPlanId(planId: number): Promise<void>

  public abstract deleteCycleBoundary(id: number): Promise<void>
}
