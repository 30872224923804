import { Injectable } from '@angular/core'
import { UserDao } from '../services/dao/user.dao'

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  constructor(private readonly userDao: UserDao) {}

  public async registerUser(): Promise<number> {
    return this.userDao.registerUser()
  }
}
