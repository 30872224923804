import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IonInput } from '@ionic/angular'
import { FocusAble } from '../length-input/length-input.component'

@Component({
  selector: 'efp-angle-input',
  templateUrl: 'angle-input.component.html',
  styleUrls: ['angle-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AngleInputComponent),
      multi: true,
    },
  ],
})
export class AngleInputComponent implements ControlValueAccessor, OnChanges, FocusAble {
  @ViewChild('angleInput', { static: false }) angleInput?: IonInput
  @Input() disabled = false
  @Input() showUnit = true
  @Input() minWidth = 80
  @Input() textAlign: 'left' | 'right' | 'center' = 'right'
  @Input() shouldResizeInput = false

  valueInDegree?: number
  valueInUnit?: number | string

  inputWidth = 'auto'

  private onChange: (val: number | undefined) => void = () => {}
  private onBlur: (val: number | undefined) => void = () => {}

  public async focus(): Promise<void> {
    setTimeout(() => {
      void this.angleInput?.setFocus()
      void this.angleInput?.getInputElement().then((elem) => elem.select())
    }, 100)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onBlur = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(valueInDegree?: number): void {
    if (valueInDegree === undefined) {
      this.valueInUnit = valueInDegree
    } else if (Number.isNaN(Number(valueInDegree))) {
      this.valueInDegree = undefined
      this.valueInUnit = undefined
    } else {
      this.valueInDegree = Number(valueInDegree)
      // JS Decimal Math problem, workaround for this: 280 * 0.01 = 2.8000000000000003
      const decimalValueParts = valueInDegree.toString().split('.')
      if (decimalValueParts.length > 1 && decimalValueParts[1].length > 0) {
        this.valueInUnit = this.valueInDegree.toFixed(2)
      } else {
        this.valueInUnit = this.valueInDegree
      }
    }

    this.resizeInput()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.unit && this.valueInUnit) {
      this.writeValue(this.valueInDegree)
    }
  }

  onValueChanged(value: number): void {
    this.valueInUnit = value
    this.valueInDegree = value
    this.onChange(this.valueInDegree)
    this.onBlur(this.valueInDegree)

    this.resizeInput()
  }

  resizeInput(): void {
    this.inputWidth = this.shouldResizeInput
      ? `${Math.max(this.minWidth, (this.valueInUnit?.toString().length ?? 0) * 10)}px`
      : 'auto'
  }
}
