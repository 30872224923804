import { AccessoryPart } from '../pages/accessory/model/AccessoryPart'

const accessoryParts: AccessoryPart[] = [
  new AccessoryPart('ZBELSTZFRA'),
  new AccessoryPart('ZBELSTZ-FO'),
  new AccessoryPart('ZBELSFRA-S'),
  new AccessoryPart('ZBELSTZ-FF'),
  new AccessoryPart('ZBELFF100'),
  new AccessoryPart('ZBJUSTSPIN'),
  new AccessoryPart('ZBJUSTSP-S'),
  new AccessoryPart('ZBBETBUFRA'),
  new AccessoryPart('ZBBETBU-FF'),
  new AccessoryPart('ZBKONSOL90'),
  new AccessoryPart('ZBFRAMECO'),
  new AccessoryPart('ZBKON90-AL'),
  new AccessoryPart('ZBKON90-FF'),
  new AccessoryPart('ZBKONL-FF'),
  new AccessoryPart('ZBKONFF100'),
  new AccessoryPart('ZBKONSOL60'),
  new AccessoryPart('ZBKONS60-S'),
  new AccessoryPart('ZBSTANDKON'),
  new AccessoryPart('ZBGEGENXPFR'),
  new AccessoryPart('ZBGEGENXPFI'),
  new AccessoryPart('ZBGEGENXPT'),
  new AccessoryPart('ZBGEGENSGFR'),
  new AccessoryPart('ZBBETBOFRA'),
  new AccessoryPart('ZBBETBO-FF'),
  new AccessoryPart('ZBRA-ABSTW'),
  new AccessoryPart('ZBRA-ABSTWI'),
  new AccessoryPart('ZBELSTZREF'),
  new AccessoryPart('ZBKONREFOR'),
  new AccessoryPart('ZBJUSPIXLI'),
  new AccessoryPart('ZBKON60XLI'),
  new AccessoryPart('ZBRA-ABSTWX'),
  new AccessoryPart('ZBELSTZFINI'),
  new AccessoryPart('ZBKONFINI'),
  new AccessoryPart('ZBELSTZREX'),
]

export function getAccessoryById(id: string): AccessoryPart {
  return accessoryParts.find((elem) => elem.id === id) as AccessoryPart
}
