import {
  CycleBoundaryDrawable,
  Edge,
  Line,
  MeshPoint,
  SNAPPING_ERROR_TOLERANCE,
  TransformationThicknessDirection,
  UnitOfLength,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { ARROW_COLOR, PRIMARY_COLOR } from '../../../constants/colors'
import { AngleLabel } from '../model/paper/AngleLabel'
import { InputLabel } from '../model/paper/InputLabel'
import { LengthLabel } from '../model/paper/LengthLabel'
import { WidthLabel } from '../model/paper/WidthLabel'
import { CENTER_POINT_LENGTH } from '../model/snapping/constants'
import { AngleInfo } from '../types/AngleInfo'
import { calculateAngleArcPoints } from '../util/paper/calculateAngleArcPoints'

export class LabelRenderService {
  public selectedAngle: AngleInfo | undefined
  public selectionPoint: paper.Point | undefined
  public hideLabelPosition: paper.Point | undefined
  private angleLayer!: paper.Layer
  private lengthLayer!: paper.Layer
  private previewArrowLayer!: paper.Layer

  private readonly view: paper.View
  private arrowSymbol!: paper.SymbolDefinition

  private arrowLeftSymbol?: paper.SymbolDefinition
  private arrowRightSymbol?: paper.SymbolDefinition
  private arrowTopSymbol?: paper.SymbolDefinition
  private arrowBottomSymbol?: paper.SymbolDefinition

  public constructor(private readonly paperScope: paper.PaperScope) {
    this.view = paperScope.view
    this.reset()

    paperScope.project.importSVG('/assets/icon/direction_change_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = ARROW_COLOR
        symbolItem.rotate(180)
        this.arrowLeftSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/direction_change_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = ARROW_COLOR
        this.arrowRightSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/arrow-top.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.scale(1, 1.5)
        symbolItem.fillColor = ARROW_COLOR
        this.arrowTopSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/arrow-bottom.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.scale(1, 1.5)
        symbolItem.fillColor = ARROW_COLOR
        this.arrowBottomSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })

    paperScope.project.importSVG('/assets/icon/t_wall_arrow.svg', {
      onLoad: (symbolItem: paper.Item) => {
        symbolItem.fillColor = PRIMARY_COLOR
        // The icon is stretched horizontally otherwise
        symbolItem.scale(0.75, 1)
        this.arrowSymbol = new paper.SymbolDefinition(symbolItem)
      },
    })
  }

  public reset(): void {
    this.lengthLayer = this.resetLayer('Length Layer', this.lengthLayer)
    this.angleLayer = this.resetLayer('Angle Layer', this.angleLayer)
    this.previewArrowLayer = this.resetLayer('Preview Arrow Layer', this.previewArrowLayer)
  }

  public unhoverAll(): void {
    this.lengthLayer.children
      .filter((item) => item instanceof InputLabel)
      .forEach((item) => (item as InputLabel).unhover())
    this.angleLayer.children
      .filter((item) => item instanceof InputLabel)
      .forEach((item) => (item as InputLabel).unhover())
  }

  public set anglesVisible(visible: boolean) {
    this.angleLayer.visible = visible
  }

  public get anglesVisible(): boolean {
    return this.angleLayer.visible
  }

  public set lengthsVisible(visible: boolean) {
    this.lengthLayer.visible = visible
  }

  public get lengthsVisible(): boolean {
    return this.lengthLayer.visible
  }

  public generateAngleLabelsForPath(path: paper.Item, isSlab: boolean): void {
    if (path.children !== undefined) {
      path.children.forEach((pathChild) => {
        this.generateAngleLabelsForPath(pathChild, isSlab)
      })
    }

    if (!(path instanceof paper.Path) || !path.segments) {
      return
    }

    const lineSegments = path.segments.map((segment, i, segments) => {
      const nextIndex = segments[i + 1] ? i + 1 : 0
      const end = segments[nextIndex].point
      const start = segment.point
      const direction = end.subtract(start)

      return { start, end, direction }
    })

    lineSegments.forEach((l1, i, segments) => {
      const nextIndex = segments[i + 1] ? i + 1 : 0
      const l2 = segments[nextIndex]

      if ((nextIndex === 0 || nextIndex === lineSegments.length - 1) && !path.closed) {
        return
      }

      const angle = l2.direction.getDirectedAngle(l1.direction)
      if (!isSlab && Math.round(angle) <= 0) {
        return
      }

      if (isSlab) {
        if (Math.round(angle) % 180 !== 0) {
          this.drawAngleLabel(l1, l2, 180 - Math.abs(angle))
        }
      } else {
        if (Math.round(180 - angle) !== 0) {
          this.drawAngleLabel(l1, l2, 180 - angle)
        }
      }
    })
  }

  public generateLengthLabelsForPath(
    path: paper.Item,
    displayMergedLenghts: boolean,
    unit: UnitOfLength,
    cycleBoundaries?: CycleBoundaryDrawable[],
    selectedTWall?: Edge
  ): void {
    if (path.children !== undefined) {
      path.children.forEach((pathChild) => {
        this.generateLengthLabelsForPath(
          pathChild,
          displayMergedLenghts,
          unit,
          cycleBoundaries,
          selectedTWall
        )
      })
    }
    if (!(path instanceof paper.Path) || !path.segments) {
      return
    }

    const mergedSegments: paper.Segment[] = []
    const cycleSegments: paper.Segment[] = []
    // eslint-disable-next-line complexity
    path.segments.forEach((segment) => {
      if (segment.curve) {
        if (
          !cycleBoundaries ||
          cycleBoundaries.length === 0 ||
          !this.hasCollinearSegmentWithBoundary(segment, cycleBoundaries)
        ) {
          this.drawLengthLabelForSegment(segment, unit, selectedTWall)
        } else if (
          !cycleSegments.includes(segment) &&
          !(
            this.hasCollinearSegmentWithBoundary(segment.previous, cycleBoundaries) &&
            segment.previous.curve.isCollinear(segment.curve)
          )
        ) {
          let cycleStart = segment.curve.point1
          let cycleEnd = segment.curve.point2
          let cycleCurrent: paper.Segment = segment
          let isNextSegmentCollinear = true
          do {
            const boundaryPoints: paper.Point[] = []
            cycleBoundaries.forEach((boundary) => {
              const boundaryPoint = this.getSegmentPointOfBoundary(cycleCurrent, boundary)
              if (boundaryPoint) {
                boundaryPoints.push(boundaryPoint)
              }
            })

            if (boundaryPoints.length > 0) {
              boundaryPoints
                .sort((p1, p2) => p1.getDistance(cycleStart) - p2.getDistance(cycleStart))
                .forEach((point) => {
                  cycleEnd = point
                  if (cycleStart.getDistance(cycleEnd) > 0) {
                    this.drawLengthLabel(
                      cycleStart,
                      cycleEnd,
                      false,
                      this.calculateStrokeWidth(),
                      unit
                    )
                  }
                  cycleStart = point
                })
            }

            isNextSegmentCollinear = cycleCurrent.curve.isCollinear(cycleCurrent.next.curve)
            if (isNextSegmentCollinear) {
              cycleEnd = cycleCurrent.curve.next.point2
              cycleSegments.push(cycleCurrent)
            } else {
              cycleEnd = cycleCurrent.curve.point2
            }
            cycleCurrent = cycleCurrent.next
          } while (
            cycleCurrent.next &&
            !cycleSegments.includes(cycleCurrent.next) &&
            cycleCurrent.next.curve &&
            isNextSegmentCollinear
          )

          if (cycleStart.getDistance(cycleEnd) > 0) {
            this.drawLengthLabel(cycleStart, cycleEnd, false, this.calculateStrokeWidth(), unit)
          }
        }

        if (displayMergedLenghts && !mergedSegments.includes(segment)) {
          const start = segment.curve.point1
          let end = segment.curve.point2
          let current = segment
          while (
            current.next &&
            !mergedSegments.includes(current.next) &&
            current.next.curve &&
            current.curve.isCollinear(current.next.curve)
          ) {
            current = current.next
            mergedSegments.push(current)
            end = current.curve.point2
          }
          if (end !== segment.curve.point2) {
            const offset = end.subtract(start).normalize(10).rotate(-90)
            this.drawLengthLabel(
              start.add(offset),
              end.add(offset),
              true,
              this.calculateStrokeWidth(),
              unit
            )
          }
        }
      }
    })
  }

  public generateWidthLabelsForConnectedWalls(
    connectingPoints: MeshPoint[],
    unit: UnitOfLength
  ): void {
    connectingPoints.forEach((point) => {
      point.edges.forEach((edge) => {
        const angleRadius = this.calculateAngleRadius()
        const labelDistance = angleRadius + edge.thickness / 2
        const direction = edge.getDirection(edge.getPosition(point)).normalize(labelDistance)
        const middlePoint = point.add(direction)
        const thicknessVector = direction.rotate(90).normalize(edge.thickness / 2)
        const start = middlePoint.add(thicknessVector)
        const end = middlePoint.subtract(thicknessVector)
        const label = new WidthLabel(
          start,
          end,
          this.calculateFontSize(),
          this.calculateStrokeWidth(),
          false,
          unit
        )

        if (
          label.textHeight <= edge.thickness * 0.8 &&
          labelDistance + label.textLength < (edge.length() - CENTER_POINT_LENGTH) / 2
        ) {
          this.lengthLayer.addChild(label)
        }
      })
    })
  }

  public findLengthLabelNearPoint(point: paper.Point, tolerance: number): LengthLabel | undefined {
    let closestLabel: LengthLabel | undefined = undefined
    let bestDistance = Infinity
    let labelLength = 0
    this.lengthLayer.children.forEach((label) => {
      if (
        label instanceof LengthLabel &&
        (label.centerPoint.getDistance(point) < bestDistance ||
          (label.centerPoint.getDistance(point) === bestDistance &&
            label.length.valueInUnit > labelLength)) &&
        label.centerPoint.getDistance(point) < tolerance
      ) {
        closestLabel = label
        bestDistance = label.centerPoint.getDistance(point)
        labelLength = label.length.valueInUnit
      }
    })

    return closestLabel
  }

  private getSegmentPointOfBoundary(
    segment: paper.Segment,
    cycleBoundary: CycleBoundaryDrawable
  ): paper.Point | undefined {
    const startLocation = segment.curve.getLocationOf(cycleBoundary.start)
    const endLocation = segment.curve.getLocationOf(cycleBoundary.end)
    if (startLocation) {
      return startLocation.point
    }

    if (endLocation) {
      return endLocation.point
    }

    return undefined
  }

  private hasCollinearSegmentWithBoundary(
    segment: paper.Segment,
    cycleBoundaries: CycleBoundaryDrawable[],
    checkedSegments: paper.Segment[] = []
  ): boolean {
    const hasOwnBoundary = cycleBoundaries.some((boundary) =>
      this.getSegmentPointOfBoundary(segment, boundary)
    )
    if (hasOwnBoundary) {
      return hasOwnBoundary
    } else if (
      segment.previous?.curve &&
      segment.curve.isCollinear(segment.previous.curve) &&
      !checkedSegments.includes(segment.previous)
    ) {
      checkedSegments.push(segment)
      return this.hasCollinearSegmentWithBoundary(
        segment.previous,
        cycleBoundaries,
        checkedSegments
      )
    } else if (
      segment.next?.curve &&
      segment.curve.isCollinear(segment.next.curve) &&
      !checkedSegments.includes(segment.next)
    ) {
      checkedSegments.push(segment)
      return this.hasCollinearSegmentWithBoundary(segment.next, cycleBoundaries, checkedSegments)
    }

    return hasOwnBoundary
  }

  private drawLengthLabelForSegment(
    segment: paper.Segment,
    unit: UnitOfLength,
    selectedTWall?: Edge
  ): void {
    const path = new paper.Path()
    path.moveTo(segment.curve.point1)
    path.lineTo(segment.curve.point2)

    const uniqueSegments = new Set(path.segments.filter((pathSegment) => pathSegment.curve))

    uniqueSegments.forEach((uniqueSegment) => {
      const curve = uniqueSegment.curve
      if (selectedTWall) {
        const neighbours = selectedTWall.getNeighbours()
        const tNeighbours = neighbours.filter((edge) =>
          neighbours.filter((e) => e !== edge).some((other) => edge.canMerge(other))
        )

        const tolerance = Math.max(selectedTWall.thickness, ...tNeighbours.map((e) => e.thickness))

        const highlightedSegments = tNeighbours
          .map((e) =>
            e
              .getOutlinePath()
              .segments.filter(
                (s) =>
                  s.curve.length > tolerance + SNAPPING_ERROR_TOLERANCE &&
                  ((curve.point1.isClose(s.curve.point1, tolerance) &&
                    curve.point2.isClose(s.curve.point2, tolerance)) ||
                    (curve.point2.isClose(s.curve.point1, tolerance) &&
                      curve.point1.isClose(s.curve.point2, tolerance)))
              )
          )
          .flat()
        if (highlightedSegments.length !== 0) {
          this.drawLengthLabel(
            curve.point1,
            curve.point2,
            false,
            this.calculateStrokeWidth(),
            unit,
            true
          )
        } else {
          this.drawLengthLabel(curve.point1, curve.point2, false, this.calculateStrokeWidth(), unit)
        }
      } else {
        this.drawLengthLabel(curve.point1, curve.point2, false, this.calculateStrokeWidth(), unit)
      }
    })
  }

  private drawLengthLabel(
    from: paper.Point,
    to: paper.Point,
    isCombinedEdgeLength: boolean,
    strokeWidth: number,
    unit: UnitOfLength,
    bold = false
  ): void {
    const lengthLabel = new LengthLabel(
      from,
      to,
      this.calculateFontSize(),
      strokeWidth,
      isCombinedEdgeLength,
      bold,
      unit
    )
    const distance = this.hideLabelPosition?.getDistance(lengthLabel.position)
    if (distance !== undefined && distance < 1) {
      // Using distance instead of equal points because of small differences in floating point numbers
      lengthLabel.hide()
    }
    this.lengthLayer.addChild(lengthLabel)
    if (this.arrowSymbol && bold) {
      this.drawDimensionArrows(lengthLabel)
    }
  }

  private drawDimensionArrows(lengthLabel: LengthLabel): void {
    const placedSymbolRight = this.arrowSymbol.place(lengthLabel.arrowPositions[0])
    placedSymbolRight.rotate(lengthLabel.textRotation + 180, placedSymbolRight.bounds.center)

    const placedSymbolLeft = this.arrowSymbol.place(lengthLabel.arrowPositions[1])
    placedSymbolLeft.rotate(lengthLabel.textRotation, placedSymbolLeft.bounds.center)

    this.lengthLayer.addChild(placedSymbolLeft)
    this.lengthLayer.addChild(placedSymbolRight)
  }

  public drawPreviewRightArrowLabel(arrowPoint: paper.Point, edgeAngle: number): void {
    if (this.arrowRightSymbol) {
      const placedSymbol = this.arrowRightSymbol.place(arrowPoint)
      placedSymbol.rotate(edgeAngle)
      this.previewArrowLayer.addChild(placedSymbol)
    }
  }

  public drawPreviewLeftArrowLabel(arrowPoint: paper.Point, edgeAngle: number): void {
    if (this.arrowLeftSymbol) {
      const placedSymbol = this.arrowLeftSymbol.place(arrowPoint)
      placedSymbol.rotate(edgeAngle)
      this.previewArrowLayer.addChild(placedSymbol)
    }
  }

  public drawPreviewThicknessArrowLabels(
    arrowPoint: paper.Point,
    thickness: number,
    direction: TransformationThicknessDirection,
    angle: number,
    vector: paper.Point
  ): void {
    if (this.arrowTopSymbol && this.arrowBottomSymbol) {
      let topPoint = arrowPoint.clone()
      let bottomPoint = arrowPoint.clone()

      const offsetToPreventOverlapping = 6
      const offsetToAdjustArrowOnLine = 7

      switch (direction) {
        case TransformationThicknessDirection.insideOut:
          topPoint = topPoint.add(
            vector.normalize(thickness / 2 - offsetToPreventOverlapping).rotate(90)
          )
          bottomPoint = bottomPoint.add(
            vector.normalize(thickness / 2 + offsetToPreventOverlapping).rotate(90)
          )
          break
        case TransformationThicknessDirection.outsideIn:
          topPoint = topPoint.subtract(
            vector.normalize(thickness / 2 + offsetToPreventOverlapping).rotate(90)
          )
          bottomPoint = bottomPoint.subtract(
            vector.normalize(thickness / 2 - offsetToPreventOverlapping).rotate(90)
          )
          break
        case TransformationThicknessDirection.evenly:
          topPoint = topPoint.subtract(
            vector.normalize(thickness / 2 + offsetToAdjustArrowOnLine).rotate(90)
          )
          bottomPoint = bottomPoint.add(
            vector.normalize(thickness / 2 + offsetToAdjustArrowOnLine).rotate(90)
          )
          break
      }

      const placedTopSymbol = this.arrowTopSymbol.place(topPoint)
      const placedBottomSymbol = this.arrowBottomSymbol.place(bottomPoint)
      placedTopSymbol.rotate(angle)
      placedBottomSymbol.rotate(angle)

      this.previewArrowLayer.addChild(placedTopSymbol)
      this.previewArrowLayer.addChild(placedBottomSymbol)
    }
  }

  private drawAngleLabel(l1: Line, l2: Line, angle: number): void {
    const { from, through, to, center } = calculateAngleArcPoints(
      l1,
      l2,
      this.calculateAngleRadius()
    )

    const angleSelectionPoint =
      this.selectionPoint && this.selectedAngle ? this.selectionPoint : null

    this.angleLayer.addChild(
      new AngleLabel(
        angle,
        from,
        through,
        to,
        center,
        angleSelectionPoint,
        this.calculateFontSize(),
        this.calculateStrokeWidth()
      )
    )
  }

  private resetLayer(name: string, existingLayer?: paper.Layer): paper.Layer {
    if (existingLayer) {
      // Removing the layer and re-creating it instead of just clearing all children seems to
      // prevent memory leak issues from paper
      existingLayer.remove()
    }
    const layer = this.paperScope.project.addLayer(new paper.Layer())
    layer.visible = existingLayer?.visible ?? true
    layer.name = name

    return layer
  }

  private calculateStrokeWidth(): number {
    return Math.min(2, 1 / this.view.zoom)
  }

  private calculateAngleRadius(): number {
    return Math.min(75, 25 / this.view.zoom + 10)
  }

  private calculateFontSize(): number {
    return Math.min(50, 13 / this.view.zoom + 2)
  }
}
