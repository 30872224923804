<div class="content-container" [ngClass]="{ fullHeight: fullHeight }">
  <div
    class="centered-view"
    [ngClass]="{
      displayFlex: displayFlex,
      bottomPaddingForFloatingButton: bottomPaddingForFloatingButton
    }">
    <ng-content></ng-content>
  </div>
</div>
<efp-safe-area-bottom *ngIf="showSafeArea"></efp-safe-area-bottom>
