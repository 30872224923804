import { Pipe, PipeTransform } from '@angular/core'
import { Length, UnitOfLength } from 'formwork-planner-lib'

@Pipe({
  name: 'efpLength',
  pure: true,
})
export class LengthPipe implements PipeTransform {
  transform(valueInCm: number, unit: UnitOfLength): string {
    // Since the pipe is pure, this is only called once for each value and unit
    return new Length(valueInCm, unit).toString()
  }
}
