<efp-sidebar-filter-section [title]="'STOCK.FILTER.ACCORDEON_OPT.HEADER' | translate">
  <ion-item-group>
    <ion-button
      fill="outline"
      color="primary"
      expand="block"
      (click)="accordionFunctions.notifyExpandButtonClick()">
      {{ 'STOCK.FILTER.ACCORDEON_OPT.EXPAND_ALL' | translate }}
    </ion-button>
  </ion-item-group>
  <ion-item-group>
    <ion-button
      fill="outline"
      expand="block"
      (click)="accordionFunctions.notifyCollapseButtonClick()">
      {{ 'STOCK.FILTER.ACCORDEON_OPT.COLLAPSE_ALL' | translate }}
    </ion-button>
  </ion-item-group>
</efp-sidebar-filter-section>
