<ion-input
  #angleInput
  *ngIf="!shouldResizeInput"
  efpInputKeyboard
  [ngModel]="valueInUnit"
  (ngModelChange)="onValueChanged($event)"
  [disabled]="disabled"
  efpInputOverwritingSelect
  type="number"
  placeholder="0"
  inputmode="numeric"
  [style.min-width.px]="minWidth"
  [ngClass]="{
    'ion-text-right': textAlign === 'right',
    'ion-text-left': textAlign === 'left',
    'ion-text-center': textAlign === 'center'
  }">
</ion-input>
<ion-input
  #angleInput
  *ngIf="valueInUnit !== undefined && shouldResizeInput"
  efpInputKeyboard
  [ngModel]="valueInUnit"
  (ngModelChange)="onValueChanged($event)"
  [disabled]="disabled"
  efpInputOverwritingSelect
  type="number"
  placeholder="0"
  inputmode="numeric"
  [style.width]="inputWidth"
  [ngClass]="{
    'ion-text-right': textAlign === 'right',
    'ion-text-left': textAlign === 'left',
    'ion-text-center': textAlign === 'center'
  }">
</ion-input>
<ion-label *ngIf="showUnit" color="primary" class="measureUnit">°</ion-label>
