import { PlanType } from 'formwork-planner-lib'
import {
  CreatePlanOutlineCommandParam,
  PlanOutlineModel,
  PlanType as ApiPlanType,
} from '../../../generated/efp-api'
import { PlanOutline } from '../../models/plan/PlanOutline'

export abstract class PlanOutlineDao {
  public static mapModelToPlanOutline(model: PlanOutlineModel): PlanOutline {
    return {
      id: model.id,
      planId: model.planId,
      outlineType: model.outlineType === ApiPlanType.Wall ? PlanType.WALL : PlanType.SLAB,
      start: {
        x: model.startX,
        y: model.startY,
      },
      end: {
        x: model.endX,
        y: model.endY,
      },
    }
  }

  public static mapPlanOutlineToCreateParam(outline: PlanOutline): CreatePlanOutlineCommandParam {
    return {
      endX: outline.end.x,
      endY: outline.end.y,
      planId: outline.planId,
      startX: outline.start.x,
      startY: outline.start.y,
      outlineType: outline.outlineType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
    }
  }

  abstract create(outlines: CreatePlanOutlineCommandParam[]): Promise<void>

  abstract findAllOutlinesByPlanId(planId: number): Promise<PlanOutline[]>

  abstract filteredPlanIdsWithOutlines(planIds: number[]): Promise<number[]>

  abstract removeAllByPlanId(planId: number): Promise<void>
}
