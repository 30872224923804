/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreatePlanSettingsCommand } from '../models/create-plan-settings-command';
import { PlanSettingsModel } from '../models/plan-settings-model';
import { UpdatePlanSettingsCommandParams } from '../models/update-plan-settings-command-params';

@Injectable({ providedIn: 'root' })
export class PlanSettingsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllBySettingIds()` */
  static readonly GetAllBySettingIdsPath = '/api/v1/plan-settings';

  /**
   * Returns all plan settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBySettingIds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBySettingIds$Plain$Response(
    params?: {
      settingsIds?: Array<number>;
      favouriteId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PlanSettingsModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.GetAllBySettingIdsPath, 'get');
    if (params) {
      rb.query('settingsIds', params.settingsIds, {});
      rb.query('favouriteId', params.favouriteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlanSettingsModel>>;
      })
    );
  }

  /**
   * Returns all plan settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBySettingIds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBySettingIds$Plain(
    params?: {
      settingsIds?: Array<number>;
      favouriteId?: number;
    },
    context?: HttpContext
  ): Observable<Array<PlanSettingsModel>> {
    return this.getAllBySettingIds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlanSettingsModel>>): Array<PlanSettingsModel> => r.body)
    );
  }

  /**
   * Returns all plan settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBySettingIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBySettingIds$Response(
    params?: {
      settingsIds?: Array<number>;
      favouriteId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PlanSettingsModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.GetAllBySettingIdsPath, 'get');
    if (params) {
      rb.query('settingsIds', params.settingsIds, {});
      rb.query('favouriteId', params.favouriteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlanSettingsModel>>;
      })
    );
  }

  /**
   * Returns all plan settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBySettingIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBySettingIds(
    params?: {
      settingsIds?: Array<number>;
      favouriteId?: number;
    },
    context?: HttpContext
  ): Observable<Array<PlanSettingsModel>> {
    return this.getAllBySettingIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlanSettingsModel>>): Array<PlanSettingsModel> => r.body)
    );
  }

  /** Path part for operation `createPlanSettings()` */
  static readonly CreatePlanSettingsPath = '/api/v1/plan-settings';

  /**
   * Add planSettings entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanSettings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanSettings$Plain$Response(
    params: {
      body: CreatePlanSettingsCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.CreatePlanSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add planSettings entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanSettings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanSettings$Plain(
    params: {
      body: CreatePlanSettingsCommand
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createPlanSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add planSettings entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanSettings$Response(
    params: {
      body: CreatePlanSettingsCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.CreatePlanSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add planSettings entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanSettings(
    params: {
      body: CreatePlanSettingsCommand
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createPlanSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getPlanSettings()` */
  static readonly GetPlanSettingsPath = '/api/v1/plan-settings/{planSettingsId}';

  /**
   * Get planSettings per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanSettings$Plain$Response(
    params: {
      planSettingsId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.GetPlanSettingsPath, 'get');
    if (params) {
      rb.path('planSettingsId', params.planSettingsId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanSettingsModel>;
      })
    );
  }

  /**
   * Get planSettings per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanSettings$Plain(
    params: {
      planSettingsId: number;
    },
    context?: HttpContext
  ): Observable<PlanSettingsModel> {
    return this.getPlanSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanSettingsModel>): PlanSettingsModel => r.body)
    );
  }

  /**
   * Get planSettings per ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlanSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanSettings$Response(
    params: {
      planSettingsId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.GetPlanSettingsPath, 'get');
    if (params) {
      rb.path('planSettingsId', params.planSettingsId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanSettingsModel>;
      })
    );
  }

  /**
   * Get planSettings per ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlanSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlanSettings(
    params: {
      planSettingsId: number;
    },
    context?: HttpContext
  ): Observable<PlanSettingsModel> {
    return this.getPlanSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanSettingsModel>): PlanSettingsModel => r.body)
    );
  }

  /** Path part for operation `updatePlanSettings()` */
  static readonly UpdatePlanSettingsPath = '/api/v1/plan-settings/{planSettingsId}';

  /**
   * Update planSettings entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlanSettings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanSettings$Plain$Response(
    params: {
      planSettingsId: number;
      body: UpdatePlanSettingsCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.UpdatePlanSettingsPath, 'patch');
    if (params) {
      rb.path('planSettingsId', params.planSettingsId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanSettingsModel>;
      })
    );
  }

  /**
   * Update planSettings entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlanSettings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanSettings$Plain(
    params: {
      planSettingsId: number;
      body: UpdatePlanSettingsCommandParams
    },
    context?: HttpContext
  ): Observable<PlanSettingsModel> {
    return this.updatePlanSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanSettingsModel>): PlanSettingsModel => r.body)
    );
  }

  /**
   * Update planSettings entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlanSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanSettings$Response(
    params: {
      planSettingsId: number;
      body: UpdatePlanSettingsCommandParams
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, PlanSettingsApiClient.UpdatePlanSettingsPath, 'patch');
    if (params) {
      rb.path('planSettingsId', params.planSettingsId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanSettingsModel>;
      })
    );
  }

  /**
   * Update planSettings entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlanSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanSettings(
    params: {
      planSettingsId: number;
      body: UpdatePlanSettingsCommandParams
    },
    context?: HttpContext
  ): Observable<PlanSettingsModel> {
    return this.updatePlanSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanSettingsModel>): PlanSettingsModel => r.body)
    );
  }

}
