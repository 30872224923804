import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { NavController } from '@ionic/angular'
import { DevicePreferencesService } from './device-preferences.service'
import { DeviceService } from './device.service'

@Injectable({
  providedIn: 'root',
})
export class AGBGuard implements CanActivate {
  constructor(
    private readonly navCtrl: NavController,
    private readonly devicePreferences: DevicePreferencesService,
    private readonly deviceService: DeviceService
  ) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const hasConfirmedAGBs = await this.devicePreferences.fetchHasConfirmedAGBs()

    if (hasConfirmedAGBs) {
      return true
    } else {
      await this.deviceService.setDeviceCountryCode()
      await this.navCtrl.navigateForward('/agb-check', {})
      return false
    }
  }
}
