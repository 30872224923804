import { Injectable } from '@angular/core'
import { ItemReorderEventDetail } from '@ionic/core'
import { FavouriteProfile, FavouriteType } from '../../../models/favourites'
import { FavouritesService } from '../../../services/favourites.service'

@Injectable()
export class FavouriteTypeService {
  constructor(private readonly favouriteService: FavouritesService) {}

  public findFavoriteType(favourite: FavouriteProfile, typeId: string): FavouriteType | undefined {
    return favourite.values.Schalungsstile.Schalsystem.find(
      (x) => x.ID === favourite.formworkSystemId
    )?.Schalungsthema.find((x) => x.ID === typeId)
  }

  public async modifyProfileOrder(
    favourite: FavouriteProfile,
    typeId: string,
    reorder: ItemReorderEventDetail
  ): Promise<void> {
    const favoriteType = this.findFavoriteType(favourite, typeId)
    if (favoriteType) {
      const temp = favoriteType.Loesung.splice(reorder.from, 1)[0]
      if (temp != null) {
        favoriteType?.Loesung.splice(reorder.to, 0, temp)
      }
      await this.favouriteService.updateFavourite(favourite)
    }
  }
}
