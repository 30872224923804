import { Component, Input } from '@angular/core'
import { Visibility } from 'efp-viewer'

@Component({
  templateUrl: './visibility-menu-3d.component.html',
  styleUrls: ['./visibility-menu-3d.component.scss'],
})
export class VisibilityMenu3DComponent {
  @Input() items?: Visibility[]

  constructor() {}

  async toggleVisibility(item: Visibility): Promise<void> {
    item.Visible = !item.Visible
  }
}
