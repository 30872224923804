import paper from 'paper/dist/paper-core'
import { AccessoryLine, CycleBoundary, CycleSymbol, SimplifiedMesh, Point2D } from '../../model'
import { MeshXmlWriter } from './meshXmlWriter'
import {
  convertPointCoordinatesBetweenPaperAndTipos,
  CURRENT_SCOPE,
  CYCLE_BOUNDARIES,
  CYCLE_SYMBOL,
  ROOT_XML_ELEMENT,
  WALL_CONTOUR,
} from './serializationConstants'

const TIPOS_SYMBOL_DISTANCE_TO_BOUNDARY = 12.5

export class WallMeshXmlWriter extends MeshXmlWriter {
  constructor(initialXml: string) {
    super(initialXml)
  }

  writeWallTiposDataToXmlFromAccessoryLines(
    wallHeight: number,
    simpleMesh: SimplifiedMesh,
    accessoryLines: AccessoryLine[]
  ): void {
    this.setTagValue(CURRENT_SCOPE, 'wall')
    this.writeWallMetadataForOutline(accessoryLines, simpleMesh, wallHeight)
    this.writeTiposLinesFromAccessoryLines(WALL_CONTOUR, accessoryLines)
  }

  writeWallTiposDataToXml(
    outlines: { start: Point2D; end: Point2D }[],
    simpleMesh: SimplifiedMesh,
    wallHeight: number
  ): void {
    this.setTagValue(CURRENT_SCOPE, 'wall')
    const wallContour = this.getElementBySelector(WALL_CONTOUR)
    this.writeTiposLinesFromOutline(wallContour, outlines)
    this.writeWallMetadataForOutline(outlines, simpleMesh, wallHeight)
  }

  writeCycleBoundariesToTiposXml(
    cycleBoundaries: CycleBoundary[],
    cycleSymbols: CycleSymbol[]
  ): void {
    this.ensureTagsExist([ROOT_XML_ELEMENT, CYCLE_BOUNDARIES])
    this.setTagValue(CYCLE_BOUNDARIES, this.generateTiposLinesFromCycle(cycleBoundaries))

    this.ensureTagsExist([ROOT_XML_ELEMENT, CYCLE_SYMBOL])
    const cycleSymbolElement = this.getElementBySelector(CYCLE_SYMBOL)
    cycleSymbolElement.append(...this.generateCycleSymbols(cycleBoundaries, cycleSymbols))
  }

  private generateTiposLinesFromCycle(cycleBoundaries: CycleBoundary[]): string {
    let tiposData = ''

    for (const item of cycleBoundaries) {
      const firstPoint = convertPointCoordinatesBetweenPaperAndTipos(item.start)
      const secondPoint = convertPointCoordinatesBetweenPaperAndTipos(item.end)

      tiposData += this.createLineElement(firstPoint, secondPoint).outerHTML
    }

    return tiposData
  }

  private generateCycleSymbols(
    cycleBoundaries: CycleBoundary[],
    cycleSymbols: CycleSymbol[]
  ): Node[] {
    const symbols: Node[] = []
    cycleBoundaries.forEach((cycle) => {
      // We use paper here only for calculations sake, no drawing should happen
      const cycleStart = new paper.Point(cycle.start)
      const cycleEnd = new paper.Point(cycle.end)
      let verticalOffset = cycleEnd.subtract(cycleStart).normalize(5)

      if (verticalOffset.angle < 0 && verticalOffset.angle > -180) {
        verticalOffset = verticalOffset.rotate(180)
      }

      const center = cycleStart.add(cycleEnd).divide(2)
      const normalizedLine = center
        .subtract(cycleStart)
        .normalize(TIPOS_SYMBOL_DISTANCE_TO_BOUNDARY)
      const left = center.add(normalizedLine.rotate(-90)).add(verticalOffset)

      const right = center.add(normalizedLine.rotate(90)).add(verticalOffset)

      const leftSymbol = this.createCycleSymbolElement(left, cycle.cycleNumberLeft)
      const rightSymbol = this.createCycleSymbolElement(right, cycle.cycleNumberRight)

      symbols.push(leftSymbol)
      symbols.push(rightSymbol)
    })

    cycleSymbols.forEach((symbol) => {
      const symbolTag = this.createCycleSymbolElement(symbol.position, symbol.cycleNumber)
      symbols.push(symbolTag)
    })

    return symbols
  }
}
