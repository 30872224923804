import { Injectable } from '@angular/core'
import {
  ChangedResultPartsCommandParam,
  CreatePlanResultCommandParam,
  CreateStockCommandParams,
  CycleBoundaryCreateCommandParam,
  CycleSymbolModel,
  FavouriteProfileCommandParams,
  ImportPlanOutlineCommandParam,
  PlanCreateCommandParams,
  PlanType as ApiPlanType,
  ScreenshotCommandParams,
} from '@efp/api'
import { PlanType } from 'formwork-planner-lib'
import { PlanSettings } from '../../../models/planSettings'
import { Project } from '../../../models/project'
import { AppSettingService } from '../../app-setting.service'
import { DataService } from '../../data.service'
import { PlanSettingsService } from '../../plan-settings.service'
import { PlanCreationDao } from '../plan-creation.dao'
import {
  PlanSettingsDao,
  mapPlanSettingsToCreatePlanSettingsCommandParams,
} from '../plan-settings.dao'
import { PlanSqlDao } from './plan.sql-dao'
import { Plan } from '../../../models/plan'
import { ChangedResultPartDao } from '../changed-result-part.dao'
import { CycleDao } from '../cycle.dao'
import { FavouriteDao } from '../favourite.dao'
import { PlanOutlineDao } from '../plan-outline.dao'
import { PlanResultDao } from '../plan-result.dao'
import { ProjectDao } from '../project.dao'
import { ScreenshotDao } from '../screenshot.dao'
import { StockDao } from '../stock.dao'

@Injectable()
export class PlanCreationSqlDao extends PlanCreationDao {
  constructor(
    private readonly appSettingsService: AppSettingService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly planSettingsDao: PlanSettingsDao,
    private readonly dataService: DataService,
    private readonly projectDao: ProjectDao,
    private readonly stockDao: StockDao,
    private readonly favouriteProfileDao: FavouriteDao,
    private readonly planOutlineDao: PlanOutlineDao,
    private readonly cycleDao: CycleDao,
    private readonly screenshotDao: ScreenshotDao,
    private readonly planResultDao: PlanResultDao,
    private readonly changedResultPartDao: ChangedResultPartDao
  ) {
    super()
  }

  public async createPlan(params: PlanCreateCommandParams): Promise<Plan> {
    if (params.planSettingsParams) {
      return await this.importPlan(params)
    }

    // workaround because insert statement is not returning the inserted element
    let templatePlanSettingsId = await this.appSettingsService.getDefaultPlanSettingsId() // app settings id
    const appPlanSettings =
      await this.planSettingsService.getDefaultProjectPlanSettingsAndSetLastUnit()

    let project: Project | undefined
    if (params.projectId !== undefined && params.projectId !== null) {
      project = await this.projectDao.findOne(params.projectId, false)
      templatePlanSettingsId = project?.defaultPlanSettings ?? templatePlanSettingsId
    }

    let templatePlanSettings: PlanSettings | undefined = appPlanSettings
    if (templatePlanSettingsId !== appPlanSettings?.id) {
      templatePlanSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
        templatePlanSettingsId
      )
    }
    if (!appPlanSettings || !templatePlanSettings) {
      throw new Error('No appPlanSettings or templatePlanSettings settings found')
    }

    // We use the app drawing settings instead of project settings, because they are not editable for projects
    templatePlanSettings.angleRastering = appPlanSettings.angleRastering
    templatePlanSettings.lengthRastering = appPlanSettings.lengthRastering
    templatePlanSettings.measurementUnit = appPlanSettings.measurementUnit
    templatePlanSettings.slabHeight = appPlanSettings.slabHeight
    templatePlanSettings.slabThickness = appPlanSettings.slabThickness
    templatePlanSettings.wallHeight = appPlanSettings.wallHeight
    templatePlanSettings.wallThickness = appPlanSettings.wallThickness

    // We have to avoid setting the favId for the wrong plan type, because it's not changeable and will block to delete the favourite
    if (params.buildingType === ApiPlanType.Slab) {
      templatePlanSettings.angleRastering = 90
      templatePlanSettings.wallFavId = null
    } else {
      templatePlanSettings.slabFavId = null
    }

    const settingsId = await this.planSettingsDao.create(
      mapPlanSettingsToCreatePlanSettingsCommandParams(templatePlanSettings),
      templatePlanSettingsId
    )

    const newDate = new Date()
    const nextPlanNumber = await this.findNextPlanNumber()
    const name = params.name + ' ' + nextPlanNumber

    const planId = await PlanSqlDao.insertPlan(this.dataService, {
      name,
      lastUsed: newDate,
      buildingType: params.buildingType === ApiPlanType.Wall ? PlanType.WALL : PlanType.SLAB,
      stockId: project?.stockId ?? null,
      projectId: params.projectId !== undefined && params.projectId !== null ? params.projectId : 1, // -> unassigned project,
      settingsId,
      currentStep: params.currentStep,
    })

    return planId
  }

  /**
   * This returns the next plan number based on the highest plan id.
   * This is NOT necessarily the ID of a newly created plan, do not use it for inserts or navigation.
   */
  private async findNextPlanNumber(): Promise<number> {
    const statement = 'SELECT id FROM Plans where id=(select max(id) from plans)'

    return this.dataService.executeStatement(statement, []).then((rs) => {
      let nextNumber: number
      if (rs.rows.length > 0) {
        const row = rs.rows.item(0)
        nextNumber = row.id
      } else {
        nextNumber = 0
      }

      return ++nextNumber
    })
  }

  private async importPlan(params: PlanCreateCommandParams): Promise<Plan> {
    if (!params.planSettingsParams) {
      throw new Error('PlanSettingsParams must be defined')
    }
    if (!params.favouriteProfileParams) {
      throw new Error('FavouriteProfileParams must be defined')
    }
    if (!params.importPlanOutlineParams) {
      throw new Error('ImportPlanOutlineParams must be defined')
    }

    if (params.stockParams) {
      params.stockId = await this.importStock(params.stockParams)
    }

    const favId = await this.importFavouriteProfile(params.favouriteProfileParams)
    if (params.buildingType === 'WALL') {
      params.planSettingsParams.wallFavouriteProfileId = favId
    } else {
      params.planSettingsParams.slabFavouriteProfileId = favId
    }

    const templatePlanSettingsId = await this.appSettingsService.getDefaultPlanSettingsId()
    params.settingsId = await this.planSettingsDao.create(
      params.planSettingsParams,
      templatePlanSettingsId
    )

    const plan = await PlanSqlDao.insertPlan(this.dataService, {
      name: params.name,
      lastUsed: new Date(),
      buildingType: params.buildingType === 'WALL' ? PlanType.WALL : PlanType.SLAB,
      stockId: params.stockId ?? null,
      projectId: params.projectId !== undefined && params.projectId !== null ? params.projectId : 1, // -> unassigned project,
      settingsId: params.settingsId,
      currentStep: params.currentStep,
      serializedMesh: params.serializedMesh ? params.serializedMesh : '',
    })

    await this.importPlanOutline(plan.id, params.importPlanOutlineParams)

    if (params.cycleSymbolModels) {
      await this.importCycleSymbols(plan.id, params.cycleSymbolModels)
    }

    if (params.cycleBoundaryParams) {
      await this.importCycleBoundaries(plan.id, params.cycleBoundaryParams)
    }

    if (params.screenshotParams) {
      await this.importScreenshots(plan.id, params.screenshotParams)
    }

    if (params.planResultParams) {
      await this.importPlanResult(plan.id, params.planResultParams)
    }

    if (params.changedResultPartsParams) {
      await this.importChangedResultParts(plan.id, params.changedResultPartsParams)
    }

    return plan
  }

  private async importStock(params: CreateStockCommandParams): Promise<number> {
    const stock = await this.stockDao.createStockWithArticles(params)
    if (!stock) {
      throw new Error('Stock must be defined')
    }
    return stock.id
  }

  private async importFavouriteProfile(params: FavouriteProfileCommandParams): Promise<number> {
    return await this.favouriteProfileDao.create({
      name: params.name,
      isStandard: params.isStandard,
      basis: JSON.parse(params.basisJson),
      values: JSON.parse(params.favouritesJson),
      formworkSystemId: params.formworkSystemId,
      useOnlyRentableArticles: params.useOnlyRentableArticles,
      formworkVersion: params.formworkVersion,
    })
  }

  private async importPlanOutline(
    planId: number,
    params: ImportPlanOutlineCommandParam[]
  ): Promise<void> {
    await this.planOutlineDao.create(
      params.map((importOutlineParam) => ({
        ...importOutlineParam,
        planId,
      }))
    )
    // TODO We could return the needed data from create, but since we're calling from an SQL dao, performance should be ok
    const outlines = await this.planOutlineDao.findAllOutlinesByPlanId(planId)

    await Promise.all(
      params.map(async (importOutlineParam) => {
        const newOutlineId = outlines.find(
          (it) =>
            it.outlineType.toUpperCase() === importOutlineParam.outlineType.toUpperCase() &&
            it.start.x === importOutlineParam.startX &&
            it.start.y === importOutlineParam.startY &&
            it.end.x === importOutlineParam.endX &&
            it.end.y === importOutlineParam.endY
        )?.id
        if (newOutlineId && importOutlineParam.accessoriesAsString) {
          const statement =
            'INSERT INTO PlanAccessoryLine (planId, outlineId, accessoriesAsString) VALUES (?,?,?)'
          const values = [planId, newOutlineId, importOutlineParam.accessoriesAsString]
          await this.dataService.executeStatement(statement, values)
        }
      })
    )
  }

  private async importCycleSymbols(planId: number, params: CycleSymbolModel[]): Promise<void> {
    const cycleSymbols: CycleSymbolModel[] = params.map((it) => ({
      planId,
      cycleNumber: it.cycleNumber,
      posX: it.posX,
      posY: it.posY,
    }))
    await this.cycleDao.removeAllSymbolsByPlanId(planId)
    await Promise.all(cycleSymbols.map(async (symbol) => this.cycleDao.createCycleSymbol(symbol)))
  }

  private async importCycleBoundaries(
    planId: number,
    params: CycleBoundaryCreateCommandParam[]
  ): Promise<void> {
    await Promise.all(
      params.map(async (cycleBoundaryParam) => {
        cycleBoundaryParam.planId = planId
        await this.cycleDao.createCycleBoundary(cycleBoundaryParam)
      })
    )
  }

  private async importScreenshots(
    planId: number,
    params: ScreenshotCommandParams[]
  ): Promise<void> {
    await Promise.all(
      params.map(async (screenshot) => {
        screenshot.planId = planId
        await this.screenshotDao.create(screenshot)
      })
    )
  }

  private async importPlanResult(
    planId: number,
    params: CreatePlanResultCommandParam
  ): Promise<void> {
    params.planId = planId
    await this.planResultDao.savePlanResult(
      {
        resultBase64Image: params.resultBase64Image ?? '',
        resultProtocol: JSON.parse(params.resultProtocol ?? ''),
        resultXML: params.resultXML ?? '',
        partList: JSON.parse(params.partList),
      },
      planId
    )
  }

  private async importChangedResultParts(
    planId: number,
    params: ChangedResultPartsCommandParam[]
  ): Promise<void> {
    await this.changedResultPartDao.deleteOldAndSaveNewByPlanId(
      planId,
      params.map((param) => {
        return {
          planId,
          articleId: param.articleId,
          amount: param.amount,
        }
      })
    )
  }
}
