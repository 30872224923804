import { Component } from '@angular/core'

@Component({
  selector: 'efp-list-tile-styling',
  templateUrl: './efp-list-tile-styling.component.html',
  styleUrls: ['./efp-list-tile-styling.component.scss'],
})
export class EfpListTileStylingComponent {
  constructor() {}
}
