import { Component, Input } from '@angular/core'

@Component({
  selector: 'efp-list-header',
  templateUrl: './efp-list-header.component.html',
  styleUrls: ['./efp-list-header.component.scss'],
})
export class EfpListHeaderComponent {
  @Input() topPadding = true

  constructor() {}
}
