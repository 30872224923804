import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  transform(value: { [key: string]: unknown } | null | undefined): string[] {
    if (value == null) {
      return []
    }
    return Object.keys(value)
  }
}
