import { SNAP_ANGLE_INTERVAL, UnitOfLength } from 'formwork-planner-lib'
import {
  DEF_SLAB_HEIGHT,
  DEF_SLAB_THICKNESS,
  DEF_WALL_HEIGHT,
  DEF_WALL_THICKNESS,
  SNAP_LENGTH_INTERVAL,
} from '../pages/planner/model/snapping/constants'

export interface DrawSettings {
  lengthRastering: number
  angleRastering: number
  wallHeight: number
  wallThickness: number
  slabHeight: number
  slabThickness: number
  measurementUnit: UnitOfLength
}

export const defaultDrawSettings: Readonly<DrawSettings> = Object.freeze({
  lengthRastering: SNAP_LENGTH_INTERVAL,
  angleRastering: SNAP_ANGLE_INTERVAL,
  wallHeight: DEF_WALL_HEIGHT,
  wallThickness: DEF_WALL_THICKNESS,
  slabHeight: DEF_SLAB_HEIGHT,
  slabThickness: DEF_SLAB_THICKNESS,
  measurementUnit: 'cm',
})
