import { Component } from '@angular/core'
import { HttpRequestSyncService } from '../../../services/http-request-sync.service'

@Component({
  selector: 'efp-request-sync-indicator',
  templateUrl: './request-sync.indicator.component.html',
  styleUrls: ['./request-sync-indicator.component.scss'],
})
export class RequestSyncIndicatorComponent {
  constructor(public readonly httpRequestSyncService: HttpRequestSyncService) {}
}
