import { Injectable } from '@angular/core'
import { startWith, pairwise, filter, map, merge } from 'rxjs'
import { PlanSettingsRepository } from '../../repositories/plan-settings.repository'
import { PlanSettings } from '../../models/planSettings'
import { planStore } from '../../repositories/plan-creation.repository'
import { Plan } from '../../models/plan'
import { getEntityByPredicate } from '@ngneat/elf-entities'
import { PlanAccessoryLineRepository } from '../../repositories/plan-accessory-line.repository'
import { PlanAccessoryLine } from '../../models/plan/PlanAccessoryLine'
import { arePointsEqual } from '../../shared/formwork-planner'
import {
  CycleRepository,
  PlanCycleBoundaries,
  PlanCycleSymbols,
} from '../../repositories/cycle.repository'
import { PlanRepository } from '../../repositories/plan.repository'

/* TODO: (#79273)
   Currently we have some endpoints which reset the calculation of a plan in the backend, and some which don't.
  
   To improve the performance, we should reset the calculation for each case in the backend and avoid the additional
   resetCalculation call from the planDao. This Service would handle to hold the cache synchronized with the backend.

   Keep in mind, that also the sql daos have to stay consistent with this service and the backend.
*/

@Injectable({
  providedIn: 'root',
})
export class ResetCalculationService {
  private _planIdsFromPlansNeededReCalculation$ = this.planRepository.plans$.pipe(
    startWith(null),
    pairwise(),
    filter(
      (
        prevAndCurrentValue: [Plan[] | null, Plan[] | null]
      ): prevAndCurrentValue is [Plan[], Plan[]] =>
        !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
    ),
    map(([previousPlans, currentPlans]) => {
      const planIds: number[] = []

      currentPlans.forEach((current) => {
        const previous = previousPlans.find((e) => e.id === current.id)

        if (this.isRecalculationForPlanNeeded(current, previous)) {
          planIds.push(current.id)
        }
      })

      return planIds
    })
  )

  private _planIdsFromPlanSettingsNeededReCalculation$ =
    this.planSettingsRepository.planSettings$.pipe(
      startWith(null),
      pairwise(),
      filter(
        (
          prevAndCurrentValue: [PlanSettings[] | null, PlanSettings[] | null]
        ): prevAndCurrentValue is [PlanSettings[], PlanSettings[]] =>
          !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
      ),
      map(([previousSettings, currentSettings]) => {
        const settingsIdToReset: number[] = []
        const planIds: number[] = []

        currentSettings.forEach((currentSetting) => {
          const previousSetting = previousSettings.find((e) => e.id === currentSetting.id)

          if (this.isRecalculationForPlansettingsNeeded(currentSetting, previousSetting)) {
            settingsIdToReset.push(currentSetting.id)
          }
        })

        settingsIdToReset.forEach((settingId) => {
          const plan = planStore.query(
            getEntityByPredicate(({ settingsId }) => settingsId === settingId)
          )

          if (plan) {
            planIds.push(plan.id)
          }
        })

        return planIds
      })
    )

  private _planIdsFromBoundariesNeededReCalculation$ =
    this.cycleRepository.planCycleBoundaries$.pipe(
      startWith(null),
      pairwise(),
      filter(
        (
          prevAndCurrentValue: [PlanCycleBoundaries[] | null, PlanCycleBoundaries[] | null]
        ): prevAndCurrentValue is [PlanCycleBoundaries[], PlanCycleBoundaries[]] =>
          !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
      ),
      map(([previousValues, currentValues]) => {
        const planIds: number[] = []

        currentValues.forEach((current) => {
          const previous = previousValues.find((e) => e.planId === current.planId)

          if (this.isRecalculationForBoundariesNeeded(current, previous)) {
            planIds.push(current.planId)
          }
        })

        previousValues.forEach((previous) => {
          const current = currentValues.find((e) => e.planId === previous.planId)

          // If boundaries were deleted, recalculate
          if (!current && planIds.indexOf(previous.planId) === -1) {
            planIds.push(previous.planId)
          }
        })

        return planIds
      })
    )

  private _planIdsFromCyclesSymbolsNeededReCalculation$ =
    this.cycleRepository.planCycleSymbols$.pipe(
      startWith(null),
      pairwise(),
      filter(
        (
          prevAndCurrentValue: [PlanCycleSymbols[] | null, PlanCycleSymbols[] | null]
        ): prevAndCurrentValue is [PlanCycleSymbols[], PlanCycleSymbols[]] =>
          !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
      ),
      map(([previousValues, currentValues]) => {
        const planIds: number[] = []

        currentValues.forEach((current) => {
          const previous = previousValues.find((e) => e.planId === current.planId)

          if (
            planIds.indexOf(current.planId) === -1 &&
            this.isRecalculationForCyclesNeeded(current, previous)
          ) {
            planIds.push(current.planId)
          }
        })

        previousValues.forEach((previous) => {
          const current = currentValues.find((e) => e.planId === previous.planId)

          // If cycle symbol was deleted, recalculate
          if (!current && planIds.indexOf(previous.planId) === -1) {
            planIds.push(previous.planId)
          }
        })

        return planIds
      })
    )

  private _planIdsFromAccessoryLinesNeededReCalculation$ =
    this.planAccessoryRepository.planAccessoryLines$.pipe(
      startWith(null),
      pairwise(),
      filter(
        (
          prevAndCurrentValue: [PlanAccessoryLine[] | null, PlanAccessoryLine[] | null]
        ): prevAndCurrentValue is [PlanAccessoryLine[], PlanAccessoryLine[]] =>
          !!prevAndCurrentValue[0] && !!prevAndCurrentValue[1]
      ),
      map(([previousValues, currentValues]) => {
        const planIds: number[] = []

        currentValues.forEach((current) => {
          const previous = previousValues.find((e) => e.id === current.id)

          if (
            planIds.indexOf(current.planId) === -1 &&
            this.isRecalculationForAccessoryLinesNeeded(current, previous)
          ) {
            planIds.push(current.planId)
          }
        })

        previousValues.forEach((previous) => {
          const current = currentValues.find((e) => e.id === previous.id)

          // If accessory line was deleted, recalculate
          if (!current && planIds.indexOf(previous.planId) === -1) {
            planIds.push(previous.planId)
          }
        })

        return planIds
      })
    )

  public planIdsNeededReCalculation$ = merge(
    this._planIdsFromPlansNeededReCalculation$,
    this._planIdsFromPlanSettingsNeededReCalculation$,
    this._planIdsFromBoundariesNeededReCalculation$,
    this._planIdsFromCyclesSymbolsNeededReCalculation$,
    this._planIdsFromAccessoryLinesNeededReCalculation$
  )

  constructor(
    private readonly planSettingsRepository: PlanSettingsRepository,
    private readonly cycleRepository: CycleRepository,
    private readonly planAccessoryRepository: PlanAccessoryLineRepository,
    private readonly planRepository: PlanRepository
  ) {}

  /* 
    Keep method synched with backend & sql daos
  */
  private isRecalculationForPlanNeeded(current: Plan, previous?: Plan): boolean {
    // If stock changed, recalculate
    if (previous && previous.stockId !== current.stockId) {
      return true
    }

    if (previous && previous.serializedMesh !== current.serializedMesh) {
      return true
    }

    return false
  }

  /* 
    Keep method synched with backend & sql daos
  */
  private isRecalculationForPlansettingsNeeded(
    currentSettings: PlanSettings,
    previousSettings?: PlanSettings
  ): boolean {
    // If formwork changed, recalculate
    if (
      previousSettings &&
      (previousSettings.formworkWall !== currentSettings.formworkWall ||
        previousSettings.formworkSlab !== currentSettings.formworkSlab)
    ) {
      return true
    }

    // If favorite profiles changed, recalculate
    if (
      previousSettings &&
      (previousSettings.wallFavId !== currentSettings.wallFavId ||
        previousSettings.slabFavId !== currentSettings.slabFavId)
    ) {
      return true
    }

    // If wall height changed, recalculate
    if (previousSettings && previousSettings.wallHeight !== currentSettings.wallHeight) {
      return true
    }

    // If slab height or thickness changed, recalculate
    if (
      previousSettings &&
      (previousSettings.slabHeight !== currentSettings.slabHeight ||
        previousSettings.slabThickness !== currentSettings.slabThickness)
    ) {
      return true
    }

    return false
  }

  /* 
    Keep method synched with backend & sql daos
  */
  private isRecalculationForBoundariesNeeded(
    current: PlanCycleBoundaries,
    previous?: PlanCycleBoundaries
  ): boolean {
    // If previous exist in store & boundary count changed, recalculate
    if (previous && previous.boundaries.length !== current.boundaries.length) {
      return true
    }

    // If some of previous boundaries are not or equal to a related current boundary, recalculate
    if (
      previous?.boundaries.some((previousBoundary) => {
        const currentBoundary = current.boundaries.find((e) => e.id === previousBoundary.id)

        return (
          !currentBoundary ||
          !arePointsEqual(previousBoundary.start, currentBoundary.start) ||
          !arePointsEqual(previousBoundary.end, currentBoundary.end) ||
          previousBoundary.cycleNumberLeft !== currentBoundary.cycleNumberLeft ||
          previousBoundary.cycleNumberRight !== currentBoundary.cycleNumberRight
        )
      })
    ) {
      return true
    }

    // If some of current boundaries are not or equal to a related previous boundary, recalculate
    if (
      previous &&
      current.boundaries.some((currentBoundary) => {
        const previousBoundary = previous.boundaries.find((e) => e.id === currentBoundary.id)

        return (
          !previousBoundary ||
          !arePointsEqual(previousBoundary.start, currentBoundary.start) ||
          !arePointsEqual(previousBoundary.end, currentBoundary.end) ||
          previousBoundary.cycleNumberLeft !== currentBoundary.cycleNumberLeft ||
          previousBoundary.cycleNumberRight !== currentBoundary.cycleNumberRight
        )
      })
    ) {
      return true
    }

    return false
  }

  /* 
    Keep method synched with backend & sql daos
  */
  private isRecalculationForCyclesNeeded(
    current: PlanCycleSymbols,
    previous?: PlanCycleSymbols
  ): boolean {
    // If previous exist in store & symbol count changed, recalculate
    if (previous && previous.symbols.length !== current.symbols.length) {
      return true
    }

    // If some of previous symbols are not or equal to a related current symbols, recalculate
    if (
      previous?.symbols.some((previousSymbol) => {
        const currentSymbol = current.symbols.find(
          (e) => e.cycleNumber === previousSymbol.cycleNumber
        )

        return !currentSymbol || !arePointsEqual(previousSymbol.position, currentSymbol.position)
      })
    ) {
      return true
    }

    // If some of current symbols are not or equal to a related previous symbols, recalculate
    if (
      current.symbols.some((currentSymbol) => {
        const previousSymbol = previous?.symbols.find(
          (e) => e.cycleNumber === currentSymbol.cycleNumber
        )

        return !previousSymbol || !arePointsEqual(previousSymbol.position, currentSymbol.position)
      })
    ) {
      return true
    }

    return false
  }

  /* 
    Keep method synched with backend & sql daos
  */
  private isRecalculationForAccessoryLinesNeeded(
    current: PlanAccessoryLine,
    previous?: PlanAccessoryLine
  ): boolean {
    if (!previous) {
      return false
    }

    // If accessories changed, recalculate
    if (previous.accessoriesAsString !== current.accessoriesAsString) {
      return true
    }

    // If outline changed, recalculate
    if (
      !arePointsEqual(previous.start, current.start) ||
      !arePointsEqual(previous.end, current.end) ||
      previous.outlineId !== current.outlineId
    ) {
      return true
    }

    return false
  }
}
