import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { Translation } from '../../services/translation.service'

@Pipe({
  name: 'efpDate',
  pure: true,
})
export class EfpDatePipe extends DatePipe implements PipeTransform {
  constructor(private readonly translate: Translation) {
    super(translate.getCurrentLanguage())
  }

  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    const dateFormat = format ?? 'short'

    if (locale) {
      console.error('Locale as Parameter not supported for EfpDatePipe')
    }

    return super.transform(value, dateFormat, timezone)
  }
}
