import { SNAPPING_ERROR_TOLERANCE } from './snapping/snappingConstants'

export const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180)
export const radiansToDegrees = (radians: number): number => radians * (180 / Math.PI)

export const isMultipleOf = (
  value: number,
  multipleOf: number,
  tolerance: number = SNAPPING_ERROR_TOLERANCE
): boolean => {
  return (
    Math.abs(value) % multipleOf < tolerance ||
    Math.abs(value) % multipleOf > multipleOf - tolerance
  )
}
