import { Component } from '@angular/core'

@Component({
  selector: 'efp-safe-area-bottom',
  templateUrl: './safe-area-bottom.component.html',
  styleUrls: ['./safe-area-bottom.component.scss'],
})
export class SafeAreaBottomComponent {
  constructor() {}
}
