<div class="accordion topbar-filter-container">
  <div [@openCloseCollapse]="isCollapsed ? 'closed' : 'open'" class="accordion-content">
    <ng-content></ng-content>
  </div>
  <ng-content select="[uncollapsable]"></ng-content>
  <ion-button *ngIf="!isCollapsed" fill="clear" class="visibility-button" (click)="toggle()">
    <ion-icon color="primary" src="assets/icon/ic_chevron-up.svg"></ion-icon>
    {{ 'SIDEBAR.HIDE_VISIBILITY_LABEL' | translate }}
  </ion-button>
  <ion-button *ngIf="isCollapsed" fill="clear" class="visibility-button" (click)="toggle()">
    <ion-icon color="primary" src="assets/icon/ic_chevron-down.svg"></ion-icon>
    {{ 'SIDEBAR.SHOW_VISIBILITY_LABEL' | translate }}
  </ion-button>
</div>
