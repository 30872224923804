import { Injectable } from '@angular/core'
import { PlanType } from 'formwork-planner-lib'
import { countryFormworksystem, PPMStatus } from '../constants/countryFormworkMatching'
import { formworkSystems, supportedSystems } from '../constants/formworkSystems'
import { AppSettings } from '../models/appSettings'
import { FormworkSystem } from '../models/formworkSystem'
import { AppSettingsRepository } from '../repositories/app-settings.repository'
import { PlanVisibilitySettingsRepository } from '../repositories/plan-visibility-settings.repository'
import { Translation } from './translation.service'

@Injectable({ providedIn: 'root' })
export class AppSettingService {
  constructor(
    private readonly translation: Translation,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly planVisibilitySettingsRepository: PlanVisibilitySettingsRepository
  ) {}

  readonly appLanguages = this.translation.getAvailableLanguages()

  async getAppSettings(): Promise<Readonly<AppSettings>> {
    const appSettings = await this.appSettingsRepository.getAppSettings()
    // TODO DO we actually want to fetch everything here?
    const drawVisibilitySettings = await this.planVisibilitySettingsRepository.findOne(
      appSettings.drawVisibilitySettingsId
    )
    if (!drawVisibilitySettings) {
      throw new Error('Draw visibility settings not found')
    }
    const cycleVisibilitySettings = await this.planVisibilitySettingsRepository.findOne(
      appSettings.cycleVisibilitySettingsId
    )
    if (!cycleVisibilitySettings) {
      throw new Error('Cycle visibility settings not found')
    }
    const assessoryVisibilitySettings = await this.planVisibilitySettingsRepository.findOne(
      appSettings.accessoryVisibilitySettingsId
    )
    if (!assessoryVisibilitySettings) {
      throw new Error('Accessory visibility settings not found')
    }

    return {
      language: appSettings.language,
      country: appSettings.country,
      defaultPlanSettingsId: appSettings.defaultPlanSettingsId,
      drawVisibilitySettings,
      cycleVisibilitySettings,
      assessoryVisibilitySettings,
    }
  }

  getAllFormworkSystems(): FormworkSystem[] {
    return supportedSystems
      .map((id) => formworkSystems.find((it) => it.id === id))
      .filter((it) => !!it) as FormworkSystem[]
  }

  async getSupportedFormworkSystemsForCurrentCountry(): Promise<FormworkSystem[]> {
    const country = (await this.appSettingsRepository.getAppSettings()).country.toUpperCase()

    // Currently solved with; JSON -> soon moved to new Backend
    // Allow "LCSTAGE" I & G, deny all others (P,O,E,N,NA)
    const countryFormworkMatching: PPMStatus[] = JSON.parse(countryFormworksystem)

    const allSystems = this.getAllFormworkSystems()

    const allowedLCStages = ['I', 'G', 'O']
    const availableSystems = allSystems.filter((system) =>
      countryFormworkMatching.some(
        (matching) =>
          matching.COUNTRY_CODE === country &&
          matching.SYSTEM === system.id &&
          allowedLCStages.includes(matching.LCSTAGE)
      )
    )

    return availableSystems.length > 0 ? availableSystems : allSystems
  }

  public async getPlanTypeOfFormworkSystemId(formworkSystemId: string): Promise<PlanType> {
    const allFormworkSystems = this.getAllFormworkSystems()
    const formworkSystem: FormworkSystem = allFormworkSystems.filter(
      (system: FormworkSystem) => system.id === formworkSystemId
    )[0]

    return formworkSystem.planType
  }

  public async getDefaultPlanSettingsId(): Promise<number> {
    return (await this.appSettingsRepository.getAppSettings()).defaultPlanSettingsId
  }
}
