import { Injectable } from '@angular/core'
import { FavouriteProfile } from '../../../models/favourites'
import { DataService } from '../../data.service'
import { FavouriteDao } from '../favourite.dao'

@Injectable()
export class FavouriteSqlDao extends FavouriteDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseFromRow(row: any): FavouriteProfile {
    const favouriteProfile: FavouriteProfile = {
      id: row.id,
      name: row.name,
      isStandard: row.isStandard,
      values: JSON.parse(row.favouritesJson),
      basis: row.basisJson ? JSON.parse(row.basisJson) : undefined,
      formworkSystemId: row.formworkSystemId ?? undefined,
      useOnlyRentableArticles: row.useOnlyRentableArticles,
      formworkVersion: row.formworkVersion,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    }
    return favouriteProfile
  }

  private async upsert(
    favorite: FavouriteProfile,
    updateOrInsert: 'update' | 'insert'
  ): Promise<number> {
    const favoriteValues = JSON.stringify(favorite.values)
    const favouriteBasis = favorite.basis ? JSON.stringify(favorite.basis) : null
    const formworkSystemId = favorite.formworkSystemId ?? null
    let statement: string
    let values: unknown[]
    if (updateOrInsert === 'update') {
      statement =
        // eslint-disable-next-line max-len
        'UPDATE Favourites SET favouritesJson = ?, basisJson = ?, name = ?, isStandard = ?, formworkSystemId = ?, updatedAt = ? WHERE id = ?'
      values = [
        favoriteValues,
        favouriteBasis,
        favorite.name,
        favorite.isStandard ? 1 : 0,
        formworkSystemId,
        favorite.updatedAt,
        favorite.id,
      ]
    } else {
      statement =
        // eslint-disable-next-line max-len
        'INSERT INTO Favourites (favouritesJson, basisJson, name, isStandard, formworkSystemId, useOnlyRentableArticles, createdAt, updatedAt) VALUES (?,?,?,?,?,?,?,?)'
      values = [
        favoriteValues,
        favouriteBasis,
        favorite.name,
        favorite.isStandard ? 1 : 0,
        formworkSystemId,
        favorite.useOnlyRentableArticles,
        favorite.createdAt,
        favorite.updatedAt,
      ]
    }

    const result = await this.dataService.executeStatement(statement, values)
    return updateOrInsert === 'update' ? favorite.id : result.insertId
  }

  public async update(favorite: FavouriteProfile): Promise<number> {
    return this.upsert(favorite, 'update')
  }

  public async create(
    favorite: Omit<FavouriteProfile, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<number> {
    const now = new Date()
    const insertId = await this.upsert(
      {
        ...favorite,
        id: 0,
        createdAt: now,
        updatedAt: now,
      },
      'insert'
    )
    return insertId
  }

  public async deleteById(id: number): Promise<void> {
    await this.dataService.executeStatement('DELETE FROM Favourites WHERE id = ?', [id])
  }

  public async findAllByFormworkSystemId(formworkSystemId: string): Promise<FavouriteProfile[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * from Favourites where formworkSystemId = ?',
      [formworkSystemId]
    )
    const favouriteProfiles: FavouriteProfile[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      favouriteProfiles[i] = this.parseFromRow(row)
    }
    return favouriteProfiles
  }

  public async findOneById(id: number): Promise<FavouriteProfile | undefined> {
    const result = await this.dataService.executeStatement(
      'SELECT * from Favourites where id = ?',
      [id]
    )
    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      return this.parseFromRow(row)
    }
  }

  public async findOneStandardByFormworkSystemId(
    formworkSystemId: string
  ): Promise<FavouriteProfile | undefined> {
    const result = await this.dataService.executeStatement(
      'SELECT * from Favourites where formworkSystemId = ? AND isStandard=1',
      [formworkSystemId]
    )
    if (result.rows.length === 0) {
      return undefined
    } else {
      const row = result.rows.item(0)
      return this.parseFromRow(row)
    }
  }

  public async findAll(): Promise<FavouriteProfile[]> {
    const result = await this.dataService.executeStatement('SELECT * from Favourites')
    const favouriteProfiles: FavouriteProfile[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      favouriteProfiles[i] = this.parseFromRow(row)
    }
    return favouriteProfiles
  }
}
