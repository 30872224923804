import { Component, Input } from '@angular/core'
import { Cycle, ToolBarControl } from 'efp-viewer'

@Component({
  templateUrl: './cycle-menu-3d.component.html',
  styleUrls: ['./cycle-menu-3d.component.scss'],
})
export class CycleMenu3DComponent {
  @Input() cycles?: Cycle[]
  @Input() toolbarControl?: ToolBarControl

  constructor() {}

  async toggleVisibility(item: Cycle): Promise<void> {
    if (this.toolbarControl) this.toolbarControl.cycle = item.CycleId
  }
}
