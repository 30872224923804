export interface PPMStatus {
  SYSTEM: string
  COUNTRY_CODE: string
  LCSTAGE: string
}

export const countryFormworksystem = `
  [
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEX124",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XALUFR",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XLIGHT",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRECO",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMA_S",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XSTAHLFR",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRPLUS",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI_S",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMI",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFRAMINI",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMA",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "FLEXS",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "P"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "O"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "G"
    },
    {
      "SYSTEM": "XFR30",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "PH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "OM",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "BR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "CL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "DE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "DK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "FI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "FR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "GB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "IN",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "LB",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "MX",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "MY",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "NL",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "NO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "PA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "PE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "PL",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "QA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "RO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "SA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "SE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "SG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "TR",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "US",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "AE",
      "LCSTAGE": "N"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "AU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "EG",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "CO",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "PPM_GROUP",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "HU",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "CZ",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "IT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "RS",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "BG",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "SK",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "GR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "KW",
      "LCSTAGE": "I"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "EE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "LV",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "LT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "CA",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "CH",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "SI",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "AT",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "HR",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "BE",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "ES",
      "LCSTAGE": "NA"
    },
    {
      "SYSTEM": "XREFORMX",
      "COUNTRY_CODE": "IE",
      "LCSTAGE": "NA"
    }
  ]
`
