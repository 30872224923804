import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { Capacitor, PluginListenerHandle } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

@Directive({
  selector: 'ion-input[efpInputKeyboard]',
})
export class InputKeyboardDirective implements OnInit, OnDestroy {
  private element: HTMLIonInputElement
  private hideKeyboardListener?: PluginListenerHandle

  constructor(element: ElementRef, private readonly renderer: Renderer2) {
    this.element = element.nativeElement as HTMLIonInputElement
  }

  async ngOnInit(): Promise<void> {
    this.renderer.setAttribute(this.element, 'autofocus', `${false}`)
    this.renderer.setAttribute(this.element, 'tabindex', `${-1}`)

    // Keyboard not available in Web
    if (Capacitor.isNativePlatform()) {
      this.hideKeyboardListener = await Keyboard.addListener('keyboardWillHide', () =>
        this.hideKeyboard()
      )
    }
  }

  @HostListener('keyup.enter')
  hideKeyboard(): void {
    if (Capacitor.isNativePlatform()) {
      void Keyboard.hide()
      void this.element.setBlur()
    }
  }

  async ngOnDestroy(): Promise<void> {
    await this.hideKeyboardListener?.remove()
  }
}
