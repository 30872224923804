import { Component } from '@angular/core'

@Component({
  selector: 'efp-safe-area-top',
  templateUrl: './safe-area-top.component.html',
  styleUrls: ['./safe-area-top.component.scss'],
})
export class SafeAreaTopComponent {
  constructor() {}
}
