import { Component, EventEmitter, Output } from '@angular/core'
import { DimensionChange } from 'formwork-planner-lib'

@Component({
  selector: 'efp-bottom-menu-container',
  templateUrl: './efp-bottom-menu-container.component.html',
  styleUrls: ['./efp-bottom-menu-container.component.scss'],
})
export class EfpBottomMenuContainerComponent {
  @Output() readonly dimensionsChange = new EventEmitter<DimensionChange>(true)

  constructor() {}
}
