import { Article } from '../../models/article'

export abstract class ArticleDao {
  public abstract createArticles(articles: Article[]): Promise<number[]>

  public abstract findAllByStockId(stockId: number): Promise<Article[]>

  public abstract delete(id: number): Promise<void>

  public abstract update(article: Article): Promise<void>

  public abstract updateAll(articles: Article[]): Promise<void>
}
