<div class="error-message-modal">
  <div class="error-message-modal-title">
    <ion-icon class="sync-icon" src="/assets/icon/warning-red.svg"></ion-icon>
    <div class="sync-text sync-failed-icon">{{ 'SYNC.FAILED' | translate }}</div>
  </div>
  <div class="error-message-modal-description">
    <span>
      {{ 'SYNC.FAILED_DESCRIPTION' | translate }}
    </span>
  </div>
  <div class="error-message-modal-button">
    <ion-button (click)="onDismiss()">{{ 'SYNC.RELOAD' | translate }}</ion-button>
  </div>
</div>
