import paper from 'paper/dist/paper-core'
import { EdgeMap } from './EdgeMap'

export class CompoundPathWithEdgeMap extends paper.CompoundPath {
  public readonly edgeMap: EdgeMap = new Map()

  constructor(object: object, edgeMap: EdgeMap = new Map()) {
    super(object)
    this.edgeMap = edgeMap
  }
}
