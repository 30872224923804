<ng-container *ngIf="currentHint">
  <ion-header class="ion-no-border">
    <ion-toolbar class="header-toolbar">
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title size="large">{{ 'ONBOARDING.MODAL_TITLE' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <div class="onboarding-content">
    <efp-onboarding-hint-content [onboardingHint]="currentHint"> </efp-onboarding-hint-content>
  </div>
  <ion-footer class="ion-no-border">
    <ion-toolbar class="footer-toolbar">
      <ion-buttons slot="start">
        <ion-button
          [ngClass]="{ hidden: !hasPrev() }"
          class="navigation-button light"
          data-test-selector="onboarding-modal-prev-hint"
          (click)="prevHint()">
          <ion-icon slot="icon-only" src="assets/icon/ic_arrow-left.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <div *ngIf="numberOfHints() > 1" class="hint-status-indicators">
        <ion-icon
          *ngFor="let hint of hints; index as i"
          (click)="goToHint(i)"
          data-test-selector="onboarding-modal-hint-status-indicator"
          aria-hidden="true"
          [ngClass]="{ active: i === this.hintIndex }"
          src="assets/icon/ellipse.svg"></ion-icon>
      </div>
      <ion-buttons slot="end">
        <ion-button
          *ngIf="hasNextHint(); else finishButton"
          class="navigation-button"
          data-test-selector="onboarding-modal-next-hint"
          (click)="nextHint()">
          <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
        </ion-button>
        <ng-template #finishButton>
          <ion-button
            class="navigation-button"
            data-test-selector="onboarding-modal-finish"
            (click)="finish()">
            <ion-icon color="white" slot="icon-only" src="assets/icon/check-small.svg"></ion-icon>
          </ion-button>
        </ng-template>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</ng-container>
