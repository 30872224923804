<ion-toolbar class="planner-toolbar">
  <ion-buttons>
    <ng-content></ng-content>
    <ng-container *ngIf="renderService">
      <ion-button
        efpTooltip
        [tooltipTitle]="'TOOLBAR.VISIBILITY_SETTINGS' | translate"
        class="expandable-btn"
        (click)="presentVisibilityPopover($event)"
        data-test-selector="planner-visibility-menu">
        <ion-icon slot="icon-only" src="assets/icon/visibility.svg"></ion-icon>
      </ion-button>
      <efp-center-button-view class="center-button-view" [model]="model"></efp-center-button-view>
    </ng-container>
  </ion-buttons>
</ion-toolbar>
