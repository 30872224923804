import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { NavController } from '@ionic/angular'
import { AuthenticationRepository } from '../repositories/authentication-repository.service'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private authService: AuthenticationService,
    private authenticationRepository: AuthenticationRepository
  ) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    await this.authService.waitUntilInitialized

    if (
      this.authenticationRepository.authenticated$.value ||
      this.authenticationRepository.testAuthenticated$.value
    ) {
      return true
    } else {
      await this.authService.configureOauth2()
      await this.navCtrl.navigateForward('/login', {})
      return false
    }
  }
}
