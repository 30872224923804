/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CycleBoundaryCreateCommandParam } from '../models/cycle-boundary-create-command-param';
import { CycleBoundaryModel } from '../models/cycle-boundary-model';
import { CycleBoundaryUpdateCommandParam } from '../models/cycle-boundary-update-command-param';
import { Unit } from '../models/unit';

@Injectable({ providedIn: 'root' })
export class CycleBoundariesApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCycleBoundary()` */
  static readonly CreateCycleBoundaryPath = '/api/v1/cycle-boundaries';

  /**
   * Add a new cycle boundary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCycleBoundary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleBoundary$Plain$Response(
    params: {
      body: CycleBoundaryCreateCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.CreateCycleBoundaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new cycle boundary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCycleBoundary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleBoundary$Plain(
    params: {
      body: CycleBoundaryCreateCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCycleBoundary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add a new cycle boundary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCycleBoundary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleBoundary$Response(
    params: {
      body: CycleBoundaryCreateCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.CreateCycleBoundaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add a new cycle boundary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCycleBoundary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCycleBoundary(
    params: {
      body: CycleBoundaryCreateCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCycleBoundary$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAllCycleBoundariesByPlanId()` */
  static readonly FindAllCycleBoundariesByPlanIdPath = '/api/v1/plans/{planId}/cycle-boundaries';

  /**
   * Get cycle boundaries per plan ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCycleBoundariesByPlanId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleBoundariesByPlanId$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CycleBoundaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.FindAllCycleBoundariesByPlanIdPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CycleBoundaryModel>>;
      })
    );
  }

  /**
   * Get cycle boundaries per plan ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCycleBoundariesByPlanId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleBoundariesByPlanId$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<CycleBoundaryModel>> {
    return this.findAllCycleBoundariesByPlanId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CycleBoundaryModel>>): Array<CycleBoundaryModel> => r.body)
    );
  }

  /**
   * Get cycle boundaries per plan ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCycleBoundariesByPlanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleBoundariesByPlanId$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CycleBoundaryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.FindAllCycleBoundariesByPlanIdPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CycleBoundaryModel>>;
      })
    );
  }

  /**
   * Get cycle boundaries per plan ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCycleBoundariesByPlanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCycleBoundariesByPlanId(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<Array<CycleBoundaryModel>> {
    return this.findAllCycleBoundariesByPlanId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CycleBoundaryModel>>): Array<CycleBoundaryModel> => r.body)
    );
  }

  /** Path part for operation `deleteCycleBoundariesByPlanId()` */
  static readonly DeleteCycleBoundariesByPlanIdPath = '/api/v1/plans/{planId}/cycle-boundaries';

  /**
   * Delete cycle boundaries per PlanId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCycleBoundariesByPlanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundariesByPlanId$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.DeleteCycleBoundariesByPlanIdPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete cycle boundaries per PlanId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCycleBoundariesByPlanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundariesByPlanId(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteCycleBoundariesByPlanId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteCycleBoundary()` */
  static readonly DeleteCycleBoundaryPath = '/api/v1/cycle-boundaries/{boundaryId}';

  /**
   * Delete cycle boundary with Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCycleBoundary$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundary$Plain$Response(
    params: {
      boundaryId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.DeleteCycleBoundaryPath, 'delete');
    if (params) {
      rb.path('boundaryId', params.boundaryId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete cycle boundary with Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCycleBoundary$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundary$Plain(
    params: {
      boundaryId: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteCycleBoundary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /**
   * Delete cycle boundary with Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCycleBoundary()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundary$Response(
    params: {
      boundaryId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Unit>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.DeleteCycleBoundaryPath, 'delete');
    if (params) {
      rb.path('boundaryId', params.boundaryId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Delete cycle boundary with Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCycleBoundary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCycleBoundary(
    params: {
      boundaryId: number;
    },
    context?: HttpContext
  ): Observable<Unit> {
    return this.deleteCycleBoundary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Unit>): Unit => r.body)
    );
  }

  /** Path part for operation `updateCycleBoundary()` */
  static readonly UpdateCycleBoundaryPath = '/api/v1/cycle-boundaries/{id}';

  /**
   * Update existing cycle boundary position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCycleBoundary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCycleBoundary$Plain$Response(
    params: {
      id: number;
      body: CycleBoundaryUpdateCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CycleBoundaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.UpdateCycleBoundaryPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CycleBoundaryModel>;
      })
    );
  }

  /**
   * Update existing cycle boundary position.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCycleBoundary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCycleBoundary$Plain(
    params: {
      id: number;
      body: CycleBoundaryUpdateCommandParam
    },
    context?: HttpContext
  ): Observable<CycleBoundaryModel> {
    return this.updateCycleBoundary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CycleBoundaryModel>): CycleBoundaryModel => r.body)
    );
  }

  /**
   * Update existing cycle boundary position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCycleBoundary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCycleBoundary$Response(
    params: {
      id: number;
      body: CycleBoundaryUpdateCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CycleBoundaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, CycleBoundariesApiClient.UpdateCycleBoundaryPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CycleBoundaryModel>;
      })
    );
  }

  /**
   * Update existing cycle boundary position.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCycleBoundary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCycleBoundary(
    params: {
      id: number;
      body: CycleBoundaryUpdateCommandParam
    },
    context?: HttpContext
  ): Observable<CycleBoundaryModel> {
    return this.updateCycleBoundary$Response(params, context).pipe(
      map((r: StrictHttpResponse<CycleBoundaryModel>): CycleBoundaryModel => r.body)
    );
  }

}
