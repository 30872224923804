import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { Country } from '@efp/api'
import { allCountries } from '../models/country'
import CountryCode from '../shared/plugins/country-code'
import { DevicePreferencesService } from './device-preferences.service'
import { AppSettingsRepository } from '../repositories/app-settings.repository'

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  countryCodePlugin = CountryCode

  constructor(
    private readonly devicePreferences: DevicePreferencesService,
    private readonly appSettingsRepository: AppSettingsRepository
  ) {}

  public async setDeviceCountryCode(): Promise<void> {
    const hasDeviceCountryCodeUsed = await this.devicePreferences.fetchDeviceCountryCode()

    // Get the device country code
    let countryCode: string | undefined
    if (!hasDeviceCountryCodeUsed && Capacitor.isNativePlatform()) {
      try {
        countryCode = (await this.countryCodePlugin.get()).countryCode
      } catch (error: unknown) {
        console.error(error)
      }
    }

    const parsedCountry = this.parseValidCountry(countryCode)
    if (parsedCountry) {
      await this.devicePreferences.setDeviceCountryCode(parsedCountry)
      await this.appSettingsRepository.updateCountry(parsedCountry)
    }
  }

  private parseValidCountry(countryCode: string | undefined): Country | undefined {
    return allCountries.find((country) => countryCode?.toLowerCase() === country)
  }
}
