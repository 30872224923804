import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class HttpCancelService {
  private pendingHTTPRequests$ = new Subject<void>()

  public readonly onCancelPendingRequests = this.pendingHTTPRequests$.asObservable()

  constructor() {}

  // Cancel Pending HTTP calls
  public cancelPendingRequests(): void {
    this.pendingHTTPRequests$.next()
  }
}
