import { inject, Injectable } from '@angular/core'
import { PlanVisibilitySettingsApiClient } from '@efp/api'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { PlanVisibilitySettingsDao } from '../plan-visibility-settings.dao'
import { firstValueFrom } from 'rxjs'

@Injectable()
export class PlanVisibilitySettingsHttpDao extends PlanVisibilitySettingsDao {
  private readonly planVisibilitySettingsApiClient = inject(PlanVisibilitySettingsApiClient)

  public async findOne(id: number): Promise<PlanVisibilitySettings | undefined> {
    return await firstValueFrom(
      this.planVisibilitySettingsApiClient.getPlanVisibilitySettings({
        planVisibilitySettingsId: id,
      })
    )
  }

  public async update(planVisibilitySettings: PlanVisibilitySettings): Promise<void> {
    await firstValueFrom(
      this.planVisibilitySettingsApiClient.updatePlanVisibilitySettings({
        planVisibilitySettingsId: planVisibilitySettings.id,
        body: planVisibilitySettings,
      })
    )
  }
}
