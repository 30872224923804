import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController, NavController } from '@ionic/angular'
import { NavigationOptions } from '@ionic/angular/providers/nav-controller'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'efp-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Input() title!: string
  @Input() isNavStep = false // required because navSteps use a different icon
  @Input() navLink?: string
  @Input() hasNavigation = true
  @Input() planId?: number
  @Input() backNavOptions?: NavigationOptions
  @Input() navDisabledAndGray = false
  @Input() hasFeedback = true
  @Input() isModal = false
  @Input() handleNavigationClick = true
  @Input() needsSafeAreaTopInset = false

  //menu disabled in agb & privacy because of ionic router, delete condition with #81044
  @Input() disablePrimaryMenu?: boolean
  @Input() webCollapsableEnabled?: boolean
  @Input() showTitleOnWeb?: boolean

  @Output() readonly navigateClicked = new EventEmitter<void>()

  public hasMobileScreenSize = true

  public showContextMenuButton = true

  constructor(
    private readonly navCtrl: NavController,
    private readonly modalController: ModalController
  ) {}

  ngOnInit(): void {
    // Screen width limit is from: https://github.com/ionic-team/ionic-framework/blob/main/core/src/css/core.scss
    this.hasMobileScreenSize = window.screen.width < 768

    this.showContextMenuButton = this.hasFeedback && this.isNative()
  }

  public isNative(): boolean {
    return Capacitor.isNativePlatform()
  }

  async navigate(): Promise<void> {
    this.navigateClicked.emit()
    if (!this.handleNavigationClick) {
      return
    }

    if (this.navLink) {
      await this.navCtrl.navigateBack(this.navLink, this.backNavOptions)
    } else {
      this.navCtrl.back()
    }
  }

  dismiss(): void {
    void this.modalController.dismiss()
  }
}
