import { AppSettingsModel, Country } from '@efp/api'

export abstract class AppSettingDao {
  abstract updateLanguage(newLang: string): Promise<void>

  abstract updateCountry(newCountry: Country): Promise<void>

  abstract getAppSettings(): Promise<Readonly<AppSettingsModel>>

  abstract findLanguage(): Promise<string>
}
