import { Component, Input } from '@angular/core'
import { ArticleForList } from '../article-list/article-list.component'
import { CYCLE_COLOR_MAPPING } from '../../../constants/colors'

@Component({
  selector: 'efp-article-cycle-quantities',
  templateUrl: 'article-cycle-quantities.component.html',
  styleUrls: ['article-cycle-quantities.component.scss'],
})
export class ArticleCycleQuantitiesComponent {
  @Input() article!: ArticleForList

  get sortedCycleNumbers(): number[] {
    return this.article.usedCycleNumbers
      ? Array.from(this.article.usedCycleNumbers.values()).sort((a, b) => a - b)
      : []
  }

  getCycleColor(cycleNumber: number): string {
    return CYCLE_COLOR_MAPPING[cycleNumber].toCSS(true)
  }
}
