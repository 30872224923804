import paper from 'paper/dist/paper-core'
import { SNAPPING_DISTANCE, SNAPPING_ERROR_TOLERANCE } from '../../utils'
import { snapToEdge } from '../../utils/snapping/snapToEdge'
import { Edge } from '../edge/Edge'
import { MeshSnapResult, SnapEdgeInfo } from '../snapping/SnapEdgeInfo'
import { MeshPoint } from './MeshPoint'

export abstract class Mesh {
  public points: Set<MeshPoint>

  public constructor() {
    this.points = new Set()
  }

  public clear(): void {
    this.points = new Set()
  }

  public abstract removeEdge(edge: Edge, skipPreserving: boolean): boolean

  public snapPointToMeshPoints(
    point: paper.Point,
    tolerance: number = SNAPPING_DISTANCE
  ): MeshPoint | undefined {
    let bestPoint: MeshPoint | undefined
    let bestDistance = Infinity

    this.points.forEach((otherPoint) => {
      if (point === otherPoint && otherPoint.edges.size === 0) {
        return
      }

      const distance = point.getDistance(otherPoint)
      if (distance < bestDistance) {
        bestPoint = otherPoint
        bestDistance = distance
      }
    })

    if (bestPoint) {
      if (bestDistance <= tolerance) {
        return bestPoint
      }
    }
    return undefined
  }

  public snapPointToEdge(point: paper.Point, edges?: Edge[]): SnapEdgeInfo | undefined {
    return snapToEdge(point, edges ? new Set(edges) : this.getAllEdges(), false)
  }

  public snapPointToEdgeOrPoint(point: paper.Point, edges?: Edge[]): MeshSnapResult | undefined {
    return this.snapPointToMeshPoints(point) ?? this.snapPointToEdge(point, edges)
  }

  public findClosestEdge(point: paper.Point): Edge | undefined {
    return snapToEdge(point, this.getAllEdges(), true)?.edge
  }

  /**
   * Generate a path to render this mesh.
   */
  public abstract generatePath(): paper.CompoundPath

  /**
   * Recalculates edge boundaries and lengths, checks for validation errors and returns the number of invalid edges.
   */
  public abstract updateEdgeValidity(): number

  public setEdge(
    startPoint: MeshPoint,
    endPoint: MeshPoint,
    thickness: number,
    intoEdge?: Edge
  ): Edge | undefined {
    if (startPoint.isClose(endPoint, SNAPPING_ERROR_TOLERANCE)) {
      return undefined
    }

    for (const edge1 of Array.from(startPoint.edges)) {
      for (const edge2 of Array.from(endPoint.edges)) {
        if (edge1 === edge2) {
          return edge1
        }
      }
    }

    let edge: Edge
    if (intoEdge) {
      edge = intoEdge
      if (intoEdge.startPoint.edges.size === 1) {
        this.points.delete(intoEdge.startPoint)
      }
      if (intoEdge.endPoint.edges.size === 1) {
        this.points.delete(intoEdge.endPoint)
      }
      intoEdge.startPoint = startPoint
      intoEdge.endPoint = endPoint
    } else {
      edge = new Edge(startPoint, endPoint, thickness)
    }

    this.points.add(startPoint)
    this.points.add(endPoint)

    startPoint.edges.add(edge)
    endPoint.edges.add(edge)

    return edge
  }

  public getAllEdges(): Set<Edge> {
    const allEdges = new Set<Edge>()
    this.points.forEach((point) => {
      point.edges.forEach((edge) => allEdges.add(edge))
    })
    return allEdges
  }
}
